import React, {useEffect, useState} from "react";
import {useDatasets} from "../datasets/Datasets";
import {useTests} from "../aitests/Test";
import Webhooks from "../webhooks/Webhooks";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RightIcon from '@mui/icons-material/ArrowRightAlt';
import Typography from "@mui/material/Typography";
import SectionHeading from "../util/SectionHeading";
import AttackCraftingTool from "./AttackCraftingTool";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import {usePositiveNumberInput} from "../util/util";
import {Autocomplete, Pagination, Stack} from "@mui/material";
import Paper from "../util/Paper";
import AttackDefenseSelector from "../aitests/AttackDefenseSelector";
import Wizard, {useWizard} from "../aitests/Wizard";

const NumField = styled(TextField)(({_theme}) => ({
    width: '15ch',
}));

const convServerToFERegion = (region) => {
    return {color: "#BB2222", id: 0, start: {x: region.x1, y: region.y1}, end: {x: region.x2, y: region.y2}};
}

const convFEToServerRegion = (region) => {
    return {x1: region.start.x, y1: region.start.y, x2: region.end.x, y2: region.end.y};
}

const AttackConfigStep = ({assets, project, test, dataset, toClass, setToClass, fromClass, setFromClass}) => {

    const [classNames, setClassNames] = useState([]);
    const {getDatasetClasses} = useDatasets();

    useEffect(() => {
        getDatasetClasses(project.organization.id, dataset.id).then(classes => {
            setClassNames(classes);
        });
    },[project.organization.id, dataset.id]);

    const onToClassChanged = (_e, value) => {
        setToClass(value);
    }

    const onFromClassChanged = (_e, value) => {
        setFromClass(value);
    }

    return <><Grid item spacing={1} container justifyContent="center" mt={2}>
        <Grid item xs={12}>
            <Paper variant="outlined">
                <SectionHeading>
                    Define the targeted attack parameters
                </SectionHeading>
                <Grid spacing={2} container justifyContent="center">
                    <Grid item>
                        <Paper variant="outlined">
                            <SectionHeading>
                                Attack Class
                            </SectionHeading>
                            <Stack direction="row" spacing={1}>
                            <Autocomplete
                                options={classNames}
                                value={fromClass}
                                onChange={onFromClassChanged}
                                sx={{ width: 200 }}
                                renderInput={(params) =>
                                    <TextField {...params} label="From class"/>}
                            />
                                <RightIcon mt={2}/>
                            <Autocomplete
                                options={classNames}
                                value={toClass}
                                onChange={onToClassChanged}
                                sx={{ width: 200 }}
                                renderInput={(params) =>
                                    <TextField {...params} label="To class"/>}
                            />
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} m={1} mt={2}>
                    <AttackDefenseSelector test={test}
                                           tasks={project.settings.tasks}
                                           assets={assets}
                    />
                </Grid>
            </Paper>
        </Grid>
    </Grid>
    </>
}

const AttackCraftingWizard = ({step = 0, project, test, settings = test.testSettings,
                                  testUpdated,
                                  assets, onFinish}) => {

    const [activeStep, setActiveStep, handleBack] = useWizard(step);

    const [dataset, setDataset] = useState(null);

    // ui is 1 based, backend 0
    const [idxProps, setValue] = usePositiveNumberInput(settings.craftedSettings.itemIdx+1, null, 1);

    const [completed] = useState([settings.configured, settings.configured, settings.configured]);
    const [toClass, setToClass] = useState("");
    const [fromClass, setFromClass] = useState("");

    const {updateTest} = useTests();
    const {getDataset, getDatasetItem} = useDatasets();

    const steps = ['Select Image', 'Attack Configuration', 'Integration'];
    const [imageUrl, setImageUrl] = useState(null);
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        getDataset(project.organization.id, project.settings.datasetId).then((ds) => {
            setDataset(ds);
            if (settings.craftedSettings.regions.length > 0) {
                setRegions([convServerToFERegion(settings.craftedSettings.regions[0])]);
            } else {
                setRegions([]);
            }
            setFromClass(settings.craftedSettings.fromClass);
            setToClass(settings.craftedSettings.toClass);
        }).finally();
    },[settings, project.organization.id, project.settings.datasetId]);

    useEffect(() => {
        if (dataset != null) {
            getDatasetItem(dataset.id, idxProps.value-1).then((blob) => {
                setImageUrl(URL.createObjectURL(blob));
            }).finally();
        }
    },[dataset, idxProps.value]);

    const saveSettings = async (s) => {
        try {
            let t = await updateTest(test.id, {testSettings: s});
            testUpdated(t);
            return t;
        } catch (_e) {
            // messaged in updateTest
        }
    }

    const handleItemChange = (_evt, valueIn) => {
        setValue(valueIn);
    }

    function detectionTestConfigStep() {
        return <>
            {dataset != null &&
                <Grid item spacing={1} container justifyContent="center" mt={2}>
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <SectionHeading>
                                Dataset sample image and region
                            </SectionHeading>
                            <Typography variant="body1" mt={1} mb={2} color="textSecondary">
                                Select a sample image and optional region to attack. Drag on the image to define a region.
                            </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center">
                                <Pagination showFirstButton showLastButton size="medium" count={dataset.datasetLength}
                                        page={idxProps.value}
                                        siblingCount={2} boundaryCount={2}
                                        onChange={handleItemChange}/>
                            <NumField {...idxProps}
                                      size="small"
                                      label="Image Index"
                                      type="number"
                                      variant="outlined"
                            />
                            </Stack>
                            <AttackCraftingTool imageUrl={imageUrl} regions={regions} setRegions={setRegions}/>
                        </Paper>
                    </Grid>
                </Grid>
            }
        </>
    }

    function isStepComplete(stp) {
        return completed[stp];
    }

    function getStepContent(stp) {
        switch (stp) {
            case 0:
                return detectionTestConfigStep();
            case 1:
                return <AttackConfigStep assets={assets} test={test} dataset={dataset} project={project}
                                         fromClass={fromClass} setFromClass={setFromClass}
                                         toClass={toClass} setToClass={setToClass}/>;
            case 2:
                return <Box mt={2}><Webhooks test={test}/></Box>;
            default:
                return 'Unknown step';
        }
    }

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            if (!settings.configured) {
                settings.configured = true;
                let t = await saveSettings(settings);
                if (t == null) {
                    return
                }
            }
            onFinish();
        } else {
            settings.craftedSettings.itemIdx = idxProps.value-1;
            if (regions.length > 0) {
                settings.craftedSettings.regions = [convFEToServerRegion(regions[0])];
            }
            settings.craftedSettings.toClass = toClass;
            settings.craftedSettings.fromClass = fromClass;
            let t = await saveSettings(settings);
            if (t != null) {
                completed[activeStep] = true;
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    }

    const handleStep = (idx) => () => {
        if (idx > 0) {
            if (completed[idx-1])
                setActiveStep(idx);
        } else {
            setActiveStep(idx);
        }
    };

    return <Wizard steps={steps}
                   activeStep={activeStep}
                   handleStep={handleStep}
                   isStepComplete={isStepComplete}
                   handleBack={handleBack}
                   handleNext={handleNext}
                   getStepContent={getStepContent}
    />
}

export default AttackCraftingWizard;

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useSnackbar} from "notistack";
import ParameterEntry from "../ParameterEntry";
import {errOptions, validateAllParams} from "../util/util";
import {isParamDisplayed} from "../ParamEntryList"

function groupByAdvanced(params) {
    let adv = [];
    let normal = [];
    for (let idx = 0; idx < params.length; idx++) {
        let param = params[idx];
        param.idx = idx; // store original idx
        if (param.advanced) {
            adv.push(param);
        } else {
            normal.push(param);
        }
    }
    return [adv, normal];
}


/**
 * Displays a dialog that allows the user to adjust asset parameters
 * @author Kobus Grobler
 * @component
 */
export default function TuneParamsDialog({paramDef, item, classMappingFiles, onClose, onFileUpload, open}) {
    const [values, setValues] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const [advanced, setAdvanced] = useState(null);
    const [normal, setNormal] = useState(null);
    const [openAdv, setOpenAdv] = useState(false);

    useEffect(() => {
        if (open && paramDef != null && paramDef.params !== null) {
            let values = new Array(paramDef.params.length).fill("");
            values.fill("");
            if (item != null) {
                let paramString = item.parameters;
                if (paramString == null)
                    paramString = item.defenseParameters;
                if (paramString != null) {
                    let params = JSON.parse(paramString);
                    Object.keys(params).map(function (key) {
                        for (let i = 0; i< paramDef.params.length; i++) {
                            if (paramDef.params[i].name === key) {
                                // found the index of the param in the param definition.
                                values[i] = params[key];
                            }
                        }
                        return key;
                    });
                }
            }
            setValues(values);
            const [adv, normParams] = groupByAdvanced(paramDef.params);
            setAdvanced(adv);
            setNormal(normParams);
        }
    }, [paramDef, item, open]);

    const handleClose = () => {
        const [msg, index] = validateAllParams(paramDef.params, values);
        if (msg !== "") {
            enqueueSnackbar('Invalid value for '+paramDef.params[index].name+': ' + msg,
                errOptions);
            return
        }
        onClose(values);
    };

    const handleCancel = () => {
        onClose(null);
    };

    function handleChange(idx, val) {
        values[idx] = val;
        setValues(values);
    }

    function getParamEntry(param) {
        if (isParamDisplayed(param)) {
            return <ListItem key={param.idx}>
                <ParameterEntry param={param} index={param.idx}
                                value={values[param.idx]}
                                onChange={handleChange}
                                fileList={classMappingFiles}
                                onFileUpload={onFileUpload}/>
            </ListItem>
        } else {
            return undefined;
        }
    }

    return (
        <Dialog fullWidth={true} onClose={handleClose} open={open}>
            <DialogTitle>Adjust parameters</DialogTitle>
            <DialogContent>
                <List>
                    {values != null && normal != null && normal.map((param) => getParamEntry(param))}
                    {values != null && advanced != null && advanced.length > 0 &&
                        <>
                            <IconButton aria-label="expand advanced"
                                        size="small"
                                        onClick={() => setOpenAdv(!openAdv)}>
                                {openAdv ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                Advanced Parameters
                            </IconButton>
                            <Collapse in={openAdv} timeout="auto" unmountOnExit>
                        {advanced.map((param) => getParamEntry(param))}
                        </Collapse>
                        </>
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

TuneParamsDialog.propTypes = {
    /**
     * Called when the dialog closes
     */
    onClose: PropTypes.func.isRequired,
    /**
     * State of the dialog
     */
    open: PropTypes.bool.isRequired,
    /**
     * The asset definition
     */
    paramDef: PropTypes.object.isRequired,
    /**
     * The asset instance
     */
    item: PropTypes.object,
    /**
     * Called when a file is uploaded via drag & drop
     */
    onFileUpload: PropTypes.func,

    classMappingFiles: PropTypes.array
};

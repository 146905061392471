/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import {getLogger} from "../util/util";
import Paper from "../util/Paper";
import SectionHeading from "../util/SectionHeading";

const log = getLogger("pfcriteria");

function Criteria({criteria, onChanged}) {
    const [value, setValue] = useState(criteria.passVal == null ? "" : criteria.passVal);
    const [direction, setDirection] = useState(criteria.above ? "true" : "false");

    const toggle = () => {
        criteria.enabled = !criteria.enabled;
        onChanged(criteria);
    }

    const onChange = (e) => {
        let newVal = parseFloat(e.target.value);
        if ((!isNaN(newVal) && newVal >= 0) || e.target.value === "") {
            setValue(e.target.value);
        }
    }

    const handleBlur = (e) => {
        let newVal = parseFloat(e.target.value);
        if (criteria.passVal === newVal)
            return;
        criteria.passVal = e.target.value;
        onChanged(criteria);
    }

    const keyPress = (e) => {
        e.stopPropagation();
        if (e.keyCode === 13) {
            let newVal = parseFloat(value);
            if (criteria.passVal === newVal)
                return;
            onChanged(criteria);
        }
    }

    return <><span>
            <Switch name={criteria.metricName}
                    color="primary"
                    onChange={toggle}
                    checked={criteria.enabled}
                    sx={{marginTop:2.5}}
            />
            <TextField sx={{margin: 1, minWidth: '28ch'}}
                       label={criteria.metricName + " sample threshold"}
                       value={value}
                       type="number"
                       onBlur={e => handleBlur(e)}
                       onKeyDown={keyPress}
                       onChange={onChange}/>

            <FormControl sx={{margin: 1}}>
            <InputLabel htmlFor="criteria-direction">Direction</InputLabel>
            <Select sx={{marginTop: 1}}
                    native
                    value={direction}
                    onChange={(e) => {
                        setDirection(e.target.value);
                        criteria.above = e.target.value === "true";
                        onChanged(criteria);
                    }}
                    inputProps={{
                        name: 'direction',
                        id: 'criteria-direction',
                    }}
            >
              <option value={"true"}>Above</option>
              <option value={"false"}>Below</option>
            </Select>
          </FormControl>
        </span>
    </>
}

function PassPercentage({settings, onChanged}) {
    const [value, setValue] = useState(settings.passPercentage == null ? "0.8" : settings.passPercentage);

    const onChange = (e) => {
        let newVal = parseFloat(e.target.value);
        if ((!isNaN(newVal) && newVal >= 0 && newVal <= 1) || e.target.value === "") {
            setValue(e.target.value);
        }
    }

    const handleBlur = (e) => {
        let newVal = parseFloat(e.target.value);
        if (settings.passPercentage === newVal)
            return;
        settings.passPercentage = e.target.value;
        onChanged();
    }

    const keyPress = (e) => {
        e.stopPropagation();
        if (e.keyCode === 13) {
            let newVal = parseFloat(value);
            if (settings.passPercentage === newVal)
                return;
            onChanged();
        }
    }

    return <><span>
        <Tooltip title="Specify the total fraction of samples satisfying the criteria below in order to pass the test.">
            <TextField sx={{margin: 1, minWidth: '28ch'}}
                       label="Fraction of passing samples"
                       value={value}
                       type="number"
                       onBlur={e => handleBlur(e)}
                       onKeyDown={keyPress}
                       onChange={onChange}/>
        </Tooltip>
        </span>
    </>
}

PassPercentage.propTypes = {
    onChanged: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired
}

export default function PassFailCriteria({task, settings, saveSettings, metricDefs}) {

    useEffect(() => {
        if (settings.metrics != null && settings.metrics[task] != null) {
            if (settings.passFailCriteria == null) {
                settings.passFailCriteria = [];
            }
            settings.passFailCriteria = settings.metrics[task].reduce((acc, m) => {
                let mdef = metricDefs.find(md => md.name === m.name);
                if (mdef != null && mdef.metric_type == null) { // only enable pf for performance metrics
                    Object.keys(m.params).forEach(key => {
                        let p = m.params[key];
                        if (m.enabled && (typeof p === 'boolean') && p) { // for enabled "Report" group type metric defs
                            if (!acc.find(e => e.metricName === key)) // see if report metric key is in pf
                                acc.push({metricName: key, enabled: false, above: true}); // no? add new pf criteria for metric
                        } else { // filter if not enabled
                            acc = acc.filter(e => e.metricName !== key);
                        }
                    });
                    // now, see if there are criteria that are no longer in metric def - they should be removed as well.
                    acc = acc.filter(crit => Object.keys(m.params).includes(crit.metricName));
                }
                return acc;
            }, settings.passFailCriteria);
        } else {
            log.debug("metrics is null");
        }
    }, [task, settings, metricDefs, settings.metrics]);

    const handleChanged = (criteria) => {
        settings.passFailCriteria[settings.passFailCriteria.findIndex(c => c.metricName === criteria.metricName)] = criteria;
        saveSettings(settings);
    }

    return <>
        <Paper variant="outlined">
            <Grid container justifyContent={"center"}>
                <Grid item>
                    <SectionHeading>Test Pass/Fail criteria</SectionHeading>
                    <PassPercentage settings={settings} onChanged={() => saveSettings(settings)}/>
                </Grid>
                <Grid item xs={12} container alignItems="flex-start">
                    {settings.passFailCriteria.map(criteria =>
                        <Grid item key={criteria.metricName}>
                            <Criteria criteria={criteria}
                                      saveSettings={saveSettings}
                                      onChanged={handleChanged}/>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Paper>
    </>
}

export {PassPercentage}
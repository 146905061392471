/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext} from 'react';
import {
    useParams
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CopyIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import AlertDialog from "../dialogs/AlertDialog";
import NameEditor from "../util/NameEditor";
import {errOptions, isAdminUser, paramSummary, shared} from "../util/util";
import AppContext from "../AppContext";
import SelectListDialog from "../dialogs/SelectListDialog";
import {useLoadOrgs, useLoadResources} from "../organizations/Organization";
import {useProfiles} from "./TestProfiles";
import {getAttackHelpLink} from "../aitests/AttackDefenseSelector";
import DeleteIcon from "../util/TrashIcon"
import TestSetupWizard from "../aitests/TestSetupWizard";
import Paper from "../util/Paper"
import {getLogger} from "../util/util";

const log = getLogger("testprofile");

function TestProfileSummary({resources, settings, defenses = [], tasks = []}) {

    const getMetrics = (task) => {
        let metrics = [];
        // metrics settings section is a map of tasks containing and array of metrics
        if (settings.metrics != null) {
            let metricList = settings.metrics[task];
            if (metricList) {
                return metricList;
            } else {
                let mdefs = resources.availableMetrics.filter(def => def.tasks.find(t => t === task));
                if (mdefs.length > 0) {
                    mdefs.forEach(def =>
                        metrics.push({enabled: true, name: def.name}));
                }
            }
        }
        return metrics;
    }

    const getPFString = (metricName) => {
        let crit = settings.passFailCriteria.find(pf => pf.enabled && pf.metricName === metricName);
        if (crit != null) {
            return metricName + " " + (crit.above ? "above " : "below ") + crit.passVal;
        } else {
            return metricName;
        }
    }

    const getEnabledPerfMetrics = (metric) => {
        let r = "";
        if (metric.params != null) {
            const keys = Object.keys(metric.params).filter(n => !n.startsWith('_'));
            if (keys.length > 0) {
                r = " -"
                keys.forEach(key => {
                    if (metric.params[key]) {
                        if (r === " -") {
                            r += " ";
                        } else {
                            r += ", "
                        }
                        r += getPFString(key);
                    }
                });
            }
        }
        return r;
    }

    const getMetricHelpLink = (_resources, metric, onlyUrl=false) => {
        let def = _resources.availableMetrics.find(_def => _def.name === metric.name)
        let url;
        if (def != null) {
            url = process.env.REACT_APP_DOCS_BASE + "modules/reports/metrics/"
            if (def.metric_type == null)
                url += def.tasks[0]+".html";
            else if (def.metric_type === 'input')
                url += "distortion.html";
            else
                url += "index.html";
        } else {
            if (onlyUrl) {
                return "";
            }
            return <span>{metric.name}</span>;
        }
        if (onlyUrl)
            return url;
        return <a href={url} target='_blank'>{metric.name}</a>;
    }

    return <>
        <Paper variant="outlined">
            {defenses != null && defenses.map((d, didx) => <React.Fragment key={didx}>
                <Typography variant="h6">
                    Defense: {d.name}
                </Typography>
                <div style={{marginLeft: "1em"}}>
                    {d.filters.length > 0 &&
                        <Typography variant="h6">
                            Attacks
                        </Typography>
                    }
                    {d.filters.map((f, idx) => <React.Fragment key={idx}>
                            {f.enabled &&
                                <Typography variant="body1">
                                    &bull; {getAttackHelpLink(resources, f)}, parameters: {paramSummary(JSON.parse(f.parameters))}
                                </Typography>
                            }
                        </React.Fragment>
                    )}
                </div>
                </React.Fragment>
            )}
            {settings.metrics != null &&
                <>
                    <Typography variant="h6">
                        Analysis
                    </Typography>
                    {tasks.map((task, t_idx) =>
                        <React.Fragment key={t_idx}>
                            {getMetrics(task).length > 0 &&
                            <Typography variant="body1">
                                Task: {task[0].toUpperCase() + task.slice(1)}
                            </Typography>
                            }
                            {getMetrics(task).map((m, idx) => <React.Fragment key={idx}>
                                {m.enabled &&
                                    <Typography variant="body1">
                                        &bull; {getMetricHelpLink(resources, m)} {getEnabledPerfMetrics(m)}
                                    </Typography>
                                }
                            </React.Fragment>)}
                        </React.Fragment>
                    )}
                </>
            }
        </Paper>
    </>
}

TestProfileSummary.propTypes = {
    settings: PropTypes.object.isRequired,
    defenses: PropTypes.array,
    tasks: PropTypes.array,
    resources: PropTypes.object.isRequired
};

/**
 * Editor view of a test profile
 * @author Kobus Grobler
 * @component
 */
export default function TestProfile() {
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [showWizard, setShowWizard] = useState(false);
    const [openOrgDlg, setOpenOrgDlg] = useState(false);
    const orgs = useLoadOrgs();
    const {getProfile, copyProfile, updateProfile, deleteProfile, addFilter, addDefense, removeDefense} = useProfiles();
    const [resources, loadResources] = useLoadResources();
    const {user} = useContext(AppContext);
    let {profileId} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState("");

    useEffect(() => {
        (async () => {
            setProfile(await getProfile(profileId));
        })();
    }, [getProfile, profileId]);

    useEffect(() => {
        if (profile != null) {
            loadResources(profile.organization.id).finally();
        }
    }, [profile, loadResources]);

    const onUpdateProfileName = (id, value) => {
        if (value.length > 0) {
            profile.name = value;
            updateProfile(profileId, profile).finally();
        } else {
            setError("Invalid profile name");
        }
    }

    const onUpdateDescription = (id, value) => {
        profile.description = value;
        updateProfile(profileId, profile).finally();
    }

    const deleteProfileClosed = (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            deleteProfile(profileId).finally();
        }
    }

    const handleTaskChange = (e) => {
        const idx = profile.settings.tasks.indexOf(e.target.name);
        if (idx > -1) {
            if (profile.settings.tasks.length === 1) {
                enqueueSnackbar('At least one task needs to be enabled.',
                    errOptions);
                return;
            }
            profile.settings.tasks.splice(idx, 1);
        } else {
            profile.settings.tasks.push(e.target.name);
        }
        updateProfile(profileId, profile).then(p => p != null ? setProfile(p) : log.debug("No profile returned."));
    }

    const handleCloseOrgDlg = async (orgIn) => {
        setOpenOrgDlg(false);
        if (orgIn != null) {
            await copyProfile(profileId, orgIn.id);
        }
    }

    const onFinish = async () => {
        setShowWizard(false);
    }

    const onSaveSettings = async (s) => {
        setProfile(await updateProfile(profile.id, {settings: s}));
    }

    const onUpdateTest = async (updateRequest) => {
        const found = profile.settings.defenses.findIndex(d => d.id === updateRequest.defense.id);
        if (found > -1) {
            profile.settings.defenses[found] = updateRequest.defense;
            setProfile(await updateProfile(profile.id, {settings: profile.settings}));
        }
    }

    const onAttackAdded = async (filter) => {
        let p = await addFilter(profile.id, filter);
        if (p != null)
            setProfile(p);
    }

    const onRemoveFilter = async (filter) => {
        profile.settings.defenses.forEach(d => {
            d.filters = d.filters.filter(f => f.id !== filter.id);
        });
        setProfile(await updateProfile(profile.id, {settings: profile.settings}));
    }

    const onAddDefense = async (rq, _testIn) => {
        let r = await addDefense(profile.id, rq);
        if (r != null) {
            setProfile(r);
        }
    }

    const onRemoveDefense = async (defense, _testIn) => {
        let r = await removeDefense(profile.id, defense.id);
        if (r != null) {
            setProfile(r);
        }
    }

    const testUpdated = (_t) => {
        // NOP
    }

    return (
        <Grid item xs={12} md={10} lg={8} xl={6} container justifyContent="center" sx={{marginTop: 2}}>
            <AlertDialog open={openConfirmDelete} title="Delete profile?"
                         description="This will permanently delete the profile."
                         onClose={deleteProfileClosed}/>
            <SelectListDialog open={openOrgDlg}
                              onClose={handleCloseOrgDlg}
                              list={orgs}
                              title="Select destination organisation"/>

            <Paper>
            <Grid item xs={12} container spacing={2} sx={{marginTop:1}}>
                {profile != null &&
                    <>
                        <Grid item xs={8}>
                            <NameEditor
                                fullWidth
                                hiddenLabel={false}
                                label="Profile Name"
                                name={profile.name}
                                onUpdateName={onUpdateProfileName}/>
                        </Grid>
                        {showWizard ?
                            <>
                                <Grid item xs={12}>
                                    <TestSetupWizard assets={resources}
                                                     settings={profile.settings}
                                                     tasks={profile.settings.tasks}
                                                     onRemoveFilter={onRemoveFilter}
                                                     onAttackAdded={onAttackAdded}
                                                     onUpdateTest={onUpdateTest}
                                                     onAddDefense={onAddDefense}
                                                     onRemoveDefense={onRemoveDefense}
                                                     testUpdated={testUpdated}
                                                     onSaveSettings={onSaveSettings}
                                                     onFinish={onFinish}
                                                     test={{id: 0, defenses: profile.settings.defenses}}
                                    />
                                </Grid>
                            </>
                            :
                            <>
                            <Grid item xs={9}>
                                <NameEditor variant="outlined"
                                            multiline
                                            fullWidth
                                            maxRows={4}
                                            minRows={4}
                                            fieldName="Description"
                                            label="Description"
                                            name={profile.description == null ? "" : profile.description}
                                            onUpdateName={onUpdateDescription}/>
                            </Grid>
                            <Grid item xs={9} sx={{marginLeft: 1}}>
                                <TestProfileSummary resources={resources}
                                                    tasks={profile.settings.tasks}
                                                    settings={profile.settings}
                                                    defenses={profile.settings.defenses}/>
                            </Grid>
                            </>
                        }
                        <Grid item xs={10} sx={{marginLeft: 1}}>
                            <Typography variant="subtitle1">
                                Enabled for:
                            </Typography>
                            {shared.TaskEnum.values().map((task, idx) =>
                                <FormControlLabel key={idx}
                                                  control={
                                                      <Checkbox
                                                          color="primary"
                                                          checked={profile.settings.tasks.indexOf(task.name) > -1}
                                                          onChange={handleTaskChange}
                                                          name={task.name}
                                                      />
                                                  }
                                                  label={task.displayName()}
                                />)
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {isAdminUser(user) &&
                                <Tooltip title="Share profile">
                                    <IconButton
                                        onClick={() => {
                                            setOpenOrgDlg(true);
                                        }}
                                        size="large">
                                        <CopyIcon/>
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="Edit profile">
                                <IconButton
                                    size="small"
                                    onClick={() => setShowWizard(true)}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete profile">
                                <IconButton
                                    size="small"
                                    onClick={() => setOpenConfirmDelete(true)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                            {error.length > 0 &&
                                <Grid item container justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="body1" color="error">
                                        {error}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </>
                }
            </Grid>
            </Paper>
        </Grid>
    );
}

TestProfile.propTypes = {};

export {TestProfileSummary}

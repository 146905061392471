import {useContext, useCallback} from "react";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";

import AppContext from "../AppContext";
import {PlatformApi} from "../api";
import {getLogger, checkError, errOptions} from "../util/util";

const log = getLogger("platform");

const usePlatform = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);

    const getEnvironments = useCallback(async () => {
        const api = new PlatformApi(conf);
        try {
            log.debug("Retrieving environments.")
            const r = await api.getEnvironments();
            log.debug(`Retrieved ${r.data.length} environments.`);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get environments'+msg, errOptions));
        }
    },[conf, enqueueSnackbar, history]);

    const getQueueInfo = useCallback(async () => {
        const api = new PlatformApi(conf);
        try {
            const r = await api.getQueueInfo();
            return r.data;
        } catch (e) {
            checkError(e, history);
        }
    },[conf, history]);

    return {getEnvironments, getQueueInfo};
}

export {usePlatform}

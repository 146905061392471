/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useContext, useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Switch from '@mui/material/Switch';
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddKeyIcon from "@mui/icons-material/VpnKey";
import AppContext from "../AppContext";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import {KeysApi} from "../api";
import {checkError, errOptions, getLogger, javaDateToTimeStr, milliesToTimeStr} from "../util/util";
import {useUsers} from "./Users";
import DeleteIcon from "../util/TrashIcon"

const log = getLogger("profile");

const useUpdateProfile = () => {
    const { setUser } = useContext(AppContext);
    const [settings, setSettings] = useState(null);
    const {updateProfile} = useUsers();

    useEffect( () => {
        if (settings != null) {
            (async () => {
                let u = await updateProfile({jsonSettings: JSON.stringify(settings)});
                if (u != null) {
                    log.debug("Updating user context.");
                    setUser(u);
                }
            })();
        }
    },[settings, setUser, updateProfile]);

    return [{setSettings}];
}

const isProjIdInFavourites = (user, projId) => {
    return !!(user.settings.favoriteProjects != null && user.settings.favoriteProjects.find(p => p === projId));
}

const addProjIdToFavourites = (user, projId) => {
    if (user.settings.favoriteProjects == null) {
        user.settings.favoriteProjects = [];
    }

    if (!isProjIdInFavourites(user, projId)) {
        user.settings.favoriteProjects.push(projId);
    }
}

const removeProjIdFromFavourites = (user, projId) => {
    if (user.settings.favoriteProjects == null) {
        return;
    }
    user.settings.favoriteProjects = user.settings.favoriteProjects.filter(p => p !== projId);
}


/**
 * This component allows the user to manage his profile.
 *
 * @author Kobus Grobler
 * @component
 */
export default function Profile() {
    const { conf, user } = useContext(AppContext);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const [keys, setKeys] = useState([]);
    const [openShowKey, setOpenShowKey] = useState(false);

    const [{setSettings}] = useUpdateProfile();

    useEffect(
        function getKeys() {
            const keysApi = new KeysApi(conf);
            keysApi.getKeys()
                .then((list) => {
                    setKeys(list.data);
                })
                .catch((e) => checkError(e, history, () =>
                    enqueueSnackbar('Failed to get keys', errOptions)));
        }, [conf, enqueueSnackbar, history]
    );

    const handleNotificationChange = async () => {
        user.settings.notifications = !user.settings.notifications;
        setSettings(user.settings);
    }

    const deleteKey = (id) => {
        const keysApi = new KeysApi(conf);
        keysApi.deleteKey(id)
            .then( () =>
                setKeys(keys.filter( k => k.id !== id))
            )
            .catch((e) => checkError(e, history, () => {
                enqueueSnackbar('Failed to delete API key', errOptions);
            }));
    }

    const createKey = () => {
        const keysApi = new KeysApi(conf);
        keysApi.createKey()
            .then( (key) => {
                keys.push(key.data);
                setKeys(keys);
                setOpenShowKey(true);
            })
            .catch((e) => checkError(e, history, () => {
                enqueueSnackbar('Failed to create API key', errOptions);
            }));
    }

    return (
        <Grid item xs={12} lg={4}>
                {openShowKey &&
                <OkCancelDialog onClose={() => setOpenShowKey(false)}
                                dialogProps={{ maxWidth: 'md'}}
                                open={openShowKey}
                                title="API Key"
                                hideCancel={true}>
                    The API key will not be shown again - please copy it now<br/>
                    Key ID: <div><b>{keys[keys.length-1].keyId}</b></div>
                    Key: <div><b>{keys[keys.length-1].key}</b></div>
                </OkCancelDialog>
                }
                <Card sx={{width: '100%', bgcolor: 'background.paper'}}>
                    <CardHeader
                        avatar={
                            <Avatar sx={{width: (theme) => theme.spacing(7), height: (theme) => theme.spacing(7)}}
                                    aria-label="user"
                                    alt={user.name}
                                    src={'data:image/png;base64,'+user.avatar}>
                                {user.name.charAt(0)}
                            </Avatar>
                        }
                        title={user.name}
                        subheader={user.email}
                    />
                    <CardContent>
                        <Typography variant="body2" component="span">
                            <div>Last log-in: {javaDateToTimeStr(user.lastLoginDateTime)}</div>
                        </Typography>
                        <Typography variant="body2" component="span">
                            <div>Processing time used: {milliesToTimeStr(user.timeUsed)}</div>
                        </Typography>
                        <Typography variant="body2" component="p">
                            <Switch color="primary" checked={user.settings.notifications}
                                      onChange={handleNotificationChange}
                                      name="notifications"
                            /> Send email notifications
                        </Typography>
                        {keys != null && keys.length > 0 &&
                        <Typography variant="body2" component="div">
                            Generated API Keys
                            <ul>
                                {keys.map((key) =>
                                    <li key={key.id}>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                deleteKey(key.id)
                                            }}>
                                            <DeleteIcon/>
                                        </IconButton>
                                        Key ID: <b>{key.keyId}</b><br/>
                                        Key creation date: <b>{javaDateToTimeStr(key.creationDate)}</b>
                                    </li>
                                )}
                            </ul>
                        </Typography>
                        }
                    </CardContent>
                    <CardActions disableSpacing>
                        <Tooltip title="Create API key" aria-label="Create API key">
                            <IconButton onClick={createKey} size="large">
                                <AddKeyIcon/></IconButton>
                        </Tooltip>
                    </CardActions>
                </Card>
            </Grid>
    );
}

Profile.propTypes = {

};

export {useUpdateProfile, addProjIdToFavourites, isProjIdInFavourites, removeProjIdFromFavourites};

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import BackIcon from '@mui/icons-material/ArrowBack';
import {SubmitButton} from "./SubmitButton";

const BackButton = ({onClick}) => {
  return <SubmitButton onClick={onClick}
                       variant="standard"
                       startIcon={<BackIcon/>}>
      Back
  </SubmitButton>
}

export {BackButton}

/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Accordion} from "@mui/material";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {NumField, usePositiveNumberInput, getLogger, CSVToArray, handleBlur, isRemoteAPISupportedByDataset} from "../util/util";
import {useDatasets} from "./Datasets";
import Paper from "../util/Paper";

const log = getLogger("datasetsettings");

function DataLoaderConfiguration({
                                     title = "Dataloader Configuration",
                                     showDatasetDetails = true,
                                     dataset,
                                     maxItemCount = 0,
                                     indexType = "ENTIRE", onIndexTypeChange,
                                     fraction = 0.1, onFractionChange,
                                     indexes = [], onIndexesChange,
                                     rangeStart = 0, onStartChange,
                                     rangeEnd = 0, onEndChange,
                                     numItems = 1, onNumItemsChange,
                                     shuffle = false, handleShuffleCb,
                                     useRemoteApi = false, handleRemoteCb,
                                     batchSize, onBatchSizeChangeCb,
                                     workers = 1, onWorkersChangeCb
                                 }) {

    const [tempFraction, setTempFraction] = useState(fraction);
    const [tempShuffle, setShuffle] = useState(shuffle);
    const [tempUseRemote, setUseRemote] = useState(useRemoteApi);
    const [rangeStartProps, setRangeStart] = usePositiveNumberInput(rangeStart);
    const [endIdxProps, setEndIdx] = usePositiveNumberInput(rangeEnd, dataset != null ? dataset.datasetLength : null);
    const [numItemsProps, setNumItems] = usePositiveNumberInput(numItems, dataset != null ? dataset.datasetLength : null);
    const [workersProps, setWorkers] = usePositiveNumberInput(workers);
    const [batchSizeProps, setBatchSize] = usePositiveNumberInput(batchSize);
    const [tempIndexes, setTempIndexes] = useState("");

    const arrToCSV = arr => {
        return arr == null ? "" : arr.reduce((prv, i) => prv + `${i},`, "");
    }

    useEffect(() => {
        setRangeStart(rangeStart);
        setEndIdx(rangeEnd);
        setNumItems(numItems);
        setTempFraction(fraction);
        setShuffle(shuffle);
        setWorkers(workers);
        setBatchSize(batchSize);
        setTempIndexes(arrToCSV(indexes));
    }, [rangeStart, rangeEnd, numItems, fraction, shuffle, workers, batchSize, indexes])

    const fractionValueText = value => {
        return `${(value * 100).toFixed(0)}%`;
    }

    const handleFractionChange = (event, value) => {
        onFractionChange(value);
    }

    const csvToIndexes = value => {
        if (value === "") {
            return [];
        }
        let arr = CSVToArray(value, ",");
        if (arr.length === 1) {
            arr = arr[0].reduce((prv, v) => {
                if (v !== '') {
                    let a = parseInt(v);
                    if (!isNaN(a)) {
                        prv.push(a);
                    } else {
                        throw new Error("Invalid CSV value.");
                    }
                }
                return prv;
            }, []);
            return arr;
        }
        throw new Error("Invalid CSV.");
    }

    const handleIndexesChange = e => {
        try {
            csvToIndexes(e.target.value);
            setTempIndexes(e.target.value);
        } catch (ex) {
            log.info(ex);
        }
    }

    const handleIndexesUpdate = value => {
        try {
            let arr = csvToIndexes(value);
            onIndexesChange(arr);
        } catch (ex) {
            log.info(ex);
        }
    }

    return <>
        {dataset != null &&
            <Grid container justifyContent="center">
                <Grid item xs={6}>
                    <Paper variant="outlined" sx={{padding: 2}}>
                        <Grid container spacing={1} justifyContent="flex-start">
                            <Grid item xs={12}>
                                <Typography variant='h6' style={{textAlign: "center"}}>
                                    {title}
                                </Typography>
                            </Grid>
                            {showDatasetDetails &&
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' style={{textAlign: "center"}}>
                                        Dataset ID: {dataset.id},
                                        {dataset.datasetLength != null &&
                                            <> Length: {dataset.datasetLength}, </>
                                        }
                                        Name: {dataset.name}, Description: {dataset.description}
                                    </Typography>
                                </Grid>
                            }

                            <Grid container item>
                                {maxItemCount > 0 &&
                                    <Grid item xs={12}>
                                        <Typography variant='body1'>
                                            <WarningIcon sx={{verticalAlign: "bottom", color: 'warning.dark'}}/>
                                            Note: a limit of {maxItemCount} dataset items is applied to this account.
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item>
                                    <RadioGroup
                                        aria-label="indexes"
                                        name="indexes"
                                        value={indexType}
                                        onChange={e => onIndexTypeChange(e.target.value)}>
                                        <FormControlLabel value="ENTIRE" control={<Radio color="primary"/>}
                                                          label="Entire dataset"/>
                                        <FormControlLabel value="RANGE" control={<Radio color="primary"/>}
                                                          label="Use a dataset range"/>
                                        <div><NumField
                                            {...rangeStartProps}
                                            onBlur={e => handleBlur(e, rangeStart, setRangeStart, onStartChange)}
                                            size="small"
                                            label="Start index"
                                            type="number"
                                            variant="outlined"
                                        />
                                            <NumField {...endIdxProps}
                                                      onBlur={e => handleBlur(e, rangeEnd, setEndIdx, onEndChange)}
                                                      size="small"
                                                      label="End index"
                                                      type="number"
                                                      variant="outlined"
                                            /></div>
                                        <FormControlLabel value="INDEXES" control={<Radio color="primary"/>}
                                                          label="Specified indexes"/>
                                        <TextField sx={{margin: 1, minWidth: '20ch'}}
                                                   onChange={handleIndexesChange}
                                                   onBlur={e => handleBlur(e, arrToCSV(indexes),
                                                       setTempIndexes, handleIndexesUpdate)}
                                                   value={tempIndexes}
                                                   size="small"
                                                   label="Comma separated indexes"
                                                   variant="outlined"
                                        />
                                        <FormControlLabel value="COUNT" control={<Radio color="primary"/>}
                                                          label="Number of items"/>
                                        <NumField {...numItemsProps}
                                                  onBlur={e => handleBlur(e, numItems, setNumItems, onNumItemsChange)}
                                                  size="small"
                                                  label="Item count"
                                                  type="number"
                                                  variant="outlined"
                                        />
                                        <FormControlLabel value="FRACTION" control={<Radio color="primary"/>}
                                                          label="Fraction of the dataset"/>
                                        <Box ml={1}>
                                            <Slider value={tempFraction}
                                                    disabled={indexType !== "FRACTION"}
                                                    onChangeCommitted={(evt, value) => handleFractionChange(evt, value)}
                                                    onChange={(evt, value) => setTempFraction(value)}
                                                    min={0.0}
                                                    step={0.01}
                                                    max={1.0}
                                                    valueLabelFormat={fractionValueText}
                                                    valueLabelDisplay="auto"
                                                    aria-labelledby="dataset-fraction-slider"/>
                                        </Box>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={tempShuffle}
                                            onChange={(e) => {
                                                setShuffle(e.target.checked);
                                                handleShuffleCb(e.target.checked)
                                            }}
                                            name="shuffleCb"
                                        />
                                    }
                                    label="Shuffle the dataset"
                                />
                            </Grid>
                            <Grid item xs={12}/>
                            <Grid item>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant={"body1"}>Advanced</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item>
                                                <NumField {...workersProps}
                                                          onBlur={e => handleBlur(e, workers, setWorkers, onWorkersChangeCb)}
                                                          size="small"
                                                          label="Number of workers"
                                                          type="number"
                                                          variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <NumField {...batchSizeProps}
                                                          onBlur={e => handleBlur(e, batchSize, setBatchSize, onBatchSizeChangeCb)}
                                                          size="small"
                                                          label="Batch size"
                                                          type="number"
                                                          variant="outlined"
                                                />
                                            </Grid>
                                            {isRemoteAPISupportedByDataset(dataset) &&
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                name="useRemoteApi"
                                                                checked={tempUseRemote}
                                                                onChange={(e) => {
                                                                    setUseRemote(e.target.checked);
                                                                    handleRemoteCb(e.target.checked)
                                                                }}
                                                            />
                                                        }
                                                        label="Use remote API"
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        }
    </>
}

/**
 * Allows configuration of a dataset
 * @author Kobus Grobler
 * @component
 */
export default function DatasetSettings({
                                            title = "Dataloader Configuration",
                                            datasetInstance = null, maxItemCount, test, orgId, datasetId,
                                            settingsUpdated, settings = test.testSettings.datasetSetting
                                        }) {
    const [dataset, setDataset] = useState(datasetInstance);
    const {getDataset} = useDatasets();

    useEffect(() => {
        if (dataset == null) {
            (async () => {
                const ds = await getDataset(orgId, datasetId);
                setDataset(ds);
            })();
        }
    }, [datasetId, orgId, getDataset]);

    const onSettingsChanged = (name, value) => {
        log.debug(`${name} changed to ${value}`);
        settings[name] = value;
        settingsUpdated(settings);
    }

    return <DataLoaderConfiguration
        dataset={dataset}
        title={title}
        maxItemCount={maxItemCount}
        indexType={settings.selectionType} onIndexTypeChange={(value) => onSettingsChanged('selectionType', value)}
        fraction={settings.fraction} onFractionChange={(value) => onSettingsChanged('fraction', value)}
        shuffle={settings.shuffle} handleShuffleCb={(value) => onSettingsChanged('shuffle', value)}
        useRemoteApi={settings.useRemoteApi} handleRemoteCb={(value) => onSettingsChanged('useRemoteApi', value)}
        rangeStart={settings.startIdx} onStartChange={(value) => onSettingsChanged('startIdx', value)}
        rangeEnd={settings.endIdx} onEndChange={(value) => onSettingsChanged('endIdx', value)}
        numItems={settings.numItems} onNumItemsChange={(value) => onSettingsChanged('numItems', value)}
        indexes={settings.indexes} onIndexesChange={(value) => onSettingsChanged('indexes', value)}
        workers={settings.workers} onWorkersChangeCb={(value) => onSettingsChanged('workers', value)}
        batchSize={settings.batchSize} onBatchSizeChangeCb={(value) => onSettingsChanged('batchSize', value)}
    />
}

DatasetSettings.propTypes = {
    /**
     * The test instance
     */
    test: PropTypes.object.isRequired,

    /**
     * The settings updated function
     */
    settingsUpdated: PropTypes.func.isRequired,

    /**
     * The organization ID
     */
    orgId: PropTypes.number.isRequired

};

export {DataLoaderConfiguration, usePositiveNumberInput}

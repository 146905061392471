import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import {filterListByGroupAndTasks} from "./AttackDefenseSelector";
import TuneParamsDialog from "./TuneParamsDialog";
import {convertUserValuesToParams, getLogger} from "../util/util";
import SectionHeading from "../util/SectionHeading";
import Paper from "../util/Paper";

const log = getLogger("detectionsettings");

const DetectionAlgorithmSetting = ({assets, tasks, test, saveSettings}) => {
    const [algos, setAlgos] = useState([]);
    const [algo, setAlgo] = useState(test.testSettings.detectionTestSettings.id == null ?
        0 : test.testSettings.detectionTestSettings.id);
    const [openTuneParam, setOpenTuneParam] = useState(false);
    const [paramDef, setParamDef] = useState(null);
    const [param, setParam] = useState(null);

    useEffect(() => {
        let localalgos = algos;
        let localalgo = algo;
        if (localalgos == null || localalgos.length === 0) {
            localalgos = filterListByGroupAndTasks(tasks, assets.availableDefenses, "detection").filter(f => f.enabled);
            setAlgos(localalgos);
            if (localalgo === 0 && localalgos.length > 0) {
                if (test.testSettings.detectionTestSettings.id == null) {
                    localalgo = localalgos[0].id;
                    test.testSettings.detectionTestSettings.id = localalgo;
                    saveSettings(test.testSettings);
                } else {
                    localalgo = test.testSettings.detectionTestSettings.id;
                }
                setAlgo(localalgo);
            }
        }
    },[saveSettings, tasks, assets.availableDefenses, algos, algo, test.testSettings]);

    useEffect(() => {
        if (algos != null) {
            const def = algos.find(a => a.id === algo);
            setParamDef(def);
            if (def != null) {
                let detection = {
                    parameters: "{}"
                }
                if (def.id === test.testSettings.detectionTestSettings.id) {
                    detection.parameters = JSON.stringify(test.testSettings.detectionTestSettings.params)
                }
                setParam(detection);
            }
        }
    }, [algos, algo, test.testSettings]);

    const onAlgoChange = (e) => {
        test.testSettings.detectionTestSettings.id = parseInt(e.target.value);
        setAlgo(test.testSettings.detectionTestSettings.id);
        saveSettings(test.testSettings);
    }

    const handleCloseTuneDlg = (values) => {
        setOpenTuneParam(false);
        if (values != null) {
            test.testSettings.detectionTestSettings.id = algo;
            test.testSettings.detectionTestSettings.params = convertUserValuesToParams(values, paramDef.params);
            param.parameters = JSON.stringify(test.testSettings.detectionTestSettings.params)
            setParam(param);
            saveSettings(test.testSettings);
        }
    }

    return <>
        {paramDef != null &&
            <TuneParamsDialog onClose={handleCloseTuneDlg}
                              open={openTuneParam}
                              paramDef={paramDef}
                              item={param}
            />
        }
        <Paper variant="outlined">
        <SectionHeading>
            Dataset Poisoning Detection
        </SectionHeading>
        <Grid item container spacing={1} alignItems="flex-end" justifyContent="flex-start">
            <Grid item>
                <TextField select
                           style={{minWidth: "25ch"}}
                           SelectProps={{
                               native: true,
                           }}
                           label="Select Detection Algorithm"
                           value={algo}
                           onChange={onAlgoChange}>
                    {algos.map((def) =>
                        <option key={def.id} value={def.id}>
                            {def.name}
                        </option>
                    )}
                </TextField>
            </Grid>
            <Grid item>
                <Tooltip title="Adjust parameters">
                                <span>
                                <IconButton
                                    size="small"
                                    onClick={() => setOpenTuneParam(true)}>
                                    <TuneIcon/>
                                </IconButton>
                                </span>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                {paramDef != null &&
                    <Typography variant="body1" style={{maxWidth:"60ch", textAlign:"left"}}
                                color="textSecondary">Description: {paramDef.description}</Typography>
                }
            </Grid>
        </Grid>
    </Paper>
  </>
}

export default DetectionAlgorithmSetting;
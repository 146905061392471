/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import * as logLib from "loglevel";
import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import g from "guardai-shared";

let log = null;

const WARNING_TIME = 5;

const shared = g.com.navinfo.guardai.shared;

const isTimeRunningOut = (organization) => {
    if (organization.settings.maxTimeAllocated > 0) {
        if (organization.timeUsed/(1000*60) > organization.settings.maxTimeAllocated-WARNING_TIME) {
            return true;
        }
    }
    return false;
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const handleBlur = (e, initialValue, tempChanged, changed) => {
    if (e !== undefined)  {
        tempChanged(e.target.value);
        if (initialValue == null || e.target.value !== initialValue.toString()) {
            log.debug("Changed from blur");
            changed(e.target.value);
        }
    } else {
        log.info("blur event is undefined.");
    }
}

const useFloatInput = (initialValue, minimum, maximum) => {
    const [value, setValue] = useState(initialValue);

    return [{ value, onChange: e => {
            let val = parseFloat(e.target.value);
            if (!isNaN(val) && val > minimum) {
                if (maximum != null) {
                    if (val >= maximum) {
                        setValue(val);
                    }
                } else {
                    setValue(val);
                }
            } else {
                setValue(0.0);
            }
        }},
        (val) => setValue(val), handleBlur
    ];
};

const usePositiveNumberInput = (initialValue, maximum, minimum) => {
    const [value, setValue] = useState(initialValue);
    return [{ value, onChange: e => {
            let val = parseInt(e.target.value);
            if (!isNaN(val) && val > -1) {
                if (maximum != null) {
                    if (val < maximum) {
                        setValue(val);
                    }
                } else {
                    if (minimum != null && val < minimum) {
                        val = minimum;
                    }
                    setValue(val);
                }
            } else {
                if (minimum != null) {
                    setValue(minimum);
                } else {
                    setValue(0);
                }
            }
        }},
        (val) => setValue(val)
    ];
};

/**
 * Utility functions for use in the ASAP frontend.
 *
 * @author Kobus Grobler
 */
const errOptions = {autoHideDuration: 3000, variant: 'error'}
const warnOptions = {autoHideDuration: 3000, variant: 'warning'}
const infoOptions = {autoHideDuration: 3000, variant: 'info'}

function checkError(e, history, msgFunc) {
    if (e.request !== undefined && (e.request.status === 403 || e.request.status === 401)) {
        log.warn("Auth failed - redirecting to login page.");
        history.replace("/login");
    } else {
        log.error(e);
        let msg = "";
        if (e.response != null) {
            if (typeof e.response.data === 'string' && e.response.data.length > 0) {
                msg = ": " + e.response.data;
            } else if (e.response.statusText != null) {
                msg = ": " + e.response.statusText;
            }
        }
        if (e.request !== undefined && e.request.status === 500) {
            msg = ": Internal server error - please contact support.";
        }
        if (msgFunc != null)
            msgFunc(msg);
    }
}

function milliesToTimeStr(s=0) {
    return new Date(s).toISOString().substring(11, 19);
}

function secondsToTimeStr(s = 0) {
    return milliesToTimeStr(s*1000);
}

function javaDateToTimeStr(date = 0) {
    if (typeof date === 'string') {
        return new Date(date).toLocaleString() // it's iso date
    } else {
        // its milliseconds
        return new Date(date * 1000).toLocaleString();
    }
}

function minutesToTimeStr(m) {
    return new Date(m * 1000 * 60).toISOString().substring(11, 16).replace(":","h")+"m";
}

function humanReadableSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

function requiredParamsFilled(defaults, params) {
    if (defaults == null)
        return false;
    if (params == null) {
        params = {};
    }
    for (const param of defaults) {
        if (Object.keys(params).indexOf(param.name) === -1 && param.def == null) {
            return false;
        }
    }
    return true;
}

function mergeDefaultsWithUserParams(defaults, params) {
    if (params == null) {
        params = {};
    }
    if (defaults == null)
        return params;
    for (const param of defaults) {
        if (Object.keys(params).indexOf(param.name) === -1 && param.def != null) {
            params[param.name] = param.def;
        }
    }
    return params;
}

function validateAllParams(params, values) {
    for (let i = 0; i < values.length; i++) {
            let param = params[i];
            let msg = isParamValid(param, values, i);
            if (msg !== "") {
                return [msg, i];
            }
    }
    return ["", 0];
}

function checkMinMax(param, val) {
    if (param.min !== undefined) {
        if (val < param.min) {
            throw "Value too small:"+val;
        }
    }
    if (param.max !== undefined) {
        if (val > param.max) {
            throw "Value too big:"+val;
        }
    }
}

function checkNumItems(param, arr) {
    if (param.maxitems !== undefined) {
        if (arr.length > param.maxitems) {
            throw "Too many values - at most "+param.maxitems+" allowed";
        }
    }
    if (param.minitems !== undefined) {
        if (arr.length < param.minitems) {
            throw `Too few values - at least ${param.minitems} required`;
        }
    }
}

/**
 * Verify if the parameter is valid
 *
 * @param param the parameter definition
 * @param values current param values
 * @param i index of param in values
 * @returns {string} empty string if valid or error message
 */
function isParamValid(param, values, i) {
    try {
        let value = values[i];
        if (value == null || value === "") {
            if (param.def === undefined) { // specifically check undefined here since 'null' is a valid default
                return "A valid value is required";
            }
            return "";
        }

        if (param.type === 'float') {
            let val = parseFloat(value);
            if (isNaN(val)) {
                return "Not a valid real number";
            }
            checkMinMax(param, val);
            values[i] = val;
        } else if (param.type === 'int') {
            let val = parseInt(value);
            if (isNaN(val)) {
                return "Not a valid integer number";
            }
            checkMinMax(param, val);
            values[i] = val;
        } else if (param.type === 'bool') {
            if (typeof (values[i]) === 'boolean') {
                return "";
            }
            if (values[i] === 'true' || values[i] === '1') {
                values[i] = true;
            } else if (values[i] === 'false' || values[i] === '0') {
                values[i] = false;
            } else {
                return "Not a valid boolean";
            }
        } else if (param.type === 'list') {
            param.list.filter((e) => {
                if (e instanceof String && e === value) {
                    return true;
                } else if (Number.isInteger(e) && e === parseInt(value)) {
                    values[i] = e;
                    return true;
                }
                return false;
            });
        } else if (param.type === 'arr') {
            if (typeof value == 'string') {
                let arr = CSVToArray(value);
                let valid = true;
                if (arr.length > 0) {
                    // check first row
                    checkNumItems(param, arr[0]);
                    arr[0].forEach(item => {
                        let val = parseFloat(item);
                        if (isNaN(val))
                            valid = false;
                        else
                            checkMinMax(param, val);
                    });
                } else {
                    valid = false;
                }
                if (!valid) {
                    return "parameter must be a valid comma separated value list of floats";
                }
            }
        }
        return ""
    } catch (e) {
        return e+'';
    }
}

/**
 * Takes the array of values and convert it to key value parameters.
 * The order of the values should match the definition order.
 *
 * @param userValues values entered by user (in a dialog)
 * @param definedParams the parameter definitions
 * @returns {{}} a dict of key value pairs
 */
function convertUserValuesToParams(userValues, definedParams) {
    let params = {};
    for (let i = 0; i < userValues.length; i++) {
        if (userValues[i] != null && userValues[i].length !== 0) {
            params[definedParams[i].name] = userValues[i];
        }
    }
    return params;
}

/**
 * Convert the params dict to a printable string summary.
 *
 * @param params a dictionary of parameters containing param values
 * @param paramDef the parameter definition (optional)
 * @returns {string} a printable string summary
 */
function convertParamsToStringSummary(params, paramDef = null) {
    let str = "";
    if (params == null) {
        return str;
    }
    Object.keys(params).forEach((key, index) => {
        let type = "";
        let val = params[key];
        if (paramDef != null) {
            let pDef = paramDef.find(p => p.name === key);
            if (pDef != null) {
                type = pDef.type;
            }
        }
        if (type === "file" && val != null && val.length > 0 && val.indexOf(":") > 0) {
            // Strip the file reference
            val = val.substr(0,val.indexOf(":"));
        }
        if (index > 0) {
            str += ", ";
        }
        str += key + ": " + val;
    });
    return str;
}

function paramSummary(params) {
    let str = convertParamsToStringSummary(params);
    if (str.length === 0) {
        str = "No custom parameters specified";
    }
    return str;
}

function CSVToArray(strData, strDelimiter = ",") {
    // Create a regular expression to parse the CSV values.
    const objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    const arrData = [[]];
    // Create an array to hold our individual pattern
    // matching groups.
    let arrMatches = null;
    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while (arrMatches = objPattern.exec(strData)) {
        // Get the delimiter that was found.
        const strMatchedDelimiter = arrMatches[1];
        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
        ) {
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);
        }
        let strMatchedValue;
        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[2].replace(
                new RegExp("\"\"", "g"),
                "\"");
        } else {
            // We found a non-quoted value.
            strMatchedValue = arrMatches[3];
        }

        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue);
    }
    // Return the parsed data.
    return (arrData);
}

/**
 * Print a float to string, rounded.
 * @param val float or if val is an array, the first number will be printed.
 * @param digits number of digits to round to. Default: 5
 * @returns {*|string|string} the printable string
 */
function printRound(val, digits = 5) {
    if (val instanceof Array) {
        return printRound(val[0]);
    } else if (typeof val === 'number') {
        return val.toFixed(digits);
    } else if (typeof val === 'string') {
        let f = parseFloat(val);
        if (isNaN(f)) {
            return val;
        }
        return printRound(f);
    } else {
        return val;
    }
}

function getLogger(name) {
    setupLogging();
    return logLib.getLogger(name);
}

function setupLogging() {
    if (log == null) {
        if (process.env && process.env.NODE_ENV === 'development') {
            const originalFactory = logLib.methodFactory;
            logLib.methodFactory = function (methodName, logLevel, loggerName) {
                const rawMethod = originalFactory(methodName, logLevel, loggerName);
                return function (message) {
                    let pre = loggerName;
                    if (pre === undefined) {
                        pre = "root"; // root logger
                    }
                    // TODO: send error level logs to server?
                    rawMethod(`${pre}(${logLevel}): ${message}`);
                };
            };

            logLib.setLevel(logLib.levels.DEBUG, false);
            logLib.getLogger("main").setLevel(logLib.levels.DEBUG, false);
            logLib.getLogger("tfs").setLevel(logLib.levels.INFO, false);
            logLib.getLogger("report").setLevel(logLib.levels.DEBUG, false);
            logLib.getLogger("report.table").setLevel(logLib.levels.DEBUG, false);
            logLib.getLogger("test").setLevel(logLib.levels.DEBUG, false);
            logLib.getLogger("projects").setLevel(logLib.levels.DEBUG, false);
            logLib.getLogger("projectsettings").setLevel(logLib.levels.INFO, false);
            logLib.getLogger("ws").setLevel(logLib.levels.DEBUG, false);
        } else {
            logLib.setLevel(logLib.levels.INFO, false);
        }
        window.log = logLib;
        log = logLib.getLogger("util");
    }
}

function isValidPythonName(value) {
    if (value.length > 0) {
        const regex = /^[a-zA-Z_]\w*$/;
        if (regex.test(value)) {
            return true;
        }
    }
    return false;
}

function limitStringLen(max, value) {
    if (value != null) {
        if (value.length > max) {
            return value.substring(0, max) + "...";
        }
    }
    return value;
}

function getModelName(project) {
    return project.modelName === 'New Model' ? project.name : project.modelName;
}

function isAdminUser(user) {
    return user != null && user.roles != null && user.roles.indexOf("ADMIN") > -1;
}

function startBrowserDownload(blob, fileName) {
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    URL.revokeObjectURL(element.href);
}

const getFileNameFromDisposition = (disposition, defaultFileName) => {
    let filename = defaultFileName;
    if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
}

const getAPIBase = (conf) => {
    let base = conf.basePath;
    if (process.env.REACT_APP_BASE !== undefined) {
        base = process.env.REACT_APP_BASE;
    }
    return base + "/api/v1/";
}

const getAPITokenParameter = (conf) => {
    return "access_token=" + conf.apiKey.substring('Bearer '.length);
}

const isRemoteAPISupportedByDataset = (dataset) => {
    return dataset.format.match(/COCO|VOC/i);
}

const NumField = styled(TextField)(({_theme}) => ({
    margin: 1,
    padding: 2,
    width: '20ch'
}));

function validUrl(str) {
    try {
        new URL(str);
        return true;
    } catch (e) {
        return false;
    }
}

function modelFrameworkHasGradients(str) {
    try {
        return shared.modelFrameworkHasGradients(shared.ModelFrameworkType.valueOf(str));
    } catch (e) {
        log.error("Error checking if model framework has gradients: " + e);
        return false;
    }
}

export { shared, useQuery, validUrl, handleBlur, usePositiveNumberInput, useFloatInput, setupLogging, getLogger, checkError, errOptions,
    warnOptions, infoOptions, humanReadableSize, convertUserValuesToParams,
    convertParamsToStringSummary, mergeDefaultsWithUserParams, validateAllParams, isParamValid, requiredParamsFilled,
    CSVToArray, printRound, paramSummary, isValidPythonName, limitStringLen, getModelName, isAdminUser,
    startBrowserDownload, getFileNameFromDisposition, secondsToTimeStr, minutesToTimeStr, javaDateToTimeStr,
    isTimeRunningOut, getAPIBase, getAPITokenParameter, milliesToTimeStr, isRemoteAPISupportedByDataset, NumField,
    modelFrameworkHasGradients};

/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import {SnackbarProvider} from 'notistack';
import {
    HashRouter as Router,
} from "react-router-dom";
import {CookiesProvider} from 'react-cookie';
import './index.css';
import App from './App';

const theme = createTheme(adaptV4Theme({
    palette: {
        mode: "light",
        primary: {
            main: '#004EA0',
            light: 'lightblue'
        },
        grey: {
//            200: '#000000',
//            300: '#000000',
//            400: '#000000',
//            500: '#000000',
//            600: '#000000',
//            700: '#000000',
//            800: '#000000',
        },
        text: {
            secondary: '#939393' // Navinfo grey 919A9F is too light and also not used in NI website
        }
    },
    typography: {
        fontFamily: [
            'Nunito',
            'sans-serif',
            'Roboto',
        ].join(','),
    },
    shape: {
        borderRadius: 10,
    }, overrides: {
        MuiPaper: {
            elevation1: {
                boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            }
        },
        MuiButton: {
            containedPrimary: {
                '&.Mui-disabled': {
                    background: '#919A9F'
                },
                background: 'transparent linear-gradient(256deg,#003e7c 0%,#1a5ca9 49%,#5090cc 100%) 0% 0% no-repeat padding-box',
                transition: 'background .25s ease-in-out',
                paddingRight: 20,
                borderRadius: 20,
            },
        },
    },
    props: {
        MuiTextField: {
            variant: 'standard',
        },
        MuiLink: {
            underline: 'hover',
        },
        MuiTooltip: {
            disableInteractive: true
        }
    }
}));

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline/>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}>
                    <CookiesProvider>
                        <Router>
                            <App/>
                        </Router>
                    </CookiesProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext, useCallback} from 'react';
import {
    useParams, useHistory
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import CopyIcon from '@mui/icons-material/Share';
import DownloadWSIcon from '@mui/icons-material/AssignmentReturned';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AlertDialog from "../dialogs/AlertDialog";
import NameEditor from "../util/NameEditor";
import {checkError, errOptions, getAPIBase, getAPITokenParameter, getLogger, isAdminUser} from "../util/util";
import {ModelApi} from "../api";
import AppContext from "../AppContext";
import {useLoadOrgs} from "../organizations/Organization";
import SelectListDialog from "../dialogs/SelectListDialog";
import Paper from "../util/Paper";
import DeleteIcon from "../util/TrashIcon"

const log = getLogger("model");

const useModel = (modelIdInit) => {
    const {conf} = useContext(AppContext);
    const [model, setModel] = useState(null);
    const [modelId, setModelId] = useState(modelIdInit);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (modelId != null) {
            (async () => {
                const api = new ModelApi(conf);
                try {
                    log.debug("Getting model for id " + modelId);
                    const r = await api.getModel(modelId);
                    setModel(r.data);
                } catch (e) {
                    checkError(e, history, (msg) =>
                        enqueueSnackbar('Failed to get model'+msg, errOptions));
                }
            })();
        }
    }, [conf, modelId, enqueueSnackbar, history]);

    const updateModel = useCallback(async (modelRequest) => {
        try {
            const api = new ModelApi(conf);
            let r = await api.updateModel(modelId, modelRequest);
            setModel(r.data);
            return r;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to update model'+msg, errOptions));
        }
    },[modelId, conf])

    const updateModelName = async (value) => {
        let rs = await updateModel({name: value});
        if (rs != null)
            enqueueSnackbar('Model name updated.');
    }

    const updateFrameworkType = async (value) => {
        let rs = await updateModel({frameworkType: value});
        if (rs != null)
            enqueueSnackbar('Framework type updated.');
    }

    const updateEnvironment = useCallback(async (value) => {
        let rs = await updateModel({environment: value});
        if (rs != null)
            enqueueSnackbar('Environment updated.');
    },[updateModel, enqueueSnackbar, history])

    const deleteModel = useCallback(async (id) => {
        try {
            const api = new ModelApi(conf);
            log.debug("Deleting model "+id);
            await api.deleteModel(id);
            return true;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to delete model'+msg, errOptions));
        }
        return false;
    },[conf, history, enqueueSnackbar])

    const updateDescription = useCallback(async (value) => {
        let rs = await updateModel({description: value});
        if (rs != null)
            return rs.data;
    },[updateModel, history, enqueueSnackbar])

    const copyModel = useCallback(async (orgId) => {
        try {
            const api = new ModelApi(conf);
            log.debug("Copying model "+modelId);
            await api.copyModel(modelId, orgId);
            return true;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to copy model'+msg, errOptions));
        }
        return false;
    },[conf, modelId, history, enqueueSnackbar])

    const downloadModelData = useCallback(async (dataType, id, options = {}) => {
        const api = new ModelApi(conf);
        try {
            log.debug(`Downloading model ${id} data of type ${dataType}`);
            return await api.downloadModelData(dataType, id, options);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to download model data'+msg, errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    const startModelBrowserDownload = useCallback( (dataType, id) => {
        log.debug(`Starting download of model ${id} data of type ${dataType}`);
        window.location = getAPIBase(conf) + "models/" + id + "/data?" + getAPITokenParameter(conf) +
            "&dataType=" + dataType;
    }, [conf]);

    const getModels = useCallback(async () => {
        try {
            const api = new ModelApi(conf);
            log.debug("Retrieving models.")
            const r = await api.getModels();
            log.debug("Models retrieved.");
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get models'+msg, errOptions));
        }
        return null;
    }, [conf, enqueueSnackbar, history]);

    return [{model, setModel, updateModel, updateFrameworkType, updateEnvironment, updateModelName, deleteModel,
        updateDescription, copyModel, downloadModelData, startModelBrowserDownload, getModels}, setModelId]
}

/**
 * View of an AI model on organizational level
 * @author Kobus Grobler
 * @component
 */
export default function Model() {
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const history = useHistory();
    let {modelId} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const [{model, setModel, updateModelName, deleteModel, updateDescription, copyModel, startModelBrowserDownload}] = useModel(modelId);
    const [openOrgDlg, setOpenOrgDlg] = useState(false);
    const orgs = useLoadOrgs();
    const {user} = useContext(AppContext);

    const deleteModelClosed = async (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            if (await deleteModel(modelId)) {
                enqueueSnackbar('Model deleted.');
                history.replace(history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/")));
            }
        }
    }

    const onUpdateDescription = async (_id, value) => {
        let data = await updateDescription(value);
        if (data != null) {
            setModel(data);
            enqueueSnackbar('Model description updated.');
        }
    }

    const handleCloseOrgDlg = async (orgIn) => {
        setOpenOrgDlg(false);
        if (orgIn != null) {
            if (await copyModel(orgIn.id)) {
                enqueueSnackbar('Model copied.');
                history.replace(history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/")));
            }
        }
    }

    const downloadData = (type) => {
        startModelBrowserDownload(type, modelId);
        enqueueSnackbar("Download started.");
    }

    return <Grid container justifyContent="center">
        <AlertDialog open={openConfirmDelete} title="Delete model?"
                     description="This will permanently delete the model and its data."
                     onClose={deleteModelClosed}/>
        <SelectListDialog open={openOrgDlg}
                          onClose={handleCloseOrgDlg}
                          list={orgs}
                          title="Select destination organisation"/>
        <Grid item xs={12} md={10} lg={8} xl={6}>
            <Paper>
            {model != null &&
                <>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <NameEditor
                        fullWidth
                        hiddenLabel={false}
                        label="Model Name"
                        name={model.name}
                        onUpdateName={(_id, value) => updateModelName(value)}/>
                    </Grid>
                    <Grid item xs={11}>
                    <NameEditor variant="outlined"
                                multiline
                                fullWidth
                                maxRows={4}
                                minRows={4}
                                fieldName="Description"
                                label="Description"
                                name={model.description == null ? "" : model.description}
                                onUpdateName={onUpdateDescription}/>
                    </Grid>
                    <Grid item xs={12} sx={{marginLeft: 1}}>
                        <Typography variant="subtitle1">
                            Model tasks:&nbsp;
                            {model.tasks != null && model.tasks.map((task, idx) =>
                                <React.Fragment key={idx}>{task}&nbsp;</React.Fragment>
                            )}
                        </Typography>
                        <Typography variant="subtitle1">
                            Framework: {model.frameworkType == null ? "PyTorch" : model.frameworkType}
                        </Typography>
                        <Typography variant="subtitle1">
                            Environment: {model.environment == null ? "Default" : model.environment}
                        </Typography>
                        <Typography variant="subtitle1">
                            Organisation: {model.organization.name}
                        </Typography>
                        <Typography variant="subtitle1">
                            Model id: {model.id}
                        </Typography>
                        <Typography variant="subtitle1">
                            Copied from project id: {model.projectSource}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Tooltip title="Delete model">
                        <IconButton
                            size="small"
                            onClick={() => setOpenConfirmDelete(true)}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                    {isAdminUser(user) &&
                        <Tooltip title="Share model">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setOpenOrgDlg(true);
                                }}>
                                <CopyIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                    {model.dataRef &&
                        <Tooltip title="Download model data">
                            <IconButton
                                size="small"
                                onClick={() => downloadData('Model')}>
                                <DownloadIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                    {model.wrapperRef &&
                        <Tooltip title="Download wrapper script">
                            <IconButton
                                size="small"
                                onClick={() => downloadData('WrapperScript')}>
                                <DownloadWSIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                    </Grid>
                </Grid>
                </>
            }
            </Paper>
        </Grid>
    </Grid>
}

Model.propTypes = {

};

export {useModel}

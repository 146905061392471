/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Grid from "@mui/material/Grid";
import Plot from "react-plotly.js/react-plotly";
import {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HideIcon from "@mui/icons-material/VisibilityOff";
import GraphIcon from "@mui/icons-material/Equalizer";
import Paper from "@mui/material/Paper";
import {buildReportTableModel, getDataByMetricAndModelName} from "../aitests/ReportTable";

/**
 * Plotting for the benchmark report
 *
 * @author Kobus Grobler
 * @component
 */
export const BenchmarkGraph = ({reports, projects, sort}) => {
    const [reportModel, setReportModel] = useState(null);
    const [showPlot, setShowPlot] = useState(false);
    const [data, setData] = useState([]);
    const [layout, setLayout] = useState({});

    useEffect(() => {
        if (reports != null) {
            setReportModel(buildReportTableModel(reports, 'projectId', projects, sort, null));
        }
    },[reports, projects, sort]);

    useEffect(() => {
        if (reportModel != null) {
            let data = [];
            let dataByMetric = getDataByMetricAndModelName(reportModel);
            let layout = {
                barmode: 'group',
            };
            let modelCnt = 0;
            Object.keys(dataByMetric).forEach((mName,idx) => {
                let axisCnt = "";
                if (idx > 0) {
                    axisCnt = ""+(idx+1);
                }
                layout["yaxis"+axisCnt] = {title: mName};
                Object.keys(dataByMetric[mName]).forEach(modelName => {
                    if (idx === 0)
                        modelCnt++;
                    let x = Object.keys(dataByMetric[mName][modelName]);
                    let y = x.map(a => dataByMetric[mName][modelName][a][0]);
                    let trace = {
                        showlegend: idx === 0,
                        x: x,
                        xaxis: 'x'+axisCnt,
                        yaxis: 'y'+axisCnt,
                        y: y,
                        name: modelName,
                        type: 'bar'
                    };
                    data.push(trace);
                });
            });
            let colorway = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b',
                '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];
            layout.colorway = colorway.slice(0,modelCnt);
            let cols = 1;
            let metricCnt = Object.keys(dataByMetric).length;
            if (metricCnt > 1) {
                cols = 2;
            }
            let rows = metricCnt/cols>>0;
            layout.grid= {rows: rows, columns: cols, pattern: 'independent'};
            setData(data);
            setLayout(layout);
        }
    },[reportModel]);

    return <>
        {reportModel != null &&
            <>
                {showPlot ?
                    <Tooltip title={"Hide graph"} aria-label="Hide plot">
                        <IconButton aria-label="hide" size="small" onClick={() => {
                            setShowPlot(false);
                        }}>
                            <HideIcon/>
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={"Show metric graphs"} aria-label="Show plot">
                        <IconButton
                            size="small"
                            onClick={() => {
                                setShowPlot(true);
                            }}>
                            <GraphIcon/>
                        </IconButton>
                    </Tooltip>
                }
                {showPlot && data.length > 0 &&
                    <MetricBarGraph xtitle="Project ID"
                                 data={data} layout={layout} projects={projects}/>
                }
                </>
        }
    </>
}

const MetricBarGraph = ({title="", xtitle="Date", ytitle="", data, layout}) => {

    return <>
        <Paper variant="outlined">
            <Grid container direction="column" alignContent="center" item xs={12}>
                <Grid item xs={12}>
                    <Plot style={{width: "80vw", height: "60vh", marginBottom:"5px"}} data={data}
                          layout={layout}
                          config={{displaylogo: false, responsive: true}}
                    />
                </Grid>
            </Grid>
        </Paper>
    </>
}

const MetricGraph = ({title="", xtitle="Date", ytitle="", data}) => {

    return <>
        <Paper variant="outlined">
        <Grid container direction="column" alignContent="center" item xs={12}>
            <Grid item xs={12}>
                <Plot style={{width: "80vw", height: "60vh", marginBottom:"5px"}} data={data}
                      layout={{
                          title: title,
                          legend: {
                              itemdoubleclick: 'toggleothers',
                              groupclick: 'toggleitem'
                          },
                          xaxis: {
                              title: {
                                  text: xtitle,
                              },
                              ticktext: data.ticktext,
                              tickvals: data[0].x,
                              tickmode: "array",
                          },
                          yaxis: {
                              title: {
                                  text: ytitle,
                                  font: {
                                      size: 14,
                                  }
                              },
                          },
                      }}
                      config={{displaylogo: false, responsive: true}}
                />
            </Grid>
        </Grid>
        </Paper>
    </>
}

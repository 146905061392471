/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import StepButton from "@mui/material/StepButton";
import DoneIcon from '@mui/icons-material/Done';
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import PropTypes from "prop-types";
import {SubmitButton} from "../util/SubmitButton";
import {BackButton} from "../util/Buttons";
import {useState} from "react";

const useWizard = (step) => {

    const [activeStep, setActiveStep] = useState(step);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    return [activeStep, setActiveStep, handleBack];
}

/**
 * Generic Wizard component
 * @param steps
 * @param activeStep
 * @param getStepContent
 * @param isStepComplete
 * @param handleStep
 * @param handleNext
 * @param handleBack
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
export default function Wizard({steps, activeStep, getStepContent, isStepComplete, handleStep, handleNext, handleBack}) {
    return <Grid container>
            <Grid item xs={12}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, idx) => {
                        return (
                            <Step key={label} completed={isStepComplete(idx)}>
                                <StepButton onClick={handleStep(idx)}>
                                    {label}
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid container>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{minHeight:"30em"}}>
                        {getStepContent(activeStep)}
                    </Grid>
                    <Grid container item justifyContent="center">
                        {activeStep > 0 &&
                            <BackButton onClick={handleBack}/>
                        }
                        <SubmitButton
                            color="primary"
                            onClick={handleNext}
                            startIcon={activeStep === steps.length - 1 ? <DoneIcon/> : <></>}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
}

Wizard.propTypes = {
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    getStepContent: PropTypes.func.isRequired,
    isStepComplete: PropTypes.func.isRequired,
    handleStep: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired
}

export {useWizard}

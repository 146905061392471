import React from "react";
import Button from "@mui/material/Button";

export const SubmitButton = React.forwardRef(({title, children, ...props}, ref) => {
  return <Button
      sx={{margin: 1}}
      type="submit"
      variant="contained"
      color="primary"
      ref={ref}
      {...props}>
    {title}{children}
  </Button>
})

SubmitButton.muiName = Button.muiName;
/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LinkIcon from '@mui/icons-material/Link';
import PublicIcon from '@mui/icons-material/Public';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";

/**
 * Select an item from a list using a dialog
 * @author Kobus Grobler
 * @component
 */
function SelectListDialog({onClose, open, title, dialogProps, list}) {

    const [closed, setClosed] = useState(false);

    useEffect(() => {
        if (open) {
            setClosed(false);
        }
    }, [open]);

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const handleCancel = () => {
        if (!closed) {
            setClosed(true);
            onClose(null);
        }
    };

    return (
        <Dialog onClose={handleCancel} open={open} {...dialogProps}>
            <DialogTitle>{title}</DialogTitle>
            <List>
                {list != null && list.map((item) => {
                    if (item.displayed === undefined || item.displayed === true) {
                        return (
                            <React.Fragment key={item.id}>
                                <ListItem disabled={item.enabled !== undefined && !item.enabled}
                                      button
                                      onClick={() => handleListItemClick(item)}
                                      >
                                <ListItemText primary={(item.format === undefined || item.format === null) ?
                                    item.name : item.name +' - '+ item.format}
                                              secondary={item.description}/>
                                {item.paper_link != null && item.paper_link.length > 0 &&
                                    <Tooltip title="External link to reference paper.">
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            onClick={(event) => {event.stopPropagation();window.open(item.paper_link,
                                                '_blank',
                                                'noopener')}}
                                            aria-label="paper"
                                            size="large">
                                            <LinkIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                    </Tooltip>
                                }
                                {item.predefined &&
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Public dataset">
                                                <PublicIcon/>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                            </React.Fragment>
                        );
                    } else {
                        return null;
                    }
                })}
            </List>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SelectListDialog.propTypes = {
    /**
     * onClose is called with null if dialog is cancelled or the selected item if confirmed
     */
    onClose: PropTypes.func.isRequired,
    /**
     * Open or close the dialog
     */
    open: PropTypes.bool.isRequired,
    /**
     * Array of items with id, name and enabled fields
     */
    list: PropTypes.array,
    /**
     * Dialog title
     */
    title: PropTypes.string.isRequired
};

export default SelectListDialog;

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import ListItemLink from "../util/ListItemLink";
import Typography from '@mui/material/Typography';
import {useHistory, useRouteMatch} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import {useSnackbar} from "notistack";
import {BenchmarkApi} from "../api";
import {checkError, errOptions, getLogger} from "../util/util";
import SelectListDialog from "../dialogs/SelectListDialog";
import AppContext from "../AppContext";
import ErrorBoundary from "../util/ErrorBoundary"
import List from "@mui/material/List";
import {SubmitButton} from "../util/SubmitButton";
import Paper from "../util/Paper";

const log = getLogger("benchmarks");

const useBenchmarks = () => {
    const {conf} = useContext(AppContext);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const getBenchmark = useCallback( async (benchmarkId) => {
        try {
            log.debug("Getting benchmark by id " + benchmarkId);
            const api = new BenchmarkApi(conf);
            const r = await api.getBenchmark(benchmarkId);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get benchmark'+msg, errOptions));
            throw e;
        }
    },[conf, enqueueSnackbar, history]);

    const getBenchmarks = useCallback( async () => {
        const api = new BenchmarkApi(conf);
        try {
            log.debug("Getting benchmark list.");
            const benchmarks = await api.getBenchmarks();
            log.debug("Retrieved benchmark list.");
            return benchmarks.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get benchmark projects'+msg, errOptions));
        }
    },[conf, enqueueSnackbar, history]);

    return {getBenchmark, getBenchmarks}
}

/**
 * Displays list of benchmark projects
 * @author Kobus Grobler
 * @component
 */
export default function Benchmarks() {
    const history = useHistory();
    const match = useRouteMatch();
    const {enqueueSnackbar} = useSnackbar();
    const {user, conf} = useContext(AppContext);
    const [openOrgDlg, setOpenOrgDlg] = useState(false);
    const [postOrgFunc, setPostOrgFunc] = useState(null);
    const [org, setOrg] = useState(null);
    const [projects, setProjects] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [nameError, setNameError] = useState("");
    const {getBenchmarks} = useBenchmarks();

    useEffect(() => {
        (async () => {
            const bms = await getBenchmarks();
            setProjects(bms);
        })();
    }, [getBenchmarks]);

    function addBenchmark(org) {
        setOrg(org);
        const api = new BenchmarkApi(conf);
        api.addBenchmark(org.id,
            {name: projectName})
            .then((p) => {
                setProjects([p.data, ...projects]);
                setProjectName("");
            })
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to add project',
                    errOptions)));
    }

    function isUserValid() {
        if (user.organizations == null || user.organizations.length === 0) {
            enqueueSnackbar('You are not a member of any organisation - please contact your administrator.', errOptions);
            return false;
        }
        return true;
    }

    const onAddProjectClick = () => {
        if (!isUserValid()) return;

        if (projectName.length < 1) {
            setNameError("Project name must be specified");
            return;
        }
        setNameError("");
        if (user.organizations.length > 1) {
            setPostOrgFunc("add");
            setOpenOrgDlg(true);
        } else {
            addBenchmark(user.organizations[0]);
        }
    }

    const handleCloseOrgDlg = (org) => {
        setOpenOrgDlg(false);
        if (org != null) {
            if (postOrgFunc === "add")
                addBenchmark(org);
        }
    }

    return  <ErrorBoundary><Grid container item xs={12} md={10} xl={6} justifyContent="center" mt={2}>
        {user != null &&
        <SelectListDialog open={openOrgDlg}
                          onClose={handleCloseOrgDlg}
                          list={user.organizations}
                          title="Select Organisation"/>
        }
        <Grid item xs={12} md={10} lg={8} xl={6}>
            <Paper>
                <TextField value={projectName}
                           fullWidth
                           error={nameError.length > 0}
                           helperText={nameError}
                           label="Benchmark Name"
                           onChange={(event) => setProjectName(event.target.value)}/>
                <SubmitButton
                    onClick={onAddProjectClick}
                    startIcon={<AddIcon/>}>
                    Add Benchmark
                </SubmitButton>
            </Paper>
        </Grid>

        <Grid item xs={12}>
            <List>
            {projects != null && projects.map((benchmark) =>
                <Accordion key={benchmark.id}>
                <ListItemLink href={`#${match.url}/${benchmark.id}`}>
                        <Typography sx={{minWidth: "300px", padding:'10px'}}>
                            {'Organisation: '+benchmark.organization.name + ', Benchmark ID: ' +
                                benchmark.id+ ', Name: '+benchmark.name}
                        </Typography>
                </ListItemLink>
                </Accordion>
            )}
            </List>
        </Grid>
    </Grid></ErrorBoundary>
}

Benchmarks.propTypes = {

};

export {useBenchmarks}

import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const StyledHeading = styled(Typography)(({ _theme }) => ({
    textAlign: "center"
}));

const SectionHeading = ({children, ...props}) => {

    return <StyledHeading variant='h6' {...props}>{children}</StyledHeading>
}

export default SectionHeading;
/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {ListItem} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import {TestProfileSummary} from "./TestProfile";
import Paper from "../util/Paper"
import './SelectTestProfile.css'

export default function SelectTestProfile({resources, onItemClicked, profiles, tasks}) {
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (profiles != null) {
            onItemClicked(profiles[selectedIndex]);
        }
    },[profiles, selectedIndex, onItemClicked]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return <>
        <Grid container spacing={2} style={{minHeight: "30em"}} alignItems="stretch">
            <Grid item xs={4}>
                <Paper variant="outlined" className="paper">
                    <List>
                        {profiles.map((item, idx) =>
                            <ListItem key={item.id} button
                                      onClick={(event) => handleListItemClick(event, idx)}
                                      selected={selectedIndex === idx}>
                                <ListItemText primary={item.name}>
                                </ListItemText>
                            </ListItem>
                        )
                        }
                    </List>
                </Paper>
            </Grid>
            <Grid item xs>
                <Paper variant="outlined"  className="paper">
                    {selectedIndex < profiles.length &&
                        <>
                            <Typography variant="h6">
                                Description
                            </Typography>
                            <Typography variant="body2" style={{whiteSpace: "pre-line"}}>
                            {profiles[selectedIndex].description}
                            </Typography>
                            {selectedIndex > 0 &&
                                <TestProfileSummary resources={resources}
                                                    tasks={tasks}
                                                    settings={profiles[selectedIndex].settings}
                                                    defenses={profiles[selectedIndex].settings.defenses}/>
                            }
                        </>
                    }
                </Paper>
            </Grid>
        </Grid>
    </>
}

SelectTestProfile.propTypes = {

    onItemClicked: PropTypes.func.isRequired,

    profiles: PropTypes.array.isRequired,

    tasks: PropTypes.array.isRequired,

    resources: PropTypes.object.isRequired,
}

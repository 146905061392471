/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Plot from "react-plotly.js/react-plotly";
import {getSampleCnt} from "./ReportTable"
import Paper from "../util/Paper";

const createPassFailReports = (pfCriteria, meta, report) => {
    let metrics = meta.attacked;
    if (metrics == null) {
        metrics = meta.benign;
    }
    let results = [];
    for (let crit of pfCriteria) {
        if (crit.enabled && crit.passVal != null) {
            for (let metric of metrics) { // metric per attack
                Object.keys(metric).forEach(mk => {
                    if (mk.startsWith(crit.metricName)) {
                        let r = {
                            name: crit.metricName,
                            passCnt: 0,
                            sampleCnt: getSampleCnt(report)
                        };
                        results.push(r);
                        let values = metric[mk];
                        for (let val of values) {
                            if (crit.above) {
                                if (val > crit.passVal) {
                                    r.passCnt += 1;
                                }
                            } else {
                                if (val < crit.passVal) {
                                    r.passCnt += 1;
                                }
                            }
                        }
                    }
                });
            }
        }
    }
    return results;
}

export default function PassFailReport({report, criteria, passPercentage=0.8}) {

    const getPlotData = (crit, result) => {
        let val = result.passCnt/result.sampleCnt;
        let goodPercent = passPercentage;
        return {
            value: val,
            title: { text: crit.metricName},
            type: "indicator",
            mode: "gauge+number+delta",
            delta: { reference: goodPercent },
            gauge: {
                bar: { color: val > goodPercent ? "green" : "red"},
                axis: {
                    dtick: 0.1,
                    range: [null, 1.0]
                },
                threshold: {
                    line: { color: "black", width: 4 },
                    thickness: 0.75,
                    value: crit.passVal
                }
            }
        }
    }

    return <>
        <Paper variant="outlined">
            <Typography variant='h6' style={{textAlign: "center"}}>Test Pass/Fail Indicators</Typography>
            <Grid item container xs={12} justifyContent={"center"}>
                {report.map( (result, idx) => <React.Fragment key={idx}>
                    <Grid item>
                    <Typography variant='body1'>% of samples that passes {criteria[idx].metricName} metric threshold of {criteria[idx].passVal}</Typography>
                    <Plot
                        data={[getPlotData(criteria[idx], result)]}
                        layout={{
                            width: 450,
                            margin: {
                                autoexpand: false,
                                b: 0, t: 0,
                            }
                        }}
                        config={{
                            displaylogo: false }}
                    />
                    </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Paper>
    </>
}

export {createPassFailReports}

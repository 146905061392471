/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext} from 'react';
import {
    useRouteMatch, useHistory
} from "react-router-dom";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import {useSnackbar} from "notistack";
import {Configuration, TransformApi} from "../api";
import {checkError, errOptions, getLogger, isValidPythonName} from "../util/util";
import AppContext from "../AppContext";
import SelectListDialog from "../dialogs/SelectListDialog";
import ListItemLink from "../util/ListItemLink";

const log = getLogger("transforms");


export default function Transforms(props) {
    const history = useHistory();
    const match = useRouteMatch();
    const {user} = useContext(AppContext);

    const {enqueueSnackbar} = useSnackbar();
    const [transforms, setTransforms] = useState(null);
    const [transformName, setTransformName] = useState("");
    const [nameError, setNameError] = useState("");
    const [openOrgDlg, setOpenOrgDlg] = useState(false);

    useEffect(() => {
        (async () => {
            const api = new TransformApi(props.conf);
            try {
                log.debug("Retrieving transforms.")
                const r = await api.getTransforms();
                setTransforms(r.data);
                log.debug(`Retrieved ${r.data.length} transforms.`);
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to get transforms', errOptions));
            }
        })();
    }, [props.conf, enqueueSnackbar, history]);

    const onAddTransformClick = () => {
        if (!isValidPythonName(transformName.trim())) {
            setNameError("A valid transform name must be specified.");
            return;
        }
        setNameError("");
        if (user.organizations.length > 1) {
            setOpenOrgDlg(true);
        } else {
            addTransform(user.organizations[0]);
        }
    }

    const addTransform = async (org) => {
        const api = new TransformApi(props.conf);
        try {
            log.debug("Adding transform.")
            const r = await api.addTransform(org.id, transformName);
            setTransforms([r.data, ...transforms]);
            history.push(`${match.url}/${r.data.id}`);

        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to add transform', errOptions));
        }
    }

    const handleCloseOrgDlg = (org) => {
        setOpenOrgDlg(false);
        if (org != null) {
            addTransform(org).then();
        }
    }

    return <Grid container item xs={12} md={10} lg={8} xl={6} justifyContent={"center"}>
        {user != null &&
            <SelectListDialog open={openOrgDlg}
                              onClose={handleCloseOrgDlg}
                              list={user.organizations}
                              title="Select Organisation"/>
        }
        <Grid item xs={12} md={10} lg={6} xl={4}>
            <Paper sx={{padding: 2, margin: 2}}>
                <TextField value={transformName}
                           fullWidth
                           sx={{marginBottom: 2}}
                           error={nameError.length > 0}
                           helperText={nameError}
                           label="Custom Transform Class Name"
                           onChange={(event) => {
                               if (event.target.value.length === 0 || isValidPythonName(event.target.value)) setTransformName(event.target.value)
                           }}/>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={onAddTransformClick}
                    startIcon={<AddIcon/>}>
                    Add Transform
                </Button>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            {transforms !== null &&
                <>
                    {transforms.length === 0 &&
                        <Typography variant="subtitle1">
                            There are no custom transforms configured for this organisation.
                        </Typography>
                    }
                    {transforms.length > 0 &&
                        <>
                            <Typography variant="h6" style={{textAlign: "center"}}>
                                Custom Transforms
                            </Typography>
                            <List>
                                {transforms.map((transform) =>
                                    <Accordion key={transform.id}>
                                        <ListItemLink href={`#${match.url}/${transform.id}`}>
                                            <ListItemText primary={transform.name}/>
                                        </ListItemLink>
                                    </Accordion>
                                )}
                            </List>
                        </>
                    }
                </>
            }
        </Grid>
    </Grid>
}

Transforms.propTypes = {
    /**
     * The API configuration
     */
    conf: PropTypes.instanceOf(Configuration).isRequired
};

/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext} from 'react';
import {useHistory, useParams} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PreviewIcon from '@mui/icons-material/Pageview';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import Box from "@mui/material/Box";
import Dropzone from 'react-dropzone'
import {checkError, errOptions, getLogger, startBrowserDownload} from "../util/util";
import AppContext from "../AppContext";
import {OrganizationApi, UsersApi} from "../api";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import AlertDialog from "../dialogs/AlertDialog";
import {useOrgs} from "../organizations/Organization";
import NameEditor from "../util/NameEditor";
import {useLoadDataset} from "../datasets/Datasets";
import DeleteIcon from "../util/TrashIcon"
import Paper from "../util/Paper";

const log = getLogger("mapping");

const dropzone= {
        maxWidth: '25em',
        minWidth: '10em',
        minHeight: '5em',
        border: 'dashed',
        borderColor: 'divider',
        borderRadius: 1,
        boxSizing: 'border-box',
        cursor: 'pointer',
        padding: '5px',
        marginRight: '5px'
};

/**
 * Retrieves and shows a class mapping file
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClassMapping() {
    const history = useHistory();
    let {orgId, ref} = useParams();

    const {getOrg, getOrgFileUsage} = useOrgs();
    const [{dataset}, loadOrgDataset] = useLoadDataset();
    const {conf, setUser} = useContext(AppContext);

    const {enqueueSnackbar} = useSnackbar();
    const [openClassView, setOpenClassView] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [mapping, setMapping] = useState(null);
    const [classNames, setClassNames] = useState([]);
    const [organization, setOrganization] = useState(null);
    const [projectList, setProjectList] = useState(null);

    const userApi = new UsersApi(conf);

    useEffect(() => {
        (async () => {
            setOrganization(await getOrg(orgId));
            setProjectList(await getOrgFileUsage(orgId, ref));
        })();
    }, [orgId]);

    useEffect(() => {
        if (organization != null && organization.settings != null && organization.settings.classMappingFiles != null) {
            let mp = organization.settings.classMappingFiles.find(mf => mf.ref === ref);
            setMapping(mp);
            loadOrgDataset(orgId, mp.datasetId);
        }
    }, [organization]);

    const askDeleteMapping = (m) => {
        if (projectList != null && projectList.length > 0) {
            enqueueSnackbar('Class mapping file is used in projects - please first remove it from the project configuration.',
                errOptions);
            return;
        }
        setMapping(m);
        setOpenConfirmDelete(true);
    }

    const deleteMapping = async (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            try {
                log.debug("Deleting " + mapping.ref);
                const api = new OrganizationApi(conf);
                await api.deleteOrganizationFile(orgId,"classmapping", mapping.ref);
                let rs = await userApi.getLoggedInUser();
                setUser(rs.data);
                history.replace('/main/mappings');
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to delete mapping',
                        errOptions));
            }
        }
    }

    const viewClassNames = async (mp) => {
        try {
            log.debug("Retrieving " + mp.ref);
            const api = new OrganizationApi(conf);
            let rs = await api.downloadOrganizationFile(orgId, "classmapping", mp.ref );
            setClassNames(rs.data);
            setOpenClassView(true);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to load mapping file',
                    errOptions));
        }
    }

    const downloadFile = async (mp) => {
        try {
            log.debug("downloading " + mp.ref);
            const api = new OrganizationApi(conf);
            let rs = await api.downloadOrganizationFile(orgId, "classmapping", mp.ref, {responseType: 'blob'});
            startBrowserDownload(rs.data, mp.fileName);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to load mapping file',
                    errOptions));
        }
    }

    const updateFileName = async (mp, name) => {
        try {
            log.debug("Renaming " + mp.ref);
            if (name.length > 0) {
                mp.fileName = name;
                const api = new OrganizationApi(conf);
                await api.renameOrganizationFile(orgId, "classmapping", mp.ref, name);
                let rs = await userApi.getLoggedInUser();
                setUser(rs.data);
            }
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to rename mapping file',
                    errOptions));
        }
    }

    const uploadFile = async (file) => {
        try {
            log.debug("Uploading file");
            const api = new OrganizationApi(conf);
            await api.uploadOrganizationFile(orgId, "classmapping", mapping.datasetId, mapping.ref, file);
            enqueueSnackbar('Updated class mapping file.');
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to upload mapping file',
                    errOptions));
        }
    }

    const getDatasetName = () => {
        if (dataset != null) {
            return dataset.name;
        }
    }

    const onDrop = async (acceptedFiles) => {
        if (acceptedFiles.length === 1) {
            let file = acceptedFiles[0];
            let name = file.path;
            if (name === undefined || name === null) {
                name = file.name;
            }
            let idxFrom = name.indexOf('/', 1);
            if (idxFrom === -1) {
                idxFrom = name.indexOf('/');
            }
            name = name.substr(idxFrom + 1);
            log.debug("dropped " + name);
            await uploadFile(file);
        }
    }

    return (
        <Grid item xs={12} md={10} lg={8} xl={6} container spacing={1} justifyContent="center" mt={2}>
            <AlertDialog open={openConfirmDelete} title="Delete mapping?"
                         description="This will permanently delete the mapping."
                         onClose={deleteMapping}/>
            <OkCancelDialog open={openClassView}
                            title="Class mapping"
                            dialogProps={{fullWidth: true, maxWidth: 'md'}}
                            onClose={() => {
                                setOpenClassView(false);
                            }}
                            hideCancel={true}>
                <span>
                    {Object.keys(classNames).map((classId, idx) =>
                        <div key={idx}>{classId}: {classNames[classId]}</div>
                    )}
                </span>
            </OkCancelDialog>
            <Paper>
                <Grid item xs={12} container>
                    <Grid item xs={12}>
                        {mapping != null ?
                            <>
                                <Typography variant="body1" style={{margin: '5px'}}>
                                    Class mapping for dataset: {getDatasetName()}
                                </Typography>
                                <NameEditor fieldName="file name"
                                            style={{minWidth: "38ch"}}
                                            label="File Name"
                                            hiddenLabel={false}
                                            name={mapping.fileName}
                                            onUpdateName={(_id, name) => updateFileName(mapping, name)}/>
                                <Typography style={{margin: '5px'}} variant="body1">
                                    Used in projects:
                                    {projectList != null && projectList.map((id) =>
                                        <React.Fragment key={id}>
                                            &nbsp;<a href={`/#/main/projects/${id}`}>{id}</a>
                                        </React.Fragment>
                                    )}
                                    {(projectList == null || projectList.length === 0) &&
                                        <>
                                            &nbsp;Not used
                                        </>
                                    }
                                </Typography>
                            </> :
                            <>
                                <Typography variant="h6">
                                    Class mapping file not found
                                </Typography>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {mapping != null &&
                            <>
                                <Dropzone onDrop={onDrop}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <Box sx={dropzone}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p>
                                                        Drag & Drop a new class mapping json file here
                                                    </p>
                                                </div>
                                            </Box>
                                        </section>
                                    )}
                                </Dropzone>
                            </>
                        }
                    </Grid>
                    {mapping != null &&
                        <Grid item xs={12}>
                            <Tooltip title="View class mapping">
                                <IconButton
                                    edge="end"
                                    onClick={() => viewClassNames(mapping)}
                                    aria-label="view"
                                    size="large">
                                    <PreviewIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Download class mapping file">
                                <IconButton
                                    edge="end"
                                    onClick={() => downloadFile(mapping)}
                                    aria-label="download"
                                    size="large">
                                    <DownloadIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete class mapping">
                                <IconButton
                                    edge="end"
                                    onClick={() => askDeleteMapping(mapping)}
                                    aria-label="delete"
                                    size="large">
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Grid>
    );
}

ClassMapping.propTypes = {};

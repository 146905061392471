/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CopyIcon from '@mui/icons-material/Share';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tooltip from "@mui/material/Tooltip";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
    useRouteMatch, useHistory
} from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {useSnackbar} from "notistack";
import {ProjectApi} from "../api";
import {checkError, errOptions, getLogger, useQuery, isAdminUser, getAPIBase, getAPITokenParameter} from "../util/util";
import AlertDialog from "../dialogs/AlertDialog";
import SelectListDialog from "../dialogs/SelectListDialog";
import AppContext from "../AppContext";
import NameEditor from "../util/NameEditor";
import {useLoadDatasets} from "../datasets/Datasets";
import {getProgressAttribs, getBusyTestRunFromStatus, CircularProgressWithLabel} from "../aitests/Test"
import {useServerUpdates} from "../ServerUpdates";
import {useUpdateProfile, addProjIdToFavourites, isProjIdInFavourites, removeProjIdFromFavourites} from "../users/Profile"
import ProjectTypeIndicator from "./ProjectTypeIndicator"
import ModelTypeIndicator from "../models/ModelTypeIndicator";
import ErrorBoundary from "../util/ErrorBoundary";
import DeleteIcon from "../util/TrashIcon";
import {useFilter} from "../util/SearchBox";
import SearchAndSort from "../util/SearchAndSort";
import {sortItemsByProps, useSortBox} from "../util/SortBox";

const log = getLogger("projects");

const useProjects = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);

    const downloadProjectData = useCallback(async (dataType, id, options = {}) => {
        const api = new ProjectApi(conf);
        try {
            log.debug(`Downloading project ${id} data of type ${dataType}`);
            return await api.downloadProjectData(id, dataType, options);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to download project data'+msg, errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    const updateProject = useCallback(async (id, request) => {
        const api = new ProjectApi(conf);
        try {
            log.debug(`Updating project #${id}`);
            let r = await api.updateProject(id, request);
            log.debug("Project updated.");
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to update project'+msg, errOptions));
        }
    },[conf, history, enqueueSnackbar]);

    const existingModelUse = useCallback(async (projectId, modelId) => {
        const api = new ProjectApi(conf);
        try {
            log.debug(`Using existing model ${modelId} with project ${projectId}`);
            let r = await api.useModel(projectId, modelId);
            log.debug("Existing model set.");
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to use model on project'+msg, errOptions));
        }
    },[conf, history, enqueueSnackbar]);

    const startProjectBrowserDownload = useCallback( (projectId, type) => {
        log.debug(`Starting download of project ${projectId} data of type ${type}`);
        window.location = getAPIBase(conf) + "projects/" + projectId + "/data?" + getAPITokenParameter(conf)
            + "&dataType=" + type;
    }, [conf]);

    const getProject = useCallback(async (projectId, hideError = false) => {
        try {
            if (!hideError)
                log.debug(`Loading project ${projectId}.`);
            const projectApi = new ProjectApi(conf);
            const r = await projectApi.getProject(projectId);
            if (!hideError)
                log.debug('Project loaded.');
            return r.data;
        } catch (e) {
            if (!hideError) {
                checkError(e, history, (msg) =>
                    enqueueSnackbar('Failed to get project' + msg, errOptions));
            } else {
                log.error(e);
            }
            return Promise.reject(e);
        }
    },[conf, history, enqueueSnackbar]);

    const deleteProject = useCallback( async (projectId) => {
        try {
            log.debug(`Deleting project ${projectId}`);
            const projectApi = new ProjectApi(conf);
            await projectApi.deleteProject(projectId);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to delete project'+msg,
                    errOptions));
        }
    }, [conf, history, enqueueSnackbar]);

    const getProjects = useCallback( async () => {
        try {
            const projectApi = new ProjectApi(conf);
            const projList = await projectApi.getProjects();
            log.debug(`Retrieved ${projList.data.length} projects.`);
            return projList.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get projects'+msg, errOptions));
            return Promise.reject(e);
        }
    }, [conf, history, enqueueSnackbar]);

    return {getProjects, getProject, deleteProject, downloadProjectData, startProjectBrowserDownload,
        updateProject, existingModelUse};
}

const AddProjectBlock = ({buttonText="Add Project"}) => {
    const history = useHistory();

    const {enqueueSnackbar} = useSnackbar();
    const {conf, user} = useContext(AppContext);

    const [nameError, setNameError] = useState("");
    const [projectName, setProjectName] = useState("");
    const [openOrgDlg, setOpenOrgDlg] = useState(false);

    const onAddProjectClick = () => {
        if (!isUserValid()) return;
        let name = projectName.trim();
        if (name.length < 1) {
            setProjectName(name);
            setNameError("A valid project name must be specified");
            return;
        }
        setNameError("");
        if (user.organizations.length > 1) {
            setOpenOrgDlg(true);
        } else {
            addProject(user.organizations[0]).then();
        }
    }

    async function addProject(orgIn) {
        const api = new ProjectApi(conf);
        try {
            let p = await api.addProject(orgIn.id, {name: projectName.trim()});
            history.push(`/main/projects/${p.data.id}`);
        } catch(e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to add project',
                    errOptions))
        }
    }

    const handleCloseOrgDlg = (orgIn) => {
        setOpenOrgDlg(false);
        if (orgIn != null) {
            addProject(orgIn).then();
        }
    }

    function isUserValid() {
        if (user.organizations == null || user.organizations.length === 0) {
            enqueueSnackbar('You are not a member of any organisation - please contact your administrator.', errOptions);
            return false;
        }
        return true;
    }

    return <Paper sx={{margin: 1, padding: 2}}>
        {user != null &&
            <SelectListDialog open={openOrgDlg}
                              onClose={handleCloseOrgDlg}
                              list={user.organizations}
                              title="Select Organisation"/>
        }

        <TextField value={projectName}
                   sx={{marginBottom: 2}}
                   fullWidth
                   error={nameError.length > 0}
                   helperText={nameError}
                   label="Project Name"
                   onChange={(event) => setProjectName(event.target.value)}/>
        <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={onAddProjectClick}
            startIcon={<AddIcon/>}>
            {buttonText}
        </Button>
    </Paper>
}

const SORT_ITEMS = [
    {key: "name", label: "Name"},
    {key: "tasks", label: "Task"},
    {key: "framework", label: "Framework"},
    {key: "id", label: "ID"},
];

/**
 * Displays list of projects
 * @author Kobus Grobler
 * @component
 */
export default function Projects({favorites = false, title}) {
    const history = useHistory();
    const match = useRouteMatch();
    const query = useQuery();
    const {enqueueSnackbar} = useSnackbar();
    const {conf, user} = useContext(AppContext);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openOrgDlg, setOpenOrgDlg] = useState(false);
    const [postOrgFunc, setPostOrgFunc] = useState(null);
    const [selectedProject, setSelectedProject] = useState(0);
    const [projects, setProjects] = useState(null);
    const [busyTests, setBusyTests] = useState(new Map()); // dict of prj id to busy tests
    const [filterProps] = useFilter();
    const [sortProps] = useSortBox(SORT_ITEMS[0].key);

    const [{setSettings}] = useUpdateProfile();
    const [{datasets}] = useLoadDatasets();
    const {getProjects, deleteProject} = useProjects();

    useEffect(() => {
        (async () => {
            log.debug(`op param: ${query.get('op')}`);
            try {
                const projList = await getProjects();
                let prjs;
                if (favorites) {
                    prjs = projList.filter(p => user.settings.favoriteProjects.includes(p.id));
                } else {
                    prjs = projList;
                }
                prjs = prjs.sort((a, b) => b.id - a.id);
                setProjects(prjs);
            } catch (e) {
                checkError(e, history, (msg) =>
                    enqueueSnackbar('Failed to get projects'+msg, errOptions));
            }
        })();
    }, [favorites, query, getProjects, history, enqueueSnackbar, user]);

    const onBusyTestRuns = useCallback((runs) => {
        if (projects != null) {
            projects.forEach(prj => {
                let tests = runs.reduce((acc, r) => {
                    if (r.projectId === prj.id) {
                        let test = acc.find(t => t.id === r.testId);
                        if (test === undefined) {
                            test = {id: r.testId, testRuns: []};
                            acc.push(test);
                        }
                        test.testRuns.push(r);
                    }
                    return acc;
                }, []);
                busyTests.set(prj.id, tests);
                return prj;
            });
            setBusyTests(new Map(busyTests));
        }
    },[projects]);

    useServerUpdates('/topic/testruns/busy', onBusyTestRuns);

    const onDeleteProject = (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            deleteProject(selectedProject).then(() => {
                setProjects(projects.filter(p => p.id !== selectedProject));
                enqueueSnackbar('Project deleted.');
            });
        }
    }

    const removeFromFavourites = (id) => {
        removeProjIdFromFavourites(user, id);
        setSettings(user.settings);
    }

    const addToFavourites = (id) => {
        addProjIdToFavourites(user, id);
        setSettings(user.settings);
    }

    const askDeleteProject = (id) => {
        setSelectedProject(id);
        setOpenConfirmDelete(true);
    }

    const askShareProject = (id) => {
        setSelectedProject(id);
        setPostOrgFunc("share");
        setOpenOrgDlg(true);
    }

    const shareProject = async (orgIn) => {
        try {
            enqueueSnackbar('Copying project...', {autoHideDuration: 3000});
            const api = new ProjectApi(conf);
            let r = await api.copyProject(selectedProject, {}, orgIn.id);
            enqueueSnackbar('Project copied.', {autoHideDuration: 2000});
            setProjects([r.data, ...projects]);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to copy project',
                    errOptions));
        }
    }

    const handleCloseOrgDlg = (orgIn) => {
        setOpenOrgDlg(false);
        if (orgIn != null) {
            switch(postOrgFunc) {
                case "share":
                    shareProject(orgIn).then();
                    break;
                default:
            }
        }
    }

    const projectUpdated = (project) => {
        setProjects(projects.map((item) => {
            if (project.id === item.id) {
                return {...project};
            }
            return item;
        }));
    }

    const onUpdateProjectName = async (id, name) => {
        log.debug(`Updating project ${id} with ${name}`);
        const api = new ProjectApi(conf);
        try {
            let r = await api.updateProject(id, {name: name});
            projectUpdated(r.data);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to update project',
                    errOptions));
        }
    }

    const handleExpansion = (_expanded, project) => {
        if (favorites) {
            history.push(`/main/projects/${project.id}`);
        } else {
            history.push(`${match.url}/${project.id}`);
        }
    };

    const sortItems = (a, b) => {
        let r = 0;
        if (sortProps.sortKey === 'tasks') {
            if (a.settings != null && b.settings && a.settings.tasks != null && b.settings.tasks != null) {
                r = a.settings.tasks[0].localeCompare(b.settings.tasks[0]);
            }
        } else if (sortProps.sortKey === 'framework') {
            if (a.model != null && b.model != null && a.model.frameworkType != null && b.model.frameworkType != null) {
                r = a.model.frameworkType.localeCompare(b.model.frameworkType);
            }
        } else {
            return sortItemsByProps(a, b, sortProps);
        }
        if (sortProps.sortDir) {
            r *= -1;
        }
        return r;
    }

    const inProjectFilter = (p) => {
        if (filterProps.filter.length > 0) {
            let ds = null;
            if (p.settings != null && datasets != null) {
                ds = datasets.find(d => d.id === p.settings.datasetId);
            }
            let filter = filterProps.filter.toLowerCase();
            return (p.id.toString() === filter ||
                p.name.toLowerCase().includes(filter) ||
                (p.modelName != null && p.modelName.toLowerCase().includes(filter)) ||
                (ds != null && ds.name.toLowerCase().includes(filter)) ||
                (p.settings != null &&
                    (p.settings.tasks != null && p.settings.tasks.find(t => t.startsWith(filter)))
                )
            );
        } else {
            return true;
        }
    }

    const getBusyProjectIndicator = (test) => {

        const testRun = getBusyTestRunFromStatus(test);

        return <Tooltip title={`Test: ${test.id} Status: ${testRun.message} Started By: ${testRun.initiator}`}>
                <span style={{marginLeft: "2em", display: 'inline-flex'}}>
            <CircularProgressWithLabel thickness={3}
                                       disableShrink
                                       {...getProgressAttribs(testRun)}
                                       size={35}/>
                    </span>
        </Tooltip>
    }

    return <><ErrorBoundary>
        <Grid container justifyContent={"center"}>
            {user != null &&
                <SelectListDialog open={openOrgDlg}
                                  onClose={handleCloseOrgDlg}
                                  list={user.organizations}
                                  title="Select Organisation"/>
            }
            <Grid item xs={6} lg={3}>
                <AlertDialog open={openConfirmDelete} title="Delete project?"
                             description="This will permanently delete the project and its data."
                             onClose={onDeleteProject}/>
                {!favorites &&
                    <AddProjectBlock/>
                }
            </Grid>
            <Grid item xs={12} style={{marginBottom: "10px"}}>
                <Grid container spacing={2} alignItems={"center"}>
                    {!favorites &&
                        <>
                    <Grid item xs={12}>
                        <SearchAndSort sortItems={SORT_ITEMS} sortProps={sortProps} filterProps={filterProps}/>
                    </Grid>
                        </>
                    }
                </Grid>
            </Grid>
            {favorites && projects !== null && projects.length > 0 &&
                <Grid item>
                    <Typography variant={"h6"}>
                        Favorite Projects
                    </Typography>
                </Grid>
            }

            <Grid item xs={12}>
                {projects !== null  && projects.filter(inProjectFilter).sort(sortItems).map((project) =>
                    <Accordion TransitionProps={{unmountOnExit: true}}
                               key={project.id}
                               onChange={(_event, expanded) =>
                                   handleExpansion(expanded, project)}>
                        <AccordionSummary>
                            <IconButton
                                size="small"
                                onFocus={(event) => event.stopPropagation()}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    askDeleteProject(project.id)
                                }}>
                                <DeleteIcon/>
                            </IconButton>
                            {isAdminUser(user) &&
                                <IconButton
                                    size="small"
                                    onFocus={(event) => event.stopPropagation()}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        askShareProject(project.id);
                                    }}>
                                    <CopyIcon/>
                                </IconButton>
                            }
                            {isProjIdInFavourites(user, project.id) ?
                                <IconButton
                                    size="small"
                                    onFocus={(event) => event.stopPropagation()}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        removeFromFavourites(project.id)
                                    }}>
                                    <FavoriteIcon/>
                                </IconButton>
                                :
                                <IconButton
                                    size="small"
                                    onFocus={(event) => event.stopPropagation()}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        addToFavourites(project.id)
                                    }}>
                                    <FavoriteBorderIcon/>
                                </IconButton>
                            }
                            <span style={{flexBasis: '80%', display: 'inline-flex'}}>
                                <Typography sx={{marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: "1em",
                                    marginRight: "1em",
                                    whiteSpace: 'nowrap'}} variant={"body1"}>
                                    {project.organization.name + ' - Project ' + project.id}
                                </Typography>
                                <span style={{marginTop: "auto", marginBottom: "auto", display: 'inline-flex'}}>
                                <ProjectTypeIndicator verified={project.verified}
                                                      tasks={project.settings != null ? project.settings.tasks : []}/>
                                {project.model != null &&
                                    <ModelTypeIndicator model={project.model} style={{marginRight: "5px", marginLeft: "5px"}}/>
                                }
                                </span>
                                <NameEditor title="Project Name"
                                            fullWidth
                                            style={{marginTop: "auto", marginBottom: "auto", minWidth: "20ch", display: 'inline-flex'}}
                                            name={project.name}
                                            id={project.id}
                                            onUpdateName={onUpdateProjectName}/>
                            </span>
                            <span style={{flexBasis: '20%', display: 'inline-flex'}}>
                                <span style={{marginLeft: "1em", display: 'inline-flex'}}>
                                {busyTests.get(project.id) != null && busyTests.get(project.id).map((t, idx) =>
                                    <React.Fragment key={idx}>
                                        {getBusyProjectIndicator(t)}
                                    </React.Fragment>
                                )}
                                </span>
                            </span>
                        </AccordionSummary>
                    </Accordion>
                )}
            </Grid>
        </Grid>
    </ErrorBoundary>
    </>
}

Projects.propTypes = {

};

export {useProjects, AddProjectBlock}
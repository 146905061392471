import React, {useContext, useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import Gauge from "./util/Gauge";
import Projects, {useProjects} from "./projects/Projects";
import ErrorBoundary from "./util/ErrorBoundary";
import AppContext from "./AppContext";
import {useDatasets} from "./datasets/Datasets";
import {useBenchmarks} from "./benchmarks/Benchmarks";

function Dashboard() {
    const {user} = useContext(AppContext);
    const {getDatasets} = useDatasets();
    const [projectCnt, setProjectCnt] = useState(null);
    const [benchmarkCnt, setBenchmarkCnt] = useState(null);
    const [datasetCnt, setDatasetCnt] = useState(null);

    const {getProjects} = useProjects();
    const {getBenchmarks} = useBenchmarks();

    useEffect(() => {
        (async () => {
            const projList = await getProjects();
            setProjectCnt(projList.length);
        })();
    }, [getProjects]);

    useEffect(() => {
        (async () => {
            const l = await getBenchmarks();
            setBenchmarkCnt(l.length);
        })();
    }, [getBenchmarks]);

    useEffect(() => {
        (async () => {
            let cnt = 0;
            if (user != null && user.organizations != null) {
                for (let org of user.organizations) {
                    let ds = await getDatasets(org.id);
                    cnt += ds.length;
                }
            }
            setDatasetCnt(cnt);
        })();
    }, [getDatasets, user]);

    return <><ErrorBoundary>
        <Grid container justifyContent="center" alignItems="stretch" spacing={1}>
            <Grid item xs>
                <Gauge title={"Projects"} val={projectCnt != null ? projectCnt : "..."}/>
            </Grid>
            <Grid item xs>
                <Gauge title={"Benchmarks"} val={benchmarkCnt != null ? benchmarkCnt : "..."}/>
            </Grid>
            <Grid item xs>
                <Gauge title={"Datasets"} val={datasetCnt != null ? datasetCnt : "..."}/>
            </Grid>
            <Grid item xs={12} mt={1}>
                <Projects favorites/>
            </Grid>
        </Grid>
    </ErrorBoundary></>
}

export default Dashboard;

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useContext, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
import {TestApi} from "../api";
import {checkError, errOptions, getLogger, handleBlur, useFloatInput} from "../util/util";
import AppContext from "../AppContext";
import DatasetSettings from "../datasets/DatasetSettings";
import DetectionAlgorithmSetting from "./DetectionAlgorithmSetting";
import {useLoadDataset} from "../datasets/Datasets";
import {useTests} from "./Test";
import TextField from "@mui/material/TextField";
import Webhooks from "../webhooks/Webhooks";
import Paper from "../util/Paper";
import SectionHeading from "../util/SectionHeading";
import Box from "@mui/material/Box";
import Wizard, {useWizard} from "./Wizard";

const log = getLogger("testwizard");


/**
 * Test configuration wizard.
 * @author Kobus Grobler
 * @component
 */
export default function DetectionTestSetupWizard({step = 0, project, test, settings = test.testSettings,
                                            testUpdated, datasetSettingsUpdated,
                                            assets, onFinish}) {
    const {conf} = useContext(AppContext);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [activeStep, setActiveStep, handleBack] = useWizard(step);
    const [{dataset}] = useLoadDataset(project.organization.id, project.settings.datasetId);
    const {updateTest} = useTests();
    const [deviationsProps, setDeviations] = useFloatInput(settings.detectionTestSettings.passDeviations == null ?
        2.5 : settings.detectionTestSettings.passDeviations, 0.0);

    const steps = ['Detection configuration', 'Validation Data loader', 'Poisoned Data loader', 'Integration'];

    const [completed] = useState([settings.configured, settings.configured, settings.configured, settings.configured]);

    const api = new TestApi(conf);

    const saveSettings = async (s) => {
        try {
            let t = await api.updateTest(test.id, {testSettings: s})
            testUpdated(t.data);
        } catch(e) {
            checkError(e, history, () => {
                enqueueSnackbar('Failed to update test', errOptions);
            });
        }
    }

    const onDeviationsChangeCb = () => {
        log.debug("deviations changed.");
        settings.detectionTestSettings.passDeviations = deviationsProps.value;
        saveSettings(settings);
    }

    function detectionTestConfigStep() {
        return <>
            <Grid item spacing={1} container justifyContent="center" mt={2}>
                <Grid item xs={7}>
                    <DetectionAlgorithmSetting tasks={project.settings.tasks}
                                               assets={assets}
                                               saveSettings={saveSettings}
                                               test={test}/>
                </Grid>
                <Grid item xs={7}>
                    <Paper variant="outlined">
                        <SectionHeading>
                            Test Criteria
                        </SectionHeading>
                        <Typography variant="body1" style={{textAlign:"left", marginBottom: "1em"}}
                                    color="textSecondary">Specify the multiple of standard deviations above the mean
                            class detection score in order to classify the dataset as poisoned.</Typography>
                        <TextField style={{minWidth: "15ch"}}
                                   {...deviationsProps}
                                   onBlur={e => handleBlur(e, settings.detectionTestSettings.passDeviations, setDeviations, onDeviationsChangeCb)}
                                   label="Multiple of standard deviations"
                                   type="number"
                                   variant="outlined"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    }

    function dataLoaderStep() {
        return <Box mt={2}><DatasetSettings test={test}
                                title={"Validation Dataloader Configuration"}
                                maxItemCount={project.organization.settings.maxItemCount}
                                orgId={project.organization.id}
                                datasetInstance={dataset}
                                settingsUpdated={datasetSettingsUpdated}
                                testUpdated={testUpdated}
        /></Box>
    }

    function poisonedDataLoaderStep() {
        return <Box mt={2}><DatasetSettings test={test}
                                title={"Poisoned Dataloader Configuration"}
                                maxItemCount={project.organization.settings.maxItemCount}
                                orgId={project.organization.id}
                                datasetInstance={dataset}
                                settings={test.testSettings.detectionTestSettings.poisonedDatasetSetting}
                                settingsUpdated={poisonedDSSettingsUpdated}
                                testUpdated={testUpdated}
        /></Box>
    }

    function isStepComplete(stp) {
        return completed[stp];
    }

    function getStepContent(stp) {
        switch (stp) {
            case 0:
                return detectionTestConfigStep();
            case 1:
                return dataLoaderStep();
            case 2:
                return poisonedDataLoaderStep();
            case 3:
                return <Box mt={2}><Webhooks test={test}/></Box>;
            default:
                return 'Unknown step';
        }
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            if (!settings.configured) {
                settings.configured = true;
                saveSettings(settings).then();
            }
            onFinish();
        } else {
            completed[activeStep] = true;
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const handleStep = (idx) => () => {
        if (idx > 0) {
            if (completed[idx-1])
                setActiveStep(idx);
        } else {
            setActiveStep(idx);
        }
    }

    const poisonedDSSettingsUpdated = async (datasetSettings) => {
        log.debug("Poisoned Dataset settings update requested.");
        test.testSettings.detectionTestSettings.poisonedDatasetSetting = datasetSettings;
        let rs = await updateTest(test.id, {
            testSettings: test.testSettings
        });
        testUpdated(rs);
    }

    return <Wizard steps={steps}
                   activeStep={activeStep}
                   handleStep={handleStep}
                   isStepComplete={isStepComplete}
                   handleBack={handleBack}
                   handleNext={handleNext}
                   getStepContent={getStepContent}
    />

}

DetectionTestSetupWizard.propTypes = {
    /**
     * The test instance
     */
    test: PropTypes.object.isRequired,

    /**
     * The project instance
     */
    project: PropTypes.object.isRequired,

    /**
     * The organizations assets
     */
    assets: PropTypes.object.isRequired,

    /**
     * The test update handler
     */
    testUpdated: PropTypes.func.isRequired,

    datasetSettingsUpdated: PropTypes.func.isRequired,

    /**
     * Optional starting step (defaults to 0)
     */
    step: PropTypes.number
}

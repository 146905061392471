/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React from "react";
import {
    Switch,
    Route,
    useRouteMatch, useHistory
} from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import './ProjectType.css'
import {SubmitButton} from "../util/SubmitButton";

export default function ProjectType() {
    const match = useRouteMatch();
    const history = useHistory();
    return <>
        <Switch>
            <Route exact path={`${match.path}`}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid container item xs={6} spacing={3} direction="column" alignItems="flex-end">
                        <Grid item>
                            <Card className="card" elevation={3}>
                                <CardMedia
                                    className="media"
                                    image="/img/robustness.svg"
                                    title="Adversarial Robustness"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Adversarial Robustness
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Adversarial Robustness measures a model's resilience against adversarial
                                        examples that are inputs with small, intentional feature perturbations that
                                        cause a machine learning model to make a false prediction.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <SubmitButton title="Start" onClick={() => {
                                        history.push(`${match.url}/projects`)
                                    }}/>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card className="card" elevation={3}>
                                <CardMedia
                                    className="media"
                                    image="/img/craftattack.svg"
                                    title="Adversarial Attack Crafting"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Adversarial Attack Crafting
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        This feature enables the tailoring of adversarial attacks to specific needs or a
                                        specific application. Only enabled for the professional subscription.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <SubmitButton title="Start" disabled onClick={() => {
                                        history.push(`${match.url}/craft`)
                                    }}/>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={3} direction="column" alignItems="flex-start">
                        <Grid item>
                            <Card className="card" elevation={3}>
                                <CardMedia
                                    className="media"
                                    image="/img/benchmark.svg"
                                    title="Benchmark and Evaluation"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Benchmark and Evaluation
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Evaluates the model on various metrics such as performance, energy/speed,
                                        biases,
                                        and explainability.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <SubmitButton title="Start" onClick={() => {
                                        history.push(`${match.url}/benchmarks`)
                                    }}/>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card className="card" elevation={3}>
                                <CardMedia
                                    className="media"
                                    image="/img/simulation.svg"
                                    title="Real life test simulation"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Real Life Test Simulation
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Access our repository of curated real life practical scenarios and adversarial
                                        attacks, challenging weather conditions and autonomous driving edge cases which
                                        can be used to obtain a more faithful estimate of the model robustness in the
                                        real world. Only enabled for the professional subscription.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <SubmitButton title="Start" disabled onClick={() => {
                                        //history.push(`${match.url}/craft`)
                                    }}/>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Route>
        </Switch>
    </>
}

import React, {useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import GraphIcon from "@mui/icons-material/Equalizer";
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import Grid from "@mui/material/Grid";
import Plot from "react-plotly.js/react-plotly";
import {StyledTableCell} from "./ReportTable";
import {paramSummary, printRound} from "../util/util";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import ReportSampleViewer from "./ReportSampleViewer";
import {DeleteReportButton, useReports} from "./Reports";

const DetectionGraph = ({summaryKey, title="", xtitle="class", ytitle="Score", summary, mean}) => {

    const getData = () => {
        let data = [{
            x: Object.keys(summary[summaryKey]),
            y: Object.keys(summary[summaryKey]).map(k => summary[summaryKey][k]),
            type: 'bar',
            name: ytitle
        }];

        if (mean == null) {
            if (summaryKey === 'score_variance') {
                mean = summary.avgVariance;
            } else if (summaryKey === 'score_mean') {
                mean = summary.avgScore;
            } else if (summaryKey === 'poison_percentage') {
                mean = summary.totalPercentageSamples;
            }
        }

        if (mean != null) {
            data.push({
                x: Object.keys(summary[summaryKey]),
                y: Object.keys(summary[summaryKey]).map(_k => mean),
                type: 'scatter',
                mode: 'lines',
                name: 'Mean'
            });
        }
        return data;
    }

    return <>
        <Grid container direction="column" alignContent="center">
            <Grid item xs={12}>
                <Plot data={getData()}
        layout={{
            title: title,
            xaxis: {
                tickangle: -45,
                title: {
                    text: xtitle,
                    font: {
                        size: 14,
                    }
                },
            },
            yaxis: {
                title: {
                    text: ytitle,
                    font: {
                        size: 14,
                    }
                },
            },
        }}
        config={{displaylogo: false}}
    />
            </Grid>
        </Grid>
    </>
}

const DetectionRow = ({row, onReportDeleted, detectionSettings}) => {
    const [showGraph, setShowGraph] = useState(false);
    const [selectedSummary, setSelectedSummary] = useState(null);
    const [summaryKey, setSummaryKey] = useState(null);
    const [summaryTitle, setSummaryTitle] = useState(null);
    const {deleteReport} = useReports();

    const onDeleteReport = (reportToDelete) => {
        deleteReport(reportToDelete).then(() => onReportDeleted(reportToDelete));
    }

    const reportSummaryTooltip = (summary, poison) => {
        return (<>
            <Typography variant="h6">
                Report summary
            </Typography>
            <Typography variant="body1">
                Run ID: {row.runId}
            </Typography>
            <Typography variant="body1">
                Report ID: {row.reports[0].id}
            </Typography>
            <Typography>
                {poison.poisoned ?
                    <>Poison detected in class: {poison.class}</> :
                    <>
                        Poison detected: No
                    </>
                }
            </Typography>
            </>)
    }

    return <>
        {selectedSummary != null &&
        <OkCancelDialog hideCancel={true}
                        dialogProps={{fullWidth: true, maxWidth: 'lg'}}
                        onClose={()=>setShowGraph(false)}
                        title={""} open={showGraph}>
            <DetectionGraph summaryKey={summaryKey} ytitle={summaryTitle}
                            summary={selectedSummary}/>
        </OkCancelDialog>
        }
        {row.detectionSummaries.map( (summary, rowIdx) =>
            <TableRow key={rowIdx}>
                <StyledTableCell>
                    <span>
                        <DeleteReportButton id={row.reports[0].id} onDeleteReport={onDeleteReport}/>
                        <Tooltip title={reportSummaryTooltip(summary, row.hasPoison(summary, detectionSettings.passDeviations))}
                                 disableInteractive={false} leaveDelay={200}>
                            <InfoIcon size="small" color={"primary"} style={{verticalAlign: "bottom", marginBottom:"3px"}}/>
                        </Tooltip>
                        {row.hasPoison(summary, detectionSettings.passDeviations).poisoned &&
                            <Tooltip title="Dataset may be poisoned">
                                <WarningIcon size="small" sx={{color: 'warning.dark', verticalAlign: "bottom", marginBottom: "3px"}}/>
                            </Tooltip>
                        }
                    </span>
                    <ReportSampleViewer id={row.reports[0].id}/>
                </StyledTableCell>
                <StyledTableCell>
                    {paramSummary(summary.params)}
                </StyledTableCell>
                <StyledTableCell>
                    {printRound(summary.totalPercentageSamples,2)}
                    <IconButton
                        size="small"
                        onClick={() => {
                            setSelectedSummary(summary);
                            setShowGraph(true);
                            setSummaryKey("poison_percentage");
                            setSummaryTitle("Poison %");
                        }}>
                        <GraphIcon/>
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                    {summary.totalPoisonSamples}
                    <IconButton
                        size="small"
                        onClick={() => {
                            setSelectedSummary(summary);
                            setShowGraph(true);
                            setSummaryKey("poison_detections");
                            setSummaryTitle("Poison samples");
                        }}>
                        <GraphIcon/>
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                    {printRound(summary.avgScore,2)}
                    <IconButton
                        size="small"
                        onClick={() => {
                            setSelectedSummary(summary);
                            setShowGraph(true);
                            setSummaryKey("score_mean");
                            setSummaryTitle("Poison score mean");
                        }}>
                        <GraphIcon/>
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                    {printRound(summary.avgVariance,2)}
                    <IconButton
                        size="small"
                        onClick={() => {
                            setSelectedSummary(summary);
                            setShowGraph(true);
                            setSummaryKey("score_variance");
                            setSummaryTitle("Poison score variance");
                        }}>
                        <GraphIcon/>
                    </IconButton>
                </StyledTableCell>
            </TableRow>
        )}
    </>
}

export default function DetectionReport({model, onReportDeleted, detectionSettings}) {
    return <>
        <TableContainer style={{maxHeight: "90vh",
            overflowY: "scroll",
            // set to max screen width, otherwise report of many metrics causes project component layout issues
            maxWidth: '90vw'}}>
            <Table style={{width: "100%", minWidth: "50em", borderCollapse: "collapse"}} stickyHeader aria-label="report table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={{textAlign: "center"}} colSpan="100%">
                            <Typography variant="h6">Poison Detection Reports</Typography>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>
                            <Typography variant="body1">Test run</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="body1">Test parameters</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="body1">Dataset &#37; poisoned</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="body1">Dataset total poisoned samples</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="body1">Dataset score mean</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography variant="body1">Dataset score variance</Typography>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {model.rows.map((row, rowIdx) =>
                        <React.Fragment key={rowIdx}>
                            <DetectionRow row={row}
                                          onReportDeleted={onReportDeleted}
                                          detectionSettings={detectionSettings}/>
                        </React.Fragment>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useDatasets} from "./Datasets";
import SelectListDialog from "../dialogs/SelectListDialog";
import {getLogger} from "../util/util";

const log = getLogger("datasets");

/**
 * Enable/Disable dataset list by format supported task
 * @param assets assets instance containing datasetFormats
 * @param dsets the list of datasets
 * @param tasks the tasks
 * @returns {*[]|*} the modified list of datasets.
 * @author Kobus Grobler
 */
function enableDSByTask(assets, dsets, tasks) {
    if (dsets == null) {
        return [];
    }
    dsets.forEach( ds => {
        let fmt = assets.datasetFormats.find(fmt => ds.format === fmt.name);
        if (fmt !== undefined) {
            ds.enabled = tasks.every(task => fmt.tasks.includes(task));
            if (ds.valid != null && ds.valid === false) {
                ds.enabled = false;
            }
        } else {
            log.warn("Format not found: "+ds.format);
            ds.enabled = false;
        }
    });
    return dsets;
}

/**
 * Allows selection of a dataset
 * @author Kobus Grobler
 * @component
 */
export default function SelectDataset({organization, assets, tasks, dataset, onDatasetsUpdated, onDatasetSelected,
                                          ...props}) {
    const [openDatasetDlg, setOpenDatasetDlg] = useState(false);
    const {getDatasets} = useDatasets();
    const [datasets, setDatasets] = useState(props.datasets);

    useEffect(() => {
        if (datasets == null) {
            getDatasets(organization.id).then( dsetsLoaded => {
                if (dsetsLoaded != null) {
                    let dsets = enableDSByTask(assets, dsetsLoaded, tasks);
                    setDatasets(dsets);
                    if (onDatasetsUpdated)
                        onDatasetsUpdated(dsets);
                }
            });
        }
    }, [assets, tasks, getDatasets, organization.id, datasets, onDatasetsUpdated]);

    useEffect( () => {
        if (datasets != null) {
            setDatasets(datasets => enableDSByTask(assets, datasets, tasks));
        }
    }, [assets, tasks, datasets]);

    const handleCloseDatasetDlg = (value) => {
        setOpenDatasetDlg(false);
        if (value != null) {
            onDatasetSelected(value);
        }
    }

    return (<Grid container spacing={1} direction="column">
        {datasets != null &&
        <SelectListDialog open={openDatasetDlg}
                          dialogProps={{maxWidth: 'md'}}
                          onClose={handleCloseDatasetDlg}
                          list={datasets}
                          title="Select Dataset"/>
        }
        <Grid item>
            <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => setOpenDatasetDlg(true)}>
                Select Dataset
            </Button>
        </Grid>
        {dataset != null &&
        <Grid item>
            <Typography variant="subtitle1">
                Selected dataset: {dataset.name}
            </Typography>
            <Typography variant='subtitle2' color="textSecondary">
                {dataset.description}
            </Typography>
        </Grid>
        }
    </Grid>);
}

SelectDataset.propTypes = {
    /**
     * The organization
     */
    organization: PropTypes.object.isRequired,

    /**
     * The previously selected dataset (optional)
     */
    dataset: PropTypes.object,

    /**
     * Called when a ds is selected
     */
    onDatasetSelected: PropTypes.func.isRequired,

    /**
     * Available assets
     */
    assets: PropTypes.object.isRequired,

    /**
     * Optionally array of datasets
     */
    datasets: PropTypes.array,

    /**
     * Called when the datasets have been updated
     */
    onDatasetsUpdated: PropTypes.func,

    /**
     * The AI task that is being performed.
     */
    tasks: PropTypes.array.isRequired,
}

export {enableDSByTask}
/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import PreviewIcon from '@mui/icons-material/Pageview';
import DoneIcon from '@mui/icons-material/Done';
import CopyIcon from '@mui/icons-material/FileCopy';
import BackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from "prop-types";
import SelectDataset from "../datasets/SelectDataset";
import TransformSelection from "../transforms/TransformSelection";
import ModelConfig from "../models/ModelConfig";
import {Configuration} from "../api";
import {getLogger, isRemoteAPISupportedByDataset, shared} from "../util/util";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import ModelCopyUpdate from "../models/ModelCopyUpdate";
import {useModel} from "../models/Model";
import {useProjects} from "./Projects";
import {useOrgs} from "../organizations/Organization";
import {useDatasets} from "../datasets/Datasets";
import {SubmitButton} from "../util/SubmitButton";
import * as Util from "../util/util";

const log = getLogger("projectwizard");

const MODEL_SOURCE_NEW = "new";
const MODEL_SOURCE_EXISTING = "existing";

function SelectDatasetStep({assets, project, tasks, dataset, setDataset, saveSettings}) {
    const {uploadClassMappingFile} = useOrgs();
    const {getDatasetClasses} = useDatasets();
    const [classNamesFromDS, setClassNamesFromDS] = useState(project.settings.useClassNamesFromDataset);
    const [useRemoteApi, setUseRemoteApi] = useState(project.settings.useRemoteApi==null ? false : project.settings.useRemoteApi);
    const [transforms, setTransforms] = useState(project.settings.transforms);
    const [classNames, setClassNames] = useState(null);
    const [openClassView, setOpenClassView] = useState(false);

    useEffect(() => {
        setClassNamesFromDS(project.settings.useClassNamesFromDataset);
        setUseRemoteApi(project.settings.useRemoteApi);
    },[project.settings.useClassNamesFromDataset, project.settings.useRemoteApi]);

    useEffect(() => {
        setTransforms(project.settings.transforms)
    },[project.settings.transforms]);

    useEffect(() => {
        setClassNames(null);
    },[dataset]);

    const onDatasetsUpdated = (dsets) => {
        if (project.settings.datasetId) {
            let dset = dsets.find((ds) => ds.id === project.settings.datasetId);
            setDataset(dset);
        }
    }

    const onDatasetSelected = async (ds) => {
        let settings = project.settings;
        settings.datasetId = ds.id;
        settings.transforms = ds.transforms;
        let p = await saveSettings(settings);
        if (p != null) {
            setDataset(ds);
        }
    }

    const onTransformsUpdated = async (updated) => {
        log.debug("updated transforms.");
        let settings = project.settings;
        settings.transforms = updated;
        let p = await saveSettings(settings);
        if (p != null)
            setTransforms(updated);
    }

    const handleCB = async (e) => {
        let settings = project.settings;
        settings[e.target.name] = e.target.checked;
        saveSettings(settings);
    }

    const viewClassNames = async () => {
        let localClassNames = classNames;
        if (classNames == null) {
            localClassNames = await getDatasetClasses(project.organization.id, dataset.id);
            setClassNames(localClassNames);
        }

        if (localClassNames != null) {
            setOpenClassView(true);
        }
    }

    async function onFileUpload(param, name, file) {
        return await uploadClassMappingFile(project.organization.id, dataset.id, file);
    }

    return <Grid direction="column" container item alignContent="center" mt={2}>
        <Grid item xs>
            <SelectDataset
                onDatasetSelected={onDatasetSelected}
                dataset={dataset}
                onDatasetsUpdated={onDatasetsUpdated}
                organization={project.organization}
                tasks={tasks}
                assets={assets}
            />
        </Grid>
        {dataset != null &&
            <Grid item>
                <TransformSelection availableTransforms={assets.transforms}
                                    onTransformsUpdated={onTransformsUpdated}
                                    format={dataset.format}
                                    assets={assets}
                                    transforms={transforms}
                                    organization={project.organization}
                                    onFileUpload={onFileUpload}
                />
                {(dataset.format === 'CocoDetection' || dataset.format === 'VOCSegmentation' ||
                        dataset.format === 'CocoSegmentation' || dataset.format === 'MNIST' || dataset.format === 'ImageNet'
                        || dataset.format === 'Cifar10' || dataset.format === 'Mapillary') &&
                    <>
                        {classNames != null &&
                            <OkCancelDialog open={openClassView}
                                            title="Dataset classes"
                                            dialogProps={{fullWidth: true, maxWidth: 'md'}}
                                            onClose={() => {
                                                setOpenClassView(false);
                                            }}
                                            hideCancel={true}>
            <span>
                {Object.keys(classNames).map((classId) =>
                    <div key={classId}>{classId}: {classNames[classId]}</div>
                )}
            </span>
                            </OkCancelDialog>
                        }
                        {isRemoteAPISupportedByDataset(dataset) &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    name="useRemoteApi"
                                    checked={useRemoteApi}
                                    onChange={handleCB}
                                    inputProps={{'aria-label': 'useRemoteApi checkbox'}}
                                />
                            }
                            label="Use remote dataset API"
                        />}

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="useClassNamesFromDataset"
                                    color="primary"
                                    checked={classNamesFromDS}
                                    onChange={handleCB}
                                    inputProps={{'aria-label': 'classnames checkbox'}}
                                />
                            }
                            label="Use class names from dataset"
                        />
                        <Tooltip title="View class names">
                            <IconButton
                                size="small"
                                onClick={viewClassNames}>
                                <PreviewIcon/>
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </Grid>
        }
    </Grid>
}

function SelectTaskStep({project, handleTaskChange}) {

    return <Grid container spacing={1} sx={{margin: 1}} justifyContent={"center"}>
        <Grid item xs={4}>
            <Typography variant="body1">
                Select the tasks that will be used in this project.
                <br/>
                The model and dataset need to support all the selected tasks.
            </Typography>
        </Grid>
        <Grid item xs={12}/>
        <Grid item container xs={4}>
            {shared.TaskEnum.values().filter(t => !(t === shared.TaskEnum.pose || t === shared.TaskEnum.odometry))
                .map((task, idx) =>
                <Grid key={idx} item>
                <FormControlLabel
                                  control={
                                      <Checkbox
                                          color="primary"
                                          checked={project.settings.tasks != null ?
                                              project.settings.tasks.indexOf(task.name) > -1 : false}
                                          onChange={handleTaskChange}
                                          name={task.name}
                                      />
                                  }
                                  label={task.displayName()}
                /></Grid>)
            }
        </Grid>
    </Grid>
}

/**
 * Project configuration wizard.
 * @author Kobus Grobler
 * @component
 */
export default function ProjectSetupWizard({conf, assets, model, project, onFrameworkTypeUpdated, onEnvironmentUpdated,
                                               projectUpdated, onModelUpdated, onFinish, step}) {
    const {updateProject, existingModelUse} = useProjects();
    const [activeStep, setActiveStep] = useState(step == null ? 0 : step);
    const [dataset, setDataset] = useState(null);
    const [modelType, setModelType] = useState(MODEL_SOURCE_NEW);
    const [models, setModels] = useState(null);
    const [existingModelId, setExistingModelId] = useState(0);
    const [modelCopyOpen, setModelCopyOpen] = useState(false);
    const [{getModels}] = useModel();

    const steps = ['Model source', 'Select model', 'Configure data and transforms', 'Configure model'];

    useEffect(() => {
        (async () => {
            setModels(await getModels());
        })();
    },[getModels]);

    useEffect(() => {
        if (project.settings != null) {
            if (models != null) {
                const sourceModel = models.find(m => m.id === project.settings.sourceModelId);
                if (sourceModel != null) {
                    setExistingModelId(project.settings.sourceModelId);
                    if (project.settings.sourceModelId > 0) {
                        setModelType(MODEL_SOURCE_EXISTING);
                    }
                } else {
                    log.info("Source model not found.");
                }
            } else {
                log.debug("Models not loaded yet.");
            }
        }
    }, [project.settings, models]);

    async function saveSettings(settings) {
        let p = await updateProject(project.id, {settings: settings});
        if (p != null) {
            projectUpdated(p);
        }
        return p;
    }

    const handleTaskChange = (e) => {
        let settings = project.settings;
        if (settings.tasks == null) {
            settings.tasks = [];
        }
        const idx = settings.tasks.indexOf(e.target.name);
        if (idx > -1) {
            settings.tasks.splice(idx, 1);
        } else {
            settings.tasks.push(e.target.name);
        }
        setDataset(null); // re-select DS (task changed)
        settings.datasetId = null;
        saveSettings(settings).then().catch();
    }

    // const onTaskSelected = async (e) => {
    //     let settings = project.settings;
    //     if (e.target.value === "") {
    //         setTask("");
    //         return;
    //     }
    //     settings.tasks = [e.target.value]; // since we only support 1 currently
    //     setDataset(null); // re-select DS (task changed)
    //     settings.datasetId = null;
    //     let p = await saveSettings(settings);
    //     if (p != null) {
    //         setTask(e.target.value);
    //     }
    // }

    function selectModelSource() {

        const handleChange = async (e) => {
            setModelType(e.target.value);
        }

        return <Grid direction="column" container item alignContent="center">
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Model source</FormLabel>
                    <RadioGroup aria-label="model type" value={modelType} onChange={handleChange}>
                        <FormControlLabel color="primary" value={MODEL_SOURCE_NEW} control={<Radio color="primary" />}
                                          label="Add and configure a new model" />
                        <FormControlLabel value={MODEL_SOURCE_EXISTING} control={<Radio color="primary" />}
                                          label="Use an available model" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    }

    function selectExistingModel() {
        const onModelSelected = async (e) => {
            setExistingModelId(e.target.value);
            if (e.target.value > 0) {
                const r = await existingModelUse(project.id, e.target.value);
                if (r != null) {
                    projectUpdated(r);
                }
            }
        }

        return <Grid direction="column" container item alignContent="center" spacing={1} sx={{margin: 1}}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    Select an existing model
                </Typography>
            </Grid>
            <Grid item>
                <TextField sx={{minWidth: "15ch"}}
                            select
                           SelectProps={{
                               native: true,
                           }}
                           label="Model"
                           value={existingModelId}
                           onChange={onModelSelected}>
                    <option value="">
                    </option>
                    {models.map((model) =>
                        <option key={model.id} value={model.id}>
                            {model.name}
                        </option>
                    )}
                </TextField>
            </Grid>
        </Grid>
    }

    function modelConfigStep() {
        return (model != null && <ModelConfig conf={conf} assets={assets}
                             project={project}
                             model={model}
                             frameworkTypeUpdated={onFrameworkTypeUpdated}
                             environmentUpdated={onEnvironmentUpdated}
                             projectUpdated={projectUpdated}
                             onModelUpdated={onModelUpdated}
                             numClasses={model.numClasses == null? 2 : model.numClasses}
        />);
    }

    function isStepComplete(step) {
        switch (step) {
            case 0:
                return true;
            case 1:
                if (modelType === MODEL_SOURCE_NEW) {
                    return project.settings.tasks != null && project.settings.tasks.length > 0;
                } else {
                    return existingModelId > 0;
                }
            case 2:
                if (dataset != null && project.settings.transforms != null) {
                    let valid = true;
                    for (const t of project.settings.transforms) {
                        const itemDef = assets.transforms.find(def => t.id === def.id);
                        if (!Util.requiredParamsFilled(itemDef.params, t.params)) {
                            valid = false;
                            break
                        }
                    }
                    return valid;
                }
                return false;
            case 3:
                return project.verified && project.settings.headSettings != null;
            default:
                return false;
        }
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return selectModelSource();
            case 1:
                if (modelType === MODEL_SOURCE_NEW) {
                    return <SelectTaskStep project={project} handleTaskChange={handleTaskChange}/>;
                } else {
                    return selectExistingModel();
                }
            case 2:
                return <SelectDatasetStep assets={assets}
                                          project={project}
                                          tasks={project.settings.tasks}
                                          dataset={dataset}
                                          setDataset={setDataset}
                                          saveSettings={saveSettings}/>
            case 3:
                return modelConfigStep();
            default:
                return 'Unknown step';
        }
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            if (project.verified) {
                onFinish();
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    return (
        <Grid container>
            <ModelCopyUpdate onClose={() => setModelCopyOpen(false)}
                             conf={conf}
                             open={modelCopyOpen}
                             project={project}
                             dialogProps={{fullWidth: true, maxWidth: 'md'}}/>
            <Grid item xs={12}>
                <Typography>
                    Project Configuration
                    <Tooltip title="Project Configuration Help">
                    <IconButton
                        color="primary"
                        onClick={() => window.open(
                            process.env.REACT_APP_DOCS_BASE + "modules/general/quickstart.html",
                            '_blank')}
                        aria-label="Help"
                        size="large">
                        <HelpIcon/>
                    </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
                <Grid container>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {getStepContent(activeStep)}
                        </Grid>
                        <Grid container item justifyContent="center">
                            {activeStep > 0 &&
                            <SubmitButton variant="text"
                                          title="Back" onClick={handleBack} startIcon={<BackIcon/>}/>
                            }

                            <SubmitButton title={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                          disabled={!isStepComplete(activeStep)}
                                          onClick={handleNext}
                                          startIcon={activeStep === steps.length - 1 ? <DoneIcon/> : <></>}/>
                            {activeStep === steps.length - 1 && isStepComplete(activeStep) &&
                            <Tooltip title="Make the model available to other projects">
                                <SubmitButton startIcon={<CopyIcon/>}
                                              onClick={() => setModelCopyOpen(true)}>
                                    Re-Use Model
                                </SubmitButton>
                            </Tooltip>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

ProjectSetupWizard.propTypes = {
    /**
     * The API configuration
     */
    conf: PropTypes.instanceOf(Configuration).isRequired,

    /**
     * The project instance
     */
    project: PropTypes.object.isRequired,

    /**
     * The organizations assets
     */
    assets: PropTypes.object.isRequired,

    /**
     * The project update handler
     */
    projectUpdated: PropTypes.func.isRequired,

    onNumClassesChanged: PropTypes.func.isRequired,
    /**
     * Optional starting step (defaults to 0)
     */
    step: PropTypes.number
}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {DataLoaderConfiguration} from "../datasets/DatasetSettings";
import {getLogger} from "../util/util";
const log = getLogger("benchmark.datasetsettings");

/**
 * Allows configuration of benchmark dataset loader
 * @author Kobus Grobler
 * @component
 */
export default function BenchmarkDatasetSettings({test, settingsUpdated, dsLen, maxItemCount}) {
    const [dataset] = useState({format:""});
    const settings = test.datasetSetting;

    useEffect(() => {
        dataset.datasetLength = dsLen;
    },[dsLen]);

    const onSettingsChanged = (name, value) => {
        log.debug(`${name} changed to ${value}`);
        settings[name] = value;
        settingsUpdated(settings);
    }

    return <DataLoaderConfiguration
        dataset={dataset}
        maxItemCount={maxItemCount}
        showDatasetDetails={false}
        indexType={settings.selectionType} onIndexTypeChange={(value) => onSettingsChanged('selectionType', value)}
        fraction={settings.fraction} onFractionChange={(value) => onSettingsChanged( 'fraction', value)}
        shuffle={settings.shuffle} handleShuffleCb={(value) => onSettingsChanged('shuffle', value)}
        rangeStart={settings.startIdx} onStartChange={(value) => onSettingsChanged( 'startIdx', value)}
        rangeEnd={settings.endIdx} onEndChange={(value) => onSettingsChanged( 'endIdx', value)}
        numItems={settings.numItems} onNumItemsChange={(value) => onSettingsChanged( 'numItems', value)}
        indexes={settings.indexes} onIndexesChange={(value) => onSettingsChanged( 'indexes', value)}
        workers={settings.workers} onWorkersChangeCb={(value) => onSettingsChanged( 'workers', value)}
        batchSize={settings.batchSize} onBatchSizeChangeCb={(value) => onSettingsChanged( 'batchSize', value)}
    />
}

BenchmarkDatasetSettings.propTypes = {
    /**
     * The test instance
     */
    test: PropTypes.object.isRequired,

    /**
     * The settings updated function
     */
    settingsUpdated: PropTypes.func.isRequired,

    maxItemCount: PropTypes.number.isRequired,

};

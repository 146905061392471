import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import {useState} from "react";

const useFilter = () => {
    const [filter, setFilter] = useState("");
    return [{filter, onChange: val => { setFilter(val)}}];
}

const namedItemFilter = (filter, item) => {
    if (filter.length > 0) {
        let flower = filter.toLowerCase();
        return item.name.toLowerCase().includes(flower)
            //|| (item.organization != null && item.organization.name.toLowerCase().includes(flower));
    }
    return true;
}

/**
 * Utility component to show a search field
 * @param title
 * @param filter
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
export default function SearchBox({title="Search", filter, onChange}) {
    return <>
    <TextField value={filter}
              label={title}
              aria-label="search"
              InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                          <SearchIcon color="action"/>
                      </InputAdornment>
                  ),
              }}
              onChange={(e) => onChange(e.target.value)}/>
    </>
}

export {useFilter, namedItemFilter}

/* tslint:disable */
/* eslint-disable */
/**
 * GuardAI Platform API
 * GuardAI is an adversarial security and robustness assessment platform for AI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: guardaisupport@navinfo.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AIFilter
 */
export interface AIFilter {
    /**
     * 
     * @type {number}
     * @memberof AIFilter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AIFilter
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIFilter
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AIFilter
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AIFilter
     */
    'parameters'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AIFilter
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AIFilter
     */
    'lossName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIFilter
     */
    'lossParams'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AIFilter
     */
    'group'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AIFilter
     */
    'paper_link'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIFilter
     */
    'codebase_name'?: string;
}
/**
 * 
 * @export
 * @interface APIKeyResponse
 */
export interface APIKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof APIKeyResponse
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIKeyResponse
     */
    'keyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIKeyResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIKeyResponse
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIKeyResponse
     */
    'lastUsedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIKeyResponse
     */
    'expiryDate'?: string;
}
/**
 * 
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * 
     * @type {Array<BoundingBox>}
     * @memberof Annotation
     */
    'bboxes'?: Array<BoundingBox>;
    /**
     * 
     * @type {Array<Segmentation>}
     * @memberof Annotation
     */
    'segmentations'?: Array<Segmentation>;
}
/**
 * 
 * @export
 * @interface AssetsResponse
 */
export interface AssetsResponse {
    /**
     * 
     * @type {string}
     * @memberof AssetsResponse
     */
    'adversarialAttacks'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsResponse
     */
    'metaAttacks'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsResponse
     */
    'noises'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsResponse
     */
    'domainAdaptations'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsResponse
     */
    'metrics'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsResponse
     */
    'defenses'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsResponse
     */
    'datasetFormats'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetsResponse
     */
    'losses'?: string;
}
/**
 * 
 * @export
 * @interface AuthorizedUserResponse
 */
export interface AuthorizedUserResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUserResponse
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUserResponse
     */
    'jwt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUserResponse
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizedUserResponse
     */
    'registered'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizedUserResponse
     */
    'autoEnrol'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedUserResponse
     */
    'roles'?: string;
    /**
     * 
     * @type {UserSettings}
     * @memberof AuthorizedUserResponse
     */
    'settings'?: UserSettings;
}
/**
 * 
 * @export
 * @interface BenchmarkProject
 */
export interface BenchmarkProject {
    /**
     * 
     * @type {number}
     * @memberof BenchmarkProject
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BenchmarkProject
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface BenchmarkRequest
 */
export interface BenchmarkRequest {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkRequest
     */
    'name'?: string;
    /**
     * 
     * @type {BenchmarkSettings}
     * @memberof BenchmarkRequest
     */
    'settings'?: BenchmarkSettings;
}
/**
 * 
 * @export
 * @interface BenchmarkResponse
 */
export interface BenchmarkResponse {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkResponse
     */
    'uid'?: string;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkResponse
     */
    'id'?: number;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof BenchmarkResponse
     */
    'organization'?: OrganizationResponse;
    /**
     * 
     * @type {BenchmarkSettings}
     * @memberof BenchmarkResponse
     */
    'settings'?: BenchmarkSettings;
}
/**
 * 
 * @export
 * @interface BenchmarkSettings
 */
export interface BenchmarkSettings {
    /**
     * 
     * @type {Array<string>}
     * @memberof BenchmarkSettings
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<BenchmarkTest>}
     * @memberof BenchmarkSettings
     */
    'tests'?: Array<BenchmarkTest>;
    /**
     * 
     * @type {Array<BenchmarkProject>}
     * @memberof BenchmarkSettings
     */
    'projects'?: Array<BenchmarkProject>;
}
/**
 * 
 * @export
 * @interface BenchmarkTest
 */
export interface BenchmarkTest {
    /**
     * 
     * @type {number}
     * @memberof BenchmarkTest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkTest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkTest
     */
    'status'?: BenchmarkTestStatusEnum;
    /**
     * 
     * @type {Array<DefenseResponse>}
     * @memberof BenchmarkTest
     */
    'defenses'?: Array<DefenseResponse>;
    /**
     * 
     * @type {DatasetSetting}
     * @memberof BenchmarkTest
     */
    'datasetSetting'?: DatasetSetting;
    /**
     * 
     * @type {{ [key: string]: Array<Metric>; }}
     * @memberof BenchmarkTest
     */
    'metrics'?: { [key: string]: Array<Metric>; };
    /**
     * 
     * @type {Array<PassFailCriteria>}
     * @memberof BenchmarkTest
     */
    'passFailCriteria'?: Array<PassFailCriteria>;
    /**
     * 
     * @type {boolean}
     * @memberof BenchmarkTest
     */
    'configured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BenchmarkTest
     */
    'storeIntermediateData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BenchmarkTest
     */
    'storeCompositeImages'?: boolean;
}

export const BenchmarkTestStatusEnum = {
    Idle: 'IDLE',
    Initializing: 'INITIALIZING',
    Running: 'RUNNING',
    Done: 'DONE',
    Err: 'ERR',
    Cancelled: 'CANCELLED',
    Submitted: 'SUBMITTED'
} as const;

export type BenchmarkTestStatusEnum = typeof BenchmarkTestStatusEnum[keyof typeof BenchmarkTestStatusEnum];

/**
 * The test update request.
 * @export
 * @interface BenchmarkTestRequest
 */
export interface BenchmarkTestRequest {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkTestRequest
     */
    'name'?: string;
    /**
     * 
     * @type {DefenseResponse}
     * @memberof BenchmarkTestRequest
     */
    'defense'?: DefenseResponse;
}
/**
 * 
 * @export
 * @interface BenchmarkTestStatusResponse
 */
export interface BenchmarkTestStatusResponse {
    /**
     * 
     * @type {Array<TestRunResponse>}
     * @memberof BenchmarkTestStatusResponse
     */
    'testRuns'?: Array<TestRunResponse>;
}
/**
 * 
 * @export
 * @interface BoundingBox
 */
export interface BoundingBox {
    /**
     * 
     * @type {Array<number>}
     * @memberof BoundingBox
     */
    'bbox'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ClassMappingFileRef
 */
export interface ClassMappingFileRef {
    /**
     * 
     * @type {string}
     * @memberof ClassMappingFileRef
     */
    'ref'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassMappingFileRef
     */
    'contentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassMappingFileRef
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClassMappingFileRef
     */
    'datasetId'?: number;
}
/**
 * 
 * @export
 * @interface CraftedTestSettings
 */
export interface CraftedTestSettings {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CraftedTestSettings
     */
    'params'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<Rectangle>}
     * @memberof CraftedTestSettings
     */
    'regions'?: Array<Rectangle>;
    /**
     * 
     * @type {number}
     * @memberof CraftedTestSettings
     */
    'itemIdx'?: number;
    /**
     * 
     * @type {string}
     * @memberof CraftedTestSettings
     */
    'fromClass'?: string;
    /**
     * 
     * @type {string}
     * @memberof CraftedTestSettings
     */
    'toClass'?: string;
}
/**
 * 
 * @export
 * @interface DataSet
 */
export interface DataSet {
    /**
     * 
     * @type {number}
     * @memberof DataSet
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataSet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSet
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSet
     */
    'format'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataSet
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSet
     */
    'predefined'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSet
     */
    'valid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataSet
     */
    'error'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DataSet
     */
    'params'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<Transform>}
     * @memberof DataSet
     */
    'transforms'?: Array<Transform>;
    /**
     * 
     * @type {DatasetSettings}
     * @memberof DataSet
     */
    'settings'?: DatasetSettings;
    /**
     * 
     * @type {number}
     * @memberof DataSet
     */
    'datasetLength'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSet
     */
    'tasks'?: Array<string>;
}
/**
 * The dataset update request.
 * @export
 * @interface DatasetRequest
 */
export interface DatasetRequest {
    /**
     * 
     * @type {string}
     * @memberof DatasetRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetRequest
     */
    'description'?: string;
    /**
     * 
     * @type {DatasetSettings}
     * @memberof DatasetRequest
     */
    'settings'?: DatasetSettings;
    /**
     * 
     * @type {string}
     * @memberof DatasetRequest
     */
    'format'?: string;
    /**
     * 
     * @type {Array<Transform>}
     * @memberof DatasetRequest
     */
    'transforms'?: Array<Transform>;
}
/**
 * 
 * @export
 * @interface DatasetSetting
 */
export interface DatasetSetting {
    /**
     * 
     * @type {string}
     * @memberof DatasetSetting
     */
    'selectionType'?: DatasetSettingSelectionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DatasetSetting
     */
    'startIdx'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetSetting
     */
    'endIdx'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DatasetSetting
     */
    'indexes'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DatasetSetting
     */
    'fraction'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetSetting
     */
    'numItems'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetSetting
     */
    'workers'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetSetting
     */
    'batchSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetSetting
     */
    'shuffle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetSetting
     */
    'useRemoteApi'?: boolean;
}

export const DatasetSettingSelectionTypeEnum = {
    Entire: 'ENTIRE',
    Range: 'RANGE',
    Indexes: 'INDEXES',
    Count: 'COUNT',
    Fraction: 'FRACTION'
} as const;

export type DatasetSettingSelectionTypeEnum = typeof DatasetSettingSelectionTypeEnum[keyof typeof DatasetSettingSelectionTypeEnum];

/**
 * 
 * @export
 * @interface DatasetSettings
 */
export interface DatasetSettings {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DatasetSettings
     */
    'params'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface DatasetTarget
 */
export interface DatasetTarget {
    /**
     * 
     * @type {{ [key: string]: Annotation; }}
     * @memberof DatasetTarget
     */
    'annotations'?: { [key: string]: Annotation; };
    /**
     * 
     * @type {string}
     * @memberof DatasetTarget
     */
    'format'?: string;
}
/**
 * The defense.
 * @export
 * @interface DefenseRequest
 */
export interface DefenseRequest {
    /**
     * 
     * @type {string}
     * @memberof DefenseRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DefenseRequest
     */
    'parameters'?: string;
}
/**
 * 
 * @export
 * @interface DefenseResponse
 */
export interface DefenseResponse {
    /**
     * 
     * @type {number}
     * @memberof DefenseResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefenseResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefenseResponse
     */
    'testId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefenseResponse
     */
    'defenseParameters'?: string;
    /**
     * 
     * @type {Array<AIFilter>}
     * @memberof DefenseResponse
     */
    'filters'?: Array<AIFilter>;
}
/**
 * 
 * @export
 * @interface DetectionTestSettings
 */
export interface DetectionTestSettings {
    /**
     * 
     * @type {number}
     * @memberof DetectionTestSettings
     */
    'id'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DetectionTestSettings
     */
    'params'?: { [key: string]: object; };
    /**
     * 
     * @type {DatasetSetting}
     * @memberof DetectionTestSettings
     */
    'poisonedDatasetSetting'?: DatasetSetting;
    /**
     * 
     * @type {number}
     * @memberof DetectionTestSettings
     */
    'passDeviations'?: number;
}
/**
 * 
 * @export
 * @interface EnvironmentResponse
 */
export interface EnvironmentResponse {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResponse
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentResponse
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface FileStatResponse
 */
export interface FileStatResponse {
    /**
     * 
     * @type {number}
     * @memberof FileStatResponse
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileStatResponse
     */
    'contentType'?: string;
}
/**
 * The filter to add.
 * @export
 * @interface FilterRequest
 */
export interface FilterRequest {
    /**
     * 
     * @type {string}
     * @memberof FilterRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FilterRequest
     */
    'parameters'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterRequest
     */
    'type'?: FilterRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FilterRequest
     */
    'enabled'?: boolean;
}

export const FilterRequestTypeEnum = {
    AdversarialAttacks: 'adversarial_attacks',
    Noises: 'noises',
    MetaAttacks: 'meta_attacks',
    DomainAdaptations: 'domain_adaptations',
    Defenses: 'defenses'
} as const;

export type FilterRequestTypeEnum = typeof FilterRequestTypeEnum[keyof typeof FilterRequestTypeEnum];

/**
 * 
 * @export
 * @interface HPODefinition
 */
export interface HPODefinition {
    /**
     * 
     * @type {number}
     * @memberof HPODefinition
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof HPODefinition
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HPODefinition
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HPODefinition
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof HPODefinition
     */
    'params'?: Array<{ [key: string]: object; }>;
}
/**
 * 
 * @export
 * @interface HeadSetting
 */
export interface HeadSetting {
    /**
     * 
     * @type {ModelHead}
     * @memberof HeadSetting
     */
    'head'?: ModelHead;
    /**
     * 
     * @type {Array<Metric>}
     * @memberof HeadSetting
     */
    'metrics'?: Array<Metric>;
}
/**
 * 
 * @export
 * @interface InviteRequest
 */
export interface InviteRequest {
    /**
     * 
     * @type {string}
     * @memberof InviteRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface LogEntryResponse
 */
export interface LogEntryResponse {
    /**
     * 
     * @type {string}
     * @memberof LogEntryResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogEntryResponse
     */
    'timeStamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogEntryResponse
     */
    'level'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'apiKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'apiKeyId'?: string;
}
/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Metric
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Metric
     */
    'params'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    'uid'?: string;
}
/**
 * The metric update request.
 * @export
 * @interface MetricDefinition
 */
export interface MetricDefinition {
    /**
     * 
     * @type {string}
     * @memberof MetricDefinition
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricDefinition
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricDefinition
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MetricDefinition
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetricDefinition
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof MetricDefinition
     */
    'params'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof MetricDefinition
     */
    'codebase_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricDefinition
     */
    'metric_type'?: string;
}
/**
 * 
 * @export
 * @interface ModelHead
 */
export interface ModelHead {
    /**
     * 
     * @type {string}
     * @memberof ModelHead
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelHead
     */
    'losses'?: Array<string>;
}
/**
 * New model information.
 * @export
 * @interface ModelRequest
 */
export interface ModelRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRequest
     */
    'lossName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRequest
     */
    'endpoint'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelRequest
     */
    'numClasses'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelRequest
     */
    'frameworkType'?: ModelRequestFrameworkTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelRequest
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<Transform>}
     * @memberof ModelRequest
     */
    'transforms'?: Array<Transform>;
    /**
     * 
     * @type {number}
     * @memberof ModelRequest
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelRequest
     */
    'environment'?: string;
}

export const ModelRequestFrameworkTypeEnum = {
    PyTorch: 'PyTorch',
    Onnx: 'ONNX',
    Keras: 'Keras',
    TensorRt: 'TensorRT',
    External: 'External'
} as const;

export type ModelRequestFrameworkTypeEnum = typeof ModelRequestFrameworkTypeEnum[keyof typeof ModelRequestFrameworkTypeEnum];

/**
 * 
 * @export
 * @interface ModelResponse
 */
export interface ModelResponse {
    /**
     * 
     * @type {number}
     * @memberof ModelResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelResponse
     */
    'projectSource'?: number;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof ModelResponse
     */
    'organization'?: OrganizationResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelResponse
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<Transform>}
     * @memberof ModelResponse
     */
    'transforms'?: Array<Transform>;
    /**
     * 
     * @type {number}
     * @memberof ModelResponse
     */
    'datasetId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelResponse
     */
    'frameworkType'?: ModelResponseFrameworkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelResponse
     */
    'environment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelResponse
     */
    'verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelResponse
     */
    'dataRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelResponse
     */
    'wrapperRef'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelResponse
     */
    'numClasses'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelResponse
     */
    'lossName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelResponse
     */
    'endpoint'?: string;
}

export const ModelResponseFrameworkTypeEnum = {
    PyTorch: 'PyTorch',
    Onnx: 'ONNX',
    Keras: 'Keras',
    TensorRt: 'TensorRT',
    External: 'External'
} as const;

export type ModelResponseFrameworkTypeEnum = typeof ModelResponseFrameworkTypeEnum[keyof typeof ModelResponseFrameworkTypeEnum];

/**
 * Optional new organization information.
 * @export
 * @interface OrganizationRequest
 */
export interface OrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequest
     */
    'name'?: string;
    /**
     * 
     * @type {OrganizationSettings}
     * @memberof OrganizationRequest
     */
    'settings'?: OrganizationSettings;
}
/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResponse
     */
    'timeUsed'?: number;
    /**
     * 
     * @type {OrganizationStatsResponse}
     * @memberof OrganizationResponse
     */
    'stats'?: OrganizationStatsResponse;
    /**
     * 
     * @type {OrganizationSettings}
     * @memberof OrganizationResponse
     */
    'settings'?: OrganizationSettings;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    'creationDateTime'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationSettings
 */
export interface OrganizationSettings {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationSettings
     */
    'disabledFeatures'?: Array<OrganizationSettingsDisabledFeaturesEnum>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSettings
     */
    'maxTimePerTask'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSettings
     */
    'maxTimeAllocated'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationSettings
     */
    'maxItemCount'?: number;
    /**
     * 
     * @type {Array<ClassMappingFileRef>}
     * @memberof OrganizationSettings
     */
    'classMappingFiles'?: Array<ClassMappingFileRef>;
}

export const OrganizationSettingsDisabledFeaturesEnum = {
    Robustness: 'ROBUSTNESS',
    Evaluation: 'EVALUATION',
    AdvCrafting: 'ADV_CRAFTING'
} as const;

export type OrganizationSettingsDisabledFeaturesEnum = typeof OrganizationSettingsDisabledFeaturesEnum[keyof typeof OrganizationSettingsDisabledFeaturesEnum];

/**
 * 
 * @export
 * @interface OrganizationStatsResponse
 */
export interface OrganizationStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationStatsResponse
     */
    'datasetStorage'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationStatsResponse
     */
    'projectStorage'?: number;
}
/**
 * 
 * @export
 * @interface PassFailCriteria
 */
export interface PassFailCriteria {
    /**
     * 
     * @type {string}
     * @memberof PassFailCriteria
     */
    'metricName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PassFailCriteria
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PassFailCriteria
     */
    'passVal'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PassFailCriteria
     */
    'above'?: boolean;
}
/**
 * 
 * @export
 * @interface PipelineResponse
 */
export interface PipelineResponse {
    /**
     * 
     * @type {string}
     * @memberof PipelineResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PipelineResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ProfileSettings
 */
export interface ProfileSettings {
    /**
     * 
     * @type {DatasetSetting}
     * @memberof ProfileSettings
     */
    'datasetSetting'?: DatasetSetting;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSettings
     */
    'configured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSettings
     */
    'storeIntermediateData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSettings
     */
    'storeCompositeImages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSettings
     */
    'overlayImageOnSegMap'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSettings
     */
    'optimalParameterization'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSettings
     */
    'batchWiseHPO'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSettings
     */
    'epsExploration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileSettings
     */
    'approximateRobustness'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProfileSettings
     */
    'hpoId'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileSettings
     */
    'hpoParams'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileSettings
     */
    'epsExplorationParams'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProfileSettings
     */
    'arParams'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: Array<Metric>; }}
     * @memberof ProfileSettings
     */
    'metrics'?: { [key: string]: Array<Metric>; };
    /**
     * 
     * @type {Array<PassFailCriteria>}
     * @memberof ProfileSettings
     */
    'passFailCriteria'?: Array<PassFailCriteria>;
    /**
     * 
     * @type {number}
     * @memberof ProfileSettings
     */
    'passPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileSettings
     */
    'testType'?: ProfileSettingsTestTypeEnum;
    /**
     * 
     * @type {DetectionTestSettings}
     * @memberof ProfileSettings
     */
    'detectionTestSettings'?: DetectionTestSettings;
    /**
     * 
     * @type {CraftedTestSettings}
     * @memberof ProfileSettings
     */
    'craftedSettings'?: CraftedTestSettings;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileSettings
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<DefenseResponse>}
     * @memberof ProfileSettings
     */
    'defenses'?: Array<DefenseResponse>;
}

export const ProfileSettingsTestTypeEnum = {
    Robustness: 'Robustness',
    Detection: 'Detection',
    Crafted: 'Crafted'
} as const;

export type ProfileSettingsTestTypeEnum = typeof ProfileSettingsTestTypeEnum[keyof typeof ProfileSettingsTestTypeEnum];

/**
 * Optional new project information.
 * @export
 * @interface ProjectRequest
 */
export interface ProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRequest
     */
    'valid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectRequest
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ModelHead>}
     * @memberof ProjectRequest
     */
    'heads'?: Array<ModelHead>;
    /**
     * 
     * @type {ProjectSettings}
     * @memberof ProjectRequest
     */
    'settings'?: ProjectSettings;
    /**
     * 
     * @type {number}
     * @memberof ProjectRequest
     */
    'datasetLength'?: number;
}
/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'uid'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'id'?: number;
    /**
     * 
     * @type {ModelResponse}
     * @memberof ProjectResponse
     */
    'model'?: ModelResponse;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectResponse
     */
    'verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'dataRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'wrapperRef'?: string;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof ProjectResponse
     */
    'organization'?: OrganizationResponse;
    /**
     * 
     * @type {Array<PipelineResponse>}
     * @memberof ProjectResponse
     */
    'pipelines'?: Array<PipelineResponse>;
    /**
     * 
     * @type {ProjectSettings}
     * @memberof ProjectResponse
     */
    'settings'?: ProjectSettings;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'modelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'modelName'?: string;
}
/**
 * 
 * @export
 * @interface ProjectSettings
 */
export interface ProjectSettings {
    /**
     * 
     * @type {number}
     * @memberof ProjectSettings
     */
    'workerVersion'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'storeIntermediateData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'storeCompositeImages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'optimalParameterization'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'batchWiseHPO'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'epsExploration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'approximateRobustness'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectSettings
     */
    'hpoId'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProjectSettings
     */
    'hpoParams'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProjectSettings
     */
    'epsExplorationParams'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProjectSettings
     */
    'arParams'?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'classMappingEstimation'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProjectSettings
     */
    'classMappingParams'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSettings
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProjectSettings
     */
    'datasetId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'useClassNamesFromDataset'?: boolean;
    /**
     * 
     * @type {Array<Transform>}
     * @memberof ProjectSettings
     */
    'transforms'?: Array<Transform>;
    /**
     * 
     * @type {Array<HeadSetting>}
     * @memberof ProjectSettings
     */
    'headSettings'?: Array<HeadSetting>;
    /**
     * 
     * @type {number}
     * @memberof ProjectSettings
     */
    'sourceModelId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettings
     */
    'useRemoteApi'?: boolean;
}
/**
 * 
 * @export
 * @interface QueueInfoResponse
 */
export interface QueueInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof QueueInfoResponse
     */
    'messageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueInfoResponse
     */
    'consumerCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueInfoResponse
     */
    'busyCount'?: number;
    /**
     * 
     * @type {Array<WorkerHeartbeatEntry>}
     * @memberof QueueInfoResponse
     */
    'workers'?: Array<WorkerHeartbeatEntry>;
}
/**
 * 
 * @export
 * @interface Rectangle
 */
export interface Rectangle {
    /**
     * 
     * @type {number}
     * @memberof Rectangle
     */
    'x1'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rectangle
     */
    'y1'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rectangle
     */
    'x2'?: number;
    /**
     * 
     * @type {number}
     * @memberof Rectangle
     */
    'y2'?: number;
}
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface ReportRequest
 */
export interface ReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportRequest
     */
    'jsonReport': string;
    /**
     * 
     * @type {number}
     * @memberof ReportRequest
     */
    'testId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportRequest
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportRequest
     */
    'dataRef'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportRequest
     */
    'runId'?: number;
}
/**
 * 
 * @export
 * @interface ReportResponse
 */
export interface ReportResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'jsonReport'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportResponse
     */
    'testId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportResponse
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportResponse
     */
    'runId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'runName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'dataRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponse
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface Segmentation
 */
export interface Segmentation {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof Segmentation
     */
    'polygons'?: Array<Array<number>>;
    /**
     * 
     * @type {number}
     * @memberof Segmentation
     */
    'area'?: number;
}
/**
 * 
 * @export
 * @interface StatusRequest
 */
export interface StatusRequest {
    /**
     * 
     * @type {string}
     * @memberof StatusRequest
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusRequest
     */
    'workerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusRequest
     */
    'status'?: StatusRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof StatusRequest
     */
    'progress'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusRequest
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusRequest
     */
    'elapsed'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusRequest
     */
    'eta'?: number;
}

export const StatusRequestStatusEnum = {
    Idle: 'IDLE',
    Initializing: 'INITIALIZING',
    Running: 'RUNNING',
    Done: 'DONE',
    Err: 'ERR',
    Cancelled: 'CANCELLED',
    Submitted: 'SUBMITTED'
} as const;

export type StatusRequestStatusEnum = typeof StatusRequestStatusEnum[keyof typeof StatusRequestStatusEnum];

/**
 * The profile object.
 * @export
 * @interface TestProfileRequest
 */
export interface TestProfileRequest {
    /**
     * 
     * @type {number}
     * @memberof TestProfileRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestProfileRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestProfileRequest
     */
    'description'?: string;
    /**
     * 
     * @type {ProfileSettings}
     * @memberof TestProfileRequest
     */
    'settings'?: ProfileSettings;
}
/**
 * 
 * @export
 * @interface TestProfileResponse
 */
export interface TestProfileResponse {
    /**
     * 
     * @type {number}
     * @memberof TestProfileResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestProfileResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestProfileResponse
     */
    'description'?: string;
    /**
     * 
     * @type {ProfileSettings}
     * @memberof TestProfileResponse
     */
    'settings'?: ProfileSettings;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof TestProfileResponse
     */
    'organization'?: OrganizationResponse;
}
/**
 * 
 * @export
 * @interface TestRequest
 */
export interface TestRequest {
    /**
     * 
     * @type {string}
     * @memberof TestRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof TestRequest
     */
    'pipelineId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRequest
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRequest
     */
    'profileId'?: number;
    /**
     * 
     * @type {TestSettings}
     * @memberof TestRequest
     */
    'testSettings'?: TestSettings;
    /**
     * 
     * @type {DefenseResponse}
     * @memberof TestRequest
     */
    'defense'?: DefenseResponse;
}
/**
 * 
 * @export
 * @interface TestResponse
 */
export interface TestResponse {
    /**
     * 
     * @type {number}
     * @memberof TestResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestResponse
     */
    'projectId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestResponse
     */
    'pipelineId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResponse
     */
    'description'?: string;
    /**
     * 
     * @type {TestSettings}
     * @memberof TestResponse
     */
    'testSettings'?: TestSettings;
    /**
     * 
     * @type {Array<DefenseResponse>}
     * @memberof TestResponse
     */
    'defenses'?: Array<DefenseResponse>;
    /**
     * 
     * @type {Array<TestRunResponse>}
     * @memberof TestResponse
     */
    'testRuns'?: Array<TestRunResponse>;
}
/**
 * 
 * @export
 * @interface TestRunRequest
 */
export interface TestRunRequest {
    /**
     * 
     * @type {string}
     * @memberof TestRunRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TestRunResponse
 */
export interface TestRunResponse {
    /**
     * 
     * @type {number}
     * @memberof TestRunResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRunResponse
     */
    'testId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRunResponse
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRunResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRunResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRunResponse
     */
    'status'?: TestRunResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TestRunResponse
     */
    'progress'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRunResponse
     */
    'submitCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRunResponse
     */
    'completedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRunResponse
     */
    'eta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestRunResponse
     */
    'elapsed'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestRunResponse
     */
    'initiator'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestRunResponse
     */
    'startTime'?: string;
}

export const TestRunResponseStatusEnum = {
    Idle: 'IDLE',
    Initializing: 'INITIALIZING',
    Running: 'RUNNING',
    Done: 'DONE',
    Err: 'ERR',
    Cancelled: 'CANCELLED',
    Submitted: 'SUBMITTED'
} as const;

export type TestRunResponseStatusEnum = typeof TestRunResponseStatusEnum[keyof typeof TestRunResponseStatusEnum];

/**
 * 
 * @export
 * @interface TestSettings
 */
export interface TestSettings {
    /**
     * 
     * @type {DatasetSetting}
     * @memberof TestSettings
     */
    'datasetSetting'?: DatasetSetting;
    /**
     * 
     * @type {boolean}
     * @memberof TestSettings
     */
    'configured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestSettings
     */
    'storeIntermediateData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestSettings
     */
    'storeCompositeImages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestSettings
     */
    'overlayImageOnSegMap'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestSettings
     */
    'optimalParameterization'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestSettings
     */
    'batchWiseHPO'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestSettings
     */
    'epsExploration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestSettings
     */
    'approximateRobustness'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TestSettings
     */
    'hpoId'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TestSettings
     */
    'hpoParams'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TestSettings
     */
    'epsExplorationParams'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TestSettings
     */
    'arParams'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: Array<Metric>; }}
     * @memberof TestSettings
     */
    'metrics'?: { [key: string]: Array<Metric>; };
    /**
     * 
     * @type {Array<PassFailCriteria>}
     * @memberof TestSettings
     */
    'passFailCriteria'?: Array<PassFailCriteria>;
    /**
     * 
     * @type {number}
     * @memberof TestSettings
     */
    'passPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof TestSettings
     */
    'testType'?: TestSettingsTestTypeEnum;
    /**
     * 
     * @type {DetectionTestSettings}
     * @memberof TestSettings
     */
    'detectionTestSettings'?: DetectionTestSettings;
    /**
     * 
     * @type {CraftedTestSettings}
     * @memberof TestSettings
     */
    'craftedSettings'?: CraftedTestSettings;
}

export const TestSettingsTestTypeEnum = {
    Robustness: 'Robustness',
    Detection: 'Detection',
    Crafted: 'Crafted'
} as const;

export type TestSettingsTestTypeEnum = typeof TestSettingsTestTypeEnum[keyof typeof TestSettingsTestTypeEnum];

/**
 * 
 * @export
 * @interface Transform
 */
export interface Transform {
    /**
     * 
     * @type {number}
     * @memberof Transform
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Transform
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Transform
     */
    'params'?: { [key: string]: object; };
}
/**
 * The transform update request.
 * @export
 * @interface TransformDefinition
 */
export interface TransformDefinition {
    /**
     * 
     * @type {number}
     * @memberof TransformDefinition
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransformDefinition
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformDefinition
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransformDefinition
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformDefinition
     */
    'tasks'?: Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof TransformDefinition
     */
    'params'?: Array<{ [key: string]: object; }>;
    /**
     * 
     * @type {string}
     * @memberof TransformDefinition
     */
    'codebase_name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformDefinition
     */
    'target_format'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TransformDefinition
     */
    'converted_target_format'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof TransformDefinition
     */
    'apply_mapping'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * The user request.
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'roles'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'jsonSettings'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserRequest
     */
    'organizations'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UserRequest
     */
    'licenseAccepted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'feedback'?: string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'registered'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'licenseAccepted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'roles'?: string;
    /**
     * 
     * @type {UserSettings}
     * @memberof UserResponse
     */
    'settings'?: UserSettings;
    /**
     * 
     * @type {Array<OrganizationResponse>}
     * @memberof UserResponse
     */
    'organizations'?: Array<OrganizationResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'lastLoginDateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'timeUsed'?: number;
}
/**
 * 
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    'notifications'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserSettings
     */
    'favoriteProjects'?: Array<number>;
}
/**
 * 
 * @export
 * @interface WebHookSettings
 */
export interface WebHookSettings {
    /**
     * 
     * @type {string}
     * @memberof WebHookSettings
     */
    'contentType'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebHookSettings
     */
    'events'?: Array<string>;
}
/**
 * The test hook object.
 * @export
 * @interface WebhookRequest
 */
export interface WebhookRequest {
    /**
     * 
     * @type {boolean}
     * @memberof WebhookRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebhookRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookRequest
     */
    'secret'?: string;
    /**
     * 
     * @type {WebHookSettings}
     * @memberof WebhookRequest
     */
    'settings'?: WebHookSettings;
}
/**
 * 
 * @export
 * @interface WebhookResponse
 */
export interface WebhookResponse {
    /**
     * 
     * @type {number}
     * @memberof WebhookResponse
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebhookResponse
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookResponse
     */
    'secret'?: string;
    /**
     * 
     * @type {WebHookSettings}
     * @memberof WebhookResponse
     */
    'settings'?: WebHookSettings;
}
/**
 * 
 * @export
 * @interface WorkerHeartbeatEntry
 */
export interface WorkerHeartbeatEntry {
    /**
     * 
     * @type {number}
     * @memberof WorkerHeartbeatEntry
     */
    'lastUpdateTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkerHeartbeatEntry
     */
    'status'?: WorkerHeartbeatEntryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkerHeartbeatEntry
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkerHeartbeatEntry
     */
    'cancel'?: boolean;
}

export const WorkerHeartbeatEntryStatusEnum = {
    Idle: 'IDLE',
    Initializing: 'INITIALIZING',
    Running: 'RUNNING',
    Done: 'DONE',
    Err: 'ERR',
    Cancelled: 'CANCELLED',
    Submitted: 'SUBMITTED'
} as const;

export type WorkerHeartbeatEntryStatusEnum = typeof WorkerHeartbeatEntryStatusEnum[keyof typeof WorkerHeartbeatEntryStatusEnum];

/**
 * 
 * @export
 * @interface WorkerHeartbeatRequest
 */
export interface WorkerHeartbeatRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkerHeartbeatRequest
     */
    'status'?: WorkerHeartbeatRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkerHeartbeatRequest
     */
    'workerId'?: string;
}

export const WorkerHeartbeatRequestStatusEnum = {
    Idle: 'IDLE',
    Initializing: 'INITIALIZING',
    Running: 'RUNNING',
    Done: 'DONE',
    Err: 'ERR',
    Cancelled: 'CANCELLED',
    Submitted: 'SUBMITTED'
} as const;

export type WorkerHeartbeatRequestStatusEnum = typeof WorkerHeartbeatRequestStatusEnum[keyof typeof WorkerHeartbeatRequestStatusEnum];


/**
 * BenchmarkApi - axios parameter creator
 * @export
 */
export const BenchmarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a benchmark to an organization.
         * @param {number} organizationId The organization ID.
         * @param {BenchmarkRequest} benchmarkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBenchmark: async (organizationId: number, benchmarkRequest: BenchmarkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('addBenchmark', 'organizationId', organizationId)
            // verify required parameter 'benchmarkRequest' is not null or undefined
            assertParamExists('addBenchmark', 'benchmarkRequest', benchmarkRequest)
            const localVarPath = `/api/v1/benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(benchmarkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new test.
         * @param {number} id The benchmark ID.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBenchmarkTest: async (id: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addBenchmarkTest', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addBenchmarkTest', 'name', name)
            const localVarPath = `/api/v1/benchmarks/{id}/addtest`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add defense to benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBenchmarkTestDefense: async (id: number, testId: number, defenseRequest: DefenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addBenchmarkTestDefense', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('addBenchmarkTestDefense', 'testId', testId)
            // verify required parameter 'defenseRequest' is not null or undefined
            assertParamExists('addBenchmarkTestDefense', 'defenseRequest', defenseRequest)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}/defense`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add filter to benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBenchmarkTestFilter: async (id: number, testId: number, filterRequest: FilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addBenchmarkTestFilter', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('addBenchmarkTestFilter', 'testId', testId)
            // verify required parameter 'filterRequest' is not null or undefined
            assertParamExists('addBenchmarkTestFilter', 'filterRequest', filterRequest)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}/filter`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a benchmark.
         * @param {number} id The benchmark ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenchmark: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBenchmark', 'id', id)
            const localVarPath = `/api/v1/benchmarks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenchmarkTest: async (id: number, testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBenchmarkTest', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('deleteBenchmarkTest', 'testId', testId)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a benchmark.
         * @param {number} id The benchmark ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmark: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBenchmark', 'id', id)
            const localVarPath = `/api/v1/benchmarks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get benchmark test status.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkTestStatus: async (id: number, testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBenchmarkTestStatus', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getBenchmarkTestStatus', 'testId', testId)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of benchmarks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove filter from benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {number} filterId The filter ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBenchmarkFilter: async (id: number, testId: number, filterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeBenchmarkFilter', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('removeBenchmarkFilter', 'testId', testId)
            // verify required parameter 'filterId' is not null or undefined
            assertParamExists('removeBenchmarkFilter', 'filterId', filterId)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}/{filterId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)))
                .replace(`{${"filterId"}}`, encodeURIComponent(String(filterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove defense from benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {number} defenseId The defense ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBenchmarkTestDefense: async (id: number, testId: number, defenseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeBenchmarkTestDefense', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('removeBenchmarkTestDefense', 'testId', testId)
            // verify required parameter 'defenseId' is not null or undefined
            assertParamExists('removeBenchmarkTestDefense', 'defenseId', defenseId)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}/defense/{defenseId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)))
                .replace(`{${"defenseId"}}`, encodeURIComponent(String(defenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startBenchmarkTest: async (id: number, testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startBenchmarkTest', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('startBenchmarkTest', 'testId', testId)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}/start`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopBenchmarkTest: async (id: number, testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stopBenchmarkTest', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('stopBenchmarkTest', 'testId', testId)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}/stop`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update benchmark.
         * @param {number} id The benchmark ID.
         * @param {BenchmarkRequest} benchmarkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmark: async (id: number, benchmarkRequest: BenchmarkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBenchmark', 'id', id)
            // verify required parameter 'benchmarkRequest' is not null or undefined
            assertParamExists('updateBenchmark', 'benchmarkRequest', benchmarkRequest)
            const localVarPath = `/api/v1/benchmarks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(benchmarkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {BenchmarkTestRequest} benchmarkTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmarkTest: async (id: number, testId: number, benchmarkTestRequest: BenchmarkTestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBenchmarkTest', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('updateBenchmarkTest', 'testId', testId)
            // verify required parameter 'benchmarkTestRequest' is not null or undefined
            assertParamExists('updateBenchmarkTest', 'benchmarkTestRequest', benchmarkTestRequest)
            const localVarPath = `/api/v1/benchmarks/{id}/{testId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(benchmarkTestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BenchmarkApi - functional programming interface
 * @export
 */
export const BenchmarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BenchmarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a benchmark to an organization.
         * @param {number} organizationId The organization ID.
         * @param {BenchmarkRequest} benchmarkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBenchmark(organizationId: number, benchmarkRequest: BenchmarkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBenchmark(organizationId, benchmarkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add new test.
         * @param {number} id The benchmark ID.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBenchmarkTest(id: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBenchmarkTest(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add defense to benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBenchmarkTestDefense(id: number, testId: number, defenseRequest: DefenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBenchmarkTestDefense(id, testId, defenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add filter to benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBenchmarkTestFilter(id: number, testId: number, filterRequest: FilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBenchmarkTestFilter(id, testId, filterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a benchmark.
         * @param {number} id The benchmark ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenchmark(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenchmark(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBenchmarkTest(id: number, testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenchmarkTest(id, testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a benchmark.
         * @param {number} id The benchmark ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmark(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmark(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get benchmark test status.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarkTestStatus(id: number, testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkTestStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarkTestStatus(id, testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of benchmarks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenchmarks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BenchmarkResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchmarks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove filter from benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {number} filterId The filter ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeBenchmarkFilter(id: number, testId: number, filterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeBenchmarkFilter(id, testId, filterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove defense from benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {number} defenseId The defense ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeBenchmarkTestDefense(id: number, testId: number, defenseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeBenchmarkTestDefense(id, testId, defenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startBenchmarkTest(id: number, testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startBenchmarkTest(id, testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopBenchmarkTest(id: number, testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopBenchmarkTest(id, testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update benchmark.
         * @param {number} id The benchmark ID.
         * @param {BenchmarkRequest} benchmarkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenchmark(id: number, benchmarkRequest: BenchmarkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenchmark(id, benchmarkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {BenchmarkTestRequest} benchmarkTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBenchmarkTest(id: number, testId: number, benchmarkTestRequest: BenchmarkTestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenchmarkTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenchmarkTest(id, testId, benchmarkTestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BenchmarkApi - factory interface
 * @export
 */
export const BenchmarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenchmarkApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a benchmark to an organization.
         * @param {number} organizationId The organization ID.
         * @param {BenchmarkRequest} benchmarkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBenchmark(organizationId: number, benchmarkRequest: BenchmarkRequest, options?: any): AxiosPromise<BenchmarkResponse> {
            return localVarFp.addBenchmark(organizationId, benchmarkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add new test.
         * @param {number} id The benchmark ID.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBenchmarkTest(id: number, name: string, options?: any): AxiosPromise<BenchmarkResponse> {
            return localVarFp.addBenchmarkTest(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add defense to benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBenchmarkTestDefense(id: number, testId: number, defenseRequest: DefenseRequest, options?: any): AxiosPromise<BenchmarkTest> {
            return localVarFp.addBenchmarkTestDefense(id, testId, defenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add filter to benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBenchmarkTestFilter(id: number, testId: number, filterRequest: FilterRequest, options?: any): AxiosPromise<BenchmarkTest> {
            return localVarFp.addBenchmarkTestFilter(id, testId, filterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a benchmark.
         * @param {number} id The benchmark ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenchmark(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBenchmark(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBenchmarkTest(id: number, testId: number, options?: any): AxiosPromise<BenchmarkResponse> {
            return localVarFp.deleteBenchmarkTest(id, testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a benchmark.
         * @param {number} id The benchmark ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmark(id: number, options?: any): AxiosPromise<BenchmarkResponse> {
            return localVarFp.getBenchmark(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get benchmark test status.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarkTestStatus(id: number, testId: number, options?: any): AxiosPromise<BenchmarkTestStatusResponse> {
            return localVarFp.getBenchmarkTestStatus(id, testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of benchmarks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenchmarks(options?: any): AxiosPromise<Array<BenchmarkResponse>> {
            return localVarFp.getBenchmarks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove filter from benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {number} filterId The filter ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBenchmarkFilter(id: number, testId: number, filterId: number, options?: any): AxiosPromise<BenchmarkTest> {
            return localVarFp.removeBenchmarkFilter(id, testId, filterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove defense from benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {number} defenseId The defense ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBenchmarkTestDefense(id: number, testId: number, defenseId: number, options?: any): AxiosPromise<BenchmarkTest> {
            return localVarFp.removeBenchmarkTestDefense(id, testId, defenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startBenchmarkTest(id: number, testId: number, options?: any): AxiosPromise<void> {
            return localVarFp.startBenchmarkTest(id, testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop a benchmark test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopBenchmarkTest(id: number, testId: number, options?: any): AxiosPromise<void> {
            return localVarFp.stopBenchmarkTest(id, testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update benchmark.
         * @param {number} id The benchmark ID.
         * @param {BenchmarkRequest} benchmarkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmark(id: number, benchmarkRequest: BenchmarkRequest, options?: any): AxiosPromise<BenchmarkResponse> {
            return localVarFp.updateBenchmark(id, benchmarkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update test.
         * @param {number} id The benchmark ID.
         * @param {number} testId The test ID.
         * @param {BenchmarkTestRequest} benchmarkTestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBenchmarkTest(id: number, testId: number, benchmarkTestRequest: BenchmarkTestRequest, options?: any): AxiosPromise<BenchmarkTest> {
            return localVarFp.updateBenchmarkTest(id, testId, benchmarkTestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenchmarkApi - object-oriented interface
 * @export
 * @class BenchmarkApi
 * @extends {BaseAPI}
 */
export class BenchmarkApi extends BaseAPI {
    /**
     * 
     * @summary Add a benchmark to an organization.
     * @param {number} organizationId The organization ID.
     * @param {BenchmarkRequest} benchmarkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public addBenchmark(organizationId: number, benchmarkRequest: BenchmarkRequest, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).addBenchmark(organizationId, benchmarkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add new test.
     * @param {number} id The benchmark ID.
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public addBenchmarkTest(id: number, name: string, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).addBenchmarkTest(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add defense to benchmark test.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {DefenseRequest} defenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public addBenchmarkTestDefense(id: number, testId: number, defenseRequest: DefenseRequest, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).addBenchmarkTestDefense(id, testId, defenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add filter to benchmark test.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {FilterRequest} filterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public addBenchmarkTestFilter(id: number, testId: number, filterRequest: FilterRequest, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).addBenchmarkTestFilter(id, testId, filterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a benchmark.
     * @param {number} id The benchmark ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public deleteBenchmark(id: number, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).deleteBenchmark(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a benchmark test.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public deleteBenchmarkTest(id: number, testId: number, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).deleteBenchmarkTest(id, testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a benchmark.
     * @param {number} id The benchmark ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public getBenchmark(id: number, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).getBenchmark(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get benchmark test status.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public getBenchmarkTestStatus(id: number, testId: number, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).getBenchmarkTestStatus(id, testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of benchmarks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public getBenchmarks(options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).getBenchmarks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove filter from benchmark test.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {number} filterId The filter ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public removeBenchmarkFilter(id: number, testId: number, filterId: number, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).removeBenchmarkFilter(id, testId, filterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove defense from benchmark test.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {number} defenseId The defense ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public removeBenchmarkTestDefense(id: number, testId: number, defenseId: number, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).removeBenchmarkTestDefense(id, testId, defenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a benchmark test.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public startBenchmarkTest(id: number, testId: number, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).startBenchmarkTest(id, testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop a benchmark test.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public stopBenchmarkTest(id: number, testId: number, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).stopBenchmarkTest(id, testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update benchmark.
     * @param {number} id The benchmark ID.
     * @param {BenchmarkRequest} benchmarkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public updateBenchmark(id: number, benchmarkRequest: BenchmarkRequest, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).updateBenchmark(id, benchmarkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update test.
     * @param {number} id The benchmark ID.
     * @param {number} testId The test ID.
     * @param {BenchmarkTestRequest} benchmarkTestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarkApi
     */
    public updateBenchmarkTest(id: number, testId: number, benchmarkTestRequest: BenchmarkTestRequest, options?: AxiosRequestConfig) {
        return BenchmarkApiFp(this.configuration).updateBenchmarkTest(id, testId, benchmarkTestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DatasetsApi - axios parameter creator
 * @export
 */
export const DatasetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add external dataset.
         * @param {number} orgId The organization ID.
         * @param {string} name 
         * @param {string} description 
         * @param {string} format 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExternalDataset: async (orgId: number, name: string, description: string, format: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('addExternalDataset', 'orgId', orgId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addExternalDataset', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('addExternalDataset', 'description', description)
            // verify required parameter 'format' is not null or undefined
            assertParamExists('addExternalDataset', 'format', format)
            const localVarPath = `/api/v1/datasets/{orgId}/external`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make a copy of a custom dataset. To copy to a different organization, extra permissions are required.
         * @param {number} id The dataset ID.
         * @param {number} [orgId] Optional organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDataset: async (id: number, orgId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyDataset', 'id', id)
            const localVarPath = `/api/v1/datasets/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomDataset: async (orgId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('deleteCustomDataset', 'orgId', orgId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomDataset', 'id', id)
            const localVarPath = `/api/v1/datasets/{id}/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download dataset data.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDataset: async (orgId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('downloadDataset', 'orgId', orgId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadDataset', 'id', id)
            const localVarPath = `/api/v1/datasets/{id}/{orgId}/data`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataset: async (orgId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getDataset', 'orgId', orgId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataset', 'id', id)
            const localVarPath = `/api/v1/datasets/{id}/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve dataset classes (coco dataset supported).
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClasses: async (orgId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getDatasetClasses', 'orgId', orgId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDatasetClasses', 'id', id)
            const localVarPath = `/api/v1/datasets/{id}/{orgId}/classes`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an item from the dataset.
         * @param {number} id The dataset ID.
         * @param {number} idx The item index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetItem: async (id: number, idx: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDatasetItem', 'id', id)
            // verify required parameter 'idx' is not null or undefined
            assertParamExists('getDatasetItem', 'idx', idx)
            const localVarPath = `/api/v1/datasets/{id}/{idx}/item`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idx"}}`, encodeURIComponent(String(idx)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a target from the dataset.
         * @param {number} id The dataset ID.
         * @param {number} idx The item index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetTarget: async (id: number, idx: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDatasetTarget', 'id', id)
            // verify required parameter 'idx' is not null or undefined
            assertParamExists('getDatasetTarget', 'idx', idx)
            const localVarPath = `/api/v1/datasets/{id}/{idx}/target`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"idx"}}`, encodeURIComponent(String(idx)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the datasets defined for this organization.
         * @param {number} orgId The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasets: async (orgId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('getDatasets', 'orgId', orgId)
            const localVarPath = `/api/v1/datasets/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {DatasetRequest} datasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomDataset: async (orgId: number, id: number, datasetRequest: DatasetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('updateCustomDataset', 'orgId', orgId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomDataset', 'id', id)
            // verify required parameter 'datasetRequest' is not null or undefined
            assertParamExists('updateCustomDataset', 'datasetRequest', datasetRequest)
            const localVarPath = `/api/v1/datasets/{id}/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload dataset.
         * @param {number} orgId The organization ID.
         * @param {string} name 
         * @param {string} description 
         * @param {string} format 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDataset: async (orgId: number, name: string, description: string, format: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('uploadDataset', 'orgId', orgId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('uploadDataset', 'name', name)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('uploadDataset', 'description', description)
            // verify required parameter 'format' is not null or undefined
            assertParamExists('uploadDataset', 'format', format)
            const localVarPath = `/api/v1/datasets/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file to the dataset and return a reference.
         * @param {number} id The dataset ID.
         * @param {string} type Upload type - i.e. \&#39;transform\&#39; for transform parameters
         * @param {number} [orgId] The organization ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDatasetFile: async (id: number, type: string, orgId?: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadDatasetFile', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadDatasetFile', 'type', type)
            const localVarPath = `/api/v1/datasets/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetsApi - functional programming interface
 * @export
 */
export const DatasetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add external dataset.
         * @param {number} orgId The organization ID.
         * @param {string} name 
         * @param {string} description 
         * @param {string} format 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addExternalDataset(orgId: number, name: string, description: string, format: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addExternalDataset(orgId, name, description, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make a copy of a custom dataset. To copy to a different organization, extra permissions are required.
         * @param {number} id The dataset ID.
         * @param {number} [orgId] Optional organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyDataset(id: number, orgId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyDataset(id, orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomDataset(orgId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomDataset(orgId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download dataset data.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDataset(orgId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDataset(orgId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataset(orgId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataset(orgId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve dataset classes (coco dataset supported).
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetClasses(orgId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetClasses(orgId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an item from the dataset.
         * @param {number} id The dataset ID.
         * @param {number} idx The item index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetItem(id: number, idx: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetItem(id, idx, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a target from the dataset.
         * @param {number} id The dataset ID.
         * @param {number} idx The item index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetTarget(id: number, idx: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetTarget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetTarget(id, idx, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the datasets defined for this organization.
         * @param {number} orgId The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasets(orgId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasets(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {DatasetRequest} datasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomDataset(orgId: number, id: number, datasetRequest: DatasetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomDataset(orgId, id, datasetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload dataset.
         * @param {number} orgId The organization ID.
         * @param {string} name 
         * @param {string} description 
         * @param {string} format 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDataset(orgId: number, name: string, description: string, format: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDataset(orgId, name, description, format, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a file to the dataset and return a reference.
         * @param {number} id The dataset ID.
         * @param {string} type Upload type - i.e. \&#39;transform\&#39; for transform parameters
         * @param {number} [orgId] The organization ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDatasetFile(id: number, type: string, orgId?: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDatasetFile(id, type, orgId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetsApi - factory interface
 * @export
 */
export const DatasetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add external dataset.
         * @param {number} orgId The organization ID.
         * @param {string} name 
         * @param {string} description 
         * @param {string} format 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addExternalDataset(orgId: number, name: string, description: string, format: string, options?: any): AxiosPromise<DataSet> {
            return localVarFp.addExternalDataset(orgId, name, description, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make a copy of a custom dataset. To copy to a different organization, extra permissions are required.
         * @param {number} id The dataset ID.
         * @param {number} [orgId] Optional organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyDataset(id: number, orgId?: number, options?: any): AxiosPromise<DataSet> {
            return localVarFp.copyDataset(id, orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomDataset(orgId: number, id: number, options?: any): AxiosPromise<Array<DataSet>> {
            return localVarFp.deleteCustomDataset(orgId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download dataset data.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDataset(orgId: number, id: number, options?: any): AxiosPromise<File> {
            return localVarFp.downloadDataset(orgId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataset(orgId: number, id: number, options?: any): AxiosPromise<DataSet> {
            return localVarFp.getDataset(orgId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve dataset classes (coco dataset supported).
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetClasses(orgId: number, id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getDatasetClasses(orgId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an item from the dataset.
         * @param {number} id The dataset ID.
         * @param {number} idx The item index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetItem(id: number, idx: number, options?: any): AxiosPromise<File> {
            return localVarFp.getDatasetItem(id, idx, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a target from the dataset.
         * @param {number} id The dataset ID.
         * @param {number} idx The item index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetTarget(id: number, idx: number, options?: any): AxiosPromise<DatasetTarget> {
            return localVarFp.getDatasetTarget(id, idx, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the datasets defined for this organization.
         * @param {number} orgId The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasets(orgId: number, options?: any): AxiosPromise<Array<DataSet>> {
            return localVarFp.getDatasets(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update dataset.
         * @param {number} orgId The organization ID.
         * @param {number} id The dataset ID.
         * @param {DatasetRequest} datasetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomDataset(orgId: number, id: number, datasetRequest: DatasetRequest, options?: any): AxiosPromise<Array<DataSet>> {
            return localVarFp.updateCustomDataset(orgId, id, datasetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload dataset.
         * @param {number} orgId The organization ID.
         * @param {string} name 
         * @param {string} description 
         * @param {string} format 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDataset(orgId: number, name: string, description: string, format: string, file?: File, options?: any): AxiosPromise<Array<DataSet>> {
            return localVarFp.uploadDataset(orgId, name, description, format, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file to the dataset and return a reference.
         * @param {number} id The dataset ID.
         * @param {string} type Upload type - i.e. \&#39;transform\&#39; for transform parameters
         * @param {number} [orgId] The organization ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDatasetFile(id: number, type: string, orgId?: number, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadDatasetFile(id, type, orgId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetsApi - object-oriented interface
 * @export
 * @class DatasetsApi
 * @extends {BaseAPI}
 */
export class DatasetsApi extends BaseAPI {
    /**
     * 
     * @summary Add external dataset.
     * @param {number} orgId The organization ID.
     * @param {string} name 
     * @param {string} description 
     * @param {string} format 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public addExternalDataset(orgId: number, name: string, description: string, format: string, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).addExternalDataset(orgId, name, description, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make a copy of a custom dataset. To copy to a different organization, extra permissions are required.
     * @param {number} id The dataset ID.
     * @param {number} [orgId] Optional organization ID to copy to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public copyDataset(id: number, orgId?: number, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).copyDataset(id, orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete dataset.
     * @param {number} orgId The organization ID.
     * @param {number} id The dataset ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public deleteCustomDataset(orgId: number, id: number, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).deleteCustomDataset(orgId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download dataset data.
     * @param {number} orgId The organization ID.
     * @param {number} id The dataset ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public downloadDataset(orgId: number, id: number, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).downloadDataset(orgId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dataset.
     * @param {number} orgId The organization ID.
     * @param {number} id The dataset ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public getDataset(orgId: number, id: number, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).getDataset(orgId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve dataset classes (coco dataset supported).
     * @param {number} orgId The organization ID.
     * @param {number} id The dataset ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public getDatasetClasses(orgId: number, id: number, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).getDatasetClasses(orgId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an item from the dataset.
     * @param {number} id The dataset ID.
     * @param {number} idx The item index.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public getDatasetItem(id: number, idx: number, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).getDatasetItem(id, idx, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a target from the dataset.
     * @param {number} id The dataset ID.
     * @param {number} idx The item index.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public getDatasetTarget(id: number, idx: number, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).getDatasetTarget(id, idx, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the datasets defined for this organization.
     * @param {number} orgId The organization ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public getDatasets(orgId: number, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).getDatasets(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update dataset.
     * @param {number} orgId The organization ID.
     * @param {number} id The dataset ID.
     * @param {DatasetRequest} datasetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public updateCustomDataset(orgId: number, id: number, datasetRequest: DatasetRequest, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).updateCustomDataset(orgId, id, datasetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload dataset.
     * @param {number} orgId The organization ID.
     * @param {string} name 
     * @param {string} description 
     * @param {string} format 
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public uploadDataset(orgId: number, name: string, description: string, format: string, file?: File, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).uploadDataset(orgId, name, description, format, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file to the dataset and return a reference.
     * @param {number} id The dataset ID.
     * @param {string} type Upload type - i.e. \&#39;transform\&#39; for transform parameters
     * @param {number} [orgId] The organization ID.
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApi
     */
    public uploadDatasetFile(id: number, type: string, orgId?: number, file?: File, options?: AxiosRequestConfig) {
        return DatasetsApiFp(this.configuration).uploadDatasetFile(id, type, orgId, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefensesApi - axios parameter creator
 * @export
 */
export const DefensesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a defense.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefense: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDefense', 'id', id)
            const localVarPath = `/api/v1/defenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update defense.
         * @param {number} id 
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefense: async (id: number, defenseRequest: DefenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDefense', 'id', id)
            // verify required parameter 'defenseRequest' is not null or undefined
            assertParamExists('updateDefense', 'defenseRequest', defenseRequest)
            const localVarPath = `/api/v1/defenses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefensesApi - functional programming interface
 * @export
 */
export const DefensesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefensesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a defense.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefense(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefenseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefense(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update defense.
         * @param {number} id 
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDefense(id: number, defenseRequest: DefenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefenseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefense(id, defenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefensesApi - factory interface
 * @export
 */
export const DefensesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefensesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a defense.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefense(id: number, options?: any): AxiosPromise<DefenseResponse> {
            return localVarFp.getDefense(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update defense.
         * @param {number} id 
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefense(id: number, defenseRequest: DefenseRequest, options?: any): AxiosPromise<DefenseResponse> {
            return localVarFp.updateDefense(id, defenseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefensesApi - object-oriented interface
 * @export
 * @class DefensesApi
 * @extends {BaseAPI}
 */
export class DefensesApi extends BaseAPI {
    /**
     * 
     * @summary Get a defense.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefensesApi
     */
    public getDefense(id: number, options?: AxiosRequestConfig) {
        return DefensesApiFp(this.configuration).getDefense(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update defense.
     * @param {number} id 
     * @param {DefenseRequest} defenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefensesApi
     */
    public updateDefense(id: number, defenseRequest: DefenseRequest, options?: AxiosRequestConfig) {
        return DefensesApiFp(this.configuration).updateDefense(id, defenseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KeysApi - axios parameter creator
 * @export
 */
export const KeysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create API key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a key.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKey: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteKey', 'id', id)
            const localVarPath = `/api/v1/keys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a key.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKey: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKey', 'id', id)
            const localVarPath = `/api/v1/keys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all of a user\'s keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeysApi - functional programming interface
 * @export
 */
export const KeysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create API key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a key.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteKey(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteKey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a key.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKey(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all of a user\'s keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIKeyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KeysApi - factory interface
 * @export
 */
export const KeysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeysApiFp(configuration)
    return {
        /**
         * 
         * @summary Create API key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKey(options?: any): AxiosPromise<APIKeyResponse> {
            return localVarFp.createKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a key.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKey(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteKey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a key.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKey(id: number, options?: any): AxiosPromise<APIKeyResponse> {
            return localVarFp.getKey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all of a user\'s keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any): AxiosPromise<Array<APIKeyResponse>> {
            return localVarFp.getKeys(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeysApi - object-oriented interface
 * @export
 * @class KeysApi
 * @extends {BaseAPI}
 */
export class KeysApi extends BaseAPI {
    /**
     * 
     * @summary Create API key.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public createKey(options?: AxiosRequestConfig) {
        return KeysApiFp(this.configuration).createKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a key.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public deleteKey(id: number, options?: AxiosRequestConfig) {
        return KeysApiFp(this.configuration).deleteKey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a key.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public getKey(id: number, options?: AxiosRequestConfig) {
        return KeysApiFp(this.configuration).getKey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all of a user\'s keys.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeysApi
     */
    public getKeys(options?: AxiosRequestConfig) {
        return KeysApiFp(this.configuration).getKeys(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the currently OAuth2 authenticated user platform token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/login/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of supported OAuth clients.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuthClients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/login/sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve an access token using API keys.
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/api/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the currently OAuth2 authenticated user platform token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of supported OAuth clients.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOAuthClients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOAuthClients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve an access token using API keys.
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the currently OAuth2 authenticated user platform token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUser(options?: any): AxiosPromise<AuthorizedUserResponse> {
            return localVarFp.getAuthUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of supported OAuth clients.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuthClients(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getOAuthClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve an access token using API keys.
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<AuthorizedUserResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @summary Get the currently OAuth2 authenticated user platform token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public getAuthUser(options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).getAuthUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of supported OAuth clients.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public getOAuthClients(options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).getOAuthClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve an access token using API keys.
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a metric to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The metric name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMetric: async (id: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMetric', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addMetric', 'name', name)
            const localVarPath = `/api/v1/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a metric by ID.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMetric: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMetric', 'id', id)
            const localVarPath = `/api/v1/metrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download metric file.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMetricFile: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadMetricFile', 'id', id)
            const localVarPath = `/api/v1/metrics/{id}/data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a metric by ID.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetric: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMetric', 'id', id)
            const localVarPath = `/api/v1/metrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the custom metrics available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update metric by ID.
         * @param {string} id The metric ID.
         * @param {MetricDefinition} metricDefinition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetric: async (id: string, metricDefinition: MetricDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMetric', 'id', id)
            // verify required parameter 'metricDefinition' is not null or undefined
            assertParamExists('updateMetric', 'metricDefinition', metricDefinition)
            const localVarPath = `/api/v1/metrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload the metric implementation.
         * @param {string} id The metric ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMetricFile: async (id: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadMetricFile', 'id', id)
            const localVarPath = `/api/v1/metrics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a metric to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The metric name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMetric(id: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMetric(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a metric by ID.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMetric(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMetric(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download metric file.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadMetricFile(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMetricFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a metric by ID.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetric(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetric(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the custom metrics available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetrics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MetricDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetrics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update metric by ID.
         * @param {string} id The metric ID.
         * @param {MetricDefinition} metricDefinition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMetric(id: string, metricDefinition: MetricDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMetric(id, metricDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload the metric implementation.
         * @param {string} id The metric ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMetricFile(id: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMetricFile(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a metric to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The metric name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMetric(id: number, name: string, options?: any): AxiosPromise<MetricDefinition> {
            return localVarFp.addMetric(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a metric by ID.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMetric(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMetric(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download metric file.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMetricFile(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadMetricFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a metric by ID.
         * @param {string} id The metric ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetric(id: string, options?: any): AxiosPromise<MetricDefinition> {
            return localVarFp.getMetric(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the custom metrics available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(options?: any): AxiosPromise<Array<MetricDefinition>> {
            return localVarFp.getMetrics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update metric by ID.
         * @param {string} id The metric ID.
         * @param {MetricDefinition} metricDefinition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetric(id: string, metricDefinition: MetricDefinition, options?: any): AxiosPromise<MetricDefinition> {
            return localVarFp.updateMetric(id, metricDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload the metric implementation.
         * @param {string} id The metric ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMetricFile(id: string, file?: File, options?: any): AxiosPromise<MetricDefinition> {
            return localVarFp.uploadMetricFile(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @summary Add a metric to an organization.
     * @param {number} id The organization ID.
     * @param {string} name The metric name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public addMetric(id: number, name: string, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).addMetric(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a metric by ID.
     * @param {string} id The metric ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public deleteMetric(id: string, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).deleteMetric(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download metric file.
     * @param {string} id The metric ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public downloadMetricFile(id: string, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).downloadMetricFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a metric by ID.
     * @param {string} id The metric ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetric(id: string, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetric(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the custom metrics available for this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetrics(options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetrics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update metric by ID.
     * @param {string} id The metric ID.
     * @param {MetricDefinition} metricDefinition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public updateMetric(id: string, metricDefinition: MetricDefinition, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).updateMetric(id, metricDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload the metric implementation.
     * @param {string} id The metric ID.
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public uploadMetricFile(id: string, file?: File, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).uploadMetricFile(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ModelApi - axios parameter creator
 * @export
 */
export const ModelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a model to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The model name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addModel: async (id: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addModel', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addModel', 'name', name)
            const localVarPath = `/api/v1/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make a copy of a model. To copy to a different organization, extra permissions are required.
         * @param {number} id The model ID.
         * @param {number} [orgId] Optional destination organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyModel: async (id: number, orgId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyModel', 'id', id)
            const localVarPath = `/api/v1/models/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a model by ID.
         * @param {number} id The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteModel', 'id', id)
            const localVarPath = `/api/v1/models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download model data.
         * @param {number} id The model ID.
         * @param {DownloadModelDataDataTypeEnum} dataType The data type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadModelData: async (id: number, dataType: DownloadModelDataDataTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadModelData', 'id', id)
            // verify required parameter 'dataType' is not null or undefined
            assertParamExists('downloadModelData', 'dataType', dataType)
            const localVarPath = `/api/v1/models/{id}/data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a model by ID.
         * @param {number} id The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModel: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getModel', 'id', id)
            const localVarPath = `/api/v1/models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the models available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update model by ID.
         * @param {number} id The model ID.
         * @param {ModelRequest} modelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModel: async (id: number, modelRequest: ModelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateModel', 'id', id)
            // verify required parameter 'modelRequest' is not null or undefined
            assertParamExists('updateModel', 'modelRequest', modelRequest)
            const localVarPath = `/api/v1/models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload model data.
         * @param {number} modelId The model ID.
         * @param {UploadModelDataDataTypeEnum} dataType 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadModelData: async (modelId: number, dataType: UploadModelDataDataTypeEnum, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('uploadModelData', 'modelId', modelId)
            // verify required parameter 'dataType' is not null or undefined
            assertParamExists('uploadModelData', 'dataType', dataType)
            const localVarPath = `/api/v1/models/{modelId}/modeldata`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelApi - functional programming interface
 * @export
 */
export const ModelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a model to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The model name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addModel(id: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addModel(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make a copy of a model. To copy to a different organization, extra permissions are required.
         * @param {number} id The model ID.
         * @param {number} [orgId] Optional destination organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyModel(id: number, orgId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyModel(id, orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a model by ID.
         * @param {number} id The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModel(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download model data.
         * @param {number} id The model ID.
         * @param {DownloadModelDataDataTypeEnum} dataType The data type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadModelData(id: number, dataType: DownloadModelDataDataTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadModelData(id, dataType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a model by ID.
         * @param {number} id The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModel(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the models available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update model by ID.
         * @param {number} id The model ID.
         * @param {ModelRequest} modelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateModel(id: number, modelRequest: ModelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateModel(id, modelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload model data.
         * @param {number} modelId The model ID.
         * @param {UploadModelDataDataTypeEnum} dataType 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadModelData(modelId: number, dataType: UploadModelDataDataTypeEnum, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadModelData(modelId, dataType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelApi - factory interface
 * @export
 */
export const ModelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a model to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The model name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addModel(id: number, name: string, options?: any): AxiosPromise<ModelResponse> {
            return localVarFp.addModel(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make a copy of a model. To copy to a different organization, extra permissions are required.
         * @param {number} id The model ID.
         * @param {number} [orgId] Optional destination organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyModel(id: number, orgId?: number, options?: any): AxiosPromise<ModelResponse> {
            return localVarFp.copyModel(id, orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a model by ID.
         * @param {number} id The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteModel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download model data.
         * @param {number} id The model ID.
         * @param {DownloadModelDataDataTypeEnum} dataType The data type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadModelData(id: number, dataType: DownloadModelDataDataTypeEnum, options?: any): AxiosPromise<File> {
            return localVarFp.downloadModelData(id, dataType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a model by ID.
         * @param {number} id The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModel(id: number, options?: any): AxiosPromise<ModelResponse> {
            return localVarFp.getModel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the models available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModels(options?: any): AxiosPromise<Array<ModelResponse>> {
            return localVarFp.getModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update model by ID.
         * @param {number} id The model ID.
         * @param {ModelRequest} modelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModel(id: number, modelRequest: ModelRequest, options?: any): AxiosPromise<ModelResponse> {
            return localVarFp.updateModel(id, modelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload model data.
         * @param {number} modelId The model ID.
         * @param {UploadModelDataDataTypeEnum} dataType 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadModelData(modelId: number, dataType: UploadModelDataDataTypeEnum, file?: File, options?: any): AxiosPromise<ModelResponse> {
            return localVarFp.uploadModelData(modelId, dataType, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelApi - object-oriented interface
 * @export
 * @class ModelApi
 * @extends {BaseAPI}
 */
export class ModelApi extends BaseAPI {
    /**
     * 
     * @summary Add a model to an organization.
     * @param {number} id The organization ID.
     * @param {string} name The model name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public addModel(id: number, name: string, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).addModel(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make a copy of a model. To copy to a different organization, extra permissions are required.
     * @param {number} id The model ID.
     * @param {number} [orgId] Optional destination organization ID to copy to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public copyModel(id: number, orgId?: number, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).copyModel(id, orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a model by ID.
     * @param {number} id The model ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public deleteModel(id: number, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).deleteModel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download model data.
     * @param {number} id The model ID.
     * @param {DownloadModelDataDataTypeEnum} dataType The data type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public downloadModelData(id: number, dataType: DownloadModelDataDataTypeEnum, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).downloadModelData(id, dataType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a model by ID.
     * @param {number} id The model ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public getModel(id: number, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).getModel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the models available for this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public getModels(options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).getModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update model by ID.
     * @param {number} id The model ID.
     * @param {ModelRequest} modelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public updateModel(id: number, modelRequest: ModelRequest, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).updateModel(id, modelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload model data.
     * @param {number} modelId The model ID.
     * @param {UploadModelDataDataTypeEnum} dataType 
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public uploadModelData(modelId: number, dataType: UploadModelDataDataTypeEnum, file?: File, options?: AxiosRequestConfig) {
        return ModelApiFp(this.configuration).uploadModelData(modelId, dataType, file, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DownloadModelDataDataTypeEnum = {
    Model: 'Model',
    WrapperScript: 'WrapperScript'
} as const;
export type DownloadModelDataDataTypeEnum = typeof DownloadModelDataDataTypeEnum[keyof typeof DownloadModelDataDataTypeEnum];
/**
 * @export
 */
export const UploadModelDataDataTypeEnum = {
    Model: 'Model',
    WrapperScript: 'WrapperScript'
} as const;
export type UploadModelDataDataTypeEnum = typeof UploadModelDataDataTypeEnum[keyof typeof UploadModelDataDataTypeEnum];


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add organization.
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganization: async (organizationRequest: OrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationRequest' is not null or undefined
            assertParamExists('addOrganization', 'organizationRequest', organizationRequest)
            const localVarPath = `/api/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make a copy of the organization to another organization (requires admin permissions).
         * @param {number} id The organization ID.
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyOrganization: async (id: number, organizationRequest: OrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyOrganization', 'id', id)
            // verify required parameter 'organizationRequest' is not null or undefined
            assertParamExists('copyOrganization', 'organizationRequest', organizationRequest)
            const localVarPath = `/api/v1/organizations/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrganization', 'id', id)
            const localVarPath = `/api/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete organization file.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationFile: async (id: number, type: string, ref: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrganizationFile', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteOrganizationFile', 'type', type)
            // verify required parameter 'ref' is not null or undefined
            assertParamExists('deleteOrganizationFile', 'ref', ref)
            const localVarPath = `/api/v1/organizations/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download organization file.
         * @param {number} id The organization ID.
         * @param {string} type Download type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrganizationFile: async (id: number, type: string, ref: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadOrganizationFile', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('downloadOrganizationFile', 'type', type)
            // verify required parameter 'ref' is not null or undefined
            assertParamExists('downloadOrganizationFile', 'ref', ref)
            const localVarPath = `/api/v1/organizations/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the assets defined for this organization.
         * @param {number} id The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetDefinitions: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssetDefinitions', 'id', id)
            const localVarPath = `/api/v1/organizations/{id}/assets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the hyper-parameter optimization methods defined for this organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinedHPO: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDefinedHPO', 'id', id)
            const localVarPath = `/api/v1/organizations/{id}/hpo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the transforms defined for this organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinedTransforms: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDefinedTransforms', 'id', id)
            const localVarPath = `/api/v1/organizations/{id}/transforms`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all members (admin access rights required).
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMembers', 'id', id)
            const localVarPath = `/api/v1/organizations/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganization', 'id', id)
            const localVarPath = `/api/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization file project usage.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFileUsage: async (id: number, type: string, ref: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationFileUsage', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getOrganizationFileUsage', 'type', type)
            // verify required parameter 'ref' is not null or undefined
            assertParamExists('getOrganizationFileUsage', 'ref', ref)
            const localVarPath = `/api/v1/organizations/{id}/file/usage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all organizations - (requires admin permissions).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rename organization file.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {string} name File name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameOrganizationFile: async (id: number, type: string, ref: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('renameOrganizationFile', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('renameOrganizationFile', 'type', type)
            // verify required parameter 'ref' is not null or undefined
            assertParamExists('renameOrganizationFile', 'ref', ref)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('renameOrganizationFile', 'name', name)
            const localVarPath = `/api/v1/organizations/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update storage and accounting information for this organization (admin rights).
         * @param {number} id The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgStats: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrgStats', 'id', id)
            const localVarPath = `/api/v1/organizations/{id}/stat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization.
         * @param {number} id 
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (id: number, organizationRequest: OrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrganization', 'id', id)
            // verify required parameter 'organizationRequest' is not null or undefined
            assertParamExists('updateOrganization', 'organizationRequest', organizationRequest)
            const localVarPath = `/api/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file to the organization and return a reference.
         * @param {number} id The organization ID.
         * @param {string} type Upload type - i.e. \&#39;classmapping\&#39;
         * @param {number} [datasetId] The dataset ID.
         * @param {string} [ref] Reference (supplied when updating)
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOrganizationFile: async (id: number, type: string, datasetId?: number, ref?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadOrganizationFile', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadOrganizationFile', 'type', type)
            const localVarPath = `/api/v1/organizations/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (datasetId !== undefined) {
                localVarQueryParameter['datasetId'] = datasetId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add organization.
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganization(organizationRequest: OrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganization(organizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make a copy of the organization to another organization (requires admin permissions).
         * @param {number} id The organization ID.
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyOrganization(id: number, organizationRequest: OrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyOrganization(id, organizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganization(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganization(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete organization file.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationFile(id: number, type: string, ref: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationFile(id, type, ref, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download organization file.
         * @param {number} id The organization ID.
         * @param {string} type Download type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOrganizationFile(id: number, type: string, ref: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOrganizationFile(id, type, ref, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the assets defined for this organization.
         * @param {number} id The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetDefinitions(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetDefinitions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the hyper-parameter optimization methods defined for this organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefinedHPO(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HPODefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefinedHPO(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the transforms defined for this organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefinedTransforms(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransformDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefinedTransforms(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all members (admin access rights required).
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get organization by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get organization file project usage.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationFileUsage(id: number, type: string, ref: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationFileUsage(id, type, ref, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all organizations - (requires admin permissions).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Rename organization file.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {string} name File name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameOrganizationFile(id: number, type: string, ref: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameOrganizationFile(id, type, ref, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update storage and accounting information for this organization (admin rights).
         * @param {number} id The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrgStats(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrgStats(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update organization.
         * @param {number} id 
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(id: number, organizationRequest: OrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(id, organizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a file to the organization and return a reference.
         * @param {number} id The organization ID.
         * @param {string} type Upload type - i.e. \&#39;classmapping\&#39;
         * @param {number} [datasetId] The dataset ID.
         * @param {string} [ref] Reference (supplied when updating)
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadOrganizationFile(id: number, type: string, datasetId?: number, ref?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadOrganizationFile(id, type, datasetId, ref, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary Add organization.
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganization(organizationRequest: OrganizationRequest, options?: any): AxiosPromise<OrganizationResponse> {
            return localVarFp.addOrganization(organizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make a copy of the organization to another organization (requires admin permissions).
         * @param {number} id The organization ID.
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyOrganization(id: number, organizationRequest: OrganizationRequest, options?: any): AxiosPromise<OrganizationResponse> {
            return localVarFp.copyOrganization(id, organizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete organization file.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationFile(id: number, type: string, ref: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganizationFile(id, type, ref, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download organization file.
         * @param {number} id The organization ID.
         * @param {string} type Download type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrganizationFile(id: number, type: string, ref: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadOrganizationFile(id, type, ref, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the assets defined for this organization.
         * @param {number} id The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetDefinitions(id: number, options?: any): AxiosPromise<AssetsResponse> {
            return localVarFp.getAssetDefinitions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the hyper-parameter optimization methods defined for this organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinedHPO(id: number, options?: any): AxiosPromise<Array<HPODefinition>> {
            return localVarFp.getDefinedHPO(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the transforms defined for this organization.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefinedTransforms(id: number, options?: any): AxiosPromise<Array<TransformDefinition>> {
            return localVarFp.getDefinedTransforms(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all members (admin access rights required).
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers(id: number, options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.getMembers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get organization by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(id: number, options?: any): AxiosPromise<OrganizationResponse> {
            return localVarFp.getOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get organization file project usage.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationFileUsage(id: number, type: string, ref: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getOrganizationFileUsage(id, type, ref, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all organizations - (requires admin permissions).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(options?: any): AxiosPromise<Array<OrganizationResponse>> {
            return localVarFp.getOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rename organization file.
         * @param {number} id The organization ID.
         * @param {string} type File type - i.e. \&#39;classmapping\&#39;
         * @param {string} ref File reference
         * @param {string} name File name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameOrganizationFile(id: number, type: string, ref: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.renameOrganizationFile(id, type, ref, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update storage and accounting information for this organization (admin rights).
         * @param {number} id The organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgStats(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.updateOrgStats(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization.
         * @param {number} id 
         * @param {OrganizationRequest} organizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(id: number, organizationRequest: OrganizationRequest, options?: any): AxiosPromise<OrganizationResponse> {
            return localVarFp.updateOrganization(id, organizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file to the organization and return a reference.
         * @param {number} id The organization ID.
         * @param {string} type Upload type - i.e. \&#39;classmapping\&#39;
         * @param {number} [datasetId] The dataset ID.
         * @param {string} [ref] Reference (supplied when updating)
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOrganizationFile(id: number, type: string, datasetId?: number, ref?: string, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadOrganizationFile(id, type, datasetId, ref, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @summary Add organization.
     * @param {OrganizationRequest} organizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public addOrganization(organizationRequest: OrganizationRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).addOrganization(organizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make a copy of the organization to another organization (requires admin permissions).
     * @param {number} id The organization ID.
     * @param {OrganizationRequest} organizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public copyOrganization(id: number, organizationRequest: OrganizationRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).copyOrganization(id, organizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an organization.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public deleteOrganization(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).deleteOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete organization file.
     * @param {number} id The organization ID.
     * @param {string} type File type - i.e. \&#39;classmapping\&#39;
     * @param {string} ref File reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public deleteOrganizationFile(id: number, type: string, ref: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).deleteOrganizationFile(id, type, ref, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download organization file.
     * @param {number} id The organization ID.
     * @param {string} type Download type - i.e. \&#39;classmapping\&#39;
     * @param {string} ref File reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public downloadOrganizationFile(id: number, type: string, ref: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).downloadOrganizationFile(id, type, ref, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the assets defined for this organization.
     * @param {number} id The organization ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getAssetDefinitions(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getAssetDefinitions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the hyper-parameter optimization methods defined for this organization.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getDefinedHPO(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getDefinedHPO(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the transforms defined for this organization.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getDefinedTransforms(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getDefinedTransforms(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all members (admin access rights required).
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getMembers(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getMembers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get organization by id.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganization(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get organization file project usage.
     * @param {number} id The organization ID.
     * @param {string} type File type - i.e. \&#39;classmapping\&#39;
     * @param {string} ref File reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationFileUsage(id: number, type: string, ref: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganizationFileUsage(id, type, ref, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all organizations - (requires admin permissions).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizations(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rename organization file.
     * @param {number} id The organization ID.
     * @param {string} type File type - i.e. \&#39;classmapping\&#39;
     * @param {string} ref File reference
     * @param {string} name File name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public renameOrganizationFile(id: number, type: string, ref: string, name: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).renameOrganizationFile(id, type, ref, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update storage and accounting information for this organization (admin rights).
     * @param {number} id The organization ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public updateOrgStats(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).updateOrgStats(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization.
     * @param {number} id 
     * @param {OrganizationRequest} organizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public updateOrganization(id: number, organizationRequest: OrganizationRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).updateOrganization(id, organizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file to the organization and return a reference.
     * @param {number} id The organization ID.
     * @param {string} type Upload type - i.e. \&#39;classmapping\&#39;
     * @param {number} [datasetId] The dataset ID.
     * @param {string} [ref] Reference (supplied when updating)
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public uploadOrganizationFile(id: number, type: string, datasetId?: number, ref?: string, file?: File, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).uploadOrganizationFile(id, type, datasetId, ref, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PlatformApi - axios parameter creator
 * @export
 */
export const PlatformApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Administrative call to cancel a worker job (admin rights required)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWorker: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelWorker', 'id', id)
            const localVarPath = `/api/v1/platform/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete platform file (only admins).
         * @param {string} type File type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlatformFile: async (type: string, ref: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deletePlatformFile', 'type', type)
            // verify required parameter 'ref' is not null or undefined
            assertParamExists('deletePlatformFile', 'ref', ref)
            const localVarPath = `/api/v1/platform/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download platform file.
         * @param {string} type Download type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPlatformFile: async (type: string, ref: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('downloadPlatformFile', 'type', type)
            // verify required parameter 'ref' is not null or undefined
            assertParamExists('downloadPlatformFile', 'ref', ref)
            const localVarPath = `/api/v1/platform/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of environments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/platform/environments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get worker queue information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/platform/workerqueue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stat platform file (only admins).
         * @param {string} type File type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statPlatformFile: async (type: string, ref: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('statPlatformFile', 'type', type)
            // verify required parameter 'ref' is not null or undefined
            assertParamExists('statPlatformFile', 'ref', ref)
            const localVarPath = `/api/v1/platform/file/stat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file part to the platform.
         * @param {number} state File upload state: 1 - part, 2 - done.
         * @param {string} ref File reference
         * @param {File} [file] The file part to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFilePart: async (state: number, ref: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('uploadFilePart', 'state', state)
            // verify required parameter 'ref' is not null or undefined
            assertParamExists('uploadFilePart', 'ref', ref)
            const localVarPath = `/api/v1/platform/filepart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file to the platform and return a reference (only admins).
         * @param {string} type Upload type - i.e. \&#39;assets\&#39;
         * @param {string} [description] File description
         * @param {boolean} [chunked] Chunked upload
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPlatformFile: async (type: string, description?: string, chunked?: boolean, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadPlatformFile', 'type', type)
            const localVarPath = `/api/v1/platform/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (chunked !== undefined) {
                localVarQueryParameter['chunked'] = chunked;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Worker heartbeat - can only be called by a worker instance.
         * @param {WorkerHeartbeatRequest} workerHeartbeatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workerHeartbeat: async (workerHeartbeatRequest: WorkerHeartbeatRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workerHeartbeatRequest' is not null or undefined
            assertParamExists('workerHeartbeat', 'workerHeartbeatRequest', workerHeartbeatRequest)
            const localVarPath = `/api/v1/platform/heartbeat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workerHeartbeatRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlatformApi - functional programming interface
 * @export
 */
export const PlatformApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlatformApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Administrative call to cancel a worker job (admin rights required)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelWorker(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelWorker(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete platform file (only admins).
         * @param {string} type File type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlatformFile(type: string, ref: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlatformFile(type, ref, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download platform file.
         * @param {string} type Download type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPlatformFile(type: string, ref: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPlatformFile(type, ref, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of environments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnvironments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvironmentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnvironments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get worker queue information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQueueInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQueueInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stat platform file (only admins).
         * @param {string} type File type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statPlatformFile(type: string, ref: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileStatResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statPlatformFile(type, ref, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a file part to the platform.
         * @param {number} state File upload state: 1 - part, 2 - done.
         * @param {string} ref File reference
         * @param {File} [file] The file part to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFilePart(state: number, ref: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFilePart(state, ref, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a file to the platform and return a reference (only admins).
         * @param {string} type Upload type - i.e. \&#39;assets\&#39;
         * @param {string} [description] File description
         * @param {boolean} [chunked] Chunked upload
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPlatformFile(type: string, description?: string, chunked?: boolean, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPlatformFile(type, description, chunked, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Worker heartbeat - can only be called by a worker instance.
         * @param {WorkerHeartbeatRequest} workerHeartbeatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workerHeartbeat(workerHeartbeatRequest: WorkerHeartbeatRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workerHeartbeat(workerHeartbeatRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlatformApi - factory interface
 * @export
 */
export const PlatformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlatformApiFp(configuration)
    return {
        /**
         * 
         * @summary Administrative call to cancel a worker job (admin rights required)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWorker(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelWorker(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete platform file (only admins).
         * @param {string} type File type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlatformFile(type: string, ref: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePlatformFile(type, ref, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download platform file.
         * @param {string} type Download type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPlatformFile(type: string, ref: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadPlatformFile(type, ref, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of environments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironments(options?: any): AxiosPromise<Array<EnvironmentResponse>> {
            return localVarFp.getEnvironments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get worker queue information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQueueInfo(options?: any): AxiosPromise<QueueInfoResponse> {
            return localVarFp.getQueueInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stat platform file (only admins).
         * @param {string} type File type - i.e. \&#39;assets\&#39;
         * @param {string} ref File reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statPlatformFile(type: string, ref: string, options?: any): AxiosPromise<FileStatResponse> {
            return localVarFp.statPlatformFile(type, ref, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file part to the platform.
         * @param {number} state File upload state: 1 - part, 2 - done.
         * @param {string} ref File reference
         * @param {File} [file] The file part to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFilePart(state: number, ref: string, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadFilePart(state, ref, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file to the platform and return a reference (only admins).
         * @param {string} type Upload type - i.e. \&#39;assets\&#39;
         * @param {string} [description] File description
         * @param {boolean} [chunked] Chunked upload
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPlatformFile(type: string, description?: string, chunked?: boolean, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadPlatformFile(type, description, chunked, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Worker heartbeat - can only be called by a worker instance.
         * @param {WorkerHeartbeatRequest} workerHeartbeatRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workerHeartbeat(workerHeartbeatRequest: WorkerHeartbeatRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.workerHeartbeat(workerHeartbeatRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlatformApi - object-oriented interface
 * @export
 * @class PlatformApi
 * @extends {BaseAPI}
 */
export class PlatformApi extends BaseAPI {
    /**
     * 
     * @summary Administrative call to cancel a worker job (admin rights required)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public cancelWorker(id: string, options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).cancelWorker(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete platform file (only admins).
     * @param {string} type File type - i.e. \&#39;assets\&#39;
     * @param {string} ref File reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public deletePlatformFile(type: string, ref: string, options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).deletePlatformFile(type, ref, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download platform file.
     * @param {string} type Download type - i.e. \&#39;assets\&#39;
     * @param {string} ref File reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public downloadPlatformFile(type: string, ref: string, options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).downloadPlatformFile(type, ref, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of environments.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getEnvironments(options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).getEnvironments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get worker queue information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getQueueInfo(options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).getQueueInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stat platform file (only admins).
     * @param {string} type File type - i.e. \&#39;assets\&#39;
     * @param {string} ref File reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public statPlatformFile(type: string, ref: string, options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).statPlatformFile(type, ref, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file part to the platform.
     * @param {number} state File upload state: 1 - part, 2 - done.
     * @param {string} ref File reference
     * @param {File} [file] The file part to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public uploadFilePart(state: number, ref: string, file?: File, options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).uploadFilePart(state, ref, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file to the platform and return a reference (only admins).
     * @param {string} type Upload type - i.e. \&#39;assets\&#39;
     * @param {string} [description] File description
     * @param {boolean} [chunked] Chunked upload
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public uploadPlatformFile(type: string, description?: string, chunked?: boolean, file?: File, options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).uploadPlatformFile(type, description, chunked, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Worker heartbeat - can only be called by a worker instance.
     * @param {WorkerHeartbeatRequest} workerHeartbeatRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public workerHeartbeat(workerHeartbeatRequest: WorkerHeartbeatRequest, options?: AxiosRequestConfig) {
        return PlatformApiFp(this.configuration).workerHeartbeat(workerHeartbeatRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a project to an organization.
         * @param {number} organizationId The organization ID.
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject: async (organizationId: number, projectRequest: ProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('addProject', 'organizationId', organizationId)
            // verify required parameter 'projectRequest' is not null or undefined
            assertParamExists('addProject', 'projectRequest', projectRequest)
            const localVarPath = `/api/v1/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make a copy of the project available to (optionally) another organization.
         * @param {number} id The project ID.
         * @param {ProjectRequest} projectRequest 
         * @param {number} [destOrgId] The optional destination organization id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProject: async (id: number, projectRequest: ProjectRequest, destOrgId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyProject', 'id', id)
            // verify required parameter 'projectRequest' is not null or undefined
            assertParamExists('copyProject', 'projectRequest', projectRequest)
            const localVarPath = `/api/v1/projects/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (destOrgId !== undefined) {
                localVarQueryParameter['destOrgId'] = destOrgId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make a copy of the project model available to the organization.
         * @param {number} id The project ID.
         * @param {ModelRequest} modelRequest 
         * @param {number} [destModelId] The optional destination model id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProjectModel: async (id: number, modelRequest: ModelRequest, destModelId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyProjectModel', 'id', id)
            // verify required parameter 'modelRequest' is not null or undefined
            assertParamExists('copyProjectModel', 'modelRequest', modelRequest)
            const localVarPath = `/api/v1/projects/{id}/copymodel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (destModelId !== undefined) {
                localVarQueryParameter['destModelId'] = destModelId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete project model data.
         * @param {number} id The project ID.
         * @param {DeleteDataDataTypeEnum} dataType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteData: async (id: number, dataType: DeleteDataDataTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteData', 'id', id)
            // verify required parameter 'dataType' is not null or undefined
            assertParamExists('deleteData', 'dataType', dataType)
            const localVarPath = `/api/v1/projects/{id}/modeldata`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a project.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProject', 'id', id)
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download project data.
         * @param {number} id The project ID.
         * @param {DownloadProjectDataDataTypeEnum} dataType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProjectData: async (id: number, dataType: DownloadProjectDataDataTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadProjectData', 'id', id)
            // verify required parameter 'dataType' is not null or undefined
            assertParamExists('downloadProjectData', 'dataType', dataType)
            const localVarPath = `/api/v1/projects/{id}/data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a project.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProject', 'id', id)
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of projects.
         * @param {number} [organizationId] The optional organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (organizationId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project .
         * @param {number} id The project ID.
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (id: number, projectRequest: ProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProject', 'id', id)
            // verify required parameter 'projectRequest' is not null or undefined
            assertParamExists('updateProject', 'projectRequest', projectRequest)
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload project data.
         * @param {number} id The project ID.
         * @param {UploadProjectDataDataTypeEnum} dataType 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProjectData: async (id: number, dataType: UploadProjectDataDataTypeEnum, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadProjectData', 'id', id)
            // verify required parameter 'dataType' is not null or undefined
            assertParamExists('uploadProjectData', 'dataType', dataType)
            const localVarPath = `/api/v1/projects/{id}/modeldata`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file to the project and return a reference.
         * @param {number} id The project ID.
         * @param {string} type Upload type - \&#39;transform\&#39; for transform parameters
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProjectFile: async (id: number, type: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadProjectFile', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadProjectFile', 'type', type)
            const localVarPath = `/api/v1/projects/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use an existing model to configure the project.
         * @param {number} id The project ID.
         * @param {number} modelId The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useModel: async (id: number, modelId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('useModel', 'id', id)
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('useModel', 'modelId', modelId)
            const localVarPath = `/api/v1/projects/{id}/usemodel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (modelId !== undefined) {
                localVarQueryParameter['modelId'] = modelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start the model verification process.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProject: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verifyProject', 'id', id)
            const localVarPath = `/api/v1/projects/{id}/verify`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a project to an organization.
         * @param {number} organizationId The organization ID.
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProject(organizationId: number, projectRequest: ProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProject(organizationId, projectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make a copy of the project available to (optionally) another organization.
         * @param {number} id The project ID.
         * @param {ProjectRequest} projectRequest 
         * @param {number} [destOrgId] The optional destination organization id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyProject(id: number, projectRequest: ProjectRequest, destOrgId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyProject(id, projectRequest, destOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make a copy of the project model available to the organization.
         * @param {number} id The project ID.
         * @param {ModelRequest} modelRequest 
         * @param {number} [destModelId] The optional destination model id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyProjectModel(id: number, modelRequest: ModelRequest, destModelId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyProjectModel(id, modelRequest, destModelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete project model data.
         * @param {number} id The project ID.
         * @param {DeleteDataDataTypeEnum} dataType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteData(id: number, dataType: DeleteDataDataTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteData(id, dataType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a project.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download project data.
         * @param {number} id The project ID.
         * @param {DownloadProjectDataDataTypeEnum} dataType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadProjectData(id: number, dataType: DownloadProjectDataDataTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadProjectData(id, dataType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a project.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of projects.
         * @param {number} [organizationId] The optional organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(organizationId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update project .
         * @param {number} id The project ID.
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(id: number, projectRequest: ProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(id, projectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload project data.
         * @param {number} id The project ID.
         * @param {UploadProjectDataDataTypeEnum} dataType 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProjectData(id: number, dataType: UploadProjectDataDataTypeEnum, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProjectData(id, dataType, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a file to the project and return a reference.
         * @param {number} id The project ID.
         * @param {string} type Upload type - \&#39;transform\&#39; for transform parameters
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProjectFile(id: number, type: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProjectFile(id, type, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use an existing model to configure the project.
         * @param {number} id The project ID.
         * @param {number} modelId The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useModel(id: number, modelId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useModel(id, modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start the model verification process.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyProject(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a project to an organization.
         * @param {number} organizationId The organization ID.
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject(organizationId: number, projectRequest: ProjectRequest, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.addProject(organizationId, projectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make a copy of the project available to (optionally) another organization.
         * @param {number} id The project ID.
         * @param {ProjectRequest} projectRequest 
         * @param {number} [destOrgId] The optional destination organization id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProject(id: number, projectRequest: ProjectRequest, destOrgId?: number, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.copyProject(id, projectRequest, destOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make a copy of the project model available to the organization.
         * @param {number} id The project ID.
         * @param {ModelRequest} modelRequest 
         * @param {number} [destModelId] The optional destination model id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProjectModel(id: number, modelRequest: ModelRequest, destModelId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.copyProjectModel(id, modelRequest, destModelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete project model data.
         * @param {number} id The project ID.
         * @param {DeleteDataDataTypeEnum} dataType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteData(id: number, dataType: DeleteDataDataTypeEnum, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.deleteData(id, dataType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a project.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download project data.
         * @param {number} id The project ID.
         * @param {DownloadProjectDataDataTypeEnum} dataType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProjectData(id: number, dataType: DownloadProjectDataDataTypeEnum, options?: any): AxiosPromise<File> {
            return localVarFp.downloadProjectData(id, dataType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a project.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(id: number, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.getProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of projects.
         * @param {number} [organizationId] The optional organization ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(organizationId?: number, options?: any): AxiosPromise<Array<ProjectResponse>> {
            return localVarFp.getProjects(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project .
         * @param {number} id The project ID.
         * @param {ProjectRequest} projectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(id: number, projectRequest: ProjectRequest, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.updateProject(id, projectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload project data.
         * @param {number} id The project ID.
         * @param {UploadProjectDataDataTypeEnum} dataType 
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProjectData(id: number, dataType: UploadProjectDataDataTypeEnum, file?: File, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.uploadProjectData(id, dataType, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file to the project and return a reference.
         * @param {number} id The project ID.
         * @param {string} type Upload type - \&#39;transform\&#39; for transform parameters
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProjectFile(id: number, type: string, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadProjectFile(id, type, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use an existing model to configure the project.
         * @param {number} id The project ID.
         * @param {number} modelId The model ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useModel(id: number, modelId: number, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.useModel(id, modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start the model verification process.
         * @param {number} id The project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProject(id: number, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.verifyProject(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Add a project to an organization.
     * @param {number} organizationId The organization ID.
     * @param {ProjectRequest} projectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public addProject(organizationId: number, projectRequest: ProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).addProject(organizationId, projectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make a copy of the project available to (optionally) another organization.
     * @param {number} id The project ID.
     * @param {ProjectRequest} projectRequest 
     * @param {number} [destOrgId] The optional destination organization id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public copyProject(id: number, projectRequest: ProjectRequest, destOrgId?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).copyProject(id, projectRequest, destOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make a copy of the project model available to the organization.
     * @param {number} id The project ID.
     * @param {ModelRequest} modelRequest 
     * @param {number} [destModelId] The optional destination model id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public copyProjectModel(id: number, modelRequest: ModelRequest, destModelId?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).copyProjectModel(id, modelRequest, destModelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete project model data.
     * @param {number} id The project ID.
     * @param {DeleteDataDataTypeEnum} dataType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteData(id: number, dataType: DeleteDataDataTypeEnum, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).deleteData(id, dataType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a project.
     * @param {number} id The project ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProject(id: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).deleteProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download project data.
     * @param {number} id The project ID.
     * @param {DownloadProjectDataDataTypeEnum} dataType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public downloadProjectData(id: number, dataType: DownloadProjectDataDataTypeEnum, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).downloadProjectData(id, dataType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a project.
     * @param {number} id The project ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProject(id: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of projects.
     * @param {number} [organizationId] The optional organization ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjects(organizationId?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjects(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project .
     * @param {number} id The project ID.
     * @param {ProjectRequest} projectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateProject(id: number, projectRequest: ProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).updateProject(id, projectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload project data.
     * @param {number} id The project ID.
     * @param {UploadProjectDataDataTypeEnum} dataType 
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public uploadProjectData(id: number, dataType: UploadProjectDataDataTypeEnum, file?: File, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).uploadProjectData(id, dataType, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file to the project and return a reference.
     * @param {number} id The project ID.
     * @param {string} type Upload type - \&#39;transform\&#39; for transform parameters
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public uploadProjectFile(id: number, type: string, file?: File, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).uploadProjectFile(id, type, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use an existing model to configure the project.
     * @param {number} id The project ID.
     * @param {number} modelId The model ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public useModel(id: number, modelId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).useModel(id, modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start the model verification process.
     * @param {number} id The project ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public verifyProject(id: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).verifyProject(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DeleteDataDataTypeEnum = {
    Model: 'Model',
    WrapperScript: 'WrapperScript'
} as const;
export type DeleteDataDataTypeEnum = typeof DeleteDataDataTypeEnum[keyof typeof DeleteDataDataTypeEnum];
/**
 * @export
 */
export const DownloadProjectDataDataTypeEnum = {
    Model: 'Model',
    WrapperScript: 'WrapperScript'
} as const;
export type DownloadProjectDataDataTypeEnum = typeof DownloadProjectDataDataTypeEnum[keyof typeof DownloadProjectDataDataTypeEnum];
/**
 * @export
 */
export const UploadProjectDataDataTypeEnum = {
    Model: 'Model',
    WrapperScript: 'WrapperScript'
} as const;
export type UploadProjectDataDataTypeEnum = typeof UploadProjectDataDataTypeEnum[keyof typeof UploadProjectDataDataTypeEnum];


/**
 * RegisterApi - axios parameter creator
 * @export
 */
export const RegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register user
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerRequest: RegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerRequest' is not null or undefined
            assertParamExists('register', 'registerRequest', registerRequest)
            const localVarPath = `/api/v1/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user invite - admin permissions required.
         * @param {InviteRequest} inviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite: async (inviteRequest: InviteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteRequest' is not null or undefined
            assertParamExists('sendInvite', 'inviteRequest', inviteRequest)
            const localVarPath = `/api/v1/register/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegisterApi - functional programming interface
 * @export
 */
export const RegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register user
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerRequest: RegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send user invite - admin permissions required.
         * @param {InviteRequest} inviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvite(inviteRequest: InviteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvite(inviteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegisterApi - factory interface
 * @export
 */
export const RegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegisterApiFp(configuration)
    return {
        /**
         * 
         * @summary Register user
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerRequest: RegisterRequest, options?: any): AxiosPromise<AuthorizedUserResponse> {
            return localVarFp.register(registerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user invite - admin permissions required.
         * @param {InviteRequest} inviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite(inviteRequest: InviteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendInvite(inviteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegisterApi - object-oriented interface
 * @export
 * @class RegisterApi
 * @extends {BaseAPI}
 */
export class RegisterApi extends BaseAPI {
    /**
     * 
     * @summary Register user
     * @param {RegisterRequest} registerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public register(registerRequest: RegisterRequest, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).register(registerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send user invite - admin permissions required.
     * @param {InviteRequest} inviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public sendInvite(inviteRequest: InviteRequest, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).sendInvite(inviteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add report.
         * @param {ReportRequest} reportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReport: async (reportRequest: ReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportRequest' is not null or undefined
            assertParamExists('addReport', 'reportRequest', reportRequest)
            const localVarPath = `/api/v1/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a report.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteReport', 'id', id)
            const localVarPath = `/api/v1/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download report data.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportData: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadReportData', 'id', id)
            const localVarPath = `/api/v1/reports/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a report.
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (reportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/api/v1/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get file from report.
         * @param {number} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFile: async (id: number, fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportFile', 'id', id)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('getReportFile', 'fileName', fileName)
            const localVarPath = `/api/v1/reports/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get meta data.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMetaData: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportMetaData', 'id', id)
            const localVarPath = `/api/v1/reports/{id}/meta`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reports for a test.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getReports', 'testId', testId)
            const localVarPath = `/api/v1/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (testId !== undefined) {
                localVarQueryParameter['testId'] = testId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View report data.
         * @param {number} id 
         * @param {number} didx 
         * @param {number} aidx 
         * @param {number} idx 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewData: async (id: number, didx: number, aidx: number, idx: number, tag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('viewData', 'id', id)
            // verify required parameter 'didx' is not null or undefined
            assertParamExists('viewData', 'didx', didx)
            // verify required parameter 'aidx' is not null or undefined
            assertParamExists('viewData', 'aidx', aidx)
            // verify required parameter 'idx' is not null or undefined
            assertParamExists('viewData', 'idx', idx)
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('viewData', 'tag', tag)
            const localVarPath = `/api/v1/reports/{id}/{didx}/{aidx}/{idx}/{tag}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"didx"}}`, encodeURIComponent(String(didx)))
                .replace(`{${"aidx"}}`, encodeURIComponent(String(aidx)))
                .replace(`{${"idx"}}`, encodeURIComponent(String(idx)))
                .replace(`{${"tag"}}`, encodeURIComponent(String(tag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add report.
         * @param {ReportRequest} reportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReport(reportRequest: ReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReport(reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a report.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download report data.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportData(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a report.
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(reportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get file from report.
         * @param {number} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportFile(id: number, fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportFile(id, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get meta data.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportMetaData(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportMetaData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get reports for a test.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReports(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReports(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary View report data.
         * @param {number} id 
         * @param {number} didx 
         * @param {number} aidx 
         * @param {number} idx 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewData(id: number, didx: number, aidx: number, idx: number, tag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewData(id, didx, aidx, idx, tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add report.
         * @param {ReportRequest} reportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReport(reportRequest: ReportRequest, options?: any): AxiosPromise<ReportResponse> {
            return localVarFp.addReport(reportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a report.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download report data.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportData(id: number, options?: any): AxiosPromise<File> {
            return localVarFp.downloadReportData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a report.
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: number, options?: any): AxiosPromise<ReportResponse> {
            return localVarFp.getReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get file from report.
         * @param {number} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFile(id: number, fileName: string, options?: any): AxiosPromise<File> {
            return localVarFp.getReportFile(id, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get meta data.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportMetaData(id: number, options?: any): AxiosPromise<File> {
            return localVarFp.getReportMetaData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reports for a test.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports(testId: number, options?: any): AxiosPromise<Array<ReportResponse>> {
            return localVarFp.getReports(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View report data.
         * @param {number} id 
         * @param {number} didx 
         * @param {number} aidx 
         * @param {number} idx 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewData(id: number, didx: number, aidx: number, idx: number, tag: string, options?: any): AxiosPromise<File> {
            return localVarFp.viewData(id, didx, aidx, idx, tag, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Add report.
     * @param {ReportRequest} reportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public addReport(reportRequest: ReportRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).addReport(reportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a report.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReport(id: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download report data.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadReportData(id: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadReportData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a report.
     * @param {number} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReport(reportId: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get file from report.
     * @param {number} id 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportFile(id: number, fileName: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportFile(id, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get meta data.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportMetaData(id: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportMetaData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reports for a test.
     * @param {number} testId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReports(testId: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReports(testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View report data.
     * @param {number} id 
     * @param {number} didx 
     * @param {number} aidx 
     * @param {number} idx 
     * @param {string} tag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public viewData(id: number, didx: number, aidx: number, idx: number, tag: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).viewData(id, didx, aidx, idx, tag, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a defense to the specified test.
         * @param {number} testId The test id.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDefense: async (testId: number, defenseRequest: DefenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('addDefense', 'testId', testId)
            // verify required parameter 'defenseRequest' is not null or undefined
            assertParamExists('addDefense', 'defenseRequest', defenseRequest)
            const localVarPath = `/api/v1/tests/{testId}/defense`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a filter.
         * @param {number} id The test id.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFilter: async (id: number, filterRequest: FilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addFilter', 'id', id)
            // verify required parameter 'filterRequest' is not null or undefined
            assertParamExists('addFilter', 'filterRequest', filterRequest)
            const localVarPath = `/api/v1/tests/{id}/filter`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new test.
         * @param {TestRequest} testRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTest: async (testRequest: TestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testRequest' is not null or undefined
            assertParamExists('addTest', 'testRequest', testRequest)
            const localVarPath = `/api/v1/tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a test.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTest: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTest', 'id', id)
            const localVarPath = `/api/v1/tests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get defenses.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefenses: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getDefenses', 'testId', testId)
            const localVarPath = `/api/v1/tests/{testId}/defenses`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get test status.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getStatus', 'testId', testId)
            const localVarPath = `/api/v1/tests/{testId}/status`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tests for a project.
         * @param {number} projectId The project id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTests: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTests', 'projectId', projectId)
            const localVarPath = `/api/v1/tests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove defense.
         * @param {number} testId The test id.
         * @param {number} id The defense id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDefense: async (testId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('removeDefense', 'testId', testId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeDefense', 'id', id)
            const localVarPath = `/api/v1/tests/{testId}/defense/{id}`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a filter from the specified test.
         * @param {number} testId The test id.
         * @param {number} id The filter id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFilter: async (testId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('removeFilter', 'testId', testId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFilter', 'id', id)
            const localVarPath = `/api/v1/tests/{testId}/filter/{id}`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a test.
         * @param {number} testId The test id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTest: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('startTest', 'testId', testId)
            const localVarPath = `/api/v1/tests/{testId}/start`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop all the running jobs in a test.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopTest: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stopTest', 'id', id)
            const localVarPath = `/api/v1/tests/{id}/stop`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update test status - can only be called by worker instance.
         * @param {number} testId 
         * @param {number} runId 
         * @param {number} taskId 
         * @param {StatusRequest} statusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatus: async (testId: number, runId: number, taskId: number, statusRequest: StatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('updateStatus', 'testId', testId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('updateStatus', 'runId', runId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateStatus', 'taskId', taskId)
            // verify required parameter 'statusRequest' is not null or undefined
            assertParamExists('updateStatus', 'statusRequest', statusRequest)
            const localVarPath = `/api/v1/tests/{testId}/status`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (runId !== undefined) {
                localVarQueryParameter['runId'] = runId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update test.
         * @param {number} id The test ID.
         * @param {TestRequest} testRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTest: async (id: number, testRequest: TestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTest', 'id', id)
            // verify required parameter 'testRequest' is not null or undefined
            assertParamExists('updateTest', 'testRequest', testRequest)
            const localVarPath = `/api/v1/tests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update test run.
         * @param {number} id The test run ID.
         * @param {TestRunRequest} testRunRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestRun: async (id: number, testRunRequest: TestRunRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTestRun', 'id', id)
            // verify required parameter 'testRunRequest' is not null or undefined
            assertParamExists('updateTestRun', 'testRunRequest', testRunRequest)
            const localVarPath = `/api/v1/tests/testrun/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testRunRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a defense to the specified test.
         * @param {number} testId The test id.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDefense(testId: number, defenseRequest: DefenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDefense(testId, defenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a filter.
         * @param {number} id The test id.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFilter(id: number, filterRequest: FilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFilter(id, filterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a new test.
         * @param {TestRequest} testRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTest(testRequest: TestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTest(testRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a test.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTest(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get defenses.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefenses(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DefenseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefenses(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get test status.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tests for a project.
         * @param {number} projectId The project id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTests(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTests(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove defense.
         * @param {number} testId The test id.
         * @param {number} id The defense id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDefense(testId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDefense(testId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a filter from the specified test.
         * @param {number} testId The test id.
         * @param {number} id The filter id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFilter(testId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFilter(testId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start a test.
         * @param {number} testId The test id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startTest(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startTest(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop all the running jobs in a test.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopTest(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopTest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update test status - can only be called by worker instance.
         * @param {number} testId 
         * @param {number} runId 
         * @param {number} taskId 
         * @param {StatusRequest} statusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatus(testId: number, runId: number, taskId: number, statusRequest: StatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatus(testId, runId, taskId, statusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update test.
         * @param {number} id The test ID.
         * @param {TestRequest} testRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTest(id: number, testRequest: TestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTest(id, testRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update test run.
         * @param {number} id The test run ID.
         * @param {TestRunRequest} testRunRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestRun(id: number, testRunRequest: TestRunRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestRunResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestRun(id, testRunRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a defense to the specified test.
         * @param {number} testId The test id.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDefense(testId: number, defenseRequest: DefenseRequest, options?: any): AxiosPromise<TestResponse> {
            return localVarFp.addDefense(testId, defenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a filter.
         * @param {number} id The test id.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFilter(id: number, filterRequest: FilterRequest, options?: any): AxiosPromise<TestResponse> {
            return localVarFp.addFilter(id, filterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new test.
         * @param {TestRequest} testRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTest(testRequest: TestRequest, options?: any): AxiosPromise<TestResponse> {
            return localVarFp.addTest(testRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a test.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get defenses.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefenses(testId: number, options?: any): AxiosPromise<Array<DefenseResponse>> {
            return localVarFp.getDefenses(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get test status.
         * @param {number} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(testId: number, options?: any): AxiosPromise<TestResponse> {
            return localVarFp.getStatus(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tests for a project.
         * @param {number} projectId The project id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTests(projectId: number, options?: any): AxiosPromise<Array<TestResponse>> {
            return localVarFp.getTests(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove defense.
         * @param {number} testId The test id.
         * @param {number} id The defense id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDefense(testId: number, id: number, options?: any): AxiosPromise<TestResponse> {
            return localVarFp.removeDefense(testId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a filter from the specified test.
         * @param {number} testId The test id.
         * @param {number} id The filter id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFilter(testId: number, id: number, options?: any): AxiosPromise<TestResponse> {
            return localVarFp.removeFilter(testId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a test.
         * @param {number} testId The test id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTest(testId: number, options?: any): AxiosPromise<void> {
            return localVarFp.startTest(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop all the running jobs in a test.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopTest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.stopTest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update test status - can only be called by worker instance.
         * @param {number} testId 
         * @param {number} runId 
         * @param {number} taskId 
         * @param {StatusRequest} statusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatus(testId: number, runId: number, taskId: number, statusRequest: StatusRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.updateStatus(testId, runId, taskId, statusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update test.
         * @param {number} id The test ID.
         * @param {TestRequest} testRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTest(id: number, testRequest: TestRequest, options?: any): AxiosPromise<TestResponse> {
            return localVarFp.updateTest(id, testRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update test run.
         * @param {number} id The test run ID.
         * @param {TestRunRequest} testRunRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestRun(id: number, testRunRequest: TestRunRequest, options?: any): AxiosPromise<TestRunResponse> {
            return localVarFp.updateTestRun(id, testRunRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @summary Add a defense to the specified test.
     * @param {number} testId The test id.
     * @param {DefenseRequest} defenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addDefense(testId: number, defenseRequest: DefenseRequest, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addDefense(testId, defenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a filter.
     * @param {number} id The test id.
     * @param {FilterRequest} filterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addFilter(id: number, filterRequest: FilterRequest, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addFilter(id, filterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new test.
     * @param {TestRequest} testRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public addTest(testRequest: TestRequest, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).addTest(testRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a test.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public deleteTest(id: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).deleteTest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get defenses.
     * @param {number} testId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public getDefenses(testId: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).getDefenses(testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get test status.
     * @param {number} testId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public getStatus(testId: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).getStatus(testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tests for a project.
     * @param {number} projectId The project id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public getTests(projectId: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).getTests(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove defense.
     * @param {number} testId The test id.
     * @param {number} id The defense id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public removeDefense(testId: number, id: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).removeDefense(testId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a filter from the specified test.
     * @param {number} testId The test id.
     * @param {number} id The filter id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public removeFilter(testId: number, id: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).removeFilter(testId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a test.
     * @param {number} testId The test id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public startTest(testId: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).startTest(testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop all the running jobs in a test.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public stopTest(id: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).stopTest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update test status - can only be called by worker instance.
     * @param {number} testId 
     * @param {number} runId 
     * @param {number} taskId 
     * @param {StatusRequest} statusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public updateStatus(testId: number, runId: number, taskId: number, statusRequest: StatusRequest, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).updateStatus(testId, runId, taskId, statusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update test.
     * @param {number} id The test ID.
     * @param {TestRequest} testRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public updateTest(id: number, testRequest: TestRequest, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).updateTest(id, testRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update test run.
     * @param {number} id The test run ID.
     * @param {TestRunRequest} testRunRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public updateTestRun(id: number, testRunRequest: TestRunRequest, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).updateTestRun(id, testRunRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestProfilesApi - axios parameter creator
 * @export
 */
export const TestProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a profile to an organization using the provided test id as a template.
         * @param {number} id The organization ID.
         * @param {number} testId The test ID from which the settings will be copied.
         * @param {string} name The profile name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProfile: async (id: number, testId: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addProfile', 'id', id)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('addProfile', 'testId', testId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addProfile', 'name', name)
            const localVarPath = `/api/v1/profiles/{testId}`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a defense to the profile
         * @param {number} id The profile ID.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestProfileDefense: async (id: number, defenseRequest: DefenseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addTestProfileDefense', 'id', id)
            // verify required parameter 'defenseRequest' is not null or undefined
            assertParamExists('addTestProfileDefense', 'defenseRequest', defenseRequest)
            const localVarPath = `/api/v1/profiles/{id}/adddefense`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a filter to the profile
         * @param {number} id The profile ID.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestProfileFilter: async (id: number, filterRequest: FilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addTestProfileFilter', 'id', id)
            // verify required parameter 'filterRequest' is not null or undefined
            assertParamExists('addTestProfileFilter', 'filterRequest', filterRequest)
            const localVarPath = `/api/v1/profiles/{id}/addfilter`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make a copy of a profile. To copy to a different organization, extra permissions are required.
         * @param {number} id The profile ID.
         * @param {number} [orgId] Optional destination organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProfile: async (id: number, orgId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyProfile', 'id', id)
            const localVarPath = `/api/v1/profiles/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new test profile
         * @param {number} orgId The organization ID.
         * @param {TestProfileRequest} testProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestProfile: async (orgId: number, testProfileRequest: TestProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('createTestProfile', 'orgId', orgId)
            // verify required parameter 'testProfileRequest' is not null or undefined
            assertParamExists('createTestProfile', 'testProfileRequest', testProfileRequest)
            const localVarPath = `/api/v1/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a profile by ID.
         * @param {number} id The profile ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProfile', 'id', id)
            const localVarPath = `/api/v1/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the test profile.
         * @param {number} id The profile ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestProfile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTestProfile', 'id', id)
            const localVarPath = `/api/v1/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the profiles available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestProfiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove defense from test profile.
         * @param {number} id The profile ID.
         * @param {number} defenseId The defense ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTestProfileDefense: async (id: number, defenseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeTestProfileDefense', 'id', id)
            // verify required parameter 'defenseId' is not null or undefined
            assertParamExists('removeTestProfileDefense', 'defenseId', defenseId)
            const localVarPath = `/api/v1/profiles/{id}/defense/{defenseId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"defenseId"}}`, encodeURIComponent(String(defenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update profile by ID.
         * @param {number} id The profile ID.
         * @param {TestProfileRequest} testProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestProfile: async (id: number, testProfileRequest: TestProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTestProfile', 'id', id)
            // verify required parameter 'testProfileRequest' is not null or undefined
            assertParamExists('updateTestProfile', 'testProfileRequest', testProfileRequest)
            const localVarPath = `/api/v1/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestProfilesApi - functional programming interface
 * @export
 */
export const TestProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a profile to an organization using the provided test id as a template.
         * @param {number} id The organization ID.
         * @param {number} testId The test ID from which the settings will be copied.
         * @param {string} name The profile name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProfile(id: number, testId: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProfile(id, testId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a defense to the profile
         * @param {number} id The profile ID.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTestProfileDefense(id: number, defenseRequest: DefenseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTestProfileDefense(id, defenseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a filter to the profile
         * @param {number} id The profile ID.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTestProfileFilter(id: number, filterRequest: FilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTestProfileFilter(id, filterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make a copy of a profile. To copy to a different organization, extra permissions are required.
         * @param {number} id The profile ID.
         * @param {number} [orgId] Optional destination organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyProfile(id: number, orgId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyProfile(id, orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new test profile
         * @param {number} orgId The organization ID.
         * @param {TestProfileRequest} testProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestProfile(orgId: number, testProfileRequest: TestProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestProfile(orgId, testProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a profile by ID.
         * @param {number} id The profile ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the test profile.
         * @param {number} id The profile ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestProfile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestProfile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the profiles available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestProfiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestProfileResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestProfiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove defense from test profile.
         * @param {number} id The profile ID.
         * @param {number} defenseId The defense ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTestProfileDefense(id: number, defenseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTestProfileDefense(id, defenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update profile by ID.
         * @param {number} id The profile ID.
         * @param {TestProfileRequest} testProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestProfile(id: number, testProfileRequest: TestProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestProfile(id, testProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestProfilesApi - factory interface
 * @export
 */
export const TestProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestProfilesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a profile to an organization using the provided test id as a template.
         * @param {number} id The organization ID.
         * @param {number} testId The test ID from which the settings will be copied.
         * @param {string} name The profile name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProfile(id: number, testId: number, name: string, options?: any): AxiosPromise<TestProfileResponse> {
            return localVarFp.addProfile(id, testId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a defense to the profile
         * @param {number} id The profile ID.
         * @param {DefenseRequest} defenseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestProfileDefense(id: number, defenseRequest: DefenseRequest, options?: any): AxiosPromise<TestProfileResponse> {
            return localVarFp.addTestProfileDefense(id, defenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a filter to the profile
         * @param {number} id The profile ID.
         * @param {FilterRequest} filterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestProfileFilter(id: number, filterRequest: FilterRequest, options?: any): AxiosPromise<TestProfileResponse> {
            return localVarFp.addTestProfileFilter(id, filterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make a copy of a profile. To copy to a different organization, extra permissions are required.
         * @param {number} id The profile ID.
         * @param {number} [orgId] Optional destination organization ID to copy to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProfile(id: number, orgId?: number, options?: any): AxiosPromise<TestProfileResponse> {
            return localVarFp.copyProfile(id, orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new test profile
         * @param {number} orgId The organization ID.
         * @param {TestProfileRequest} testProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestProfile(orgId: number, testProfileRequest: TestProfileRequest, options?: any): AxiosPromise<TestProfileResponse> {
            return localVarFp.createTestProfile(orgId, testProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a profile by ID.
         * @param {number} id The profile ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the test profile.
         * @param {number} id The profile ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestProfile(id: number, options?: any): AxiosPromise<TestProfileResponse> {
            return localVarFp.getTestProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the profiles available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestProfiles(options?: any): AxiosPromise<Array<TestProfileResponse>> {
            return localVarFp.getTestProfiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove defense from test profile.
         * @param {number} id The profile ID.
         * @param {number} defenseId The defense ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTestProfileDefense(id: number, defenseId: number, options?: any): AxiosPromise<TestProfileResponse> {
            return localVarFp.removeTestProfileDefense(id, defenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update profile by ID.
         * @param {number} id The profile ID.
         * @param {TestProfileRequest} testProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestProfile(id: number, testProfileRequest: TestProfileRequest, options?: any): AxiosPromise<TestProfileResponse> {
            return localVarFp.updateTestProfile(id, testProfileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestProfilesApi - object-oriented interface
 * @export
 * @class TestProfilesApi
 * @extends {BaseAPI}
 */
export class TestProfilesApi extends BaseAPI {
    /**
     * 
     * @summary Add a profile to an organization using the provided test id as a template.
     * @param {number} id The organization ID.
     * @param {number} testId The test ID from which the settings will be copied.
     * @param {string} name The profile name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public addProfile(id: number, testId: number, name: string, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).addProfile(id, testId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a defense to the profile
     * @param {number} id The profile ID.
     * @param {DefenseRequest} defenseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public addTestProfileDefense(id: number, defenseRequest: DefenseRequest, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).addTestProfileDefense(id, defenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a filter to the profile
     * @param {number} id The profile ID.
     * @param {FilterRequest} filterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public addTestProfileFilter(id: number, filterRequest: FilterRequest, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).addTestProfileFilter(id, filterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make a copy of a profile. To copy to a different organization, extra permissions are required.
     * @param {number} id The profile ID.
     * @param {number} [orgId] Optional destination organization ID to copy to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public copyProfile(id: number, orgId?: number, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).copyProfile(id, orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new test profile
     * @param {number} orgId The organization ID.
     * @param {TestProfileRequest} testProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public createTestProfile(orgId: number, testProfileRequest: TestProfileRequest, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).createTestProfile(orgId, testProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a profile by ID.
     * @param {number} id The profile ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public deleteProfile(id: number, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).deleteProfile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the test profile.
     * @param {number} id The profile ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public getTestProfile(id: number, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).getTestProfile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the profiles available for this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public getTestProfiles(options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).getTestProfiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove defense from test profile.
     * @param {number} id The profile ID.
     * @param {number} defenseId The defense ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public removeTestProfileDefense(id: number, defenseId: number, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).removeTestProfileDefense(id, defenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update profile by ID.
     * @param {number} id The profile ID.
     * @param {TestProfileRequest} testProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestProfilesApi
     */
    public updateTestProfile(id: number, testProfileRequest: TestProfileRequest, options?: AxiosRequestConfig) {
        return TestProfilesApiFp(this.configuration).updateTestProfile(id, testProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransformApi - axios parameter creator
 * @export
 */
export const TransformApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a transform to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The transform name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTransform: async (id: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addTransform', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addTransform', 'name', name)
            const localVarPath = `/api/v1/transforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a transform by ID.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransform: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTransform', 'id', id)
            const localVarPath = `/api/v1/transforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download transform file.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadFile', 'id', id)
            const localVarPath = `/api/v1/transforms/{id}/data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a transform by ID.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransform: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTransform', 'id', id)
            const localVarPath = `/api/v1/transforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the transforms available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransforms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/transforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update transform by ID.
         * @param {number} id The transform ID.
         * @param {TransformDefinition} transformDefinition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransform: async (id: number, transformDefinition: TransformDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTransform', 'id', id)
            // verify required parameter 'transformDefinition' is not null or undefined
            assertParamExists('updateTransform', 'transformDefinition', transformDefinition)
            const localVarPath = `/api/v1/transforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transformDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload the data file for the transform.
         * @param {number} id The transform ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (id: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadFile', 'id', id)
            const localVarPath = `/api/v1/transforms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransformApi - functional programming interface
 * @export
 */
export const TransformApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransformApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a transform to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The transform name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTransform(id: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransformDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTransform(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a transform by ID.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransform(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransform(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download transform file.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a transform by ID.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransform(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransformDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransform(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the transforms available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransforms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransformDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransforms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update transform by ID.
         * @param {number} id The transform ID.
         * @param {TransformDefinition} transformDefinition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransform(id: number, transformDefinition: TransformDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransformDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransform(id, transformDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload the data file for the transform.
         * @param {number} id The transform ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(id: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransformDefinition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransformApi - factory interface
 * @export
 */
export const TransformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransformApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a transform to an organization.
         * @param {number} id The organization ID.
         * @param {string} name The transform name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTransform(id: number, name: string, options?: any): AxiosPromise<TransformDefinition> {
            return localVarFp.addTransform(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a transform by ID.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransform(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTransform(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download transform file.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(id: number, options?: any): AxiosPromise<File> {
            return localVarFp.downloadFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a transform by ID.
         * @param {number} id The transform ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransform(id: number, options?: any): AxiosPromise<TransformDefinition> {
            return localVarFp.getTransform(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the transforms available for this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransforms(options?: any): AxiosPromise<Array<TransformDefinition>> {
            return localVarFp.getTransforms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update transform by ID.
         * @param {number} id The transform ID.
         * @param {TransformDefinition} transformDefinition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransform(id: number, transformDefinition: TransformDefinition, options?: any): AxiosPromise<TransformDefinition> {
            return localVarFp.updateTransform(id, transformDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload the data file for the transform.
         * @param {number} id The transform ID.
         * @param {File} [file] The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(id: number, file?: File, options?: any): AxiosPromise<TransformDefinition> {
            return localVarFp.uploadFile(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransformApi - object-oriented interface
 * @export
 * @class TransformApi
 * @extends {BaseAPI}
 */
export class TransformApi extends BaseAPI {
    /**
     * 
     * @summary Add a transform to an organization.
     * @param {number} id The organization ID.
     * @param {string} name The transform name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransformApi
     */
    public addTransform(id: number, name: string, options?: AxiosRequestConfig) {
        return TransformApiFp(this.configuration).addTransform(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a transform by ID.
     * @param {number} id The transform ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransformApi
     */
    public deleteTransform(id: number, options?: AxiosRequestConfig) {
        return TransformApiFp(this.configuration).deleteTransform(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download transform file.
     * @param {number} id The transform ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransformApi
     */
    public downloadFile(id: number, options?: AxiosRequestConfig) {
        return TransformApiFp(this.configuration).downloadFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a transform by ID.
     * @param {number} id The transform ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransformApi
     */
    public getTransform(id: number, options?: AxiosRequestConfig) {
        return TransformApiFp(this.configuration).getTransform(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the transforms available for this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransformApi
     */
    public getTransforms(options?: AxiosRequestConfig) {
        return TransformApiFp(this.configuration).getTransforms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update transform by ID.
     * @param {number} id The transform ID.
     * @param {TransformDefinition} transformDefinition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransformApi
     */
    public updateTransform(id: number, transformDefinition: TransformDefinition, options?: AxiosRequestConfig) {
        return TransformApiFp(this.configuration).updateTransform(id, transformDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload the data file for the transform.
     * @param {number} id The transform ID.
     * @param {File} [file] The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransformApi
     */
    public uploadFile(id: number, file?: File, options?: AxiosRequestConfig) {
        return TransformApiFp(this.configuration).uploadFile(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the currently logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user logs.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLogs: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserLogs', 'id', id)
            const localVarPath = `/api/v1/users/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users (access rights required).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user feedback.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFeedback: async (userRequest: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('sendFeedback', 'userRequest', userRequest)
            const localVarPath = `/api/v1/users/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update profile of currently authenticated user.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (userRequest: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('updateProfile', 'userRequest', userRequest)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user by user id - (admin permission required)
         * @param {number} id The user ID.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: number, userRequest: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('updateUser', 'userRequest', userRequest)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the currently logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoggedInUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoggedInUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user logs.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLogs(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogEntryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLogs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users (access rights required).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send user feedback.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFeedback(userRequest: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendFeedback(userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update profile of currently authenticated user.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(userRequest: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user by user id - (admin permission required)
         * @param {number} id The user ID.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: number, userRequest: UserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the currently logged-in user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUser(options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getLoggedInUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user logs.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLogs(id: number, options?: any): AxiosPromise<Array<LogEntryResponse>> {
            return localVarFp.getUserLogs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users (access rights required).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user feedback.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFeedback(userRequest: UserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendFeedback(userRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update profile of currently authenticated user.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(userRequest: UserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateProfile(userRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user by user id - (admin permission required)
         * @param {number} id The user ID.
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: number, userRequest: UserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUser(id, userRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Delete a user.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(id: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the currently logged-in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getLoggedInUser(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getLoggedInUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(id: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user logs.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserLogs(id: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserLogs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users (access rights required).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send user feedback.
     * @param {UserRequest} userRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public sendFeedback(userRequest: UserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).sendFeedback(userRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update profile of currently authenticated user.
     * @param {UserRequest} userRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateProfile(userRequest: UserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateProfile(userRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user by user id - (admin permission required)
     * @param {number} id The user ID.
     * @param {UserRequest} userRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(id: number, userRequest: UserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(id, userRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VersionsApi - axios parameter creator
 * @export
 */
export const VersionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get platform version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionsApi - functional programming interface
 * @export
 */
export const VersionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get platform version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlatformVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlatformVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionsApi - factory interface
 * @export
 */
export const VersionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get platform version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformVersion(options?: any): AxiosPromise<string> {
            return localVarFp.getPlatformVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionsApi - object-oriented interface
 * @export
 * @class VersionsApi
 * @extends {BaseAPI}
 */
export class VersionsApi extends BaseAPI {
    /**
     * 
     * @summary Get platform version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionsApi
     */
    public getPlatformVersion(options?: AxiosRequestConfig) {
        return VersionsApiFp(this.configuration).getPlatformVersion(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a webhook for a test.
         * @param {number} testId The test ID.
         * @param {WebhookRequest} webhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhook: async (testId: number, webhookRequest: WebhookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('createWebhook', 'testId', testId)
            // verify required parameter 'webhookRequest' is not null or undefined
            assertParamExists('createWebhook', 'webhookRequest', webhookRequest)
            const localVarPath = `/api/v1/webhooks/test/{testId}`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a webhook.
         * @param {number} hookId The hook ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhook: async (hookId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hookId' is not null or undefined
            assertParamExists('deleteWebhook', 'hookId', hookId)
            const localVarPath = `/api/v1/webhooks/{hookId}`
                .replace(`{${"hookId"}}`, encodeURIComponent(String(hookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a webhook.
         * @param {number} webhookId The Webhook ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhook: async (webhookId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('getWebhook', 'webhookId', webhookId)
            const localVarPath = `/api/v1/webhooks/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all webhooks defined for a test.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhooks: async (testId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('getWebhooks', 'testId', testId)
            const localVarPath = `/api/v1/webhooks/test/{testId}`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a webhook.
         * @param {number} hookId The hook ID.
         * @param {WebhookRequest} webhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhook: async (hookId: number, webhookRequest: WebhookRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hookId' is not null or undefined
            assertParamExists('updateWebhook', 'hookId', hookId)
            // verify required parameter 'webhookRequest' is not null or undefined
            assertParamExists('updateWebhook', 'webhookRequest', webhookRequest)
            const localVarPath = `/api/v1/webhooks/{hookId}`
                .replace(`{${"hookId"}}`, encodeURIComponent(String(hookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a webhook for a test.
         * @param {number} testId The test ID.
         * @param {WebhookRequest} webhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebhook(testId: number, webhookRequest: WebhookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebhook(testId, webhookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a webhook.
         * @param {number} hookId The hook ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebhook(hookId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebhook(hookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a webhook.
         * @param {number} webhookId The Webhook ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhook(webhookId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhook(webhookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all webhooks defined for a test.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhooks(testId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebhookResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhooks(testId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a webhook.
         * @param {number} hookId The hook ID.
         * @param {WebhookRequest} webhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebhook(hookId: number, webhookRequest: WebhookRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebhook(hookId, webhookRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a webhook for a test.
         * @param {number} testId The test ID.
         * @param {WebhookRequest} webhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhook(testId: number, webhookRequest: WebhookRequest, options?: any): AxiosPromise<WebhookResponse> {
            return localVarFp.createWebhook(testId, webhookRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a webhook.
         * @param {number} hookId The hook ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhook(hookId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWebhook(hookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a webhook.
         * @param {number} webhookId The Webhook ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhook(webhookId: number, options?: any): AxiosPromise<WebhookResponse> {
            return localVarFp.getWebhook(webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all webhooks defined for a test.
         * @param {number} testId The test ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhooks(testId: number, options?: any): AxiosPromise<Array<WebhookResponse>> {
            return localVarFp.getWebhooks(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a webhook.
         * @param {number} hookId The hook ID.
         * @param {WebhookRequest} webhookRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhook(hookId: number, webhookRequest: WebhookRequest, options?: any): AxiosPromise<WebhookResponse> {
            return localVarFp.updateWebhook(hookId, webhookRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @summary Create a webhook for a test.
     * @param {number} testId The test ID.
     * @param {WebhookRequest} webhookRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public createWebhook(testId: number, webhookRequest: WebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).createWebhook(testId, webhookRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a webhook.
     * @param {number} hookId The hook ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public deleteWebhook(hookId: number, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).deleteWebhook(hookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a webhook.
     * @param {number} webhookId The Webhook ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhook(webhookId: number, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getWebhook(webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all webhooks defined for a test.
     * @param {number} testId The test ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhooks(testId: number, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getWebhooks(testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a webhook.
     * @param {number} hookId The hook ID.
     * @param {WebhookRequest} webhookRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public updateWebhook(hookId: number, webhookRequest: WebhookRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).updateWebhook(hookId, webhookRequest, options).then((request) => request(this.axios, this.basePath));
    }
}




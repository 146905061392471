import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";

/**
 * Displays the version, copyright and terms for the app.
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            GuardAI v{process.env.REACT_APP_VERSION} - Copyright &#169; &nbsp;
            <Link color="inherit" href="https://www.navinfo.eu/">
                NavInfo EU
            </Link> {new Date().getFullYear()}.
            <br/><Link color="inherit" href="/eula.html">Terms &amp; Conditions</Link>
        </Typography>
    );
}

export default Copyright;

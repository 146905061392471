/*
 * Copyright (C) 2023 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useCallback, useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import URLIcon from '@mui/icons-material/Public';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useSnackbar} from "notistack";
import AppContext from "../AppContext";
import {WebhooksApi} from "../api";
import {checkError, errOptions} from "../util/util";
import {getLogger} from "loglevel";

const log = getLogger("webhook");

const useWebhooks = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);

    const getWebhooks = useCallback(async (testId) => {
        try {
            log.debug("Retrieving webhooks for "+testId);
            const api = new WebhooksApi(conf);
            const hooks = await api.getWebhooks(testId);
            log.debug(`Retrieved ${hooks.data.length} webhooks for ${testId}`);
            return hooks.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get webhooks' + msg, errOptions));
            throw e;
        }
    }, [conf,enqueueSnackbar,history]);

    const createWebhook = useCallback(async (testId, hookReq) => {
        try {
            log.debug("Creating webhook for "+testId);
            const api = new WebhooksApi(conf);
            const hook = await api.createWebhook(testId, hookReq);
            log.debug(`Created webhook for ${testId}`);
            return hook.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to add webhook' + msg, errOptions));
            throw e;
        }
    }, [conf,enqueueSnackbar,history]);

    const deleteWebhook = useCallback(async (hookId) => {
        try {
            log.debug("Deleting webhook "+hookId);
            const api = new WebhooksApi(conf);
            await api.deleteWebhook(hookId);
            log.debug(`Deleted webhook ${hookId}`);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to remove webhook' + msg, errOptions));
            throw e;
        }
    }, [conf,enqueueSnackbar,history]);

    const updateWebhook = useCallback(async (hookId, hr) => {
        try {
            log.debug("Updating webhook "+hookId);
            const api = new WebhooksApi(conf);
            await api.updateWebhook(hookId, hr);
            log.debug(`Updated webhook ${hookId}`);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to update webhook' + msg, errOptions));
            throw e;
        }
    }, [conf,enqueueSnackbar,history]);

    return {getWebhooks, createWebhook, deleteWebhook, updateWebhook}
}

/**
 * UI to configure a webhook
 * @author Kobus Grobler
 * @component
 */
export default function WebHook({testId, webhook={settings: {}}, newWebhook=true, onAdded, onUpdated ,onCancel}) {
    const [url, setUrl] = useState(webhook.url == null ? "": webhook.url);
    const [secret, setSecret] = useState(webhook.secret == null ? "": webhook.secret);
    const [contentType, setContentType] = useState(webhook.settings.contentType == null ? "application/x-www-form-urlencoded": webhook.settings.contentType);
    const [enabled, setEnabled] = useState(webhook.enabled == null ? true: webhook.enabled);
    const {createWebhook, updateWebhook} = useWebhooks();
    const {enqueueSnackbar} = useSnackbar();

    const handleAccept = () => {
        if (!(url.startsWith("https://") || url.startsWith("http://"))) {
            enqueueSnackbar('The URL must start with http:// or https://', errOptions);
            return;
        }
        try {
            new URL(url);
        } catch (_) {
            enqueueSnackbar('Invalid URL', errOptions);
            return;
        }
        webhook.url = url;
        webhook.secret = secret;
        webhook.settings.contentType = contentType;
        webhook.enabled = enabled;
        if (newWebhook) {
            createWebhook(testId, webhook).then((hook) => {
                if (onAdded!= null) {
                    onAdded(hook);
                }
            }).catch((_e) =>{}); // user info handled in hook
        } else {
            updateWebhook(webhook.id, webhook).then( (hook) => {
                if (onUpdated != null) {
                    onUpdated(hook);
                }
            }).catch((_e) =>{});
        }
    }

    const onContentTypeChange = (e) => {
        setContentType(e.target.value);
    }

    return <>
    <Paper sx={{ padding: 2}}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <TextField value={url}
                   fullWidth={true}
                   label="Webhook URL"
                   size="small"
                   required={true}
                   onChange={(event) => {
                       setUrl(event.target.value);
                   }}
                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <URLIcon />
                           </InputAdornment>
                       ),
                   }}
        />
        </Grid>
        <Grid item xs={12}>
        <TextField value={secret}
                   style={{minWidth: "40ch"}}
                   label="Optional secret or token added to Authorization header"
                   type="password"
                   size="small"
                   onChange={(event) => {
                       setSecret(event.target.value);
                   }}/>
        </Grid>
            <Grid item>
                <TextField select
                           SelectProps={{
                               native: true,
                           }}
                           label="Content type"
                           value={contentType}
                           onChange={onContentTypeChange}>
                        <option value={"application/x-www-form-urlencoded"}>
                            {"application/x-www-form-urlencoded"}
                        </option>
                    <option value={"application/json"}>
                        {"application/json"}
                    </option>
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            onChange={(e) => setEnabled(e.target.checked)}
                            checked={enabled}
                        />}
                    label="Enabled"
                />
            </Grid>

            <Grid item container justifyContent={"center"}>
                <Button onClick={() => onCancel()}>
                    Cancel
                </Button>
                <Button onClick={handleAccept}
                        variant="contained"
                        color="primary">
                    {newWebhook ? "Add" : "Update"}
                </Button>
            </Grid>
        </Grid>
    </Paper>
    </>
}

export {useWebhooks}
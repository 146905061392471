import Paper from "@mui/material/Paper";
import SortBox from "./SortBox";
import SearchBox from "./SearchBox";
import React from "react";

export default function SearchAndSort({sortItems, sortProps, filterProps}) {
    return <><Paper variant="outlined" style={{padding: "5px"}}>
        <SortBox sortItems={sortItems} {...sortProps}/>
        <SearchBox {...filterProps}/>
    </Paper></>
}

/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Dropzone from 'react-dropzone'
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import {isParamValid, getLogger} from "./util/util";
import "./ParameterEntry.css"

const log = getLogger("paramentry");

const dropzone = {
        maxWidth: '25em',
        minWidth: '10em',
        minHeight: '5em',
        border: 'dashed',
        borderColor: 'divider',
        borderRadius: 1,
        boxSizing: 'border-box',
        cursor: 'pointer',
        padding: '5px',
        marginRight:'5px'
}

/**
 * View for entering a parameter of a specific type.
 * Used in TuneParamsDialog
 *
 * @author Kobus Grobler
 * @component
 */
function ParameterEntry({fileList, param, ...props}) {
    const [value, setValue] = useState(props.value);
    const [errorMsg, setErrorMsg] = useState(props.errorMsg == null ? "" : props.errorMsg);

    useEffect(() => {
        setErrorMsg(props.errorMsg == null ? "" : props.errorMsg);
    }, [props.errorMsg]);

    function isValid(newValue) {
        if (newValue==="" || newValue==="-") {
            setErrorMsg("");
            return true;
        }
        if (param.type === 'float') {
            let val = parseFloat(newValue);
            if (isNaN(val)) {
                setErrorMsg("Not a valid real number");
                return false;
            }
        } else if (param.type === 'int') {
            let val = parseInt(newValue);
            if (isNaN(val)) {
                setErrorMsg("Not a valid integer number");
                return false;
            }
        }
        setErrorMsg("");
        return true;
    }

    const onChange = event => {
        let newValue;
        if (param.type === 'bool') {
            // boolean component
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
            if (!isValid(newValue)) {
                return;
            }
        }
        setValue(() => {
            props.onChange(props.index, newValue);
            return newValue;
        });
    };

    const onBlur = event => {
        let err = isParamValid(param, [value], 0);
        if (err.length > 0) {
            setErrorMsg(err);
        }
    }

    function getLabel() {
        let label = param.name;
        let dispDefaults = (props.displayDefaults === undefined || props.displayDefaults === true)
            && param.def !== undefined;

        if (param.type === 'arr') {
            if (param.desc) {
                label+=': '+ param.desc;
                if (dispDefaults) {
                    label += ' Def: ' + param.def;
                }
                return label;
            } else {
                label += ' CSV array of: '
            }
        }
        if (param.min !== undefined)
          label +='Min: '+param.min;

        if (param.max !== undefined) {
            if (label !== param.name) label+=", ";
            label += 'Max: ' + param.max;
        }

        if (dispDefaults) {
            if (label !== param.name) label+=", ";
            if (forceDefault()) {
                label += 'Fixed to: ' + param.def;
            } else if (param.def != null) {
                label += 'Def: ' + param.def;
            }
        }
        if (label !== param.name) {
            label = label.slice(0,param.name.length)+' ('+label.slice(param.name.length)+' )';
        }
        return label;
    }

    function forceDefault() {
        return param.force_default !== undefined && param.force_default === true;
    }

    const setFileValue = (name, ref) => {
        let newValue = name + ":" + ref;
        setValue(() => {
            props.onChange(props.index, newValue);
            return newValue;
        });
    }

    const onDrop = async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            let file = acceptedFiles[0];
            let name = file.path;
            if (name === undefined || name === null) {
                name = file.name;
            }
            let idxFrom = name.indexOf('/', 1);
            if (idxFrom === -1) {
                idxFrom = name.indexOf('/');
            }
            name = name.substr(idxFrom + 1);
            log.debug("dropped "+name);
            if (props.onFileUpload != null) {
                let ref = await props.onFileUpload(param, name, file);
                if (ref != null) {
                    setFileValue(name, ref);
                }
            }
        }
    }

    const getFileName = () => {
        if (value != null && value.length > 0) {
            let idx = value.indexOf(":");
            if (idx > 0) {
                return value.substr(0, idx);
            }
        }
        return "";
    }

    const getFileIdx = () => {
        if (value != null && value.length > 0) {
            let idx = value.indexOf(":");
            if (idx > 0) {
                let ref = value.substr(idx+1);
                return fileList.findIndex(f => f.ref === ref);
            }
        }
        return "";
    }

    const getCheckedValue = () => {
        if (value == null || value === "") {
            if (param.def !== undefined) {
                return param.def;
            }
            return false;
        }
        return value;
    }

    return <>
        {param.type === 'list' &&
            <Tooltip arrow title={param.desc} open={param.desc == null ? false: undefined }>
        <TextField fullWidth
                   className="textField"
                   error={errorMsg.length > 0}
                   select
                   disabled={forceDefault()}
                   SelectProps={{
                       native: true,
                   }}
                   helperText={errorMsg}
                   label={getLabel()}
                   value={value}
                   onChange={onChange}>
            <option value="">
            </option>
            {param.list.map((option, idx) => (
                <option key={idx} value={option}>
                    {option}
                </option>
            ))}
        </TextField>
            </Tooltip>
        }

        {param.type === 'bool' &&
            <Tooltip arrow title={param.desc == null ? "" : param.desc} open={param.desc == null ? false: undefined }>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={forceDefault()}
                            color="primary"
                            onChange={onChange}
                            checked={getCheckedValue()}
                        />}
                    label={getLabel()}
                />
            </Tooltip>
        }

        {param.type === 'file' &&
            <>
                <Dropzone onDrop={onDrop} noClick={true}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <Box sx={dropzone}>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>
                                        Drag & Drop the required file here
                                    </p>
                                    <Tooltip arrow title={param.desc}
                                             open={param.desc == null ? false : undefined}>
                                    <span>
                                        <Typography variant="body2" color="textSecondary">{getLabel()}:</Typography>
                                        <Typography variant="body2" className="fileField">{getFileName()}</Typography>
                                    </span>
                                    </Tooltip>
                                </div>
                            </Box>
                        </section>
                    )}
                </Dropzone>
                {fileList != null &&
                    <>
                    <span>
                        Or select from the list of available files below:
                        <TextField fullWidth
                               className="textField"
                               error={errorMsg.length > 0}
                               select
                               SelectProps={{
                                   native: true,
                               }}
                               helperText={errorMsg}
                               label={getLabel()}
                               value={getFileIdx()}
                               onChange={(e) => {
                                   setFileValue(fileList[e.target.value].fileName, fileList[e.target.value].ref)}
                        }>
                        <option value="">
                        </option>
                        {fileList.map((file, idx) => (
                            <option key={idx} value={idx}>
                                {file.fileName}
                            </option>
                        ))}
                        </TextField></span>
                    </>
                }
            </>
        }
        {(param.type !== 'list' && param.type !== 'file' && param.type !== 'bool') &&
            <Tooltip arrow title={param.desc == null ? "" : param.desc} open={param.desc == null ? false: undefined }>
            <TextField fullWidth className="textField"
                       error={errorMsg.length > 0}
                   helperText={errorMsg}
                   disabled={forceDefault()}
                   label={getLabel()}
                   value={value}
                   onBlur={onBlur}
                   onChange={onChange}/>
            </Tooltip>
        }
    </>
}

ParameterEntry.propTypes = {
    /**
     * The parameter definition
     */
    param: PropTypes.object.isRequired,

    /**
     * Called when the value changes
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Parameter value
     */
    value: PropTypes.any.isRequired,

    /**
     * Called when a file is uploaded via drag & drop
     */
    onFileUpload: PropTypes.func,

    /**
     * If false, don't display default in label
     */
    displayDefaults: PropTypes.bool,

    errorMsg: PropTypes.string
}

export default ParameterEntry;

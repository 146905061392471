/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/HelpOutline";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import * as Util from "../util/util"
import TuneParamsDialog from "../aitests/TuneParamsDialog";
import AlertDialog from "../dialogs/AlertDialog";
import {hasDisplayedParams} from "../ParamEntryList";
import {getClassMappingFiles} from "../classmapping/ClassMappings";

const log = Util.getLogger("tfs");

function isConverter(transformDef) {
    return transformDef.converted_target_format != null;
}

/**
 * Get target converters from transform definitions.
 * @param datasetFormats
 * @param definitions
 * @param datasetFormat
 * @returns {*}
 */
function getTargetConverters(datasetFormats, definitions, datasetFormat) {
    let task = formatToSupportedTask(datasetFormats, datasetFormat);
    return definitions.filter(t => isConverter(t) && t.tasks.find(tsk => task === tsk));
}

/**
 * Provides a reverse mapping from DS format to supported task.
 * @param datasetFormats
 * @param format
 * @returns {string|null}
 */
function formatToSupportedTask(datasetFormats, format) {
    if (format == null || datasetFormats == null || datasetFormats.length === 0) {
        log.error("Invalid parameters supplied for format to task mapping.");
        return "";
    }
    let dsformat = datasetFormats.find(df => df.name === format);
    if (dsformat == null) {
        log.error("Unable to find dataset format "+format);
        return "";
    }
    let tasks = dsformat.tasks;
    if (tasks == null || tasks.length === 0) {
        log.error("No tasks found for " + format);
        return "";
    }
    return tasks[0];
}

/**
 * Add a new transform to the selection list.
 *
 * @param list list to add to
 * @param idx index in list
 * @param transformDef the transform definition
 */
function addSelectedTransformToList(list, idx, transformDef) {
    let uid = 0;
    for (const item of list) {
        if (item.uid > uid) uid = item.uid;
    }
    uid++;
    let newItem = {
        ...transformDef,
        uid: uid
    };
    newItem.params = {};
    list.splice(idx, 0, newItem);
}

function filterTransformDefs(dsFormats, format, transformDefs, targetFormat) {
    if (format == null) {
        log.error("ds format is null.")
        return transformDefs;
    }
    let fmt = dsFormats.find( df => df.name === format);
    if (fmt == null) {
        log.error("could not find "+format+" in ds formats.")
        return transformDefs;
    }

    let task = fmt.tasks[0];

    let targets = [fmt.name];

    if (targetFormat != null) {
        targets = [targetFormat];
    }

    return transformDefs.filter(t => {
        if (t.enabled != null && !t.enabled)
            return false;
        if (t.target_format == null || t.target_format.length === 0) {
            return t.tasks.find(tsk => task === tsk);
        }
        if (isConverter(t)) {
            // converter - use ds formats, not converted targetFormat
            // return t.target_format.find(tf => fmt.targets.find(supportedTf => tf === supportedTf))
            //     && t.tasks.find(tsk => task === tsk);
            return false; // don't show converters in tf list
        }
        return t.target_format.find(tf => targets.find(supportedTf => tf === supportedTf))
            && t.tasks.find(tsk => task === tsk);
    });
}

/**
 * Allows configuration of a set of transforms
 * @author Kobus Grobler
 * @component
 */
export default function TransformSelection({assets, organization, onTransformsUpdated, ...props}) {
    const [openTuneDlg, setOpenTuneDlg] = useState(false);
    const [transform, setTransform] = useState(null);
    const [transformDef, setTransformDef] = useState(null);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [targetConverter, setTargetConverter] = useState(0);
    const [targetFormat, setTargetFormat] = useState(null);
    const [openConfirmChangeConverter, setOpenConfirmChangeConverter] = useState(false);
    const [item, setItem] = useState(null);
    const [mappingFiles, setMappingFiles] = useState([]);

    useEffect( () => {
        let tfDefs = null;
        if (props.transforms == null) {
            tfDefs = filterTransformDefs(assets.datasetFormats, props.format, props.availableTransforms, targetFormat);
            setLeft([]);
        } else {
            // find converters in selected transforms
            let converterDef = getTargetConverters(assets.datasetFormats, props.availableTransforms, props.format).filter( converter =>
                props.transforms.find( t => t.id === converter.id));

            let tformat = targetFormat;
            // if we have a converter, update the supported transforms with the converter format
            if (converterDef.length > 0) {
                log.debug("tfs update conv:"+converterDef[0].name+" ds:"+props.format);
                setTargetConverter(converterDef[0].id);
                tformat = converterDef[0].converted_target_format;
                setTargetFormat(tformat);
            } else {
                log.debug("tfs update, no conv, ds:"+props.format);
            }

            for (let i = 0; i < props.transforms.length; i++) {
                // ensure we have unique ids for transforms at the start
                props.transforms[i].uid = i+1;
            }

            tfDefs = filterTransformDefs(assets.datasetFormats, props.format, props.availableTransforms, tformat);

            // filter selected transforms by valid transform definitions
            // this will also remove the target converter since it is removed from defs
            let l = props.transforms.filter( t => tfDefs.find( tf => tf.id === t.id));
            setLeft(l);
        }
        setRight(tfDefs);

    }, [assets, props.transforms, props.availableTransforms, props.format]);

    useEffect( () => {
        setMappingFiles(getClassMappingFiles([organization]));
    }, [organization]);

    const handleTransformClick = (transform) => () => {
        let transformDef = right.find( item => item.id === transform.id);
        // json string used by tune params dialog
        transform.parameters = JSON.stringify(transform.params);
        setTransformDef(transformDef);
        setTransform(transform);
        setOpenTuneDlg(true);
    }

    const handleCloseTuneDlg = (values) => {
        setOpenTuneDlg(false);
        if (values != null) {
            let T = left.find( item => item.uid === transform.uid);
            T.params = Util.convertUserValuesToParams(values, transformDef.params);
            T.parameters = null;
            notifyTransformChange(left, targetConverter);
        }
    }

    function reorder(list, src, dest) {
        const result = Array.from(list);
        const [removed] = result.splice(src, 1);
        result.splice(dest, 0, removed);
        return result;
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // same list drop
            if (destination.droppableId === 'left') {
                if (left.length > 0) {
                    let destItemDef = right.find(def => left[destination.index].id === def.id);
                    if (destItemDef != null) {
                        if (destItemDef.converted_target_format != null) {
                            // this is a converter - fix to 1st item
                            return;
                        }
                    }
                }
                let newLeft = reorder(left, source.index, destination.index);
                setLeft(newLeft);
                notifyTransformChange(newLeft, targetConverter);
            }
        } else {
            // drop on selection
            if (destination.droppableId === 'left') {
                // add when dropped on selection
                addSelectedTransformToList(left, destination.index, right[source.index]);
            } else {
                // remove when dropped available
                left.splice(source.index, 1);
            }
            setLeft(left);
            notifyTransformChange(left, targetConverter);
        }
    };

    function notifyTransformChange(list, converterId) {
        let converter = null;
        if (converterId != null) {
            converter = props.availableTransforms.find(def => def.id === converterId);
        }
        if (converter != null) {
            let persistList = [...list];
            addSelectedTransformToList(persistList, 0, converter);
            onTransformsUpdated(persistList);
        } else {
            onTransformsUpdated(list);
        }
    }

    const changeConverter = (value) => {
        let tfDefs = null;
        if (value === "") { // target converter not set
            value = 0;
            setTargetFormat(null);
            tfDefs = filterTransformDefs(assets.datasetFormats, props.format, props.availableTransforms, null);
        } else {
            value = parseInt(value);
            let converter = props.availableTransforms.find(def => def.id === value);
            setTargetFormat(converter.converted_target_format);
            tfDefs = filterTransformDefs(assets.datasetFormats, props.format, props.availableTransforms, converter.converted_target_format);
        }

        let newTransforms = left.filter( t => tfDefs.find( tf => tf.id === t.id));
        setLeft(newTransforms);
        setRight(tfDefs);
        setTargetConverter(value);
        notifyTransformChange(newTransforms, value);
    }

    const onTargetConverterChanged = (e) => {
        if (left.length > 0) {
            setOpenConfirmChangeConverter(true);
            setItem(e.target.value);
        } else {
            changeConverter(e.target.value);
        }
    }

    const confirmConverter = (accepted, value) => {
        setOpenConfirmChangeConverter(false);
        if (accepted) {
            changeConverter(item);
        }
    }

    const customList = (items, provided, snapshot, name) => (
        <Paper sx={[{minWidth: 250, height: 300, overflow: 'auto'},
            snapshot.isDraggingOver && {backgroundColor: 'primary.light'}
        ]}>
            <List dense ref={provided.innerRef}
                  {...provided.droppableProps}>
                {items.map((item, idx) => {
                    let labelId = `transform-list-${name}-${item.id}`;
                    if (item.uid != null) {
                        labelId += '-'+item.uid;
                    }
                    // key should not include the index of the item
                    let keyId = item.uid || item.id;
                    let secondary;
                    let itemDef;
                    let valid = false;
                    secondary = item.description;
                    let draggable = true;
                    if (name === 'left') {
                        itemDef = right.find(def => item.id === def.id);
                        if (itemDef != null) {
                            if (itemDef.converted_target_format != null) {
                                // this is a converter - fix to 1st item
                                draggable = false;
                            }
                            valid = Util.requiredParamsFilled(itemDef.params, item.params);
                            secondary = Util.convertParamsToStringSummary(Util.mergeDefaultsWithUserParams(itemDef.params, item.params), itemDef.params);
                            if (!valid) {
                                secondary += "(missing parameters)";
                            }
                            let task = formatToSupportedTask(assets.datasetFormats, props.format);
                            if (valid && task != null) {
                                if (itemDef.apply_mapping[task] != null) {
                                    secondary += " (";
                                    if (itemDef.apply_mapping[task].apply_to_input) {
                                        secondary += "input";
                                        if (itemDef.apply_mapping[task].apply_to_target) {
                                            secondary += ", ";
                                        }
                                    }
                                    if (itemDef.apply_mapping[task].apply_to_target) {
                                        secondary += "target";
                                    }
                                    secondary += ")";
                                }
                            }
                        }
                    }
                    return (
                        <Draggable isDragDisabled={!draggable} key={keyId} draggableId={labelId} index={idx}>
                            {(provided, _snapshot) => (
                                <ListItem disabled={props.disabled}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                        <ListItemText primary={item.name}
                                                      sx={(name === 'left' && !valid) && {color: 'error.dark'}}
                                                      secondary={secondary}/>


                                    {name === 'left' && itemDef != null && hasDisplayedParams(itemDef) &&
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                disabled={props.disabled}
                                                onClick={handleTransformClick(item)}
                                                color={"primary"}
                                                size="large">
                                                <EditIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            )}
                        </Draggable>
                    );
                })}
                {provided.placeholder}
            </List>
         </Paper>
    )

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {transformDef != null &&
                <TuneParamsDialog onClose={handleCloseTuneDlg}
                                  open={openTuneDlg}
                                  paramDef={transformDef}
                                  item={transform}
                                  classMappingFiles={mappingFiles}
                                  onFileUpload={props.onFileUpload}
                />
            }

            <AlertDialog open={openConfirmChangeConverter}
                         title="Change target format?"
                         description="Changing the target format will remove invalid transforms."
                         item={item}
                         onClose={confirmConverter}/>

            <Grid container
                     spacing={2}
                     justifyContent="flex-start"
                     alignItems="flex-start">

                {/*{getTargetConverters(assets.datasetFormats, props.availableTransforms, props.format).length > 0 &&*/}
                {/*    <>*/}
                {/*<Grid item container xs={6}>*/}
                {/*</Grid>*/}
                {/*<Grid item container xs={6}>*/}
                {/*    <TextField select*/}
                {/*               SelectProps={{*/}
                {/*                   native: true,*/}
                {/*               }}*/}
                {/*               label="Target converter"*/}
                {/*               value={targetConverter}*/}
                {/*               onChange={onTargetConverterChanged}>*/}
                {/*        <option value="">*/}
                {/*        </option>*/}
                {/*        {getTargetConverters(assets.datasetFormats, props.availableTransforms, props.format).map((converter) =>*/}
                {/*            <option key={converter.id} value={converter.id}>*/}
                {/*                {converter.name}*/}
                {/*            </option>*/}
                {/*        )}*/}
                {/*    </TextField>*/}
                {/*</Grid>*/}
                {/*    </>*/}
                {/*}*/}

                {/* Left and right order has been swapped in the display per request */}
                <Grid item xs={6}>
                    <Typography variant={"body1"}>
                        Available Transforms
                        <IconButton
                            color="primary"
                            onClick={() => window.open(
                                process.env.REACT_APP_DOCS_BASE + "modules/transforms/index.html",
                                '_blank')}
                            aria-label="Help"
                            size="large">
                            <HelpIcon/>
                        </IconButton>
                    </Typography>
                    <Droppable droppableId="right">
                    {(provided, snapshot) =>
                        customList(right, provided, snapshot, "right")
                    }
                </Droppable>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"body1"}>
                        Selected Transforms
                        <IconButton
                            style={{color: "transparent" /* just a placeholder for alignment purposes */}}
                            size="large">
                            <HelpIcon/>
                        </IconButton>
                    </Typography>
                    <Droppable droppableId="left">
                        {(provided, snapshot) =>
                            customList(left, provided, snapshot, "left")
                        }
                    </Droppable>
                </Grid>
        </Grid>
        </DragDropContext>
    );
}

TransformSelection.propTypes = {
    /**
     * Called when the list of selected transforms is updated
     */
    onTransformsUpdated: PropTypes.func.isRequired,

    /**
     * The supplied list of available transforms
     */
    availableTransforms: PropTypes.array.isRequired,

    /**
     * The current set of selected transforms
     */
    transforms: PropTypes.array,

    /**
     * Dataset format
     */
    format: PropTypes.string.isRequired,

    /**
     * Defined assets
     */
    assets: PropTypes.object.isRequired,

    /**
     * Organization
     */
    organization: PropTypes.object.isRequired,

    /**
     * Called when a file is uploaded via drag & drop
     */
    onFileUpload: PropTypes.func

}

export {
    addSelectedTransformToList
}


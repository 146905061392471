import Grid from "@mui/material/Grid";
import Plot from "react-plotly.js/react-plotly";
import {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HideIcon from "@mui/icons-material/VisibilityOff";
import GraphIcon from "@mui/icons-material/Equalizer";
import Paper from "@mui/material/Paper";
import {buildReportTableModel, getDataForMetricCombo} from "./ReportTable";

export const ReportMetricGraph = ({reports}) => {
    const [reportModel, setReportModel] = useState(null);
    const [showPlot, setShowPlot] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (reports != null) {
            setReportModel(buildReportTableModel(reports, 'runId', null, null, null));
        }
    },[reports]);

    useEffect(() => {
        if (reportModel != null) {
            let data = [];
            reportModel.getHeaderMetricNames().forEach(m => {
                reportModel.getHeaderMetricColumnNames(m).forEach( combo => {
                    let xydata = getDataForMetricCombo(reportModel, m, combo);
                    if (xydata.y.length > 0) {
                        data.push({
                            x: xydata.x,
                            y: xydata.y,
                            mode: 'lines+markers',
                            connectgaps: true,
                            name: m + " "+combo
                        });
                    }
                });
            });
            setData(data);
        }
    },[reportModel]);

    return <>
        {reportModel != null &&
            <>
                {showPlot ?
                    <Tooltip title={"Hide graph"} aria-label="Hide plot">
                        <IconButton aria-label="hide" size="small" onClick={() => {
                            setShowPlot(false);
                        }}>
                            <HideIcon/>
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={"Show metric graphs"} aria-label="Show plot">
                        <IconButton
                            size="small"
                            onClick={() => {
                                setShowPlot(true);
                            }}>
                            <GraphIcon/>
                        </IconButton>
                    </Tooltip>
                }
                {showPlot &&
                    <MetricGraph xtitle="Test Run"
                                 data={data}/>
                }
                </>
        }
    </>
}

const MetricGraph = ({title="", xtitle="Date", ytitle="", data}) => {

    const getData = () => {
        return data;
    }

    return <>
        <Paper variant="outlined">
        <Grid container direction="column" alignContent="center" item xs={12}>
            <Grid item xs={12}>
                <Plot style={{width: "80vw", height: "60vh", marginBottom:"5px"}} data={getData()}
                      layout={{
                          title: title,
                          xaxis: {
                              title: {
                                  text: xtitle,
                              },
                          },
                          yaxis: {
                              title: {
                                  text: ytitle,
                                  font: {
                                      size: 14,
                                  }
                              },
                          },
                      }}
                      config={{displaylogo: false, responsive: true}}
                />
            </Grid>
        </Grid>
        </Paper>
    </>
}

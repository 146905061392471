import React, {useEffect, useRef, useState} from 'react';
import Konva from 'konva';
import {Stage} from 'react-konva';
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Box from "@mui/material/Box";
import Regions from './Regions';
import BaseImage from './BaseImage';
import ErrorBoundary from "../util/ErrorBoundary";
import {getLogger} from "loglevel";

const log = getLogger("act");

function getRelativePointerPosition(node) {
    // the function will return pointer position relative to the passed node
    const transform = node.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();

    // get pointer (say mouse or touch) position
    const pos = node.getStage().getPointerPosition();

    // now we find relative point
    return transform.point(pos);
}

function zoomStage(stage, scaleBy) {
    const oldScale = stage.scaleX();

    const pos = {
        x: stage.width() / 2,
        y: stage.height() / 2,
    };
    const mousePointTo = {
        x: pos.x / oldScale - stage.x() / oldScale,
        y: pos.y / oldScale - stage.y() / oldScale,
    };

    const newScale = Math.max(0.05, oldScale * scaleBy);

    const newPos = {
        x: -(mousePointTo.x - pos.x / newScale) * newScale,
        y: -(mousePointTo.y - pos.y / newScale) * newScale,
    };

    const newAttrs = limitAttributes(stage, {...newPos, scale: newScale});

    stage.to({
        x: newAttrs.x,
        y: newAttrs.y,
        scaleX: newAttrs.scale,
        scaleY: newAttrs.scale,
        duration: 0.1,
    });
}

function limitAttributes(stage, newAttrs) {
    const box = stage.findOne('Image').getClientRect();
    const minX = -box.width + stage.width() / 2;
    const maxX = stage.width() / 2;

    const x = Math.max(minX, Math.min(newAttrs.x, maxX));

    const minY = -box.height + stage.height() / 2;
    const maxY = stage.height() / 2;

    const y = Math.max(minY, Math.min(newAttrs.y, maxY));

    const scale = Math.max(0.05, newAttrs.scale);

    return {x, y, scale};
}

const ZoomButtons = ({stageRef}) => {

    return <Box>
        <IconButton color="primary" aria-label="zoom in"
                    onClick={() => {
                        zoomStage(stageRef.current, 1.2);
                    }}>
            <ZoomInIcon/>
        </IconButton>
        <IconButton color="primary" aria-label="zoom out"
                    onClick={() => {
                        zoomStage(stageRef.current, 0.8);
                    }}>
            <ZoomOutIcon/>
        </IconButton>
    </Box>
}

const INITIAL_HEIGHT=480;

const AttackCraftingTool = ({imageUrl, regions, setRegions}) => {
    const stageRef = useRef();
    const [size, setSize] = useState({width: 600, height: INITIAL_HEIGHT});
    const [imageSize, setImageSize] = useState(null);
    const [scale, setScale] = useState(1.0);
    const [isDrawing, toggleDrawing] = useState(false);
    const [regionId, setSelectRegion] = useState(null);
    const [brightness] = useState();
    const [id, setNextId] = useState(1);
    const [dragStarted, setDragStarted] = useState(false);

    useEffect(() => {
        function checkSize() {
            log.debug("resize event");
            const container = document.querySelector('.craftingpanel');
            setSize({
                width: container.offsetWidth,
                height: INITIAL_HEIGHT
            });
        }

        checkSize();
        window.addEventListener('resize', checkSize);
        return () => window.removeEventListener('resize', checkSize);
    }, []);

    const onDragEnd = (_e) => {
        log.debug("drag end");
        setDragStarted(false);
    }

    const onDragStart = (_e) => {
        log.debug("drag start");
        setDragStarted(true);
        toggleDrawing(false);
    }

    const onChange = (regionIn, coords) => {
        log.debug(`Region #${regionIn.id} changed:`+JSON.stringify(coords));
        setRegions(regions.map(region => {
            if (regionIn.id === region.id) {
                region.start.x = coords.x;
                region.start.y = coords.y;
                region.end.x = coords.x + coords.width;
                region.end.y = coords.y + coords.height;
            }
            return region;
        }));
    }

    return (<ErrorBoundary>
            <div className="craftingpanel">
                <ZoomButtons stageRef={stageRef}/>
                <Stage
                    ref={stageRef}
                    width={size.width}
                    height={size.height}
                    scaleX={scale}
                    scaleY={scale}
                    className="canvas"
                    onClick={(e) => {
                        if (!e.target.name().startsWith('region')) {
                            log.debug("Unselecting selected region");
                            setSelectRegion(null);
                        }
                    }}
                    onWheel={(e) => {
                        e.evt.preventDefault();
                        const stage = stageRef.current;
                        const dx = -e.evt.deltaX;
                        const dy = -e.evt.deltaY;
                        const pos = limitAttributes(stage, {
                            x: stage.x() + dx,
                            y: stage.y() + dy,
                            scale: stage.scaleX(),
                        });
                        stageRef.current.position(pos);
                    }}
                    onMouseDown={(e) => {
                        log.debug(`Mouse down ${dragStarted} ${regionId}`);
                        if (dragStarted || regionId != null || regions.length > 0) // just one region
                            return;
                        toggleDrawing(true);
                        const point = getRelativePointerPosition(e.target.getStage());
                        const region = {
                            id: id,
                            color: Konva.Util.getRandomColor(),
                            start: point,
                        };
                        setNextId(id+1);
                        setRegions([...regions, region]);
                    }}
                    onMouseMove={(e) => {
                        if (!isDrawing) {
                            return;
                        }
                        const lastRegion = {...regions[regions.length - 1]};
                        lastRegion.end = getRelativePointerPosition(e.target.getStage());
                        setRegions(regions.map( r => r.id === lastRegion.id ? lastRegion : r));
                    }}
                    onMouseUp={(e) => {
                        toggleDrawing(false);
                        log.debug(`Mouse up - drag:${dragStarted} draw:${isDrawing}`);
                        if (dragStarted || !isDrawing) {
                            return;
                        }
                        const lastRegion = {...regions[regions.length - 1]};
                        lastRegion.end = getRelativePointerPosition(e.target.getStage());
                        log.debug("up:"+JSON.stringify(lastRegion.start)+ ","+JSON.stringify(lastRegion.end));
                        if (Math.abs(lastRegion.start.x-lastRegion.end.x) < 5 ||
                            Math.abs(lastRegion.start.y-lastRegion.end.y) < 5) {
                            log.debug("Region too small");
                            setRegions(regions.filter(r => r.id !== lastRegion.id ));
                        } else {
                            setRegions(regions.map(r => r.id === lastRegion.id ? lastRegion : r));
                        }
                    }}>
                    <BaseImage url={imageUrl}
                               setSize={setSize}
                               setScale={setScale}
                               setImageSize={setImageSize}
                               width={size.width}
                               height={size.height}
                               brightness={brightness}/>
                    <Regions regions={regions}
                             selectedId={regionId}
                             onDragEnd={onDragEnd}
                             onDragStart={onDragStart}
                             onChange={onChange}
                             setSelectRegion={(id) => {
                                 log.debug(`Selecting region id ${id}`);
                                 setSelectRegion(id);
                             }}/>
                </Stage>
            </div>
        </ErrorBoundary>
    );
};

export default AttackCraftingTool

export {getRelativePointerPosition}

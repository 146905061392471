/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useContext, useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    matchPath, useHistory
} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MuiLink from '@mui/material/Link'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ProjectsIcon from '@mui/icons-material/Assessment';
import BenchmarksIcon from '@mui/icons-material/Compare';
import ProfileIcon from '@mui/icons-material/Face';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import HelpIcon from "@mui/icons-material/HelpOutline";
import HomeIcon from "@mui/icons-material/Home";
import DatasetsIcon from '@mui/icons-material/PhotoLibrary';
import TransformIcon from '@mui/icons-material/Transform';
import MetricIcon from '@mui/icons-material/SquareFoot';
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import {useCookies} from 'react-cookie';
import {Configuration, UsersApi} from "./api";
import Dashboard from "./Dashboard";
import Profile from "./users/Profile";
import AppContext from './AppContext';
import ProjectType from "./projects/ProjectType";
import Models from "./models/Models";
import Model from "./models/Model";
import Users from "./users/Users";
import Benchmark from "./benchmarks/Benchmark";
import Transforms from "./transforms/Transforms";
import Transform from "./transforms/Transform";
import {checkError, errOptions, getLogger, isAdminUser} from "./util/util";
import {useSnackbar} from "notistack";
import Organizations from "./organizations/Organizations";
import Benchmarks from "./benchmarks/Benchmarks";
import Projects from "./projects/Projects";
import Project from "./projects/Project";
import ClassMappings from "./classmapping/ClassMappings";
import TestProfiles from "./profiles/TestProfiles";
import TestProfile from "./profiles/TestProfile";
import CustomMetrics from "./metrics/CustomMetrics";
import CustomMetric from "./metrics/CustomMetric";
import EULACheck from "./EULACheck";
import PlatformInfo from "./platform/PlatformInfo";
import Datasets from "./datasets/Datasets";
import Feedback from "./users/Feedback";
import AttackCraftingTool from "./attackcrafting/AttackCraftingTool"

const log = getLogger("main");

const drawerWidth = 240;

const appBar = (theme) => {
    return {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    }
}

const appBarShift = (theme) => {
    return {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    }
}

const toolbar = (theme) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    }
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Main(props) {
    const context = useContext(AppContext);
    const params = {basePath: context.basePath, apiKey: 'Bearer ' + props.login.jwt};
    const [user, setUser] = useState(null);
    const [conf, setConf] = useState(new Configuration(params));
    const contextValue = {conf, setConf, user, setUser};
    const [cookies, setCookie] = useCookies(['asapui']);

    const theme = useTheme();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();

    const [open, setOpen] = useState(cookies.asapui == null ? true : cookies.asapui.drawerOpen);
    const [view, setView] = useState("");

    useEffect(function getUser() {
        if (cookies.asapui == null) {
            setCookie('asapui',  {}, {secure: true, sameSite: "strict"});
        }
        if (props.login != null && props.login.jwt != null) {
            log.debug("Getting logged in user");
            const api = new UsersApi(new Configuration(params));
            api.getLoggedInUser()
                .then((r) => {
                    log.debug("Retrieved logged in user");
                    setUser(r.data);
                    setConf(conf);
                })
                .catch((e) => checkError(e, history, (msg) => {
                    enqueueSnackbar('Failed to get logged in user details'+msg, errOptions);
                }));
        }
    }, [props.login]);

    useEffect(function getViewFromLocation() {
        if (location != null) {
            let viewName = "Main";
            if (matchPath(location.pathname, {path: match.path + '/models'}) != null) {
                viewName = 'Models';
            } else if (matchPath(location.pathname, {path: match.path + '/profiles'}) != null) {
                viewName = 'Test Profiles';
            } else if (matchPath(location.pathname, {path: match.path + '/transforms'}) != null) {
                viewName = 'Transforms';
            } else if (matchPath(location.pathname, {path: match.path + '/projects'}) != null) {
                viewName = 'Projects';
            } else if (matchPath(location.pathname, {path: match.path + '/benchmarks'}) != null) {
                viewName = 'Benchmarks';
            } else if (matchPath(location.pathname, {path: match.path + '/users'}) != null) {
                viewName = 'Users';
            } else if (matchPath(location.pathname, {path: match.path + '/organizations'}) != null) {
                viewName = 'Organizations';
            } else if (matchPath(location.pathname, {path: match.path + '/profile'}) != null) {
                viewName = 'Profile';
            } else if (matchPath(location.pathname, {path: match.path + '/dashboard'}) != null) {
                viewName = 'Dashboard';
            } else if (matchPath(location.pathname, {path: match.path + '/mappings'}) != null) {
                viewName = 'Class Mappings';
            } else if (matchPath(location.pathname, {path: match.path + '/datasets'}) != null) {
                viewName = 'Datasets';
            } else if (matchPath(location.pathname, {path: match.path + '/metrics'}) != null) {
                viewName = 'Custom Metrics';
            }
            setView(viewName);
            document.title = 'NavInfo GuardAI - '+viewName;
        }
    }, [location, match]);

    const handleDrawerOpen = () => {
        setOpen(true);
        cookies.asapui.drawerOpen = true;
        setCookie('asapui', cookies.asapui, {secure: true, sameSite: "strict"});
    };

    const handleDrawerClose = () => {
        setOpen(false);
        cookies.asapui.drawerOpen = false;
        setCookie('asapui', cookies.asapui, {secure: true, sameSite: "strict"});
    };

    const readyToUseApp = () => {
        if (user != null) {
            return user.registered && user.licenseAccepted;
        }
    }

    return (
        <div style={{display: 'flex'}}>
            <AppContext.Provider value={contextValue}>
                <AppBar position="fixed" sx={[appBar, open && appBarShift]}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={[open && {display: 'none', marginRight: 36}]}
                            size="large">
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="body1">
                            {props.login.name} - {view}
                        </Typography>
                        <Feedback context={view}/>
                        <div style={{flexGrow: 1}}/>
                        <PlatformInfo/>
                        <a href="https://www.navinfo.eu/">
                            <img src="img/applogo.png" style={{marginTop: "5px"}} alt="app logo" height="40px"/>
                        </a>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    {readyToUseApp() &&
                        <List>
                            <Tooltip title={"Dashboard"}>
                                <Link to={`${match.url}/dashboard`}>
                                    <ListItemButton key="dashboard">
                                        <ListItemIcon><DashboardIcon/></ListItemIcon>
                                        <ListItemText primary="Dashboard"/>
                                    </ListItemButton>
                                </Link>
                            </Tooltip>
                            <Tooltip title={"Projects"}>
                            <Link to={`${match.url}/projects`}>
                                <ListItemButton key="analysis">
                                    <ListItemIcon><ProjectsIcon/></ListItemIcon>
                                    <ListItemText primary="Projects"/>
                                </ListItemButton>
                            </Link>
                            </Tooltip>

                            <Tooltip title={"Benchmarks"}>
                            <Link to={`${match.url}/benchmarks`}>
                                <ListItemButton key="benchmarks">
                                    <ListItemIcon><BenchmarksIcon/></ListItemIcon>
                                    <ListItemText primary="Benchmarks"/>
                                </ListItemButton>
                            </Link>
                            </Tooltip>
                            <Divider/>
                                <Tooltip title={"Datasets"}>
                            <Link to={`${match.url}/datasets`}>
                                <ListItemButton key="datasets">
                                    <ListItemIcon><DatasetsIcon/></ListItemIcon>
                                    <ListItemText primary="Datasets"/>
                                </ListItemButton>
                            </Link>
                                </Tooltip>

                                <Tooltip title={"Models"}>
                            <Link to={`${match.url}/models`} style={{textDecoration: 'none'}}>
                                <ListItemButton key="models">
                                    <ListItemIcon>
                                    <span style={{textDecoration: 'none', marginLeft: '5px', fontSize: '1.5em',
                                        fontWeight: 'bold'}}>Z</span>
                                    </ListItemIcon>
                                    <ListItemText style={{textDecoration: 'underline'}} primary="Model Zoo"/>
                                </ListItemButton>
                            </Link>
                                </Tooltip>
                                <Tooltip title={"Test Profiles"}>
                            <Link to={`${match.url}/profiles`} style={{textDecoration: 'none'}}>
                                <ListItemButton key="profiles">
                                    <ListItemIcon>
                                    <span style={{textDecoration: 'none', marginLeft: '5px', fontSize: '1.5em',
                                        fontWeight: 'bold'}}>P</span>
                                    </ListItemIcon>
                                    <ListItemText style={{textDecoration: 'underline'}} primary="Test Profiles"/>
                                </ListItemButton>
                            </Link>
                                </Tooltip>
                            {/*    <Tooltip title={"Transforms"}>*/}
                            {/*<Link to={`${match.url}/transforms`}>*/}
                            {/*    <ListItemButton key="transforms">*/}
                            {/*        <ListItemIcon><TransformIcon/></ListItemIcon>*/}
                            {/*        <ListItemText primary="Transforms"/>*/}
                            {/*    </ListItemButton>*/}
                            {/*</Link>*/}
                            {/*    </Tooltip>*/}
                                {/*<Tooltip title={"Metrics"}>*/}
                                {/*    <Link to={`${match.url}/metrics`}>*/}
                                {/*    <ListItemButton key="metrics">*/}
                                {/*        <ListItemIcon>*/}
                                {/*            <MetricIcon/>*/}
                                {/*        </ListItemIcon>*/}
                                {/*        <ListItemText primary="Metrics"/>*/}
                                {/*    </ListItemButton>*/}
                                {/*</Link>*/}
                                {/*</Tooltip>*/}
                            {/*<Tooltip title={"Class mapping"}>*/}
                            {/*<Link to={`${match.url}/mappings`} style={{textDecoration: 'none'}}>*/}
                            {/*    <ListItemButton key="mappings">*/}
                            {/*        <ListItemIcon>*/}
                            {/*        <span style={{textDecoration: 'none', marginLeft: '5px', fontSize: '1.5em',*/}
                            {/*            fontWeight: 'bold'}}>M</span>*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText style={{textDecoration: 'underline'}} primary="Class mappings"/>*/}
                            {/*    </ListItemButton>*/}
                            {/*</Link>*/}
                            {/*</Tooltip>*/}
                            <Divider/>
                            <Tooltip title={"Profile"}>
                            <Link to={`${match.url}/profile`}>
                                <ListItemButton key="profile">
                                    <ListItemIcon><ProfileIcon/></ListItemIcon>
                                    <ListItemText primary="Profile"/>
                                </ListItemButton>
                            </Link>
                            </Tooltip>
                            {isAdminUser(props.login) &&
                                <>
                                    <Link to={`${match.url}/users`}>
                                        <ListItemButton key="users">
                                            <ListItemIcon><PeopleIcon/></ListItemIcon>
                                            <ListItemText primary="Users"/>
                                        </ListItemButton>
                                    </Link>
                                    <Link to={`${match.url}/organizations`}>
                                        <ListItemButton key="organizations">
                                            <ListItemIcon><BusinessIcon/></ListItemIcon>
                                            <ListItemText primary="Organisations"/>
                                        </ListItemButton>
                                    </Link>
                                </>
                            }
                        </List>
                    }
                    <Divider/>
                    <List>

                        {readyToUseApp() &&
                            <>
                            <Tooltip title={"Home"}>
                            <Link to={`${match.url}`}>
                                <ListItemButton key="home">
                                    <ListItemIcon><HomeIcon/></ListItemIcon>
                                    <ListItemText primary="Home"/>
                                </ListItemButton>
                            </Link>
                            </Tooltip>
                            <Tooltip title={"Documentation"}>
                                <MuiLink href={process.env.REACT_APP_DOCS_BASE} target='_blank' underline="always">
                                    <ListItemButton key="Documentation">
                                        <ListItemIcon><HelpIcon/></ListItemIcon>
                                        <ListItemText primary="Documentation"/>
                                    </ListItemButton>
                                </MuiLink>
                            </Tooltip>
                            </>
                        }
                        <Tooltip title={"Logout"}>
                        <ListItemButton key="logout" onClick={props.logout}>
                            <ListItemIcon><LogoutIcon/></ListItemIcon>
                            <ListItemText primary="Logout"/>
                        </ListItemButton>
                        </Tooltip>
                    </List>
                </Drawer>
                {
                    <main style={{flexGrow: 1}}>
                        <Box sx={toolbar}/>
                        <Grid p={1} container>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center">
                                    {readyToUseApp() &&
                                        <Switch>
                                            <Route exact path={`${match.path}/models`}>
                                                <Models/>
                                            </Route>
                                            <Route path={`${match.path}/models/:modelId`}>
                                                <Model conf={conf}/>
                                            </Route>
                                            <Route exact path={`${match.path}/profiles`}>
                                                <TestProfiles/>
                                            </Route>
                                            <Route path={`${match.path}/profiles/:profileId`}>
                                                <TestProfile/>
                                            </Route>
                                            <Route exact path={`${match.path}/transforms`}>
                                                <Transforms conf={conf}/>
                                            </Route>
                                            <Route path={`${match.path}/transforms/:transformId`}>
                                                <Transform conf={conf}/>
                                            </Route>
                                            <Route exact path={`${match.path}/benchmarks`}>
                                                <Benchmarks/>
                                            </Route>
                                            <Route path={`${match.path}/benchmarks/:benchmarkId`}>
                                                <Benchmark/>
                                            </Route>
                                            <Route exact path={`${match.path}/projects`}>
                                                <Projects/>
                                            </Route>
                                            <Route path={`${match.path}/projects/:projectId`}>
                                                <Project/>
                                            </Route>
                                            <Route path={`${match.path}/analysis`}>
                                                <ProjectType conf={conf}/>
                                            </Route>
                                            <Route path={`${match.path}/profile`}>
                                                <Profile/>
                                            </Route>
                                            <Route path={`${match.path}/dashboard`}>
                                                <Dashboard/>
                                            </Route>
                                            <Route path={`${match.path}/users`}>
                                                <Users/>
                                            </Route>
                                            <Route path={`${match.path}/organizations`}>
                                                <Organizations conf={conf}/>
                                            </Route>
                                            <Route exact path={`${match.path}/metrics`}>
                                                <CustomMetrics/>
                                            </Route>
                                            <Route path={`${match.path}/metrics/:uuid/`}>
                                                <CustomMetric/>
                                            </Route>
                                            <Route path={`${match.path}/mappings`}>
                                                <ClassMappings/>
                                            </Route>
                                            <Route path={`${match.path}/datasets`}>
                                                <Datasets/>
                                            </Route>
                                            <Route path={`${match.path}/craft`}>
                                                <AttackCraftingTool/>
                                            </Route>
                                            <Route path={`${match.path}`}>
                                                <ProjectType/>
                                            </Route>
                                        </Switch>
                                    }
                                    {!readyToUseApp() &&
                                        <><Typography variant="h6" style={{marginTop: "5em"}}>
                                            {user == null ?
                                                <>
                                                    {props.login.autoEnrol ?
                                                        <>Thank you for signing up!<br/>Your account is being created.
                                                            When the test environment setup completes in a few minutes,
                                                            you will
                                                            receive a notification email.<br/>Should this not be the
                                                            case,&nbsp;
                                                            <a href="mailto:guardaisupport@navinfo.eu?subject=Support request for GuardAI"
                                                               target="_blank">please contact support</a>.
                                                        </> :
                                                        <>You are not yet registered on the platform -
                                                            You will shortly receive an email with an invitation
                                                            link.<br/>Alternatively,
                                                            you can request a new invitation from: &nbsp;
                                                            <a href="mailto:guardaisupport@navinfo.eu?subject=Access request for GuardAI"
                                                               target="_blank">guardaisupport@navinfo.eu</a>
                                                        </>
                                                    }
                                                </> :
                                                <>
                                                    {!user.licenseAccepted &&
                                                        <EULACheck user={user}/>
                                                    }
                                                </>
                                            }
                                        </Typography></>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </main>
                }
            </AppContext.Provider>
        </div>
    );
}

export default Main;

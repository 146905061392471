/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Tooltip from "@mui/material/Tooltip";
import React from "react";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";

const baseStyle = {
        display: "inline-block",
        minWidth: "1.9rem",
        minHeight: "1.9rem",
        maxWidth: "1.9rem",
        maxHeight: "1.9rem",
        height: "1.9rem",
        width: "1.9rem",
        verticalAlign: "center",
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "bold",
        borderRadius: "50%",
        border: "2px",
        borderStyle: "solid",
        paddingTop: "2px"
};

/**
 * Displays a task type icon based on the tasks list provided
 * @param tasks the array of tasks
 * @param verified if the project or status is verified
 * @param props optional props
 * @returns {JSX.Element} the component
 * @constructor
 */
export default function ProjectTypeIndicator({tasks = [], verified = true, ...props}) {

    function getTasksString() {
        if (tasks == null) {
            return "";
        }
        return tasks.reduce((acc, t) => acc + t.charAt(0).toUpperCase() + t.substring(1) + " ", "").trim();
    }

    return <Tooltip title={`${(verified ? "Model Tasks: "+getTasksString(): "Project not verified")}`}>
        <Stack direction="row">
        {tasks != null && tasks.sort().map( (t, idx) =>
            <Box component="span" key={t} mr={tasks.length > 1 && idx < tasks.length-1 ? 1 : 0}
                 sx={[baseStyle,
                     verified && {
                         color: 'primary.main'
                     },
                     !verified && {
                         color: 'text.secondary'
                     }
                 ]}
                 {...props}>
                {t.charAt(0).toUpperCase()}
            </Box>
        )}
        </Stack>
    </Tooltip>
}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import {Stack} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {useDatasets} from "../datasets/Datasets";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HideIcon from "@mui/icons-material/VisibilityOff";
import ViewIcon from "@mui/icons-material/Visibility";
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import DownloadIcon from "@mui/icons-material/CloudDownload";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Slider from "@mui/material/Slider";
import {useReports} from "./Reports";
import {getLogger} from "../util/util";

const log = getLogger("imageplayer");

/**
 * ImagePlayer to view sequential data images esp. for Kitty
 * @param hide
 * @param pause
 * @param loadFunction
 * @param startIdx
 * @param count
 * @param interval
 * @param loadInterval
 * @param imageStyle
 * @param title
 * @param externalIdx
 * @param onIdxUpdated
 * @param reportId
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
const ImagePlayer = ({hide = false, pause = false, loadFunction, startIdx = 0,
                         count = 50, interval = 250, loadInterval = 250,
                         imageStyle, title, externalIdx, onIdxUpdated, reportId = 0}) => {

    const [images, setImages] = useState([]);
    const [idx, setIdx] = useState(externalIdx);
    const [noMoreImages, setNoMoreImages] = useState(false);

    useEffect(() => {
        if (reportId !== 0) {
            setImages([]);
            setNoMoreImages(false);
            setIdx(externalIdx);
        }
    }, [reportId]);

    useEffect(() => {
        if (externalIdx != null && images.length <= externalIdx && !hide && !noMoreImages) {
            loadFunction(externalIdx).then((data) => {
                setImages([...images, {url: URL.createObjectURL(data), title: "image_" + images.length}]);
                setIdx(externalIdx);
            }).catch((_e) => {
                setNoMoreImages(true);
            });
        } else if (externalIdx != null && externalIdx < images.length) {
            setIdx(externalIdx);
        }
    }, [externalIdx, hide, loadFunction, images, noMoreImages]);

    useEffect(() => {
        if (externalIdx == null && !hide && !pause && !noMoreImages) {
            const id = setInterval(async () => {
                try {
                    let data = await loadFunction(startIdx + images.length);
                    let localImages = [...images, {url: URL.createObjectURL(data), title: "image_" + images.length}];
                    setImages(localImages);
                    setIdx(localImages.length - 1);
                    if (onIdxUpdated != null)
                        onIdxUpdated(localImages.length - 1);
                    if (localImages.length >= count) {
                        setNoMoreImages(true);
                    }
                } catch (e) {
                    log.debug(e);
                    setNoMoreImages(true);
                }
            }, Math.max(loadInterval, interval));
            return () => clearInterval(id);
        }
    }, [externalIdx, onIdxUpdated, hide, pause, startIdx, count, images, interval, noMoreImages,
        loadInterval, loadFunction]);

    useEffect(() => {
        if (externalIdx == null && !pause && noMoreImages) {
            const id = setInterval(() => {
                setIdx((idx) => {
                    let localIdx = idx + 1;
                    if (localIdx >= images.length) {
                        localIdx = 0;
                    }
                    if (onIdxUpdated != null)
                        onIdxUpdated(localIdx);
                    return localIdx;
                });
            }, interval);
            return () => clearInterval(id);
        }
    }, [images.length, externalIdx, onIdxUpdated, noMoreImages, pause, interval]);

    return <>
        {images.length > 0 && idx < images.length && !hide && // only hide here since we do not want to lose images when component unmounts
            <>{title != null &&
                title +" item at idx "+idx
            }
            <img style={imageStyle}
                src={images[idx].url} alt="data player"/>
            </>
        }
    </>
}

/**
 * Dataset image player
 * @param hide
 * @param interval
 * @param pause
 * @param orgId
 * @param datasetId
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
function DatasetPlayer({hide, interval, pause, orgId, datasetId}) {
    const {getDatasetItem} = useDatasets();

    const loadFunction = useCallback((idx) => {
        return getDatasetItem(datasetId, idx, false);
    }, [datasetId, getDatasetItem]);

    return <ImagePlayer title="Dataset Image" imageStyle={{margin: "0.5", maxWidth: "80vw", minWidth: "10vw", maxHeight: "80vh"}}
                        interval={interval} hide={hide} pause={pause} loadFunction={loadFunction}/>
}

const getDsIdxFromName = (name) => {
    if (name != null) {
        let m = name.match(/image(\d+)_(\d+)-batch(\d+)_/);
        if (m != null) {
            return parseInt(m[1])+parseInt(m[2])*parseInt(m[3]);
        }
    }
}

function ReportPlayer({hide, zoom, interval, pause, reportId, datasetId}) {
    const {getReportMetaData, getReportFile, getSampleData} = useReports();
    const {getDatasetItem} = useDatasets();
    const [fileRefs, setFileRefs] = useState(null);
    const [filePaths, setFilePaths] = useState(null);
    const [idx, setIdx] = useState(0);

    useEffect(() => {
        getReportMetaData(reportId).then(data => {
            if (data != null) {
                setIdx(0);
                if (data.version === 2) {
                    if (data.file_refs != null) {
                        let refs = Object.values(data.file_refs);
                        setFilePaths(refs);
                    }
                } else {
                    setFileRefs(data.file_refs);
                }
            }
        });
    }, [reportId, getReportMetaData])

    const loadFunction = useCallback((idx) => {
        if (fileRefs != null) {
            return getSampleData(reportId, 0, fileRefs[idx]);
        } else {
            return getReportFile(reportId, filePaths[idx]);
        }
    }, [reportId, fileRefs, filePaths, getSampleData, getReportFile]);

    const dsLoadFunction = useCallback((idx) => {
        let dsIdx;
        if (fileRefs != null) {
            dsIdx = getDsIdxFromName(fileRefs[idx]);
        } else {
            dsIdx = getDsIdxFromName(filePaths[idx]);
        }
        return getDatasetItem(datasetId, dsIdx, false);
    }, [datasetId, fileRefs, filePaths, getDatasetItem]);

    return <>
        {(fileRefs != null || filePaths != null) && <>
            <ImagePlayer title="Dataset"
                         externalIdx={idx}
                         imageStyle={{margin: "0.5", maxWidth: "80vw", minWidth: "10vw", maxHeight: "80vh"}}
                         count={fileRefs != null ? fileRefs.length: filePaths.length}
                         interval={interval}
                         hide={hide} pause={pause}
                         loadFunction={dsLoadFunction}/>
            <ImagePlayer imageStyle={zoom ? {maxWidth: "90vw", minWidth: "10vw", height: "auto", width: "90vw"}:
                {margin: "0.5", maxWidth: "80vw", minWidth: "10vw", maxHeight: "80vh"}}
                         onIdxUpdated={setIdx}
                         count={fileRefs != null ? fileRefs.length: filePaths.length}
                         interval={interval}
                         hide={hide} pause={pause}
                         loadFunction={loadFunction}
                         reportId={reportId}/>
            </>
        }
    </>
}

/**
 * Generic player component
 * @param orgId - organization Id
 * @param datasetId - used by dataset player
 * @param reportId
 * @param showReportData
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
export default function Player({orgId, datasetId, reportId, showReportData}) {
    const [hideSamples, setHideSamples] = useState(true);
    const [pause, setPause] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [interval, setInterval] = useState(500);
    const {downloadReportData} = useReports();

    return <><Stack alignItems={"flex-start"}>
        <Stack direction="row" alignItems={"center"} spacing={1}>
            <span style={{whiteSpace: "nowrap"}}>View samples from the last test run</span>
            {hideSamples &&
                <Tooltip title={"View samples"} aria-label="View samples">
                    <IconButton aria-label="view" size="small" onClick={() => {
                        if (hideSamples) {
                            setHideSamples(false);
                        }
                    }}>
                        <ViewIcon/>
                    </IconButton>
                </Tooltip>
            }
            {!hideSamples &&
                <>
                    <Tooltip title={"Hide player"} aria-label="Hide player">
                        <IconButton aria-label="hide" size="small" onClick={() => {
                            setHideSamples(true);
                        }}>
                            <HideIcon/>
                        </IconButton>
                    </Tooltip>
                    {!pause &&
                        <Tooltip title={"Pause player"} aria-label="Pause player">
                            <IconButton aria-label="pause" size="small" onClick={() => setPause(true)}>
                                <PauseIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                    {pause &&
                        <Tooltip title={"Resume player"} aria-label="Resume player">
                            <IconButton aria-label="Resume" size="small" onClick={() => setPause(false)}>
                                <PlayIcon/>
                            </IconButton>
                        </Tooltip>
                    }

                    <Slider aria-label="Speed" min={10} max={1000}
                            style={{minWidth:"10em"}}
                            valueLabelFormat={interval+"ms interval"}
                            valueLabelDisplay="auto"
                            value={interval} onChange={(val, newVal) => setInterval(newVal)}
                            />
                    {showReportData &&
                        <Tooltip title={"Download samples"} aria-label="Download samples">
                            <IconButton aria-label="download" size="small" onClick={() => downloadReportData(reportId)}>
                                <DownloadIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title={"Zoom"} aria-label="Zoom">
                        <IconButton aria-label="zoom" size="small" onClick={() => setZoom(!zoom)}>
                            {zoom ? <ZoomOutIcon/> : <ZoomInIcon/>}
                        </IconButton>
                    </Tooltip>
                </>
            }
        </Stack>
        {showReportData ?
            <ReportPlayer interval={interval} pause={pause} zoom={zoom} hide={hideSamples} reportId={reportId} datasetId={datasetId}/>:
            <DatasetPlayer interval={interval} pause={pause} hide={hideSamples} datasetId={datasetId} orgId={orgId}/>
        }
    </Stack></>
}

export {DatasetPlayer, getDsIdxFromName};

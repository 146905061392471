/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useState, useEffect} from "react";
import JSZip from "jszip/dist/jszip.min"
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import HelpIcon from "@mui/icons-material/HelpOutline";
import Dropzone from 'react-dropzone'
import IconButton from "@mui/material/IconButton";
import DownloadWSIcon from '@mui/icons-material/AssignmentReturned';
import EditIcon from '@mui/icons-material/Edit';
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import DownloadIcon from '@mui/icons-material/CloudDownload';
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import URLIcon from "@mui/icons-material/Public";
import {ModelResponseFrameworkTypeEnum, ProjectApi} from "../api";
import {
    NumField,
    checkError,
    errOptions,
    getLogger,
    handleBlur,
    humanReadableSize,
    usePositiveNumberInput,
    validUrl, infoOptions
} from "../util/util";
import AlertDialog from "../dialogs/AlertDialog";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import CodeEditor from "../CodeEditor";
import {usePlatform} from "../platform/Platform";
import {useProjects} from "../projects/Projects";
import DeleteIcon from "../util/TrashIcon"
import Paper from "../util/Paper"

const log = getLogger("modelconfig");

const dropzone = {
        minWidth: '15em',
        minHeight: '8em',
        backgroundColor: 'background.default',
        border: 'dashed',
        borderColor: 'divider',
        borderRadius: 1,
        boxSizing: 'border-box',
        cursor: 'pointer',
        padding: '5px'
}
    // dropzoneActive: {
    //     borderColor: theme.palette.primary.main,
    // }

/**
 * UI to configure the model
 * @author Kobus Grobler
 * @component
 */
export default function ModelConfig({conf, project, model, projectUpdated, frameworkTypeUpdated,
                                        environmentUpdated, numClasses, onModelUpdated}) {
    const WRAPPER_FILE_NAME = 'model_under_test.py';
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {getEnvironments} = usePlatform();
    const [environments, setEnvironments] = useState([]);
    const [environment, setEnvironment] = useState("default");
    const [zipFile, setZipFile] = useState(null);
    const [zipCnt, setZipCnt] = useState(0);
    const [wrapperContents, setWrapperContents] = useState("");
    const [progress, setProgress] = useState(0);
    const [busy, setBusy] = useState(false);
    const [progressVariant, setProgressVariant] = useState("determinate");
    const [frameworkType, setFrameworkType] = useState(model.frameworkType==null ?
        ModelResponseFrameworkTypeEnum.PyTorch : model.frameworkType);
    const [numClassesProps, setNumClasses] = usePositiveNumberInput(numClasses);

    const [openConfirmDeleteData, setOpenConfirmDeleteData] = useState(false);
    const [openEditor, setOpenEditor] = useState(false);
    const {downloadProjectData, startProjectBrowserDownload} = useProjects();
    const [endpoint, setEndpoint] = useState(model.endpoint == null ? "https://" : model.endpoint);

    const projectApi = new ProjectApi(conf);

    useEffect(() => {
        (async () => {
            setEnvironments(await getEnvironments());
        })();
    }, [getEnvironments]);


    useEffect(() => {
        setEnvironment(model.environment == null ? "default" : model.environment);
    }, [model]);

    const findEnvironmentByName = (name) => {
        if (environments != null) {
            return environments.find(e => e.name === name);
        }
    }

    const getEnvDescription = (name) => {
        let env = findEnvironmentByName(name);
        if (env != null) {
            return env.description;
        }
        return "PyTorch 1.9, Tensorflow 2.8"
    }

    const askDeleteData = () => {
        setOpenConfirmDeleteData(true);
    }

    const deleteData = async (result) => {
        setOpenConfirmDeleteData(false);
        if (result) {
            try {
                await projectApi.deleteData(project.id, 'WrapperScript');
                let p = await projectApi.deleteData(project.id, 'Model');
                setWrapperContents("");
                setZipFile(null);
                projectUpdated(p.data);
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to delete data',
                        errOptions));
            }
        }
    }

    function onUploadProgress(evt) {
        if (evt.lengthComputable) {
            setProgressVariant("determinate");
            if (evt.total > 0) {
                setProgress((evt.loaded / evt.total) * 100);
            }
        } else {
            setProgressVariant("");
        }
    }

    const onUpload = async (dataType, file) => {
        setBusy(true);
        try {
            log.debug("Uploading file.");
            let t = await projectApi.uploadProjectData(project.id, dataType, file, {onUploadProgress: onUploadProgress});
            enqueueSnackbar('Upload completed.');
            setZipFile(null);
            setZipCnt(0);
            setProgress(0);
            setFrameworkType(t.data.model.frameworkType);
            projectUpdated(t.data);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to upload data'+msg,
                    errOptions))
        } finally {
            setProgress(0);
            setBusy(false);
        }
    }

    const prog = {lastUpdate: 0}

    function zipUpdateCb(metadata) {
        let now = Date.now();
        if (now - prog.lastUpdate > 1000) {
            prog.lastUpdate = now;
            setProgress(metadata.percent);
        }
    }

    const uploadOneModelFile = (file) => {
        onUpload('Model', file).finally();
    }

    const onDrop = (acceptedFiles) => {
        if (busy)
            return;
        if (acceptedFiles.length === 1) {
            let file = null;
            if (frameworkType === ModelResponseFrameworkTypeEnum.Onnx) {
                if (acceptedFiles[0].name.endsWith(".onnx")) {
                    file = acceptedFiles[0];
                } else {
                    enqueueSnackbar('Uploaded file should have .onnx extension.', errOptions);
                    return;
                }
            } else if (frameworkType === ModelResponseFrameworkTypeEnum.TensorRt) {
                if (acceptedFiles[0].name.endsWith(".onnx") || acceptedFiles[0].name.endsWith(".trt")) {
                    file = acceptedFiles[0];
                } else {
                    enqueueSnackbar('Uploaded file should have .onnx or .trt extension.', errOptions);
                    return;
                }
            } else if (frameworkType === ModelResponseFrameworkTypeEnum.Keras) {
                if (acceptedFiles[0].name.endsWith(".h5")) {
                    file = acceptedFiles[0];
                } else {
                    enqueueSnackbar('Uploaded file should have .h5 extension.', errOptions);
                    return;
                }
            } else {
                if (acceptedFiles[0].name.endsWith(".zip")) {
                    file = acceptedFiles[0];
                }
            }
            if (file != null) {
                uploadOneModelFile(file);
                return;
            }
        }

        if (frameworkType !== ModelResponseFrameworkTypeEnum.PyTorch && frameworkType !== ModelResponseFrameworkTypeEnum.External) {
            enqueueSnackbar('Only one file is required.', errOptions);
            return;
        }

        setProgressVariant("determinate");
        setZipFile(null);
        let zip = new JSZip();
        let cnt = 0;
        setBusy(true);
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => {
                setBusy(false);
                enqueueSnackbar('File reading aborted.', errOptions);
            }
            reader.onerror = () => {
                setBusy(false);
                enqueueSnackbar('File reading failed.', errOptions);
            }
            reader.onload = () => {
                let name = file.path;
                if (name === undefined || name === null) {
                    name = file.name;
                }
                let idxFrom = name.indexOf('/', 1);
                if (idxFrom === -1) {
                    idxFrom = name.indexOf('/');
                }
                name = name.substr(idxFrom + 1);
                if (name === WRAPPER_FILE_NAME) {
                    log.debug("Wrapper found in upload.");
                } else {
                    // don't add wrapper to model package - send separately.
                    zip.file(name, reader.result);
                }
                cnt++;
                setZipCnt(cnt);
                if (cnt === acceptedFiles.length) {
                    enqueueSnackbar('Compressing files...', infoOptions);
                    zip.generateAsync({type: "blob", compression: "DEFLATE", streamFiles: true}, zipUpdateCb)
                        .then(function (blob) {
                            setBusy(true);
                            setZipFile(blob);
                            onUpload('Model', blob);
                        }).finally(() => {
                        setBusy(false);
                        setProgress(0);
                    });
                }
            }
            reader.readAsArrayBuffer(file);
        });
    }

    const downloadData = (type) => {
        startProjectBrowserDownload(project.id, type);
        enqueueSnackbar("Download started.");
    }

    const editWrapper = async () => {
        let r = await downloadProjectData('WrapperScript', project.id);
        if (r != null) {
            setOpenEditor(true);
            setWrapperContents(r.data);
        }
    }

    const saveCodeChanges = async (contents) => {
        let blob = new Blob([wrapperContents], {
            type: 'text/x-python'
        });
        await onUpload('WrapperScript', blob);
        setWrapperContents(contents);
        setOpenEditor(false);
    }

    async function sendVerification() {
        try {
            let r = await projectApi.verifyProject(project.id);
            projectUpdated(r.data);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar(`Failed to send verification request (${msg}).`, errOptions));
        }
    }

    return (
        <Grid container justifyContent="center" mt={2}>
            <AlertDialog open={openConfirmDeleteData} title="Delete model data?"
                         description="This will permanently delete the model data."
                         onClose={deleteData}/>
            <OkCancelDialog onClose={() => setOpenEditor(false)}
                            dialogProps={{fullWidth: true, maxWidth: 'md'}}
                            open={openEditor}
                            title="Wrapper script editor"
                            hideOk={true}
                            actions={<Tooltip title="Save changes">
                                <IconButton
                                    onClick={() => {
                                        saveCodeChanges(wrapperContents);
                                    }}
                                    size="large">
                                    <SaveIcon/>
                                </IconButton>
                            </Tooltip>
                            }>
                <CodeEditor id={project.id}
                            helpLink={process.env.REACT_APP_DOCS_BASE+"modules/pipeline/configuration/wrapper_script.html"}
                            content={wrapperContents}
                            onChange={setWrapperContents}
                            onSaveChanges={saveCodeChanges}
                />
            </OkCancelDialog>
            <Grid item md={6} lg={5} xl={4}>
                <Paper variant="outlined">
                    <Grid container alignItems="center" justifyContent={"flex-start"} spacing={1} p={1}>
                        <Grid item container alignItems="center" justifyContent={"center"} spacing={1}>
                            <Grid item>
                                <Typography variant='h6'>
                                    Model Configuration
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link target="_blank"
                                      href={process.env.REACT_APP_DOCS_BASE+"modules/models/index.html"}>
                                    <HelpIcon/>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item>
                            <FormControl variant="outlined" sx={{margin: 1, minWidth: 200}}>
                                <InputLabel>Model Framework</InputLabel>
                                <Select
                                    value={frameworkType}
                                    onChange={(e)=> {
                                        setFrameworkType(e.target.value);
                                        frameworkTypeUpdated(e.target.value);
                                    }}
                                    label="Model Framework"
                                >
                                    {Object.values(ModelResponseFrameworkTypeEnum).map((v, idx) =>
                                        <MenuItem key={idx} value={v}>{v}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {frameworkType === ModelResponseFrameworkTypeEnum.External &&
                            <>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs>
                            <TextField value={endpoint} style={{margin:5}}
                                       fullWidth={true}
                                       label="External endpoint"
                                       required={true}
                                       error={!validUrl(endpoint)}
                                       onChange={(event) => {
                                           setEndpoint(event.target.value);
                                       }}
                                       onBlur={(event) => {
                                           let ev = event.target.value;
                                           if (validUrl(ev)) {
                                               setEndpoint(ev);
                                               onModelUpdated({endpoint: ev});
                                           }
                                       }}

                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <URLIcon />
                                               </InputAdornment>
                                           ),
                                       }}
                            />
                        </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item>
                            <NumField sx={{margin: 1}} {...numClassesProps}
                                      onBlur={e => handleBlur(e, numClasses, setNumClasses,(v)=> onModelUpdated({numClasses: v}))}
                                      label="Number of classes"
                                      type="number"
                                      variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>

                        {environments != null && environments.length > 0 &&
                            <>
                            <Grid item>
                                <FormControl variant="outlined" sx={{margin: 1, minWidth: 200}}>
                                    <InputLabel>Environment</InputLabel>
                                    <Select
                                        value={environment}
                                        onChange={(e) => {
                                            setEnvironment(e.target.value);
                                            environmentUpdated(e.target.value);
                                        }}
                                        label="Environment"
                                    >
                                        <MenuItem value="default">Default</MenuItem>
                                        {environments.map((e, idx) =>
                                            <MenuItem key={idx} value={e.name}>{e.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="textSecondary" style={{maxWidth: '25em'}}>
                                {getEnvDescription(environment)}
                                </Typography>
                            </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Dropzone onDrop={onDrop} disabled={busy}>
                                {({getRootProps, getInputProps, isDragActive}) => (
                                    <section>
                                        <Box {...getRootProps()}
                                             sx={[dropzone,
                                                 isDragActive && !busy && {
                                                 borderColor: 'primary.main'}
                                             ]}>
                                            <input {...getInputProps()} />
                                            {(busy || zipFile != null) &&
                                                <LinearProgress variant={progressVariant}
                                                                value={progress}/>
                                            }
                                            {zipFile == null && zipCnt > 0 &&
                                            <p>Compressed {zipCnt} files.</p>
                                            }
                                            {zipFile != null &&
                                            <>
                                                <p>Added {zipCnt} files ({humanReadableSize(zipFile.size, true)}) -
                                                    uploading...</p>
                                            </>}
                                            {zipFile == null &&
                                            <p>{frameworkType === ModelResponseFrameworkTypeEnum.PyTorch &&
                                                <span>
                                                    Drag & Drop the directory containing a PyTorch model
                                                    and its dependencies or a zip file containing the required files.
                                                </span>
                                            }
                                            {frameworkType === ModelResponseFrameworkTypeEnum.Onnx &&
                                                <span>Drag & Drop an ONNX model (IR v7 and above supported)</span>
                                            }
                                            {frameworkType === ModelResponseFrameworkTypeEnum.Keras &&
                                                <span>Drag & Drop a {frameworkType} model</span>
                                            }
                                            {frameworkType === ModelResponseFrameworkTypeEnum.TensorRt &&
                                                <span>Drag & Drop a {frameworkType} model</span>
                                            }
                                            {frameworkType === ModelResponseFrameworkTypeEnum.External &&
                                                <span>Drag & Drop {frameworkType} model wrapper dependencies if required.</span>
                                            }
                                            </p>
                                            }
                                        </Box>
                                    </section>
                                )}
                            </Dropzone>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item container justifyContent="center">
                        <Grid item>
                            {project.dataRef &&
                            <Tooltip title="Delete uploaded data">
                                <IconButton
                                    size="small"
                                    onFocus={(event) => event.stopPropagation()}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        askDeleteData()
                                    }}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                            }
                            {project.dataRef &&
                            <Tooltip title="Download model data">
                                <IconButton
                                    size="small"
                                    onClick={() => downloadData('Model')}>
                                    <DownloadIcon/>
                                </IconButton>
                            </Tooltip>
                            }
                            {project.wrapperRef &&
                                <Tooltip title="Download wrapper script">
                                    <IconButton
                                        size="small"
                                        onClick={() => downloadData('WrapperScript')}>
                                        <DownloadWSIcon/>
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title="Edit wrapper script">
                                <IconButton
                                    size="small"
                                    onClick={editWrapper}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                            {(!project.verified || project.settings.headSettings == null) &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={sendVerification}>
                                        Verify
                                    </Button>
                            }
                        </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

ModelConfig.propTypes = {
    /**
     * The project instance
     */
    project: PropTypes.object.isRequired,

    model: PropTypes.object.isRequired,

    assets: PropTypes.object.isRequired,
    /**
     * Called when the project instance is updated
     */
    projectUpdated: PropTypes.func.isRequired,

    frameworkTypeUpdated: PropTypes.func.isRequired,

    environmentUpdated:  PropTypes.func.isRequired,

    onModelUpdated: PropTypes.func.isRequired,
    /**
     * The API configuration
     */
    conf: PropTypes.object.isRequired
};


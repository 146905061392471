import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import { Image, Layer } from "react-konva";

function useImage(url, crossOrigin) {
    const statusRef = useRef('loading');
    const imageRef = useRef();
    const [_, setStateToken] = useState(0);
    const oldUrl = useRef();
    const oldCrossOrigin = useRef();

    if (oldUrl.current !== url || oldCrossOrigin.current !== crossOrigin) {
        statusRef.current = 'loading';
        imageRef.current = undefined;
        oldUrl.current = url;
        oldCrossOrigin.current = crossOrigin;
    }

    useLayoutEffect(
        function () {
            if (!url) return;
            var img = document.createElement('img');

            function onload() {
                statusRef.current = 'loaded';
                imageRef.current = img;
                setStateToken(Math.random());
            }

            function onerror() {
                statusRef.current = 'failed';
                imageRef.current = undefined;
                setStateToken(Math.random());
            }

            img.addEventListener('load', onload);
            img.addEventListener('error', onerror);
            crossOrigin && (img.crossOrigin = crossOrigin);
            img.src = url;

            return function cleanup() {
                img.removeEventListener('load', onload);
                img.removeEventListener('error', onerror);
            };
        },
        [url, crossOrigin]
    );

    return [imageRef.current, statusRef.current];
}

const BaseImage = ({url, setSize, setImageSize, setScale, width, height, brightness}) => {
    const [image] = useImage(url);

    useEffect(() => {
        if (!image) {
            return;
        }
        const scale = Math.min(width / image.width, height / image.height);
        setScale(scale);
        setImageSize({ width: image.width, height: image.height });
    }, [image, width, height, setScale, setImageSize]);

    const layerRef = useRef(null);

    useEffect(() => {
        const canvas = layerRef.current.getCanvas()._canvas;
        canvas.style.filter = `brightness(${(brightness + 1) * 100}%)`;
    }, [brightness]);

    return (<Layer ref={layerRef}>
            <Image image={image}/>
        </Layer>);
};

export default BaseImage

export {useImage}

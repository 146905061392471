/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React from "react";
import HelpIcon from "@mui/icons-material/HelpOutline";
import Link from "@mui/material/Link";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import PropTypes from "prop-types";

/**
 * A code editor component
 * @author Kobus Grobler
 * @component
 */
export default function CodeEditor({id, content, helpLink, onChange}) {

    return (<>
        <span id={id + "editor"}/>
        {helpLink != null &&
            <>
                <Link target="_blank"
                      href={helpLink}>
                    <HelpIcon/>
                </Link>
            </>
        }
    <AceEditor
        value={content}
        width="100%"
        height="400px"
        mode="python"
        theme="github"
        onChange={(val) => onChange(val)}
        name={id+"editor"}
        editorProps={{ $blockScrolling: true }}
    />
    </>)
}

CodeEditor.propTypes = {
    /**
     * The editor content
     */
    content: PropTypes.string.isRequired,

    /**
     * The unique id for this editor
     */
    id: PropTypes.any.isRequired,

    /**
     * Called when the content changes.
     */
    onChange: PropTypes.func.isRequired

};


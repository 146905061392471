/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import React from "react";
import Paper from "../util/Paper";

const AR_DEF = {
    name: "ar",
    params: [{
        name: 'score_threshold',
        description: "Apply score threshold to approximate detection targets (from benign prediction)",
        type: 'float',
        def: 0.0
    }],
};

const EPS_DEF = {
    name: "eps",
    params: [{
        name: 'eps_range_start',
        displayed: true,
        type: 'float',
        min: 0,
        def: 0.1
    }, {
        name: 'eps_range_stop',
        displayed: true,
        type: 'float',
        min: 0.01,
        def: 1.0
    }, {
        name: 'eps_range_steps',
        displayed: true,
        type: 'int',
        min: 2,
        def: 2,
        max: 20
    }],
};

export default function RobustnessSettings({settings, onSettingsChange, onSetEPSParams, onSetARParams}) {
    return <>
        <Paper variant="outlined">
            <Typography variant='h6' style={{textAlign: "center"}}>Robustness Analysis</Typography>
            <Grid item xs={12} container direction="column" alignItems="flex-start">
                <Grid item>
                    <FormControlLabel
                        control={
                            <>
                                <Switch
                                    name="epsExploration"
                                    color="primary"
                                    onChange={(e) => onSettingsChange(e)}
                                    checked={settings.epsExploration}
                                />
                                <IconButton
                                    disabled={!settings.epsExploration}
                                    size="small"
                                    onClick={onSetEPSParams}>
                                    <TuneIcon/>
                                </IconButton>
                            </>
                        }
                        label="Enable eps exploration"
                    /></Grid>
                {/*<Grid item>*/}
                {/*    <FormControlLabel*/}
                {/*        control={*/}
                {/*            <>*/}
                {/*                <Switch*/}
                {/*                    name="approximateRobustness"*/}
                {/*                    color="primary"*/}
                {/*                    onChange={(e) => onSettingsChange(e)}*/}
                {/*                    checked={settings.approximateRobustness}*/}
                {/*                />*/}
                {/*                <IconButton*/}
                {/*                    disabled={!settings.approximateRobustness}*/}
                {/*                    size="small"*/}
                {/*                    onClick={onSetARParams}>*/}
                {/*                    <TuneIcon/>*/}
                {/*                </IconButton>*/}
                {/*            </>*/}
                {/*        }*/}
                {/*        label="Approximate robustness"*/}
                {/*    />*/}
                {/*</Grid>*/}
            </Grid>
        </Paper>
    </>
}

export {EPS_DEF, AR_DEF}

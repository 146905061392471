/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useState, useEffect, useContext} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {
  Switch,
  Route,
  useHistory,
  matchPath, useLocation
} from "react-router-dom";
import TagManager from 'react-gtm-module'
import {useCookies} from 'react-cookie';
import './App.css';
import SignIn from "./SignIn";
import Main from "./Main";
import Copyright from "./Copyright";
import Register from "./Register";
import AppContext from "./AppContext";
import {Configuration, LoginApi} from "./api";
import {getLogger, setupLogging} from "./util/util";

const log = getLogger("app");

function App() {
  const theme = useTheme();
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const history = useHistory();
  const location = useLocation();
  const { basePath } = useContext(AppContext);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    // only init GTM on production instance
    log.debug("App location: "+window.location);
    if (window.location.href.startsWith("https://guardai.navinfo.cloud")) {
      TagManager.initialize({ gtmId: 'GTM-KPQP5WM' });
    }
  }, []);

  useEffect(() => {
    setupLogging();
    const log = getLogger("app");
    document.body.style.backgroundColor = theme.palette.background.paper;
    if (matchPath(location.pathname, {path: '/register'}) == null &&
        matchPath(location.pathname, {path: '/login'}) == null) {
      (async () => {
        const params = {basePath: basePath, credentials: "include"};
        const conf = new Configuration(params);
        const api = new LoginApi(conf);
        try {
            let user = await api.getAuthUser();
            log.debug("Got authenticated user: " + JSON.stringify(user.data));
            if (user.data.uid !== undefined) {
              if (user.data.jwt != null) {
                // we were able to get a new platform token using current OAuth login
                setCookie('auth', JSON.stringify(user.data), {secure: true, sameSite: "strict"});
              }
              setAuthUser(user.data);
              history.replace("/main");
            } else {
              log.error("Invalid login response object received.");
              history.replace("/login");
            }
        } catch (e) {
          if (cookies.auth != null) {
            if (authUser == null) {
              try {
                setAuthUser(cookies.auth);
              } catch (ex) {
                log.error("Failed to parse cookie: " + cookies.auth);
                removeCookie('auth'); // get a new cookie with next login
                history.replace("/login");
              }
            }
          } else {
            // no cookie or OAuth2 authenticated
            if (authUser == null) {
              history.replace("/login");
            }
          }
        }
      })()
    }
  }, []);

  const handleLogout = () => {
    removeCookie('auth',{secure: true, sameSite: "strict"});
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      history.replace("/login"); // this is for dev build
    } else {
      // this has no effect in dev build (not proxied to server), but will have on production build
      window.location.replace("/logout");
    }
  }

  return (
      <>
        <div className="App">
          <Switch>
            <Route path="/main">
              {authUser !== null &&
              <Main login={authUser} logout={handleLogout}/>
              }
            </Route>
            <Route path="/register/:token">
              <Register/>
            </Route>
            <Route path="/">
              <SignIn/>
            </Route>
          </Switch>
        </div>
        <footer className="copyright">
          <Box mt={2}>
            <Copyright/>
          </Box>
        </footer>
      </>
  );
}

export default App;

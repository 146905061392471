/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import React, {useCallback, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {checkError, errOptions, getLogger} from "../util/util";
import ReportTable, {AttackSummary, buildReportTableModel, getReportsGroupedBy} from "../aitests/ReportTable";
import ErrorBoundary from "../util/ErrorBoundary"
import {useLoadReports} from "../aitests/Reports";
import {BenchmarkGraph} from "./BenchmarkGraph";

const log = getLogger("benchmarkreports");

function BenchmarkReportTable({conf, projects, reports, test, sort, expanded, onReportDeleted}) {
    const [open, setOpen] = useState(expanded);
    const [model, setModel] = useState(null);

    useEffect(() => {
        if (open) {
            log.debug("building report table");
            setModel(buildReportTableModel(reports, 'projectId', projects, sort));
        }
    }, [reports, sort, open])

    const onReportNameChanged = (id, newName) => {
        let reports = reports.map(r => {
            if (r.runId === id) {
                let cp = {...r};
                cp.runName = newName;
                return cp;
            } else {
                return r;
            }
        });
        setModel(buildReportTableModel(reports, 'projectId', projects, sort));
    }

    const onViewTypeChange = (viewType) => {
        setModel(buildReportTableModel(reports, 'projectId', projects, sort, viewType));
    }

    return <>
        <IconButton aria-label="expand report row"
                    size="small"
                    onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
        </IconButton>
        <Typography variant="body1" component="span">
            {test.name},&nbsp;
        </Typography>
        <Typography variant="body2" component="span">
            Run ID: {reports[0].runId}
        </Typography>
        {open &&
            <BenchmarkGraph reports={reports} projects={projects} sort={sort}/>
        }
        {model != null &&
            <>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Typography variant="body2" color="textSecondary">
                    <AttackSummary report={model.rows[0]}/>
                </Typography>
                <ReportTable conf={conf}
                             model={model}
                             reports={reports}
                             projects={projects}
                             test={test}
                             sort={sort}
                             onReportDeleted={onReportDeleted}
                             onViewTypeChange={onViewTypeChange}
                             hideTestAndRunNames
                             groupBy='projectId'/>
                </Collapse>
            </>
        }
    </>
}

/**
 * Lists the benchmark reports
 * @author Kobus Grobler
 * @component
 */
function BenchmarkReports({conf, projects, busy, test}) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const onLoaded = useCallback((newReports) => {
        try {
            log.debug("benchmark reports loaded.");
            let r = getReportsGroupedBy(newReports, 'runId');
            setReports(r);
            loadReportsByTestId(null); // to force refresh when busy flag changes and test is set
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to parse reports',
                    errOptions));
        }
    },[history, enqueueSnackbar]);

    const [{reports, setReports}, loadReportsByTestId] = useLoadReports(onLoaded);

    useEffect(() => {
        if (test.expanded && !busy) {
            loadReportsByTestId(test.id);
        }
    }, [conf, test, test.id, test.expanded, busy]);

    const [sortKey, setSortKey] = useState("projectId");
    const [sort, setSort] = useState(null);

    const handleSortChange = (e) => {
        setSortKey(e.target.value);
        setSort({key: e.target.value});
    }

    const onReportDeleted = (id) => {
        log.debug(`Report id ${id} deleted.`);
        setReports(reports.map((m) => m.filter(r => r.id !== id)));
    }

    return <>
        <ErrorBoundary>
            <Typography variant="h5">Benchmark Reports</Typography>
            <Grid container>
                <Grid item xs={12}>
                    {/*Sort box*/}
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Sort By</FormLabel>
                        <RadioGroup aria-label="sort" name="sort" value={sortKey} onChange={handleSortChange}>
                            <div>
                                <FormControlLabel value="projectId" control={<Radio color="primary"/>}
                                                  label="Project ID"/>
                                <FormControlLabel value="datasetname" control={<Radio color="primary"/>}
                                                  label="Dataset"/>
                                <FormControlLabel value="modelName" control={<Radio color="primary"/>}
                                                  label="Model"/>
                            </div>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {reports !== null && reports.map((runIdReports, idx) => {
                    return (<React.Fragment key={idx}>
                        {runIdReports.length > 0 && //len may be 0 after report deleted
                            <Grid item xs={12}>
                                <BenchmarkReportTable conf={conf}
                                                      test={test}
                                                      expanded={idx === 0}
                                                      sort={sort}
                                                      onReportDeleted={onReportDeleted}
                                                      reports={runIdReports}
                                                      projects={projects}/>
                            </Grid>
                        }
                    </React.Fragment>)}
                )}</Grid>
        </ErrorBoundary>
    </>
}

export default BenchmarkReports;

/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext, useCallback, useMemo} from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItemText from "@mui/material/ListItemText";
import {useSnackbar} from "notistack";
import Tooltip from "@mui/material/Tooltip";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import {checkError, errOptions, getLogger} from "../util/util";
import AppContext from "../AppContext";
import AlertDialog from "../dialogs/AlertDialog";
import {TestProfilesApi} from "../api";
import ListItemLink from "../util/ListItemLink";
import ProjectTypeIndicator from "../projects/ProjectTypeIndicator"
import DeleteIcon from "../util/TrashIcon"
import {namedItemFilter, useFilter} from "../util/SearchBox";
import SearchAndSort from "../util/SearchAndSort";
import {sortItemsByProps, useSortBox} from "../util/SortBox";

const log = getLogger("profiles");

const useLoadProfiles = () => {
    const {conf} = useContext(AppContext);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const profilesApi = new TestProfilesApi(conf);
                log.debug("Loading profiles...");
                let rs = await profilesApi.getTestProfiles();
                log.debug(rs.data.length +" profiles loaded.");
                setProfiles(rs.data);
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to load profiles',
                        errOptions));
            }
        })()
    }, [conf, enqueueSnackbar, history]);

    return [profiles, setProfiles];
}

const useProfiles = () => {
    const {conf} = useContext(AppContext);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const profilesApi = useMemo( () => new TestProfilesApi(conf),
        [conf]);

    const addProfile = useCallback(async (orgId, testId, name) => {
        try {
            log.debug("Adding profile...");
            let rs = await profilesApi.addProfile(orgId, testId, name);
            log.debug("Profile added: "+ rs.data.id);
            return rs.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to add profile'+msg, errOptions));
        }
    },[profilesApi, enqueueSnackbar, history]);

    const updateProfile = useCallback(async (profileId, p) => {
        try {
            log.debug("Updating profile by id " + profileId);
            const r = await profilesApi.updateTestProfile(profileId, {name: p.name, description: p.description,
                settings: p.settings});
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to update profile'+msg, errOptions));
            throw e;
        }
    },[profilesApi, enqueueSnackbar, history]);

    const addFilter = useCallback(async (profileId, filter) => {
        try {
            log.debug("Adding filter to profile id " + profileId);
            const r = await profilesApi.addTestProfileFilter(profileId, filter);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to add filter'+msg, errOptions));
        }
    },[profilesApi, enqueueSnackbar, history]);

    const addDefense = useCallback(async (profileId, defense) => {
        try {
            log.debug("Adding defense to profile id " + profileId);
            const r = await profilesApi.addTestProfileDefense(profileId, defense);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to add defense'+msg, errOptions));
        }
    },[profilesApi, enqueueSnackbar, history]);

    const removeDefense = useCallback(async (profileId, defenseId) => {
        try {
            log.debug("Removing defense from profile id " + profileId);
            const r = await profilesApi.removeTestProfileDefense(profileId, defenseId);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to remove defense'+msg, errOptions));
        }
    },[profilesApi, enqueueSnackbar, history]);

    const deleteProfile = useCallback(async (profileId) => {
        try {
            await profilesApi.deleteProfile(profileId);
            enqueueSnackbar('Profile deleted.');
            history.replace(history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/")));
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to delete profile'+msg, errOptions));
        }
    },[profilesApi, history, enqueueSnackbar]);

    const getProfile = useCallback(async (profileId) => {
        try {
            log.debug("Getting profile by id " + profileId);
            const r = await profilesApi.getTestProfile(profileId);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get profile'+msg, errOptions));
        }
    },[profilesApi, history, enqueueSnackbar]);

    const copyProfile = useCallback(async (profileId, orgId) => {
        try {
            log.debug("Copying profile.");
            await profilesApi.copyProfile(profileId, orgId);
            enqueueSnackbar('Profile copied.');
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to copy profile'+msg, errOptions));
        }
    },[profilesApi, history, enqueueSnackbar]);

    return {getProfile, copyProfile, addProfile, updateProfile, deleteProfile, addFilter, addDefense, removeDefense};
}

const SORT_ITEMS = [
    {key: "name", label: "Name"},
];

/**
 * Retrieves and shows the list of test profiles
 * @returns {JSX.Element}
 * @constructor
 */
export default function TestProfiles () {
    const history = useHistory();
    const match = useRouteMatch();
    const {conf} = useContext(AppContext);

    const {enqueueSnackbar} = useSnackbar();
    const [profiles, setProfiles] = useLoadProfiles();
    const [filterProps] = useFilter();
    const [sortProps] = useSortBox(SORT_ITEMS[0].key);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [profile, setProfile] = useState(null);

    const askDeleteProfile = (p) => {
        setProfile(p);
        setOpenConfirmDelete(true);
    }

    const deleteProfile = async (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            try {
                log.debug("Deleting " + profile.name);
                const profilesApi = new TestProfilesApi(conf);
                await profilesApi.deleteProfile(profile.id);
                setProfiles(profiles.filter( p => p.id !== profile.id));
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to delete profile',
                        errOptions));
            }
        }
    }

    const inFilter = (item) => {
        return namedItemFilter(filterProps.filter, item);
    }

    const sortItems = (a, b) => {
        return sortItemsByProps(a, b, sortProps);
    }

    return (
        <Grid container justifyContent={"center"}>
            <AlertDialog open={openConfirmDelete} title="Delete profile?"
                         description="This will permanently delete the profile."
                         onClose={deleteProfile}/>
            <Grid item xs={12} md={10} lg={8} xl={6}>
                        {profiles.length === 0 &&
                            <Typography variant="subtitle1">
                                There are no test profiles defined
                            </Typography>
                        }
                        {profiles.length > 0 &&
                            <>
                                <Typography variant="h6" style={{textAlign: "center"}}>
                                    Test profiles
                                </Typography>
                                <SearchAndSort sortItems={SORT_ITEMS} sortProps={sortProps} filterProps={filterProps}/>
                                    <List>
                                    {profiles.filter(inFilter).sort(sortItems).map(item =>
                                        <Accordion key={item.id}>
                                        <ListItemLink href={`#${match.url}/${item.id}`}>
                                            <ProjectTypeIndicator tasks={item.settings.tasks} style={{marginRight: "0.5em"}}/>
                                            <ListItemText style={{marginLeft: "0.5em"}} primary={`${item.organization.name} - ${item.name}`}>
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title="Delete profile">
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => askDeleteProfile(item)}
                                                        aria-label="delete"
                                                        size="large">
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItemLink>
                                        </Accordion>
                                    )}
                                    </List>
                            </>
                        }
            </Grid>
        </Grid>
    );
}

TestProfiles.propTypes = {

};

export {useLoadProfiles, useProfiles}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState} from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from "prop-types";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from '@mui/icons-material/Info';
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {styled} from "@mui/material/styles";
import {getLogger, limitStringLen, paramSummary, printRound, javaDateToTimeStr} from '../util/util';
import ErrorBoundary from "../util/ErrorBoundary";
import Report from "./Report";
import AlertDialog from "../dialogs/AlertDialog";
import EPSPlot from "./EPSPlot";
import OptimizationPlot from "./OptimizationPlot";
import {Tooltip} from "@mui/material";
import NameEditor from "../util/NameEditor";
import {useTestRun} from "./Test";
import {DeleteReportButton, useReports} from "./Reports";

const COLLAPSED_VIEW_TYPE = 'collapsed';
const EXPANDED_VIEW_TYPE = 'expanded';
const COMP_METRIC_NAME = 'Harmonic robustness';

const log = getLogger("report.table");

class MetricModel {
    name;
    #types = {};
    #model;

    constructor(model, name) {
        this.#model = model;
        this.name = name;
    }

    getAvg(type) {
        return this.#types[type].avg;
    }

    getMin(type) {
        return this.#types[type].min;
    }

    getMax(type) {
        return this.#types[type].max;
    }

    getValues(type) {
        if (!this.hasType(type))
            return null;
        return this.#types[type].values;
    }

    getTypes() {
        if (this.#model.getViewType() === EXPANDED_VIEW_TYPE) {
            return Object.keys(this.#types).filter(t => this.name === COMP_METRIC_NAME || this.name === 'SSIM' || t.indexOf('Robustness') > -1);
        } else {
            return Object.keys(this.#types);
        }
    }

    hasType(type) {
        return this.#types[type] != null;
    }

    addVal(type, val) {
        if (isNaN(val)) {
            val = 0;
        }
        if (!this.hasType(type)) {
            this.#types[type] = {values:[], min: Number.MAX_VALUE, max: 0, avg: 0};
        }
        let mtype = this.#types[type]
        let values = mtype.values;
        values.push(val);
        if (val > mtype.max) {
            mtype.max = val;
        }
        if (val < mtype.min) {
            mtype.min = val;
        }
        mtype.avg = values.reduce((a, b) => (a + b)) / values.length;
    }
}

class DefenseModel {
    name;
    params;
    constructor(name, params) {
        this.name = name;
        this.params = params;
    }
}

class AttackModel {
    #metrics = {};
    name;
    runId;
    date;
    #params = {};
    #model;
    #valid = true;
    #defense;

    constructor(model, name, defense = null) {
        this.name = name;
        this.#model = model;
        this.#defense = defense;
    }

    getDefense() {
        return this.#defense;
    }

    getMetric(mName) {
        return this.#metrics[mName];
    }

    getValid() {
        return this.#valid;
    }

    metricNames() {
        if (this.#model.getViewType() === COLLAPSED_VIEW_TYPE) {
            let names = Object.keys(this.#metrics).filter(mn => mn === COMP_METRIC_NAME);
            if (names.length === 0) {
                return Object.keys(this.#metrics);
            } else {
                return names;
            }
        } else {
            return Object.keys(this.#metrics);
        }
    }

    getParam(key) {
        return this.#params[key];
    }

    addParam(key, val) {
        if (this.#params[key] == null) {
            this.#params[key] = [];
        }
        this.#params[key].push(val);
    }

    addMetricVal(mType, mName, val) {
        if (isNaN(val)) {
            this.#valid = false;
        }
        if (mType === 'target-benign' || mType === 'benign') {
            mType = 'Benign'; // map for old reports
        }

        if (mType === 'target-attacked' || mType === 'attacked') {
            mType = 'Attacked'; // map for old reports
        }

        if (mType === 'robustness') {
            mType = 'Robustness score'; // map for old reports
        }

        if (mName.indexOf('-') > 0) {
            mName = mName.substring(0, mName.indexOf('-'));
        }

        if (this.#metrics[mName] == null) {
            this.#metrics[mName] = new MetricModel(this.#model, mName);
        }

        this.#metrics[mName].addVal(mType, val);
    }
}

class ReportTableModel {
    rows = []; // of ReportTableRows
    maxNamesIdx = 0;
    #viewType = COLLAPSED_VIEW_TYPE;

    isDetectionModel() {
        return this.rows.length > 0 && this.rows[0].detectionSummaries.length > 0;
    }

    setViewType(viewType) {
        this.#viewType = viewType;
    }

    getViewType() {
        return this.#viewType;
    }

    /**
     * Returns a merge of the metric column names of all the rows in the table, i.e. all the columns found for a metric
     * in all the rows will be returned.
     * @param metricName
     * @returns {string[]}
     */
    getHeaderMetricColumnNames(metricName) {
        if (metricName == null) {
            log.error("Metric name is null");
        }
        let names = {};
        for (let row of this.rows) {
            row.getOverallColumnNames(metricName).forEach( name => {
                names[name] = true;
            });
        }
        return Object.keys(names);
    }

    /**
     * Returns the metric names of the row in the table with the most metrics
     * @returns {string[]}
     */
    getHeaderMetricNames() {
        return this.rows[this.maxNamesIdx].overAll.metricNames();
    }
}

class ReportTableRow {
    attacks = {};
    overAll;
    optData;
    optMetricData;
    optMethod;
    detectionSummaries = [];
    reports;
    runId;
    datasetName;
    datasetRangeStart;
    datasetRangeStop;
    datasetBatchSize;
    #model;

    constructor(model) {
        this.#model = model;
    }

    attackKeys() {
        return Object.keys(this.attacks);
    }

    attackNames() {
        return Object.values(this.attacks).map(a => a.name);
    }

    getOverallColumnNames(metricName) {
        if (metricName == null) {
            log.error("Metric name is null!");
        }

        let attack;
        if (this.attackKeys().length > 1) {
            attack = this.attacks[this.attackKeys()[1]];
        } else {
            // NoAttack
            attack = this.attacks[this.attackKeys()[0]];
        }
        if (attack == null) {
            return [];
        }
        let metric = attack.getMetric(metricName);

        if (metric != null) {
            return metric.getTypes();
        } else {
            return [];
        }
    }

    rowHasValidMetricData(metricName) {
        // each column expected for the metric should be valid
        if (this.getOverallColumnNames(metricName).length === 0) {
            log.debug("Row "+this.runId +" with metric " + metricName +" has no data.");
        } else {
            return true;
        }
    }

    parseOptV1(attack) {
        this.optMethod = attack['opt'];
        let params = attack['pms'];
        params.forEach(param => {
            this.optData[param] = [];
        });
        this.optMetricData['target-attacked'] = [];
        let attackedIdx = 1;
        attack['prf'].forEach((prf, idx) => {
            if (prf === 'target-attacked') {
                attackedIdx = idx;
            }
        });
        attack['report'].forEach(sample => {
            params.forEach((key, idx) => {
                this.optData[key].push(sample['pms'][idx]);
            });
            let att = sample['prf'][attackedIdx];
            if (att instanceof Array) {
                this.optMetricData['target-attacked'].push(att[0]);
            } else {
                this.optMetricData['target-attacked'].push(att);
            }
        });
    }

    parseOptV2(attack) {
        this.optMethod = attack['optimizer'];
        let params = attack['params'];
        params.forEach(param => {
            this.optData[param] = [];
        });
        this.optMetricData['target-attacked'] = [];
        let attackedIdx = 1;
        attack['metrics'].forEach((prf, idx) => {
            if (prf === 'target-attacked') {
                attackedIdx = idx;
            }
        });
        attack['report'].forEach(sample => {
            params.forEach((key, idx) => {
                this.optData[key].push(sample['params'][idx]);
            });
            let att = sample['metrics'][attackedIdx];
            if (att instanceof Array) {
                this.optMetricData['target-attacked'].push(att[0]);
            } else {
                this.optMetricData['target-attacked'].push(att);
            }
        });
    }

    hasPoison(detectionSummary, allowedDeviations) {
        let classes = Object.keys(detectionSummary.score_variance);
        let p = {
            poisoned: false,
            class: null
        };
        classes.forEach(k => {
            let scoreStdDev = Math.sqrt(detectionSummary.score_variance[k]);
            if (scoreStdDev > detectionSummary.avgStdDev*allowedDeviations) {
                if (p.poisoned) {
                    if (detectionSummary.score_variance[k] > detectionSummary.score_variance[p.class]) {
                        p.class = k;
                    }
                } else {
                    p.poisoned = true;
                    p.class = k;
                }
            }
        });
        return p;
    }

    addDetectionSummary(detectionSummary) {
        if (detectionSummary.score_mean == null) {
            log.error("Detection summary does not contain expected key score_mean");
            return;
        }
        let classes = Object.keys(detectionSummary.score_mean);
        if (classes.length > 0) {
            let total = 0;
            classes.forEach(k => total += detectionSummary.score_mean[k]);
            detectionSummary.avgScore = total / classes.length;
            total = 0;
            classes.forEach(k => total += detectionSummary.score_variance[k]);
            detectionSummary.avgVariance = total / classes.length;
            detectionSummary.avgStdDev = Math.sqrt(detectionSummary.avgVariance);
            total = 0;
            classes.forEach(k => total += detectionSummary.poison_detections[k]);
            detectionSummary.totalPoisonSamples = total;
            total = 0;
            classes.forEach(k => total += detectionSummary.poison_percentage[k]);
            detectionSummary.totalPercentageSamples = total/classes.length;
        }
        this.detectionSummaries.push(detectionSummary);
    }

    addOptimization(opt_report) {
        opt_report.forEach(attack => {
            this.optData = {};
            this.optMetricData = {};
            if (attack['opt'] != null) {
                this.parseOptV1(attack);
            } else if (attack['optimizer'] != null) {
                this.parseOptV2(attack);
            } else {
                log.error("Unknown opt report structure");
            }
        });
    }

    getAttack(report, attackName, runnerReport) {
        let key = attackName;
        let defense = getDefenseFromRunnerReport(runnerReport);
        if (defense != null) {
            key = defense.name+'_'+key;
        }
        if (this.attacks[key] == null) {
            let attackModel = new AttackModel(this.#model, attackName, defense);
            attackModel.runId = report.runId;
            attackModel.date = report.date;
            attackModel.reportParams = runnerReport.params;
            this.attacks[key] = attackModel;
        }
        return this.attacks[key];
    }

    parseV2Report(report, runnerReport) {
        if (runnerReport.performance_report != null) {
            for (let i = 0; i < runnerReport.filters.length; i++) {
                if (runnerReport.filters[i].filter_type === 'defense') {
                    continue;
                }
                let attack = this.getAttack(report, runnerReport.filters[i].filter_name, runnerReport);
                // only add eps once (it's the same for all mTypes)
                if (runnerReport.params != null && runnerReport.params['eps'] != null) {
                    attack.addParam('eps', runnerReport.params['eps']);
                }
                runnerReport.performance_report.forEach((metric) => {
                    Object.keys(metric).forEach( mType => {
                        if (mType === 'name' || mType === 'type')
                            return;
                        if (metric.type === 'input' && mType.toLowerCase() === 'benign')
                            return;
                        let val = metric[mType];
                        attack.addMetricVal(mType, metric.name, val);
                    });
                });
            }
        }
        if (runnerReport['detection_report'] != null
            && runnerReport['detection_report'] instanceof Array
            && runnerReport['detection_report'].length > 0) {
            log.trace(`adding detection report from ${report.id}`);
            runnerReport['detection_report'].forEach(r => {
                this.addDetectionSummary(r);
            });
        }
        setDatasetParams(this, runnerReport);

    }

    addPerformanceReport(report, runnerReport, attacks) {
        if (runnerReport.performance_report != null) {
            for (let i = 0; i < attacks.length; i++) {
                let attack = this.getAttack(report, attacks[i], runnerReport);
                // only add eps once (it's the same for all mTypes)
                if (runnerReport.params != null && runnerReport.params['eps'] != null) {
                    attack.addParam('eps', runnerReport.params['eps']);
                }
                Object.keys(runnerReport.performance_report).forEach(mName => {
                    if (mName.startsWith("_"))
                        return;
                    let metric = runnerReport.performance_report[mName];
                    Object.keys(metric).forEach( mType => {
                        let val = runnerReport.performance_report[mName][mType];
                        if (val instanceof Array) {
                            val = val[i];
                        }
                        attack.addMetricVal(mType, mName, val);
                    });
                });
            }
        }
    }

    addType(mType, runnerReport, report) {
        if (runnerReport[mType] !== undefined) {
            let attackName = runnerReport["attack"];
            let attack = this.getAttack(report, attackName, runnerReport);
            // only add eps once (it's the same for all mTypes)
            if (runnerReport.params != null && runnerReport.params['eps'] != null) {
                attack.addParam('eps', runnerReport.params['eps']);
            }
            Object.keys(runnerReport[mType]).forEach(mName => {
                if (mName.startsWith("_"))
                    return;
                let val = runnerReport[mType][mName];
                if (val instanceof Array) {
                    val = val[0];
                }
                attack.addMetricVal(mType, mName, val);
            });
        } else {
            if (log.getLevel() <= log.levels.TRACE) {
                log.trace(`Report for type ${mType} is undefined in report id: ${report.id}, run id:${report.runId}.`);
            }
        }
    }
}

function getDefenseFromRunnerReport(runnerReport) {
    if (runnerReport.version === 2) {
        let defenses = runnerReport.filters.filter(f => f.filter_type === 'defense');
        if (defenses.length > 0) {
            let defense = defenses[0];
            let params = {};
            Object.keys(defense).forEach(k => {
                if (k !== 'filter_name' && k !== 'filter_type') {
                    params[k] = defense[k];
                }
            });
            return new DefenseModel(defense.filter_name, params);
        }
    } else {
        if (runnerReport.defenses instanceof Array && runnerReport.defenses.length > 0) {
            return new DefenseModel(runnerReport.defenses[0].name, runnerReport.defenses[0].params);
        }
    }
}

function groupBy(reports, groupKey) {
    return reports.reduce(function (acc, report) {
        let key = report[groupKey] == null ? 0 : report[groupKey];
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(report)
        return acc
    }, {});
}

function getReportsGroupedBy(reports, groupKey) {
    // group raw reports by key
    let groupedBy = groupBy(reports, groupKey);

    let sorted = Object.keys(groupedBy).sort((a, b) => b - a);
    let groups = [];
    sorted.forEach(key => {
        groups.push(groupedBy[key])
    });
    return groups;
}

function sortRows(rows, sort) {
    let fn = null;
    if (sort.key === 'datasetname') {
        fn = (a, b) => (b.datasetName == null || a.datasetName == null) ?
            -1 : b.datasetName.localeCompare(a.datasetName);
    }
    if (sort.key === 'projectId') {
        fn = (a, b) => b.projectId - a.projectId;
    }
    if (sort.key === 'modelName') {
        fn = (a, b) => (b.modelName == null || a.modelName == null) ?
            -1 : b.modelName.localeCompare(a.modelName);
    }
    if (fn != null) {
        if (sort.desc) {
            rows = rows.sort(fn);
        } else {
            rows = rows.sort((b,a) => fn(a,b));
        }
    }
    return rows
}

function getDataByMetricAndModelName(model) {
    let dataByMetric = {};
    model.getHeaderMetricNames().forEach(metricName => {
        let metricData = {};
        dataByMetric[metricName] = metricData;
        model.rows.forEach(row => {
            let modelData = {};
            if (metricData[row.modelName] == null) {
                metricData[row.modelName] = modelData;
            } else {
                modelData = metricData[row.modelName];
            }
            row.attackKeys().forEach(attackKey => {
                let attack = row.attacks[attackKey];
                if (attack != null) {
                    let metric = attack.getMetric(metricName);
                    if (metric != null) {
                        if (metric.hasType("Benign")) {
                            modelData["Benign"] = metric.getValues("Benign");
                        }
                        if (metric.hasType("Attacked")) {
                            modelData[attackKey] = metric.getValues("Attacked");
                        }
                    }
                }
            });
        });
    });
    return dataByMetric;
}

function getDataForMetricCombo(model, metricName, metricCombo) {
    let data = {x:[],y:[]};
    model.rows.forEach( (row, idx) => {
        let metric = row.overAll.getMetric(metricName);
        if (metric != null) {
            if (metric.hasType(metricCombo)) {
                data.y.push(metric.getAvg(metricCombo));
                data.x.push(idx);
                // data.x.push(new Date(row.overAll.date*1000));
                // data.x.push(row.runId);
            }
        }
    });
    return data;
}

function buildReportTableModel(reports, groupKey = 'runId', projects = null, sort = null, viewType = COLLAPSED_VIEW_TYPE) {
    // group raw reports by key
    let groupedBy = groupBy(reports, groupKey);

    // sort groups of raw   reports in descending order
    let sorted = Object.keys(groupedBy).sort((a, b) => b - a);

    let model = new ReportTableModel();
    model.setViewType(viewType);
    let maxNames = 0;
    let rows = [];
    let i = 0;
    // build a report rows for each group
    sorted.forEach(key => {
        let reportsByGroup = groupedBy[key];

        let t = buildGroupedReportRows(model, reportsByGroup, projects);
        if (t.overAll.metricNames().length > maxNames) {
            maxNames = t.overAll.metricNames().length;
            model.maxNamesIdx = i;
        }
        i++;
        rows.push(t);
    });

    if (sort != null) {
        rows = sortRows(rows, sort);
    }
    model.rows = rows;
    return model;
}

function getSampleCnt(report) {
    return report.sample_cnt;
}

/**
 * Set dataset properties on parsed from raw runner report.
 * @param parsed the parsed object
 * @param report the runner report from json
 */
function setDatasetParams(parsed, report) {
    if (report.version != null && report.version >= 1) {
        if (report.dataset == null) {
            log.error("Report dataset field is null.");
        } else {
            parsed.datasetName = report.dataset.name;
            parsed.datasetRangeStart = report.dataset.start_idx;
            parsed.datasetRangeStop = report.dataset.stop_idx;
            parsed.datasetBatchSize = report.dataset.batch_size;
            parsed.datasetItems = report.dataset.num_items;
            parsed.datasetShuffle = report.dataset.shuffle;
        }
        parsed.storeIntermediate = report.store_intermediate;
    } else {
        parsed.datasetName = report.dataset_name;
        parsed.datasetRangeStart = report.dataset_start_idx;
        parsed.datasetRangeStop = report.dataset_stop_idx;
        parsed.datasetBatchSize = report.dataset_batch_size;
        parsed.datasetItems = report.dataset_num_items;
        parsed.datasetShuffle = report.dataset_shuffle;
    }

}

function buildGroupedReportRows(model, reports, projects) {
    let reportTable = new ReportTableRow(model);
    reportTable.reports = reports;
    reportTable.overAll = new AttackModel(model,"Overall");
    reports.forEach(report => {
        let runnerReport;
        try {
            runnerReport = JSON.parse(report.jsonReport.replace(/\bNaN\b/g,
                '"Unable to calculate"'));
            if (runnerReport instanceof Array) {
                runnerReport = runnerReport[0];
            }
        } catch (e) {
            log.error(e);
            return;
        }

        if (runnerReport.version === 2) {
            reportTable.parseV2Report(report, runnerReport);
        } else {
            if (runnerReport['optimization_report'] != null
                && runnerReport['optimization_report'] instanceof Array
                && runnerReport['optimization_report'].length > 0) {
                log.trace(`adding optimization report from ${report.id}`);
                reportTable.addOptimization(runnerReport['optimization_report']);
            }

            if (runnerReport['detection_report'] != null
                && runnerReport['detection_report'] instanceof Array
                && runnerReport['detection_report'].length > 0) {
                log.trace(`adding detection report from ${report.id}`);
                runnerReport['detection_report'].forEach(r => {
                    reportTable.addDetectionSummary(r);
                });
            }

            if (runnerReport.version === 1) {
                if (runnerReport.attacks != null) {
                    // worker should have "NoAttack" here...
                    if (runnerReport.attacks.length === 0) {
                        runnerReport.attacks.push("NoAttack");
                    }
                    reportTable.addPerformanceReport(report, runnerReport, runnerReport.attacks);
                } else {
                    log.error("Report attacks field is null.");
                }
            } else {
                reportTable.addType('robustness', runnerReport, report);
                reportTable.addType('attacked-robustness', runnerReport, report);
                reportTable.addType('target-benign', runnerReport, report);
                reportTable.addType('benign', runnerReport, report);
                reportTable.addType('target-attacked', runnerReport, report);
                reportTable.addType('attacked', runnerReport, report);
                reportTable.addType('benign-attacked', runnerReport, report);
            }

            setDatasetParams(reportTable, runnerReport);
        }

        reportTable.runId = report.runId;
        reportTable.runName = report.runName;
        reportTable.projectId = report.projectId;
        if (projects != null) {
            let prj = projects.find(p => p.id === report.projectId);
            if (prj != null) {
                reportTable.projectName = limitStringLen(15, prj.name);
                reportTable.modelName = prj.modelName;
            }
        }
    });

    reportTable.attackKeys().forEach(name => {
        reportTable.attacks[name].metricNames().forEach(mName => {
            let attack = reportTable.attacks[name];
            let metric = attack.getMetric(mName);
            metric.getTypes().forEach(mType => {
                reportTable.overAll.addMetricVal(mType, mName, metric.getAvg(mType));
            });
            reportTable.overAll.date = attack.date; // need a valid date on overAll as well,
            // pick any of the attack dates
        })
    });

    return reportTable;
}

function AttackSummary({report}) {
    if (report == null) {
        return <span/>;
    }
    return report.attackKeys().map((name, idx) => {
        let sep = ", ";
        if (idx === report.attackKeys().length - 1) {
            sep = "";
        }
        return (<span key={idx}>{name}{sep}</span>);
    })
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        border: 'solid 1px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderRight: 'solid 1px',
        borderRightColor: theme.palette.text.disabled
    },
}));

const StyledTableRow = styled(TableRow)(({ _theme }) => ({

}));

/**
 * Individual attack columns
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function AttackColls({attack, metricName, model}) {

    function printMetric(metric, mType) {
        return (<div>
            {(metric != null && metric.hasType(mType)) ?
                <>
                    {printRound(metric.getAvg(mType))}
                </>
                :
                <></>
            }
        </div>)
    }

    return (<>
            {model.getHeaderMetricColumnNames(metricName).map( (mType, idx) =>
                <StyledTableCell key={idx} component="th" scope="row">
                    {printMetric(attack.getMetric(metricName),mType)}
                </StyledTableCell>
            )}
        </>
    );
}

function MetricSummaryColls({model, options, report, metricName}) {

    function getValue(metric, mType) {
        switch(options.values) {
            case "Average":
                return metric.getAvg(mType);
            case "Min":
                return metric.getMin(mType);
            case "Max":
                return metric.getMax(mType);
            default:
                log.error("Invalid value type: "+options.values);
        }
    }

    function printMetric(metric, mType) {
        return (<div>
            {(metric != null && metric.hasType(mType)) ?
            <>
                {printRound(getValue(metric, mType))}
            </>
                :
                <></>
            }
        </div>)
    }

    return (<>
        {model.getHeaderMetricColumnNames(metricName).map( (mType, idx) =>
            <StyledTableCell key={idx} scope="row">
                {printMetric(report.overAll.getMetric(metricName), mType)}
            </StyledTableCell>)
        }
        </>);
}

function ReportRow({report, onDeleteReport, model, ...props}) {
    const [openConfirmDeleteAll, setOpenConfirmDeleteAll] = useState(false);
    const [open, setOpen] = useState(false);

    const deleteReports = (result) => {
        setOpenConfirmDeleteAll(false);
        if (result) {
            //onDeleteReport(reportToDelete);
        }
    }

    function showEPS(attackKey) {
        if (report.optMethod == null && report.attacks[attackKey].getParam('eps') != null) {
           let eps = report.attacks[attackKey].getParam('eps');
           if (eps.length > 1) {
                if (eps[0] !== eps[1]) { // will be true for eps exploration and if eps param differs between multiple
                    // attacks per test.
                    return true;
                }
           }
        }
        return false;
    }

    function parseReport(report) {
        log.debug("Parsing report");
        try {
            let r =  JSON.parse(report.jsonReport.replace(/\bNaN\b/g, '"Unable to calculate"'));
            if (r instanceof Array) {
                r = r[0];
            }
            setDatasetParams(report, r);
            return r;
        } catch (e) {
            log.error(e);
        }
    }

    function getIndividualReportName(report) {
        let r = parseReport(report);
        let name = "";
        if (r != null) {
            if (r.version < 2) {
                if (r.attacks != null && r.attacks.length > 0) {
                    name =  r.attacks[0];
                }
                if (r.attack != null) {
                    name = r.attack;
                }
            } else {
                if (r.filters != null) {
                    let filters = r.filters.filter(f => f.filter_type !== "defense");
                    if (filters.length > 0) {
                        name = filters[0].filter_name;
                    }
                }
            }

            let defense = getDefenseFromRunnerReport(r);
            if (defense != null) {
                name = name + " with " + defense.name+ " defense";
            }
        }
        return name;
    }

    return  <>
        <AlertDialog open={openConfirmDeleteAll} title="Delete all reports?"
                     description="This will permanently delete the reports."
                     onClose={deleteReports}/>
        {report.attackKeys().map((attackKey, idx) => (
            <React.Fragment key={idx}>
            <StyledTableRow style={{verticalAlign: "center"}}>
                <StyledTableCell style={{paddingLeft: "25px"}}>
                    <Typography variant="subtitle1">
                        {(report.optMethod != null || showEPS(attackKey)) &&
                            <IconButton aria-label="expand plot row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                        }
                        {props.name}
                    </Typography>
                    <Typography variant="body1">{report.attacks[attackKey].name}
                        {report.attacks[attackKey].getDefense() != null ? " with "+report.attacks[attackKey].getDefense().name+" defense" : ""}
                        {report.attacks[attackKey].getValid() ? "" : " (invalid - no gradient)"}</Typography>
                    <Typography variant="body1">Time: {javaDateToTimeStr(report.attacks[attackKey].date)}</Typography>
                    {report.attackKeys().length === 1 &&
                        <Typography variant="body1" color="textSecondary">
                            Parameters: {paramSummary(report.attacks[attackKey].reportParams)}
                        </Typography>
                    }
                </StyledTableCell>

        {report.overAll.metricNames().map((mName, mIdx) =>
            <React.Fragment key={mIdx}>
                <AttackColls name={attackKey}
                             model={model}
                             metricName={mName}
                             attack={report.attacks[attackKey]}
                             report={report}
                />
            </React.Fragment>
        )}
            </StyledTableRow>
            {showEPS(attackKey) &&
                <StyledTableRow>
                <StyledTableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan="100%">
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <EPSPlot report={report} title={attackKey} attack={report.attacks[attackKey]}/>
                    </Collapse>
                </StyledTableCell>
                </StyledTableRow>
            }
            {report.optMethod != null &&
                <StyledTableRow>
                <StyledTableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan="100%">
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <OptimizationPlot report={report} title={report.optMethod}/>
                </Collapse>
                </StyledTableCell>
                </StyledTableRow>
            }
            </React.Fragment>
            ))}

        <StyledTableRow>
            <StyledTableCell colSpan="100%">
            <Typography variant="h5">Individual Test Reports</Typography>
        {report.reports.map((item) =>
            <Accordion key={item.id}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}>
                    <DeleteReportButton id={item.id} onDeleteReport={onDeleteReport}/>
                    <Typography style={{marginTop:'auto', marginBottom:'auto'}} variant="body1">
                        Report {item.id} for {getIndividualReportName(item)}, completed: {javaDateToTimeStr(item.date)}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Report report={item} runnerReport={parseReport(item)}/>
                </AccordionDetails>
            </Accordion>
        )}
            </StyledTableCell>
        </StyledTableRow>
    </>
}

function ReportSummaryRow({report, options={}, test, onReportNameChanged, ...props}) {
    const [open, setOpen] = useState(false);
    const [updateTestRun] = useTestRun();

    function reportSummaryTooltip() {
        return (<>
            <Typography variant="h6">
                Report summary
            </Typography>
            {report.projectId != null &&
                <>
                <Typography variant="body1">
                    {props.hideTestAndRunNames ? (
                        <a href={`#/main/projects/${report.projectId}`}>Project : {report.projectId}</a>
                        ) :
                        `Project: ${report.projectId}`
                    }
                <br/>{report.modelName != null ? `Model: ${report.modelName}` : ""}
                </Typography>
                </>
            }
            <Typography variant="body1">
                Test Run: {report.runId}
            </Typography>
            <Typography variant="body1">
                Attacks: <AttackSummary report={report}/>
            </Typography>
            <br/>
            <Typography variant="body1">
                Dataset
            </Typography>
            <Typography variant="body1">
            {report.datasetName != null &&
                <>Name: {report.datasetName}</>
            }
            {report.datasetRangeStart != null &&
                <><br/>Range: {report.datasetRangeStart} to {report.datasetRangeStop}</>
            }
            {report.datasetItems != null &&
                <><br/>Items: {report.datasetItems}</>
            }
                {report.datasetShuffle &&
                    <>&nbsp;Shuffle</>
                }
            {report.datasetBatchSize != null &&
                <><br/>Batch size: {report.datasetBatchSize}</>
            }
            </Typography>
            </>)
    }

    function reportSummaryCell() {
        return (<>
            <table>
                <tbody>
                <tr>
                    <td valign="top">
                        <Typography variant="body1" color="textSecondary">
                            {report.projectId != null &&
                                <><a href={`#/main/projects/${report.projectId}`}>
                                    Project: {report.projectId}</a> {report.projectName}
                                    <br/>{report.modelName != null ? `Model: ${limitStringLen(15, report.modelName)}` : ""}</>
                            }
                        </Typography>
                    </td>
                    <td>
                        <Typography variant="body1" color="textSecondary">
                            {report.datasetName != null &&
                                <>Dataset: {report.datasetName}</>
                            }
                            {report.datasetRangeStart != null &&
                                <><br/>Range: {report.datasetRangeStart} to {report.datasetRangeStop}</>
                            }
                            {report.datasetItems != null &&
                                <><br/>Items: {report.datasetItems}</>
                            }
                            {report.datasetShuffle &&
                                <>&nbsp;Shuffle</>
                            }
                        </Typography>
                    </td>
                </tr>
                </tbody>
            </table>
        </>);
    }

    const getRunName = () => {
        if (report.runName != null) {
            return report.runName;
        }
        return test.name;
    }

    const updateRunName = (id, newName) => {
        report.runName = newName;
        onReportNameChanged(id, newName);
        updateTestRun(id, {name: newName});
    }

    return (<TableRow sx={{backgroundColor: 'action.hover'}}
                      style={{verticalAlign: "center"}}>
        <StyledTableCell style={{minWidth: "150px"}}>
            <IconButton aria-label="expand row"
                        size="small"
                        onClick={() => {setOpen(!open); props.expanded(!open);}}>
                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
            <Tooltip title={reportSummaryTooltip()} disableInteractive={false} leaveDelay={200}>
                <InfoIcon color={"primary"}/>
            </Tooltip>
            {!props.hideTestAndRunNames &&
                <>
                    <NameEditor style={{marginLeft: "1px"}} onUpdateName={updateRunName}
                                                                  fullWidth
                                                                label="Run Name"
                                                            name={getRunName()}
                                                            id={report.runId}/>
                </>
            }
            {reportSummaryCell()}
        </StyledTableCell>
        {report.overAll.metricNames().map((mName, mIdx) =>
            <React.Fragment key={mIdx}>
                <MetricSummaryColls report={report}
                                    options={options}
                             model={props.model}
                             metricName={mName}
                             reportName={test.name}
                             expanded={open}
                             test={test}/>

            </React.Fragment>
        )}
    </TableRow>);
}

function ReportRowView({options, report, model, test, onReportNameChanged, onDeleteReport, hideTestAndRunNames}) {
    const [open, setOpen] = useState(false);

    return (<>
        <ReportSummaryRow
        report={report}
        options={options}
        model={model}
        test={test}
        hideTestAndRunNames={hideTestAndRunNames}
        onReportNameChanged={onReportNameChanged}
        expanded={(state) => setOpen(state)}
        />
        {open &&
            <ReportRow
                report={report}
                model={model}
                test={test}
                onDeleteReport={onDeleteReport}
            />
        }
    </>)
}

/**
 * The tabular summary of reports for a test.
 *
 * @author Kobus Grobler
 * @component
 */
export default function ReportTable({model, onReportNameChanged, onReportDeleted, onViewTypeChange, test,
                                        hideTestAndRunNames}) {
    const {deleteReport} = useReports();
    const [showValuesType, setShowValuesType] = useState("Average");
    const [displayType, setDisplayType] = useState(COLLAPSED_VIEW_TYPE);

    const handleDeleteReport = (reportToDelete) => {
        deleteReport(reportToDelete).then(() => onReportDeleted(reportToDelete));
    }

    return <>
        <ErrorBoundary>
            {model != null && model.rows.length > 0 &&
                <>
                    <TextField sx={{ minWidth: "10em", marginRight: 1}}
                           variant="outlined"
                           size="small"
                           margin="dense"
                           select
                           SelectProps={{
                               native: true,
                           }}
                           label="Show values"
                           value={showValuesType}
                           onChange={(e) => setShowValuesType(e.target.value)}>
                    {["Average", "Min", "Max"].map((val) =>
                        <option key={val} value={val}>
                            {val}
                        </option>
                    )}
                </TextField>
                    <FormControl component="fieldset" margin='dense' size='small'>
                        <RadioGroup aria-label="display options" name="display_options"
                                    value={displayType}
                                    onChange={(e) => {
                                        setDisplayType(e.target.value);
                                        onViewTypeChange(e.target.value);
                                    }}>
                            <div>
                                <FormControlLabel value={COLLAPSED_VIEW_TYPE} control={<Radio color="primary"/>}
                                                  label="Summary view"/>
                                <FormControlLabel value="expanded" control={<Radio color="primary"/>}
                                                  label="Expanded view"/>
                                <FormControlLabel value="detail" control={<Radio color="primary"/>}
                                                  label="Detail view"/>
                            </div>
                        </RadioGroup>
                    </FormControl>

                    <TableContainer style={{maxHeight: "90vh",
                        overflowY: "scroll",
                        // set to max screen width, otherwise report of many metrics causes project component layout issues
                        maxWidth: '90vw'}}>
                    <Table style={{width: "100%", minWidth: "50em", borderCollapse: "collapse"}}
                           stickyHeader aria-label="report table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{textAlign: "center"}}>
                                    <Typography variant="h6">Metric</Typography>
                                </StyledTableCell>
                                {model.getHeaderMetricNames().map((mName, idx) =>
                                <StyledTableCell key={idx}
                                                 colSpan={model.getHeaderMetricColumnNames(mName).length}
                                                 style={{textAlign: "center"}}>
                                    <Typography variant="h6">{mName}</Typography>
                                </StyledTableCell>
                                )}
                            </TableRow>
                            <TableRow>
                                <StyledTableCell style={{textAlign: "center"}}>
                                    <Typography variant="body1">Attack report</Typography>
                                </StyledTableCell>
                                {model.getHeaderMetricNames().map((mName) =>
                                    model.getHeaderMetricColumnNames(mName).map( (name, idx) =>
                                        <StyledTableCell key={idx} style={{textAlign: "left"}}>
                                            <Typography variant="body1">{name}</Typography>
                                        </StyledTableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {model.rows.map((report, rowIdx) =>
                                <React.Fragment key={rowIdx}>
                                    <ReportRowView
                                        hideTestAndRunNames={hideTestAndRunNames}
                                        report={report}
                                        model={model}
                                        test={
                                        test}
                                        options={{values: showValuesType}}
                                        onDeleteReport={handleDeleteReport}
                                        onReportNameChanged={onReportNameChanged}
                                    />
                                </React.Fragment>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                </>
            }
        </ErrorBoundary>
    </>
}

ReportTable.propTypes = {
    /**
     * The test tp which this report belongs.
     */
    test: PropTypes.object.isRequired,

    /**
     * Called with report id when a user deleted a report
     */
    onReportDeleted: PropTypes.func.isRequired,

    onViewTypeChange: PropTypes.func.isRequired,

    groupBy: PropTypes.string,

    sort: PropTypes.object,

}

export {StyledTableCell, AttackSummary, getReportsGroupedBy, buildReportTableModel, setDatasetParams, ReportTableRow, getSampleCnt,
    MetricModel, getDefenseFromRunnerReport, getDataForMetricCombo, getDataByMetricAndModelName, EXPANDED_VIEW_TYPE}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect} from 'react';
import {
    useParams, useHistory
} from "react-router-dom";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import AlertDialog from "../dialogs/AlertDialog";
import NameEditor from "../util/NameEditor";
import {checkError, errOptions, getLogger, isValidPythonName} from "../util/util";
import {Configuration, TransformApi} from "../api";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import CodeEditor from "../CodeEditor";
import DeleteIcon from "../util/TrashIcon"

const log = getLogger("transform");

/**
 * View of a Custom transform
 * @author Kobus Grobler
 * @component
 */
export default function Transform(props) {
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const history = useHistory();
    let {transformId} = useParams();

    const {enqueueSnackbar} = useSnackbar();
    const [transform, setTransform] = useState(null);
    const [contents, setContents] = useState("");
    const [openEditor, setOpenEditor] = useState(false);
    const [error, setError] = useState("");

    const api = new TransformApi(props.conf);

    useEffect(() => {
        (async () => {
            try {
                log.debug("Getting transform by id " + transformId);
                const r = await api.getTransform(transformId);
                setTransform(r.data);
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to get transform', errOptions));
            }
        })();
    }, [props.conf]);

    const updateTransform = async (tf) => {
        try {
            log.debug("Updating transform by id " + transformId);
            const r = await api.updateTransform(transformId, tf);
            setTransform(r.data);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to update transform', errOptions));
        }
    }

    const onUpdateTransformName = (id, value) => {
        if (isValidPythonName(value)) {
            setError("");
            transform.name = value;
            transform.codebase_name = value;
            updateTransform(transform);
        } else {
            setError("Invalid transform name");
        }
    }

    const onUpdateDescription = (id, value) => {
        transform.description = value;
        updateTransform(transform);
    }

    const editTransform = async () => {
        if (transform.codebase_name == null || transform.codebase_name.length === 0) {
            setError("Codebase name must be specified.")
            return;
        }
        setError("");
        try {
            let r = await api.downloadFile(transform.id);
            setOpenEditor(true);
            setContents(r.data);
        } catch(e) {
            checkError(e, history, () =>
            enqueueSnackbar('Failed to download transform',
                errOptions));
        }
    }

    const saveCodeChanges = async () => {
        try {
            let blob = new Blob([contents], {
                type: 'text/x-python'
            });
            let rs = await api.uploadFile(transform.id, blob);
            setContents(contents);
            setTransform(rs.data);
            setOpenEditor(false);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to save transform',
                    errOptions));
        }
    }

    const deleteTransform = async (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            try {
                await api.deleteTransform(transformId);
                enqueueSnackbar('Transform deleted.');
                history.replace(history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/")));
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to delete transform', errOptions));
            }
        }
    }

    const handleTaskChange = (e) => {
        const idx = transform.tasks.indexOf(e.target.name);
        if (idx > -1) {
            transform.tasks.splice(idx,1);
        } else {
            transform.tasks.push(e.target.name);
        }
        setTransform({...transform});
        updateTransform(transform);
    }

    return (
        <Grid container item xs={12} md={10} lg={8} xl={6} justifyContent="center" sx={{marginTop: 2}}>
            <AlertDialog open={openConfirmDelete} title="Delete transform?"
                         description="This will permanently delete the transform and its data."
                         onClose={deleteTransform}/>
            <Grid item xs={12}>
                <Paper sx={{padding: 2}}>
                {transform != null &&
                    <>
                        <OkCancelDialog onClose={() => setOpenEditor(false)}
                                        dialogProps={{fullWidth: true, maxWidth: 'md'}}
                                        open={openEditor}
                                        title="Transform editor"
                                        hideOk={true}
                                        actions={<Tooltip title="Save changes">
                                            <IconButton
                                                onClick={() => {
                                                    saveCodeChanges();
                                                }}
                                                size="large">
                                                <SaveIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        }>
                            <CodeEditor id={transform.id}
                                        content={contents}
                                        onChange={setContents}
                                        onSaveChanges={saveCodeChanges}
                            />
                        </OkCancelDialog>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                                <NameEditor
                                    style={{marginTop: "3px"}}
                                    fullWidth
                                    hiddenLabel={false}
                                    label="Transform Class Name"
                                    onChange={(val) => val.length === 0 || isValidPythonName(val)}
                                    name={transform.name}
                                    onUpdateName={onUpdateTransformName}/>
                            </Grid>
                            <Grid item xs={12}>
                                <NameEditor variant="outlined"
                                            multiline
                                            fullWidth
                                            maxRows={4}
                                            minRows={4}
                                            fieldName="Description"
                                            label="Description"
                                            name={transform.description == null ? "" : transform.description}
                                            onUpdateName={onUpdateDescription}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Transform id: {transform.id}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Transform tasks:
                                </Typography>
                                {["classification", "detection", "segmentation"].map((task, idx) =>
                                    <FormControlLabel key={idx}
                                                      control={
                                                          <Checkbox
                                                              color="primary"
                                                              checked={transform.tasks.indexOf(task) > -1}
                                                              onChange={handleTaskChange}
                                                              name={task}
                                                          />
                                                      }
                                                      label={task}
                                    />)
                                }
                            </Grid>
                        <Grid item xs={12}>
                            <Tooltip title="Edit transform">
                                <IconButton
                                    size="small"
                                    onClick={editTransform}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete transform">
                                <IconButton
                                    size="small"
                                    onClick={() => setOpenConfirmDelete(true)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                            {error.length > 0 &&
                            <Grid item container justifyContent="flex-start" alignItems="flex-start">
                                <Typography variant="body1" color="error">
                                    {error}
                                </Typography>
                            </Grid>
                        }
                        </Grid>
                    </>
                }
                </Paper>
            </Grid>
        </Grid>
    );
}

Transform.propTypes = {
    /**
     * The API configuration
     */
    conf: PropTypes.instanceOf(Configuration).isRequired,

};

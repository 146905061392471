import React, {useEffect, useRef} from "react";
import {Layer, Rect, Transformer} from "react-konva";
import {getLogger} from "loglevel";

const log = getLogger("regions");

const Region = ({selectedId, onChange, onDragEnd, onDragStart, region, setSelectRegion}) => {
    const shapeRef = useRef();
    const trRef = useRef();

    useEffect(() => {
        if (selectedId === region.id) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedId, region.id]);

    return (
        <><Rect
            name={"region " + region.id}
            opacity={selectedId === region.id ? 0.8 : 0.5}
            x={region.start.x}
            y={region.start.y}
            width={region.end.x - region.start.x}
            height={region.end.y - region.start.y}
            fill={region.color}
            onClick={() => {
                setSelectRegion(region.id);
            }}
            onTap={() => {
                setSelectRegion(region.id);
            }}
            ref={shapeRef}
            draggable
            onDragEnd={(e) => {
                onChange(region, {
                    x: e.target.x(),
                    y: e.target.y(),
                    width: region.end.x-region.start.x,
                    height: region.end.y-region.start.y
                });
                onDragEnd(e);
            }}
            onDragStart={onDragStart}
            onTransformEnd={(_e) => {
                log.debug("transform end");
                const node = shapeRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
                node.scaleX(1);
                node.scaleY(1);
                onChange(region, {
                    x: node.x(),
                    y: node.y(),
                    // set minimal value
                    width: Math.max(5, node.width() * scaleX),
                    height: Math.max(node.height() * scaleY),
                });
            }}/>
            {selectedId === region.id && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
}

const Regions = ({regions, selectedId, setSelectRegion, onDragStart, onDragEnd, onChange}) => {
    const layerRef = useRef();
    return (
        <Layer ref={layerRef}>
            {regions.map((region, idx) => {
                if (region.end == null) {
                    // No end point yet.
                    return <React.Fragment key={idx}></React.Fragment>
                }
                return <Region key={idx}
                               region={region}
                               onDragEnd={onDragEnd}
                               onDragStart={onDragStart}
                               selectedId={selectedId}
                               setSelectRegion={setSelectRegion}
                               onChange={onChange}
                />
            })}
        </Layer>
    );
};

export default Regions;

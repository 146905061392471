/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import {Configuration, BenchmarkApi} from "../api";
import BenchmarkReports from "./BenchmarkReports";
import {checkError, errOptions, getLogger, modelFrameworkHasGradients} from "../util/util";
import {isTestBusy, StartStopProgress} from "../aitests/Test";
import {getTimeOutMessage, isTimeAvailable} from "../organizations/Organization";
import BenchmarkSetupWizard from "./BenchmarkSetupWizard";

const log = getLogger("benchtest");

function allProjectsHaveGradients(projects) {
    if (projects == null)
        return false;
    return projects.find(p => !modelFrameworkHasGradients(p.model.frameworkType)) == null;
}

/**
 * Composes the configuration of an adversarial test
 * @author Kobus Grobler
 *
 * @component
 */
export default function BenchmarkTest({conf, assets, benchmark, test, testUpdated, projects, ...props}) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const [status, setStatus] = useState(null);
    const [busy, setBusy] = useState(false);
    const api = new BenchmarkApi(conf);

    useEffect(() => {
        const id = setInterval(async () => {
            if (test.expanded) {
                try {
                    let s = await api.getBenchmarkTestStatus(benchmark.id, test.id)
                    setBusy(isTestBusy(s.data));
                    setStatus(s.data);
                } catch(e) {
                    log.error(e);
                    if (e.request.status === 401) {
                        history.replace("/login");
                    }
                }
            }
        }, 1000);
        return () => clearInterval(id);
    }, [conf, test.id, test.expanded, busy, benchmark.id]);

    const onClick = async () => {
        if (isTestBusy(status)) {
            try {
                await api.stopBenchmarkTest(benchmark.id, test.id);
            } catch(e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to stop test',
                        errOptions));
            }
        } else {
            if (!isTimeAvailable(benchmark.organization)) {
                getTimeOutMessage(benchmark.organization);
                enqueueSnackbar(getTimeOutMessage(benchmark.organization),
                    {autoHideDuration: 5000, variant: 'error'});
                return;
            }

            try {
                let r = await api.startBenchmarkTest(benchmark.id, test.id);
                enqueueSnackbar('Started test #' + test.id);
                setBusy(true);
                testUpdated(r.data);
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to start test',
                        errOptions));
            }
        }
    }

    const datasetSettingsUpdated = async (datasetSettings) => {
        benchmark.settings.tests.find(t => t.id === test.id).datasetSetting = datasetSettings;
        try {
            log.debug("Updating settings");
            await api.updateBenchmark(benchmark.id, {
                settings: benchmark.settings
            });
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to save settings'+msg,
                    errOptions));
        }
    }

    const saveSettings = async (settings) => {
        try {
            benchmark.settings.tests = benchmark.settings.tests.map(t => t.id === test.id ? settings : t);
            log.debug("Saving settings");
            let r = await api.updateBenchmark(benchmark.id, {
                settings: benchmark.settings
            });
            benchmark.settings = r.data.settings;
            let updatedtest = benchmark.settings.tests.find(t => t.id === test.id);
            if (updatedtest != null) {
                test.metrics = updatedtest.metrics;
            }
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to save settings'+msg,
                    errOptions));
        }
    }

    const onFinish = async () => {
        test.configure = false;
        test.configured = true;
        await saveSettings(test);
        testUpdated(test);
    }

    return <Grid container spacing={2} alignItems="baseline" justifyContent="center">
        {test.configure &&
        <Grid item xs={12}>
            <BenchmarkSetupWizard hasGradients={allProjectsHaveGradients(projects)}
                                  assets={assets} test={test} benchmark={benchmark} tasks={props.tasks}
                                  settings={benchmark.settings.tests.find(t => t.id === test.id)}
                                  dsLen={props.dsLen} datasetSettingsUpdated={datasetSettingsUpdated}
                                  onSaveSettings={saveSettings}
                                  onFinish={onFinish}

                                    testUpdated={testUpdated}
            />
        </Grid>
        }

        {!test.configure &&
            <>
        <Grid item xs={12}>
            <StartStopProgress compact={true} status={status} onClick={onClick}/>
        </Grid>

        <Grid xs={12} item>
            <BenchmarkReports conf={conf}
                              test={test}
                              projects={projects}
                              busy={busy}/>
        </Grid>
            </>
        }
    </Grid>
}

BenchmarkTest.propTypes = {

    /**
     * The API configuration
     */
    conf: PropTypes.instanceOf(Configuration).isRequired,

    /**
     * The test object
     */
    test: PropTypes.object.isRequired,

    /**
     * The benchmark object
     */
    benchmark: PropTypes.object.isRequired,

    assets: PropTypes.object.isRequired,

    testUpdated: PropTypes.func.isRequired,

    tasks: PropTypes.array.isRequired,

    projects: PropTypes.array.isRequired,
}

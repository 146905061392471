/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import FeedbackIcon from '@mui/icons-material/Feedback';
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import {useUsers} from "./Users";

/**
 * Show dialog with feedback text
 * @author Kobus Grobler
 * @returns {JSX.Element}
 */
export default function Feedback({context}) {
    const {sendFeedback} = useUsers();
    const [open, setOpen] = useState(false);
    const [feedback, setFeedback] = useState("");

    const handleClose = async (accepted) => {
        setOpen(false);
        if (accepted && feedback.length > 0) {
            await sendFeedback("[view="+context+"]\n"+feedback);
            setFeedback("");
        }
    }

    const onFeedbackClick = () => {
        setOpen(true);
    }

    return <>
        <Tooltip title={"Send feedback or feature request for this page"}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onFeedbackClick}
                edge="end"
                size="large">
        <FeedbackIcon/>
    </IconButton>
        </Tooltip>
        <Dialog open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please send us your feedback or feature request
                </DialogContentText>
                <TextField
                    multiline
                    variant="outlined"
                    autoFocus
                    minRows={4}
                    maxRows={10}
                    margin="dense"
                    id="Description"
                    fullWidth
                    onChange={(e) => setFeedback(e.target.value)}
                    value={feedback}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleClose(true)} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

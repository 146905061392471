import Typography from "@mui/material/Typography";
import React, {useContext, useState} from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

import {useUsers} from "./users/Users";
import AppContext from "./AppContext";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

/**
 * Shows the EULA and expect user to accept it.
 *
 * @param user
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
function EULACheck({user}) {
    const history = useHistory();

    const [accepted, setAccepted] = useState(false);
    const {updateProfile} = useUsers();
    const {setUser} = useContext(AppContext);

    const handleAccepted = (e) => {
        setAccepted(e.target.checked);
    }

    const onContinueClick = async () => {
        let u = await updateProfile({licenseAccepted: true});
        if (u != null) {
            setUser(u);
            history.replace("/main");
        }
    }

    return <><Grid container>
        <Grid item xs={12}>
        <Typography variant="h6">
            Please accept the <Link target='_blank' href="/eula.html">end user license agreement</Link> to continue.
        </Typography>
        </Grid>
        <Grid item>
    <FormControlLabel
        control={
            <Checkbox
                checked={accepted}
                onChange={handleAccepted}
                name="license"
                color="primary"
            />
        }
        label="I accept the terms of the license"
    />
        <br/>
        <Button
            disabled={!accepted}
            type="submit"
            variant="contained"
            color="primary"
            onClick={onContinueClick}>
            Continue
        </Button>
        </Grid>
    </Grid></>
}

export default EULACheck;

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText';
import SendIcon from "@mui/icons-material/Send";
import LogsIcon from '@mui/icons-material/Subject';
import Box from "@mui/material/Box";
import React, {useState} from "react";
import AlertDialog from "../dialogs/AlertDialog";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import {useUsers} from "./Users"
import DeleteIcon from "../util/TrashIcon"
import {javaDateToTimeStr, milliesToTimeStr} from "../util/util";

const MenuProps = {
    PaperProps: {
        style: {
            minWidth: 300,
        },
    },
};

export default function User({user, organizations, onSendInvite, onDeleteUser, onMemberUpdate, handleAdminChange,
                                 ...props}) {
    const [admin, setAdmin] = useState(props.admin);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openLogs, setOpenLogs] = useState(false);
    const [userOrgs, setUserOrgs] = useState(user.organizations.map((it) => it.id));
    const {getUserLogs} = useUsers();
    const [logs, setLogs] = useState([]);

    const askDeleteUser = () => {
        setOpenConfirmDelete(true);
    }

    const deleteUser = (confirm) => {
        setOpenConfirmDelete(false);
        if (confirm) {
            onDeleteUser(user);
        }
    }

    const sendInvite = () => {
        onSendInvite(user.email, user.name);
    }

    const userOrgChange = (event) => {
        onMemberUpdate(user, event.target.value);
        setUserOrgs(event.target.value);
    }

    const showUserLogs = async () => {
        let logs = await getUserLogs(user.id);
        setLogs(logs);
        setOpenLogs(true);
    }

    return (
        <Grid item xs={12}>
                <AlertDialog open={openConfirmDelete} title="Delete user?"
                             description="This action will delete the user."
                             onClose={deleteUser}/>
                <OkCancelDialog hideCancel={true}
                                dialogProps={{fullWidth: true, maxWidth: 'lg'}}
                                onClose={() => setOpenLogs(false)}
                                open={openLogs}
                                title="Recent user logs">
                    {openLogs &&
                          <pre style={{fontFamily: 'Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace',
                              fontSize: '12px',
                              height: '100%', width: '100%', whiteSpace: 'pre-wrap', overflowY: 'scroll'}}>
                              {logs.map((l, idx) =>
                                <React.Fragment key={idx}>
                                    <Box sx={[l.level === 'ERROR' && {
                                        color: 'error.dark'
                                    }, l.level === 'WARNING' && {
                                        color: 'warning.dark'
                                    }]}>
                                        {`${l.level} ${javaDateToTimeStr(l.timeStamp)}: ${l.message}\n`}
                                    </Box>
                                </React.Fragment>
                                )}
                          </pre>
                    }
                </OkCancelDialog>

                <Card variant="outlined">
                <CardHeader
                    avatar={
                        <Avatar aria-label="user"
                                alt={user.name}
                                src={'data:image/png;base64,'+user.avatar}>
                            {user.name.charAt(0)}
                        </Avatar>
                    }
                    title={user.name}
                        subheader={user.email}
                />
                <CardContent>
                    <FormControl sx={{margin: 1, minWidth: "25ch"}}>
                        <InputLabel id={user.id+"member-label-id"}>Member of</InputLabel>
                    <Select
                        id={user.id+"member-label-id"}
                        name="orgs"
                        multiple
                        value={userOrgs}
                        onChange={userOrgChange}
                        input={<Input />}
                        renderValue={(selected) => {
                            let val = "";
                            if (organizations != null) {
                                for (let i = 0; i < selected.length; i++) {
                                    val += organizations.find((it) => it.id === selected[i]).name;
                                    if (i < selected.length - 1) {
                                        val += ", ";
                                    }
                                }
                            }
                            return val;
                        }}
                        MenuProps={MenuProps}
                    >
                        {organizations != null && organizations.map((org) => (
                            <MenuItem key={org.id} value={org.id}>
                                <Checkbox color="primary" checked={userOrgs.find((el) => el === org.id) !== undefined} />
                                <ListItemText primary={org.name} />
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                    <Typography variant="body2" component="p">
                        <Checkbox checked={admin}
                                  onChange={(event) =>
                                      setAdmin(handleAdminChange(user, event.target.checked))}
                                  name="admin"
                        /> Admin User
                    </Typography>
                    { !user.registered &&
                    <Typography variant="body2" component="span">
                        <div>This user is not registered with the platform - send an invite.</div>
                    </Typography>
                    }
                    {!user.licenseAccepted &&
                        <Typography variant="body2" component="span">
                            <div>License not accepted.</div>
                        </Typography>
                    }
                    { user.lastLoginDateTime != null &&
                        <Typography variant="body2" component="span">
                            <div>Last log-in: {javaDateToTimeStr(user.lastLoginDateTime)}</div>
                        </Typography>
                    }
                    <Typography variant="body2" component="span">
                        <div>Processing time used: {milliesToTimeStr(user.timeUsed)}</div>
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <Tooltip title="Delete user" aria-label="Delete user">
                    <IconButton onClick={askDeleteUser} size="large">
                        <DeleteIcon/></IconButton>
                    </Tooltip>
                    { !user.registered &&
                    <Tooltip title="Send invite" aria-label="send invite">
                        <IconButton onClick={sendInvite} size="large">
                            <SendIcon/>
                        </IconButton>
                    </Tooltip>
                    }
                    <Tooltip title="Show user logs" aria-label="user logs">
                        <IconButton onClick={showUserLogs} size="large">
                            <LogsIcon/>
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
            </Grid>
    );
}

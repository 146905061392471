/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import React, {useCallback, useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import {ReportsApi} from "../api";
import {checkError, errOptions, getLogger, startBrowserDownload} from "../util/util";
import ReportTable from "./ReportTable";
import ErrorBoundary from "../util/ErrorBoundary"
import AppContext from "../AppContext";
import DetectionReport from "./DetectionReport";
import AlertDialog from "../dialogs/AlertDialog";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "../util/TrashIcon";
import Tooltip from "@mui/material/Tooltip";

const log = getLogger("reports");

/**
 * Hook function used to load reports
 * @param onLoaded called when load completes with list as param
 */
const useLoadReports = (onLoaded) => {
    const {getReports} = useReports();
    const [reports, setReports] = useState(null);
    const [testId, setTestId] = useState(null);

    const callback = useCallback( (testIdVal) => {
        setTestId(testIdVal)
    },[])

    useEffect(() => {
        if (testId != null) {
            getReports(testId).then(r => {
                if (onLoaded != null) {
                    onLoaded(r);
                } else {
                    setReports(r);
                }
            });
        }
    },[getReports, testId, onLoaded]);

    return [{reports, setReports}, callback];

}

const useReports = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);

    const getReportMetaData = useCallback( async (reportId) => {
        log.debug("Loading report meta for "+reportId);
        const api = new ReportsApi(conf);
        try {
            let rs = await api.getReportMetaData(reportId);
            log.debug("Loaded meta for " + reportId);
            if (typeof rs.data === "string") {
                log.debug("Meta data json parsing failed, retrying by replacing NaN");
                return JSON.parse(rs.data.replace(/\bNaN\b/g, '"***NaN***"'), function(key, value) {
                    return value === "***NaN***" ? NaN : value;
                });
            }
            return rs.data;
        } catch (e) {
            if (e.request.status !== 404) {
                log.debug("Failed to load meta data.");
            }
            throw e;
        }
    },[conf])

    const getSampleData = useCallback( async (reportId, idx, name) => {
        log.debug(`Loading sample ${idx} for ${reportId}, ${name}`);
        const api = new ReportsApi(conf);
        let file = await api.viewData(reportId, 0, 0, idx, name, {responseType: 'blob'});
        log.debug("Loaded sample");
        return file.data;
    },[conf])

    const getReportFile = useCallback( async (reportId, name) => {
        log.debug(`Loading sample ${name} for ${reportId}`);
        const api = new ReportsApi(conf);
        let file = await api.getReportFile(reportId, name,{responseType: 'blob'});
        log.debug("Loaded sample");
        return file.data;
    },[conf])

    const deleteReport = useCallback(async (reportId) => {
        try {
            const api = new ReportsApi(conf);
            log.debug("Deleting report: "+reportId);
            await api.deleteReport(reportId);
        } catch(e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to delete report',
                    errOptions));
            throw e;
        }
    },[conf, history, enqueueSnackbar]);

    const getReports = useCallback(async (testId) => {
        try {
            const api = new ReportsApi(conf);
            log.debug(`Loading reports for ${testId}.`);
            let r = await api.getReports(testId);
            log.debug("Reports loaded.");
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to retrieve reports'+msg, errOptions));
            throw e;
        }
    },[conf, history, enqueueSnackbar]);

    const downloadReportData = useCallback(async (reportId) => {
        try {
            const api = new ReportsApi(conf);
            let file = await api.downloadReportData(reportId, {responseType: 'blob'});
            startBrowserDownload(file.data, "report_" + reportId + ".zip");
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to download file'+msg,
                    errOptions));
        }
    },[conf, history, enqueueSnackbar]);

    return {getReports, getReportMetaData, getSampleData, getReportFile, downloadReportData, deleteReport};

}

const DeleteReportButton = ({id, onDeleteReport}) => {
    const [reportToDelete, setReportToDelete] = useState(0);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const deleteReport = (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            onDeleteReport(reportToDelete);
        }
    }

    const askDeleteReport = (id) => {
        setReportToDelete(id);
        setOpenConfirmDelete(true);
    }

    return <>
        <AlertDialog open={openConfirmDelete} title="Delete report?"
                     description="This will permanently delete the report and its data."
                     onClose={deleteReport}/>
        <Tooltip title={"Delete report"}><IconButton
            size="small"
            edge="start"
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => {
                event.stopPropagation();
                askDeleteReport(id)
            }}>
            <DeleteIcon/>
        </IconButton>
        </Tooltip>
    </>
}

/**
 * Lists the test reports
 * @author Kobus Grobler
 * @component
 */
export default function Reports({test, model, onReportDeleted, onReportNameChanged, onViewTypeChange}) {

    return <>
        <ErrorBoundary>
            <Typography variant="h6">Test Results</Typography>
            {model != null &&
                <Grid container>
                    <Grid item xs={12}>
                        {model.isDetectionModel() ?
                            <DetectionReport model={model}
                                             onReportDeleted={onReportDeleted}
                                             detectionSettings={test.testSettings.detectionTestSettings}/> :
                            <ReportTable model={model}
                                         test={test}
                                         onReportDeleted={onReportDeleted}
                                         onReportNameChanged={onReportNameChanged}
                                         onViewTypeChange={onViewTypeChange}
                            />
                        }
                    </Grid>
                </Grid>
            }
        </ErrorBoundary>
    </>
}

export {useLoadReports, useReports, DeleteReportButton};

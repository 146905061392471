/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useContext, useEffect, useState, useCallback} from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PeopleIcon from '@mui/icons-material/People';
import WarningIcon from '@mui/icons-material/Warning';
import StatsIcon from '@mui/icons-material/Equalizer';
import CopyIcon from "@mui/icons-material/Share";
import {useHistory} from "react-router-dom";
import AlertDialog from "../dialogs/AlertDialog";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {OrganizationApi} from "../api";
import {usePositiveNumberInput, checkError, errOptions, getLogger, isAdminUser, humanReadableSize, isTimeRunningOut,
    minutesToTimeStr, javaDateToTimeStr, secondsToTimeStr} from "../util/util";
import NameEditor from "../util/NameEditor";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import DeleteIcon from "../util/TrashIcon"

const log = getLogger("organization");

const numField = {
    margin: 2,
    width: '25ch'
}

/**
 * Load the list of all organizations if an admin user.
 * @returns {[{orgs: *[], setOrgs: (value: (((prevState: *[]) => *[]) | *[])) => void}]}
 */
const useLoadOrgs = () => {
    const {user} = useContext(AppContext);
    const [orgs, setOrgs] = useState([]);
    const {getOrgs} = useOrgs();

    useEffect(() => {
        if (isAdminUser(user)) {
            getOrgs().then((orgs) => {
                if (orgs != null)
                    setOrgs(orgs);
            });
        }
    }, [getOrgs, user]);
    return orgs;
}

const useOrgs = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);
    const orgApi = new OrganizationApi(conf);

    const updateOrgStats = async (id) => {
        try {
            log.debug("updating org stats.");
            await orgApi.updateOrgStats(id);
        } catch (ex) {
            checkError(ex, history, () =>
                enqueueSnackbar('Failed to get organization stats.',
                    errOptions));
        }
    }

    const getOrg = useCallback(async (id) => {
        try {
            log.debug("getting org.");
            const orgApi = new OrganizationApi(conf);
            let rs = await orgApi.getOrganization(id);
            return rs.data;
        } catch (ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to get organization'+msg,
                    errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    const getOrgFileUsage = async (id, ref, type = "classmapping") => {
        try {
            log.debug("getting org file usage.");
            let rs = await orgApi.getOrganizationFileUsage(id, type, ref);
            return rs.data;
        } catch (ex) {
            checkError(ex, history, () =>
                enqueueSnackbar('Failed to get organization file usage.',
                    errOptions));
        }
    }

    const uploadClassMappingFile = useCallback(async (orgId, datasetId, file) => {
        try {
            const api = new OrganizationApi(conf);
            log.debug(`Uploading new class mapping file to org #${orgId}, dataset #${datasetId}`);
            let t = await api.uploadOrganizationFile(orgId, "classmapping", datasetId, undefined, file);
            log.debug("File ref returned: " + t.data);
            enqueueSnackbar('Upload completed.');
            return t.data;
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to upload file',
                    errOptions));
        }
    },[conf, enqueueSnackbar, history]);

    const getMembers = useCallback(async (orgId) => {
        try {
            const api = new OrganizationApi(conf);
            log.debug(`Getting members for org #${orgId}`);
            return (await api.getMembers(orgId)).data;
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to get members',
                    errOptions));
        }
    },[conf, enqueueSnackbar, history]);

    const getOrgs = useCallback(async () => {
        try {
            log.debug("Loading orgs.");
            const orgApi = new OrganizationApi(conf);
            let rs = await orgApi.getOrganizations();
            log.debug(`Loaded ${rs.data.length} orgs.`);
            return rs.data;
        } catch (ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to get list of organizations'+msg,
                    errOptions));
        }
    },[conf, enqueueSnackbar, history]);

    const getHPO = useCallback(async (orgId) => {
        try {
            const orgApi = new OrganizationApi(conf);
            const hpo_result = await orgApi.getDefinedHPO(orgId);
            log.debug("hpo defs retrieved");
            return hpo_result.data;
        } catch (ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to get list of hpo algorithms'+msg, errOptions));
        }
    },[conf, enqueueSnackbar, history]);

    return {getOrgs, updateOrgStats, getOrg, getOrgFileUsage, uploadClassMappingFile, getMembers, getHPO};
}

/**
 * Hook function used to load resources available for the organization
 * @param onLoaded called when load completes
 */
const useLoadResources = (onLoaded) => {
    const {conf} = useContext(AppContext);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [orgId, setOrgId] = useState(null);
    const [availableAttacks, setAvailableAttacks] = useState([]);
    const [metaAttacks, setMetaAttacks] = useState([]);
    const [availableNoises, setAvailableNoises] = useState([]);
    const [domainAdaptations, setDomainAdaptations] = useState([]);
    const [availableDefenses, setAvailableDefenses] = useState([]);
    const [availableMetrics, setAvailableMetrics] = useState([]);
    const [datasetFormats, setDatasetFormats] = useState([]);
    const [transforms, setTransforms] = useState([]);
    const [losses, setLosses] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const loadResources = useCallback(async (orgIdIn) => {
        try {
            if (orgId == null || orgId !== orgIdIn) {
                setLoaded(false);
                log.debug("Loading asset resources.");
                const orgApi = new OrganizationApi(conf);
                const assetsResult = await orgApi.getAssetDefinitions(orgIdIn);
                const rs = await orgApi.getDefinedTransforms(orgIdIn);
                setAvailableAttacks(JSON.parse(assetsResult.data.adversarialAttacks));
                setMetaAttacks(JSON.parse(assetsResult.data.metaAttacks));
                setAvailableNoises(JSON.parse(assetsResult.data.noises));
                setDomainAdaptations(JSON.parse(assetsResult.data.domainAdaptations));
                setAvailableDefenses(JSON.parse(assetsResult.data.defenses));
                setAvailableMetrics(JSON.parse(assetsResult.data.metrics));
                setLosses(JSON.parse(assetsResult.data.losses));
                setDatasetFormats(JSON.parse(assetsResult.data.datasetFormats).filter(ds => ds.enabled));
                setTransforms(rs.data);
                setLoaded(true);
                setOrgId(orgIdIn);
                log.debug("Loaded asset resources.");
                if (onLoaded != null) {
                    onLoaded();
                }
            }
        } catch (ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to load resources'+msg, errOptions));
        }
    },[conf, enqueueSnackbar, history, onLoaded, orgId]);

    return [{loaded,
        availableAttacks, setAvailableAttacks,
        metaAttacks, setMetaAttacks,
        availableDefenses, setAvailableDefenses,
        availableNoises, setAvailableNoises,
        domainAdaptations, setDomainAdaptations,
        availableMetrics, setAvailableMetrics,
        transforms, setTransforms,
        datasetFormats, setDatasetFormats,
        losses, setLosses
    }, loadResources];
}

const getTimeOutMessage = (organization) => {
    return "Your organization has used up all its allocated processing time (" +
        minutesToTimeStr(organization.settings.maxTimeAllocated)+
        ") - please request additional time from the administrator";
}

const isTimeAvailable = (organization) => {
    if (organization.settings.maxTimeAllocated > 0) {
        if (organization.timeUsed/(1000*60) > organization.settings.maxTimeAllocated) {
            return false;
        }
    }
    return true;
}

const TimeRunningOutIndicator = ({organization}) => {
    return <>
        {isTimeRunningOut(organization) &&
            <>
                <WarningIcon sx={{color: 'warning.dark'}}/>
                <Typography>
                    {isTimeAvailable(organization) ?
                        <>
                            {minutesToTimeStr(organization.timeUsed / (1000 * 60))} out
                            of {minutesToTimeStr(organization.settings.maxTimeAllocated)} allocated processing time used.
                        </>
                        :
                        <>
                            All {minutesToTimeStr(organization.settings.maxTimeAllocated)} allocated processing time
                            used - request additional processing time to continue.
                        </>

                    }
                </Typography>
            </>
        }
    </>
}

/**
 * Shows the information for an organization.
 * @author Kobus Grobler
 * @component
 */
export default function Organization({organization, onOrgCopied, onDeleteOrg, onUpdateOrganization}) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const {conf} = useContext(AppContext);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const [busy, setBusy] = useState(false);

    const [showUsers, setShowUsers] = useState(false);

    const [users, setUsers] = useState([]);

    const [maxTimePerTaskProps, setMaxTimePerTask] = usePositiveNumberInput(organization.settings.maxTimePerTask, null);

    const [maxTimeAllocatedProps, setMaxTimeAllocated] = usePositiveNumberInput(organization.settings.maxTimeAllocated, null);

    const [maxItemCountProps, setMaxItemCount] = usePositiveNumberInput(organization.settings.maxItemCount, null);

    const {updateOrgStats, getMembers} = useOrgs();
    const [stats] = useState(organization.stats);

    const onStats = async () => {
        await updateOrgStats(organization.id);
        enqueueSnackbar('Calculating storage usage, refresh this page after a while...');
    }

    const askDeleteUser = () => {
        setOpenConfirmDelete(true);
    }

    const deleteOrg = (confirm) => {
        setOpenConfirmDelete(false);
        if (confirm) {
            onDeleteOrg(organization);
        }
    }

    const onCopy = async () => {
        enqueueSnackbar('Copying organization, this may take a while...');
        const orgApi = new OrganizationApi(conf);
        try {
            setBusy(true);
            log.debug("Copying organization.");
            let r = await orgApi.copyOrganization(organization.id, {name: organization.name+" - Copy"});
            log.debug("Org copy complete.");
            onOrgCopied(r.data);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to copy organization', errOptions));
        } finally {
            setBusy(false);
        }
    }

    const onNamedFieldChanged = (value, name) => {
        let s = organization.settings;
        s[name] = value;
        onUpdateOrganization(organization, {settings: s});
    }

    const onNameChange = (newName) => {
        onUpdateOrganization(organization, {name: newName});
    }

    const handleBlur = (e, value, tempChanged, changed) => {
        if (e !== undefined)  {
            tempChanged(e.target.value);
            if (value == null || e.target.value !== value.toString()) {
                log.debug("Changed from blur");
                changed(e.target.value, e.target.name);
            }
        } else {
            log.info("blur event is undefined.");
        }
    }

    const onViewMembers = async () => {
        const users = await getMembers(organization.id);
        if (users != null) {
            setUsers(users);
            setShowUsers(true);
        }
    }

    return (
        <Grid item xs={12}>
                <AlertDialog open={openConfirmDelete} title="Delete organisation?"
                             description="This action will delete the organisation."
                             onClose={deleteOrg}/>

                <OkCancelDialog hideCancel={true}
                                dialogProps={{maxWidth: 'md'}}
                                onClose={() => setShowUsers(false)}
                                open={showUsers}
                                title="Organization members">
                    <>
                    {users.length === 0 &&
                        <span>This organization has no members</span>
                    }
                    {showUsers && users.map((user, idx) =>
                              <React.Fragment key={idx}>
                                  <Typography variant="body1">
                                  {user.name}
                                  </Typography>
                              </React.Fragment>
                    )}
                    </>
                </OkCancelDialog>

                <Card variant="outlined">
                <CardHeader
                    avatar={
                        <Avatar aria-label="user">
                            {organization.name.charAt(0)}
                        </Avatar>
                    }
                />
                <CardContent>
                    <NameEditor fieldName="Organisation Name"
                                name={organization.name != null ? organization.name : ""}
                                fullWidth
                                style={{marginTop: "auto", marginBottom: "10px", display: 'inline-flex'}}
                                onUpdateName={(id, value) => onNameChange(value)}/>
                    {organization.creationDateTime != null &&
                        <Typography variant="body2" component="span">
                            <div>Creation date: {javaDateToTimeStr(organization.creationDateTime)}</div>
                        </Typography>
                    }
                    <Typography variant="body2" component="span">
                        <div>Processing time used: {secondsToTimeStr(organization.timeUsed/1000)}</div>
                    </Typography>
                    {stats != null &&
                        <Typography variant="body2" component="span">
                            <div>Dataset Storage: {humanReadableSize(stats.datasetStorage)}</div>
                            <div>Project Storage: {humanReadableSize(stats.projectStorage)}</div>
                        </Typography>
                    }
                    <TextField sx={numField}
                               {...maxTimeAllocatedProps}
                               onBlur={e => handleBlur(e, organization.settings.maxTimeAllocated, setMaxTimeAllocated, onNamedFieldChanged)}
                               size="small"
                               label="Max time allocated (min)"
                               type="number"
                               name="maxTimeAllocated"
                               variant="outlined"
                    />
                    <TextField sx={numField}
                               {...maxTimePerTaskProps}
                               onBlur={e => handleBlur(e, organization.settings.maxTimePerTask, setMaxTimePerTask, onNamedFieldChanged)}
                               size="small"
                               label="Max task limit (min)"
                               type="number"
                               name="maxTimePerTask"
                               variant="outlined"
                    />
                    <TextField sx={numField}
                               {...maxItemCountProps}
                               onBlur={e => handleBlur(e, organization.settings.maxItemCount, setMaxItemCount, onNamedFieldChanged)}
                               size="small"
                               label="Max item limit (cnt)"
                               type="number"
                               name="maxItemCount"
                               variant="outlined"
                    />
                </CardContent>
                <CardActions>
                    <Tooltip title="Update organization stats" aria-label="organization stats">
                        <IconButton disabled={busy} onClick={onStats} size="large">
                            <StatsIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy organization" aria-label="Copy organization">
                        <IconButton disabled={busy} onClick={onCopy} size="large">
                            <CopyIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Members" aria-label="View Members">
                        <IconButton disabled={busy} onClick={onViewMembers} size="large">
                            <PeopleIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete organisation" aria-label="Delete organisation">
                        <IconButton onClick={askDeleteUser} size="large">
                            <DeleteIcon/></IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
            </Grid>
    );
}


Organization.propTypes = {
    /**
     * The organization instance
     */
    organization: PropTypes.object.isRequired,

    /**
     * Called when the user updates settings or name.
     */
    onUpdateOrganization: PropTypes.func.isRequired,

    onOrgCopied: PropTypes.func.isRequired,

    /**
     * Called when the user deletes and organization.
     */
    onDeleteOrg: PropTypes.func.isRequired

};

export {useLoadResources, useLoadOrgs, useOrgs, TimeRunningOutIndicator, isTimeAvailable, getTimeOutMessage};

/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import ParameterEntry from "./ParameterEntry";

function isParamDisplayed(param) {
    return typeof param.displayed === 'undefined' || param.displayed;
}

/**
 * Returns true if the definition has displayable parameters
 * @param def definition from config
 * @returns {boolean} true if the definition has displayable parameters
 */
function hasDisplayedParams(def) {
    if (def != null && def.params != null) {
        return def.params.find(param => isParamDisplayed(param)) !== undefined;
    }
    return false;
}

/**
 * Displays list that allows the user to adjust asset parameters
 * @author Kobus Grobler
 * @component
 */
export default function ParamEntryList({paramDef, params, onValuesChanged, ...props}) {
    const [values, setValues] = useState(null);
    const [errors, setErrors] = useState(props.errors);

    useEffect(() => {
        let newValues = new Array(paramDef.params.length).fill("");
        if (params != null) {
            Object.keys(params).map(function (key) {
                for (let i = 0; i < paramDef.params.length; i++) {
                    if (paramDef.params[i].name === key) {
                        // found the index of the param in the param definition.
                        newValues[i] = params[key];
                    }
                }
                return key;
            });
        }
        setValues(newValues);
        onValuesChanged(newValues);
    }, [paramDef, params]);

    useEffect(() => {
        setErrors(props.errors);
    }, [props.errors]);

    function handleChange(idx, val) {
        values[idx] = val;
        setValues(values);
        onValuesChanged(values);
    }

    return (<>
        {values != null && paramDef.params.map((param, idx) =>
            (isParamDisplayed(param) &&
                <Box key={idx} mb={1}><ParameterEntry param={param}
                                index={idx}
                                value={values[idx]}
                                errorMsg={errors == null ? null : errors[idx]}
                                onChange={handleChange}
                                displayDefaults={true}
                                onFileUpload={props.onFileUpload}/></Box>
            ))}
        {paramDef.params.length === 0 &&
            <>No adjustable parameters available</>
        }
    </>);
}

ParamEntryList.propTypes = {
    /**
     * The asset definition
     */
    paramDef: PropTypes.object.isRequired,

    /**
     * The params
     */
    params: PropTypes.object.isRequired,

    /**
     * Called when param values changes
     */
    onValuesChanged: PropTypes.func.isRequired,

    /**
     * Called when a file is uploaded via drag & drop
     */
    onFileUpload: PropTypes.func.isRequired,

    errors: PropTypes.array,

};

export {hasDisplayedParams, isParamDisplayed}

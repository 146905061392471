/*
 * Copyright (C) 2023 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React from "react";
import Grid from "@mui/material/Grid";
import Plot from "react-plotly.js";
import Paper from "./Paper";
import Typography from "@mui/material/Typography";

export default function Gauge({title, val}) {

    const getPlotData = (val) => {
        return {
            value: val,
            type: "indicator",
            mode: "number",
        }
    }

    return <>
        <Paper variant="outlined">
            <Grid item container xs={12} justifyContent={"center"}>
                <Typography mb={0}>
                    {title}
                </Typography>

                <Plot style={{width: "100%", minWidth: "5em", minHeight: "5em", margin:"0px", padding:"0"}}
                        data={[getPlotData(val)]}
                          layout={{
                              margin: {
                                  autoexpand: false,
                                  b: 0, t: 0, l: 0, r: 0
                              }
                          }}
                          config={{displaylogo: false, responsive: true }}
                    />
            </Grid>
        </Paper>
    </>
}

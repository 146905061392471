/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import AlertDialog from "../dialogs/AlertDialog";

/**
 * Text editor component
 * @author Kobus Grobler
 * @component
 */
export default function NameEditor({multiline,
                                       variant,
                                       fullWidth,
                                       minRows, maxRows,
                                       hiddenLabel= true,
                                       label, ...props}) {
    const [name, setName] = useState(props.name == null ? "" : props.name);
    const [nameError, setNameError] = useState(props.errorMessage == null ? "": props.errorMessage);
    const [openConfirmChange, setOpenConfirmChangeName] = useState(false);
    const fieldName = props.fieldName == null ? "name" : props.fieldName;

    useEffect(() => {
        setNameError(props.errorMessage == null ? "": props.errorMessage);
    }, [props.errorMessage]);

    useEffect(() => {
        setName(props.name);
    }, [props.name]);

    function isNameValid() {
        return name.length >= 1;
    }

    function update() {
        if (isNameValid()) {
            setNameError("");
            props.onUpdateName(props.id, name);
        } else {
            setNameError("Invalid "+fieldName);
        }
    }

    const keyPress = (e) => {
        if (!multiline) {
            e.stopPropagation();
            if (e.keyCode === 13) {
                if (props.name !== name) {
                    update();
                }
            }
        }
    }

    const onBlur = () => {
        if (props.name !== name) {
            setOpenConfirmChangeName(true);
        }
    }

    const confirmClose = (result) => {
        setOpenConfirmChangeName(false);
        if (result) {
            update();
        } else {
            setName(props.name);
        }
    }

    return <>
        <AlertDialog open={openConfirmChange} title="Save changes?"
                     description={"You have changed the "+fieldName+" - save changes?"}
                     onClose={confirmClose}/>
        <TextField style={{...props.style}}
                   value={name}
                   label={label}
                   variant={variant}
                   multiline={multiline}
                   minRows={minRows}
                   maxRows={maxRows}
                   sx={{marginLeft: 1, marginRight: 1}}
                   fullWidth={fullWidth}
               error={nameError.length > 0}
               size="small"
               hiddenLabel={hiddenLabel}
               helperText={nameError}
               onClick={(event) => {
                   event.stopPropagation();
               }}
               onKeyDown={keyPress}
               onBlur={onBlur}
               onChange={(event) => {
                   if (props.onChange) {
                       if (props.onChange(event.target.value)) {
                           setName(event.target.value)
                       }
                   } else {
                       setName(event.target.value)
                   }
               }}/>
    </>
}

NameEditor.propTypes = {
    /**
     * The value of the name field
     */
    name: PropTypes.string,
    /**
     * Function that will be called on update with (id, name)
     */
    onUpdateName: PropTypes.func.isRequired,

    /**
     * Optional function that will be called on change with name
     */
    onChange: PropTypes.func,

    /**
     * Optional id associated with the name field
     */
    id: PropTypes.number,

    /**
     * Optional name to be used in messages. Defaults to "name"
     */
    fieldName: PropTypes.string,

    /**
     * Optional error message
     */
    errorMessage: PropTypes.string
};

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext, useCallback} from 'react';
import {
    useParams, useHistory
} from "react-router-dom";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import SettingsIcon from '@mui/icons-material/Settings';
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import AddTestIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AlertDialog from "../dialogs/AlertDialog";
import NameEditor from "../util/NameEditor";
import {getModelName, limitStringLen, checkError, errOptions, getLogger, shared} from "../util/util";
import {BenchmarkApi, Configuration, ProjectApi} from "../api";
import SelectListDialog from "../dialogs/SelectListDialog";
import AppContext from "../AppContext";
import {useLoadDatasets} from "../datasets/Datasets"
import BenchmarkTest from "./BenchmarkTest"
import ErrorBoundary from "../util/ErrorBoundary"
import {useLoadResources, TimeRunningOutIndicator} from "../organizations/Organization";
import DeleteIcon from "../util/TrashIcon"
import {useBenchmarks} from "./Benchmarks";
import Paper from "../util/Paper";
import {SubmitButton} from "../util/SubmitButton";
import {useProjects} from "../projects/Projects";

const log = getLogger("benchmark");

function ProjectGrid({benchmark, datasets, onDSLenUpdated, onProjectsLoaded, conf, ...props}) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {getProjects, getProject} = useProjects();
    const [validProjects, setValidProjects] = useState(null);
    const [projects, setProjects] = useState([]);
    const [openDlg, setOpenDlg] = useState(false);

    useEffect(() => {
        (async () => {
            if (benchmark.settings.projects != null) {
                log.debug("Loading projects: " + benchmark.settings.projects.length);
                let prjs = [];
                for (let prj of benchmark.settings.projects) {
                    try {
                        let p = await getProject(prj.id, true);
                        if (p.modelId != null && p.settings != null) {
                            prjs.push(p);
                        }
                    } catch (e) {
                        if (e.response.status === 404) { // likely deleted
                            log.info(`Project with id ${prj.id} not found.`);
                        } else {
                            checkError(e, history, () =>
                                enqueueSnackbar('Failed to get project',
                                    errOptions));
                        }
                    }
                }
                log.debug("Loading projects done.");
                setProjects(prjs);
                onProjectsLoaded(prjs);
            }
        })()
    },[getProject, enqueueSnackbar, history, benchmark.settings.projects, onProjectsLoaded]);

    const selectProjectModel = async () => {
        const r = await getProjects();
        let f = r.filter( p => {
            if (p.verified) {
                if (p.settings.tasks[0] === benchmark.settings.tasks[0]
                    && p.organization.id === benchmark.organization.id)
                    return true;
            }
            return false;
        });
        setValidProjects(f);
        setOpenDlg(true);
    }

    const addProjectToBenchmark = (prj) => {
        projects.push(prj);
        setProjects(projects);
        props.onProjectsUpdated(projects);
    }

    const onSelectProjectClose = (value) => {
        setOpenDlg(false);
        if (value != null) {
            if (projects.find( p => p.id === value.id) === undefined) {
                addProjectToBenchmark(value);
            }
        }
    }

    const onRemoveCol = (col) => {
        let prjs = projects.filter(p => col.projects.find(item => p.id === item.id) === undefined);
        setProjects(prjs);
        props.onProjectsUpdated(prjs);
    }

    const onConfigureProject = (id) => {
        history.push(`/main/projects/${id}`);
    }

    const onAddNewProject = async (id, ds) => {
        let p = projects.find(prj => prj.id === id);
        const api = new ProjectApi(conf);
        try {
            let r = await api.addProject(p.organization.id,
                {name: `${p.modelName} + ${ds.name}`});
            let settings = r.data.settings;
            settings.tasks = benchmark.settings.tasks;
            settings.datasetId = ds.id;
            r = await api.updateProject(r.data.id, {settings: settings});
            addProjectToBenchmark(r.data);
            history.push(`/main/projects/${r.data.id}`);
        } catch(e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to add project',
                    errOptions));
        }
    }

    function getDatasetFromId(id) {
        if (datasets == null) {
            return null;
        }
        return datasets.find(item => item.id === id);
    }

    const getColumns = () => {
        let cols = [];
        if (projects != null) {
            projects.forEach(p => {
                let existingModel = cols.find( col => col.modelName === p.modelName);
                if (existingModel === undefined) {
                    cols.push({
                        name: getModelName(p),
                        fullName: getModelName(p),
                        modelName: p.modelName,
                        id: p.id,
                        projects: [p],
                    });
                } else {
                   existingModel.projects.push(p);
                }
            });
        }
        return cols;
    }

    const getColumnsByRow = (row) => {
        let cols = getColumns();
        cols = cols.map(col => {
            let prj = col.projects.find( p => p.settings.datasetId === row.id);
            if (prj !== undefined) {
                return {
                    datasetId: row.id,
                    id: prj.id,
                    verified: prj.verified
                };
            } else {
                return {
                    id: col.projects[0].id,
                };
            }
        });
        return cols;
    }

    function getRows() {
        let d = [];
        if (projects != null) {
            let minDsLen = 0;
            projects.forEach( p => {
                if (p.settings.datasetId != null) {
                    let existingDs = d.find(item => item.id === p.settings.datasetId);
                    // only unique datasets
                    if (existingDs === undefined) {
                        let ds = getDatasetFromId(p.settings.datasetId);
                        if (ds != null) {
                            if (ds.datasetLength > minDsLen)
                                minDsLen = ds.datasetLength;
                            d.push(ds);
                        } else {
                            log.error(`Dataset id #${p.settings.datasetId} not found`);
                        }
                    }
                }
            });
            onDSLenUpdated(minDsLen);
        }
        return d;
    }

    const isEnabled = (id) => {
        return benchmark.settings.projects.find( p => p.id === id).enabled;
    }

    const enableChanged = (id) => {
        let prj = benchmark.settings.projects.find( p => p.id === id);
        prj.enabled = !prj.enabled;
        props.onSettingsChanged(benchmark.settings);
    }

    return <span>
        <SelectListDialog onClose={onSelectProjectClose}
                          open={openDlg}
                          list={validProjects}
                          title="Select project"
        />
        {datasets != null &&
            <Table style={{minWidth: '650px'}}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={projects.length + 1}>
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={() => selectProjectModel()}>
                                <AddIcon/>
                            </IconButton>&nbsp;<b>Add project to benchmark</b>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left"> </TableCell>
                        {getColumns().map((col, idx) =>
                            <TableCell key={idx} align="left">
                                <Tooltip title="Remove from benchmark">
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        onClick={() => onRemoveCol(col)}>
                                        <RemoveIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={`Id: ${col.id}, ${col.fullName}`}>
                                    <span>{col.name}</span>
                                </Tooltip>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getRows().map((ds, idx) => (
                        <TableRow key={idx}>
                            <TableCell>
                                <Tooltip title={`Id: ${ds.id}, ${ds.name}`}>
                                    <span>{`${limitStringLen(20, ds.name)}`}</span>
                                </Tooltip>
                            </TableCell>
                            {getColumnsByRow(ds).map(p => p.datasetId === ds.id ?
                                <TableCell key={p.id}>
                                    <Tooltip title={`Configure project ${p.id}`}>
                                    <IconButton
                                        color={p.verified ? "primary" : "secondary"}
                                        size="small"
                                        onClick={() => onConfigureProject(p.id)}>
                                        <SettingsIcon/>
                                    </IconButton>
                                    </Tooltip>
                                    &nbsp;
                                    <Switch color="primary" checked={isEnabled(p.id)}
                                            onChange={() => enableChanged(p.id)}/>
                                </TableCell>
                             :
                                <TableCell key={p.id}>
                                    <Tooltip title="Add new dataset/model combination">
                                    <IconButton
                                        color="secondary"
                                        size="small"
                                        onClick={() => onAddNewProject(p.id, ds)}>
                                        <AddIcon/>
                                    </IconButton>
                                    </Tooltip>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        }
    </span>
}

ProjectGrid.propTypes = {
    /**
     * The benchmark project
     */
    benchmark: PropTypes.object.isRequired,

    onProjectsUpdated: PropTypes.func.isRequired,

    onProjectsLoaded: PropTypes.func.isRequired,

    conf: PropTypes.instanceOf(Configuration).isRequired,

};

function SelectTasks(props) {
    const [task, setTask] = useState(null);

    return <Grid direction="column" container item alignContent="center">
        <Grid item xs={12}>
            <Typography mt={1} mb={1}>
                Select the tasks that are performed by models in this benchmark.
            </Typography>
        </Grid>
        <Grid item>
            <TextField style={{minWidth: "20ch"}}
                       select
                       SelectProps={{
                           native: true,
                       }}
                       label="Benchmark Task"
                       value={task}
                       onChange={(e) => {
                           setTask(e.target.value);
                           props.onTaskSelected(e.target.value)
                       }}>
                <option value="">
                </option>
                {shared.TaskEnum.values().map((t) =>
                    <option key={t.name} value={t.name}>
                        {t.displayName()}
                    </option>
                )}
            </TextField>
        </Grid>
    </Grid>
}

/**
 * View of a benchmark project
 * @author Kobus Grobler
 * @component
 */
export default function Benchmark() {
    const {conf} = useContext(AppContext);
    const {getBenchmark} = useBenchmarks();
    const [{datasets}, loadDatasetsByOrgId] = useLoadDatasets();
    const [dsLen, setDsLen] = useState(0);

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openConfirmDeleteTest, setOpenConfirmDeleteTest] = useState(false);
    const history = useHistory();
    let {benchmarkId} = useParams();

    const {enqueueSnackbar} = useSnackbar();
    const [benchmark, setBenchmark] = useState(null);

    const [testToDelete, setTestToDelete] = useState(0);
    const [resources, loadResourcesByOrgId] = useLoadResources();

    const [projects, setProjects] = useState([]);

    const api = new BenchmarkApi(conf);

    useEffect(() => {
        getBenchmark(benchmarkId).then( bm => {
            setBenchmark(bm);
        }).catch( (_e) => {}); // getBenchmark() sends user notification on error.
    }, [getBenchmark, benchmarkId]);

    useEffect( () => {
        if (benchmark != null) {
            loadResourcesByOrgId(benchmark.organization.id);
            loadDatasetsByOrgId(benchmark.organization.id);
        }
    }, [benchmark, loadResourcesByOrgId, loadDatasetsByOrgId]);

    const deleteProject = async (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            try {
                await api.deleteBenchmark(benchmarkId);
                enqueueSnackbar('Benchmark project deleted.');
                history.replace(history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/")));
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to delete project', errOptions));
            }
        }
    }

    async function updateBenchmark(request) {
        try {
            let tests = benchmark.settings.tests;
            let r = await api.updateBenchmark(benchmark.id, request);
            let b = r.data;
            b.settings.tests = b.settings.tests.map((item) => {
                let oldTest = tests.find( t => t.id === item.id);
                if (oldTest != null) {
                    item.expanded = oldTest.expanded;
                    return {...item};
                }
                return item;
            });
            setBenchmark(b);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to update benchmark', errOptions));
        }
    }

    const onUpdateBenchmarkName = async (id, value) => {
        await updateBenchmark({name: value});
    }

    const onTaskSelected = async (task) => {
        benchmark.settings.tasks = [task];
        await updateBenchmark({settings: benchmark.settings});
    }

    const onAddTest = async () => {
        try {
        let rs = await api.addBenchmarkTest(benchmark.id, 'New Test');
        setBenchmark(rs.data);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to add benchmark test'+msg, errOptions));
        }
    }

    const handleExpansion = (expanded, test) => {
        benchmark.settings.tests.forEach((item) => {
            if (test.id === item.id) {
                test.expanded = expanded;
                if (!test.configured) {
                    test.configure = true;
                }
                testUpdated(test);
            }
        });
    };

    const testUpdated = test => {
        benchmark.settings.tests = benchmark.settings.tests.map((item) => {
            if (test.id === item.id) {
                if (test.expanded === undefined) {
                    test.expanded = true; // otherwise, we loose the expanded state (and therefore state updates)
                }
                if (test.configure === undefined) {
                    test.configure = item.configure;
                }
                return {...test};
            } else
                return item;
        });
        setBenchmark({...benchmark});
    }

    const askDeleteTest = (id) => {
        setTestToDelete(id);
        setOpenConfirmDeleteTest(true);
    }

    const deleteTest = async (result) => {
        setOpenConfirmDeleteTest(false);
        if (result) {
            try {
                let rs = await api.deleteBenchmarkTest(benchmark.id, testToDelete);
                setBenchmark(rs.data);
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to delete benchmark test', errOptions));
            }
        }
    }

    const onUpdateTestName = async (id, name) => {
        try {
            let rs = await api.updateBenchmarkTest(benchmark.id, id, {name: name});
            testUpdated(rs.data);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to update benchmark test', errOptions));
        }
    }

    const onProjectsUpdated = async (prjs) => {
        log.debug("Projects updated");
        setProjects(prjs);
        benchmark.settings.projects = prjs.map(p => {return {id: p.id, enabled: true}});
        await updateBenchmark({settings: benchmark.settings});
    }

    const onSettingsChanged = async (settings) => {
        benchmark.settings = settings;
        await updateBenchmark({settings: benchmark.settings});
    }

    const onProjectsLoaded = useCallback((prjs) => {
        setProjects(prjs);
    }, []);

    const onDSLenUpdated = (len) => {
        setDsLen(len);
    }

    return <ErrorBoundary>
        {benchmark != null && resources.loaded &&
        <Grid container style={{marginTop:"1em"}} spacing={1}>
            <AlertDialog open={openConfirmDelete} title="Delete project?"
                         description="This will permanently delete the project and its data."
                         onClose={deleteProject}/>
            <AlertDialog open={openConfirmDeleteTest} title="Delete test?"
                         description="This will permanently delete the test and its data."
                         onClose={deleteTest}/>
            <Grid item container justifyContent="center" spacing={1}>
                <Grid item xs={4}>
                    <Paper>
                    <NameEditor
                        label="Benchmark Name"
                        fullWidth
                        name={benchmark.name}
                        style={{minWidth: '30ch', paddingRight: "10px"}}
                        onUpdateName={onUpdateBenchmarkName}/>
                    </Paper>
                </Grid>
            <Grid item xs={12}>
                {benchmark.settings != null && benchmark.settings.tasks != null && benchmark.settings.tasks.length > 0 ?
                    <Paper>
                        <Typography>
                            Benchmark configuration for <b>{benchmark.settings.tasks[0]}</b> tasks.
                        </Typography>
                <ProjectGrid conf={conf}
                             benchmark={benchmark}
                             datasets={datasets}
                             onDSLenUpdated={onDSLenUpdated}
                             onProjectsLoaded={onProjectsLoaded}
                             onProjectsUpdated={onProjectsUpdated}
                             onSettingsChanged={onSettingsChanged}
                />
                    </Paper>
                    :
                    <SelectTasks onTaskSelected={onTaskSelected}/>
                }
            </Grid>
            </Grid>
            {benchmark.settings != null
                && benchmark.settings.tasks != null && benchmark.settings.tasks.length > 0 &&
                benchmark.settings.projects != null && benchmark.settings.projects.length > 0 &&
            <>
            <Grid item>
                <SubmitButton
                    onClick={onAddTest}
                    startIcon={<AddTestIcon/>}>
                    Add Test
                </SubmitButton>
            </Grid>
                <Grid item>
                    <TimeRunningOutIndicator organization={benchmark.organization}/>
                </Grid>
            {benchmark.settings.tests != null && benchmark.settings.tests.map((test) => {
                if (test.datasetSetting == null) {
                    test.datasetSetting = {};
                }
                return <Grid key={test.id} item xs={12}>
                    <Accordion TransitionProps={{unmountOnExit: true}}
                               expanded={test.expanded === undefined ? false : test.expanded}
                               onChange={(_event, expanded) =>
                        handleExpansion(expanded, test)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            <IconButton
                                size="small"
                                onFocus={(event) => event.stopPropagation()}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    askDeleteTest(test.id)
                                }}>
                                <DeleteIcon/>
                            </IconButton>
                            <Tooltip title="Change test configuration">
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (!test.configure) {
                                            test.configure = true;
                                            handleExpansion(true, test);
                                        }
                                    }}>
                                    <SettingsIcon/>
                                </IconButton>
                            </Tooltip>
                            <span style={{
                                flexBasis: '33.33%',
                                paddingTop: '5px',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                display: 'inline-flex'}}>
                            <Typography sx={{marginLeft: 1, whiteSpace: 'nowrap'}}
                                        variant={"body1"}>Test {test.id}</Typography>
                                <NameEditor
                                    title="Test Name"
                                    name={test.name}
                                    style={{minWidth: "20ch"}}
                                    fullWidth
                                    id={test.id}
                                    onUpdateName={onUpdateTestName}/>
                            </span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <BenchmarkTest conf={conf}
                                           test={test}
                                           projects={projects}
                                           benchmark={benchmark}
                                           testUpdated={testUpdated}
                                           dsLen={dsLen}
                                           assets={resources}
                                           tasks={benchmark.settings.tasks}/>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            })}

            </>}

            <Grid item container justifyContent="center">
                <Grid item>
                    <Tooltip title="Delete benchmark">
                        <IconButton
                            size="small"
                            onClick={() => setOpenConfirmDelete(true)}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
    </Grid>}
    </ErrorBoundary>
}

import AlertDialog from "../dialogs/AlertDialog";
import {useCallback, useState} from "react";

const useConfirmDelete = () => {
    const [open, setOpen] = useState(false);
    const [context, setContext] = useState(null);
    const openWithContext = useCallback( (context) => {
        setContext(context);
        setOpen(true);
    },[setContext, setOpen]);

    return [{ open, context, setOpen }, openWithContext];
}

export default function ConfirmDelete({title, description, onClose, confirmDeleteProps}) {

    return <>
        <AlertDialog open={confirmDeleteProps.open}
                     title={title}
                     description={description}
                     onClose={(accepted) => { confirmDeleteProps.setOpen(false);
                         onClose(accepted, confirmDeleteProps.context)}}/>
    </>
}

export {useConfirmDelete}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React from "react";
import Grid from '@mui/material/Grid';
import PropTypes from "prop-types";
import {paramSummary} from '../util/util';
import Typography from "@mui/material/Typography";
import {getDefenseFromRunnerReport} from "./ReportTable";
import ReportSampleViewer from "./ReportSampleViewer";

/**
 * View of a test report
 * @author Kobus Grobler
 * @component
 */
export default function Report({report, runnerReport}) {
    const msg = runnerReport == null ? "Failed to parse report" : null;

    return <>
            {msg != null &&
            <div>{msg}</div>
            }
            {runnerReport != null &&
            <Grid container direction={"column"}>
                {report.dataRef != null &&
                    <>
                        <Typography component="div" variant="body1">
                        {runnerReport.attack != null &&
                        <Grid item>
                            Attack parameters: {paramSummary(runnerReport.params)}
                        </Grid>
                        }
                        {getDefenseFromRunnerReport(runnerReport) != null &&
                            <Grid item>
                                Defense parameters: {paramSummary(getDefenseFromRunnerReport(runnerReport).params)}
                            </Grid>
                        }
                        <Grid item>
                            <ReportSampleViewer id={report.id} title="Explore test data"/>
                        </Grid>
                        </Typography>
                    </>
                }
            </Grid>
            }
    </>
}

Report.propTypes = {
    /**
     * The report instance
     */
    report: PropTypes.object.isRequired
}

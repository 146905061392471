import * as React from "react";
import {SvgIcon} from "@mui/material";

const SvgOnnx = (props) => (
    <SvgIcon viewBox="0 0 55 55" {...props}>
        <style>
            {
                ".ONNX-Logo_svg__st0{fill:#fff}.ONNX-Logo_svg__st1{fill:#545554}.ONNX-Logo_svg__st2{fill:#3e3e3d}.ONNX-Logo_svg__st3{fill:#4d4c4d}"
            }
        </style>
        <path
            className="ONNX-Logo_svg__st1"
            d="M40.5 9l8.7 16c-.3.4-.5.9-.5 1.4 0 .2 0 .4.1.6l-11.4 7.8c-.1-.1-.2-.1-.3-.2L40.5 9"
        />
        <path
            className="ONNX-Logo_svg__st2"
            d="M37.1 38.4c.7-.3 1.2-1.1 1.2-1.9 0-.2 0-.4-.1-.5l10.4-7.1-10.5 18.2-1-8.7z"
        />
        <path
            className="ONNX-Logo_svg__st3"
            d="M34.5 37.9c.3.3.7.5 1.1.6l1 8.9c-.4.2-.8.6-1 1l-21.5-2.1 20.4-8.4z"
        />
        <path
            d="M15 20.1c.3-.1.6-.2.8-.3l18.3 15.9c-.1.2-.1.5-.1.8v.1L13.5 45c-.3-.4-.8-.8-1.3-.9l2.8-24z"
            fill="#5d5d5d"
        />
        <path
            className="ONNX-Logo_svg__st3"
            d="M4.5 24.9v-.4l8.6-5.1c.2.2.4.3.6.4l-2.8 24-7-17.3c.3-.4.6-.9.6-1.6z"
        />
        <path
            className="ONNX-Logo_svg__st2"
            d="M14.2 15.9c-1 .2-1.8 1-1.8 2.1v.1l-7.6 4.5L16.6 5.7l-2.4 10.2z"
        />
        <path
            className="ONNX-Logo_svg__st1"
            d="M37.6 6.9l-21.7 9.5c-.1-.1-.2-.1-.2-.2l2.8-11.8h.1c.8 0 1.4-.4 1.8-1l17.2 3.5z"
        />
        <path
            d="M38 8.3c.3.3.6.6 1 .7l-3.4 25.4c-.2 0-.4.1-.6.2l-18.5-16c0-.2.1-.4.1-.6v-.4L38 8.3z"
            fill="#717272"
        />
    </SvgIcon>
)

export default SvgOnnx;

/*
 * Copyright (C) 2023 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useContext, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {BenchmarkApi} from "../api";
import {checkError, convertUserValuesToParams, errOptions, getLogger} from "../util/util";
import AppContext from "../AppContext";
import AttackDefenseSelector from "../aitests/AttackDefenseSelector";
import TuneParamsDialog from "../aitests/TuneParamsDialog";
import MetricSelection from "../aitests/MetricSelection";
import PassFailCriteria from "../aitests/PassFailCriteria";
import {SubmitButton} from "../util/SubmitButton";
import {BackButton} from "../util/Buttons";
import BenchmarkDatasetSettings from "./BenchmarkDatasetSettings";
import {DataStorage} from "../aitests/TestSetupWizard";

const log = getLogger("benchwizard");

/**
 * Test configuration wizard.
 * @author Kobus Grobler
 * @component
 */
export default function BenchmarkSetupWizard({step = 0, benchmark, test, settings, dsLen,
                                            tasks, testUpdated, datasetSettingsUpdated,
                                            assets, onFinish, onSaveSettings, hasGradients}) {
    const {conf} = useContext(AppContext);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [testParam, setTestParam] = useState(null);
    const [testParamDef, setTestParamDef] = useState(null);
    const [openTuneParam, setOpenTuneParam] = useState(false);
    const [activeStep, setActiveStep] = useState(step);
    const inProfile = false;

    const steps = ['Data loader', 'Attacks/Defenses', 'Analysis'];

    const [completed] = useState([settings.configured, settings.configured, settings.configured, settings.configured]);

    const api = new BenchmarkApi(conf);

    const saveSettings = async (s) => {
        onSaveSettings(s);
    }

    const handleSettingsChange = e => {
        settings[e.target.name] = !settings[e.target.name];
        saveSettings(settings).then();
    }

    const onSetParams = (def, params) => {
        let paramStr = {
            parameters: JSON.stringify(params)
        }
        setTestParam(paramStr);
        setTestParamDef(def);
        setOpenTuneParam(true);
    }

    function handleCloseTuneDlg(values) {
        setOpenTuneParam(false);
        if (values != null) {
            let metric = settings.metrics[tasks[0]].find(m => m.name === testParamDef.name);
            if (metric != null) {
                metric.params = convertUserValuesToParams(values, testParamDef.params);
            } else {
                log.error("Metric not found:"+testParamDef.name);
            }
            log.debug("Saving params");
            saveSettings(settings).then();
        }
    }

    function analysisStep() {
        return <><Grid item xs={12} spacing={1} container alignItems="stretch" justifyContent={"center"} mt={2}>
            {tasks.map(task =>
                <Grid item xs={9} key={task}>
                    <MetricSelection task={task}
                                     settings={settings}
                                     metricDefs={assets.availableMetrics}
                                     onSetParams={onSetParams}
                                     saveSettings={saveSettings}/>
                </Grid>
            )}
            {!inProfile &&
                <>
                <Grid item xs={9}>
                    <PassFailCriteria task={tasks[0]}
                                      settings={settings}
                                      metricDefs={assets.availableMetrics}
                                      saveSettings={saveSettings}/>
                </Grid>
                <Grid item xs={9}>
                <DataStorage task={tasks[0]}
                    settings={settings}
                    onSettingsChange={handleSettingsChange}/>
                </Grid>
                </>
            }
        </Grid>
        </>
    }

    function testConfigStep() {
        const onUpdateTest = async (testIn, request) => {
            try {
                let rs = await api.updateBenchmarkTest(benchmark.id, testIn.id, request);
                testUpdated(rs.data);
            } catch(e) {
                checkError(e, history, (msg) =>
                    enqueueSnackbar('Failed to update test'+msg,
                        errOptions));
            }
        }

        const onRemoveDefense = async (defense, testIn) => {
            try {
                let rs = await api.removeBenchmarkTestDefense(benchmark.id, testIn.id, defense.id);
                testUpdated(rs.data);
            } catch(e) {
                checkError(e, history, (msg) =>
                    enqueueSnackbar('Failed to remove defense'+msg,
                        errOptions));
            }
        }

        const onAddDefense = async (rq, testIn) => {
            try {
                let rs = await api.addBenchmarkTestDefense(benchmark.id, testIn.id, rq);
                testUpdated(rs.data);
            } catch(e) {
                checkError(e, history, (msg) =>
                    enqueueSnackbar('Failed to add defense'+msg,
                        errOptions));
            }
        }

        const onAttackAdded = async (testIn, filter) => {
            try {
                let rs = await api.addBenchmarkTestFilter(benchmark.id, testIn.id, filter);
                testUpdated(rs.data);
            } catch(e) {
                checkError(e, history, (msg) =>
                    enqueueSnackbar('Failed to add attack'+msg,
                        errOptions))
            }
        }

        const onRemoveFilter = async (filter) => {
            try {
                let rs = await api.removeBenchmarkFilter(benchmark.id, test.id, filter.id);
                testUpdated(rs.data);
            } catch(e) {
                checkError(e, history, (msg) =>
                    enqueueSnackbar('Failed to remove filter'+msg,
                        errOptions))
            }
        }

        return <>
            <Grid spacing={1} container alignItems="stretch" justifyContent={"center"} mt={2}>
                <Grid item xs={12} m={1}>
                    <AttackDefenseSelector conf={conf}
                                           hasGradients={hasGradients}
                                           test={test}
                                           assets={assets}
                                           testUpdated={testUpdated}
                                           onRemoveFilter={onRemoveFilter}
                                           onAttackAdded={onAttackAdded}
                                           onUpdateTest={onUpdateTest}
                                           onAddDefense={onAddDefense}
                                           onRemoveDefense={onRemoveDefense}
                                           tasks={tasks}
                    />
                </Grid>
            </Grid>
        </>
    }

    function dataLoaderStep() {
        return <Box mt={2}>
            <BenchmarkDatasetSettings conf={conf}
                                      test={test}
                                      maxItemCount={benchmark.organization.settings.maxItemCount}
                                      dsLen={dsLen}
                                      settingsUpdated={datasetSettingsUpdated}/>
        </Box>
    }

    function isStepComplete(stp) {
        return completed[stp];
    }

    function getStepContent(stp) {
        switch (stp) {
            case 0:
                return dataLoaderStep();
            case 1:
                return testConfigStep();
            case 2:
                return analysisStep();
            default:
                return 'Unknown step';
        }
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            onFinish();
        } else {
            completed[activeStep] = true;
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleStep = (idx) => () => {
        if (idx > 0) {
            if (completed[idx-1])
                setActiveStep(idx);
        } else {
            setActiveStep(idx);
        }
    }

    return (<Grid container>
        {testParamDef != null &&
            <TuneParamsDialog onClose={handleCloseTuneDlg}
                              open={openTuneParam}
                              paramDef={testParamDef}
                              item={testParam}
            />
        }
        <Grid item xs={12}>
            <Typography>Test Configuration</Typography>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, idx) => {
                        const stepProps = {};
                        return (
                            <Step key={label} {...stepProps} completed={isStepComplete(idx)}>
                                <StepButton onClick={handleStep(idx)}>
                                    {label}
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid container>
                <Grid container spacing={1} alignItems="stretch">
                    <Grid item xs={12} style={{minHeight:"50vh", overflowY: "auto"}}>
                        {getStepContent(activeStep)}
                    </Grid>
                    <Grid container item justifyContent="center">
                        {activeStep > 0 &&
                            <BackButton onClick={handleBack}/>
                        }

                        <SubmitButton
                            title={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            onClick={handleNext}
                            startIcon={activeStep === steps.length - 1 ? <DoneIcon/> : <></>}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>);
}

BenchmarkSetupWizard.propTypes = {
    /**
     * The test instance
     */
    test: PropTypes.object.isRequired,

    /**
     * If all the projects in the benchmark has gradients
     */
    hasGradients:  PropTypes.bool.isRequired,

    /**
     * The organizations assets
     */
    assets: PropTypes.object.isRequired,

    /**
     * The finish handler
     */
    onFinish: PropTypes.func.isRequired,

    /**
     * The test update handler
     */
    testUpdated: PropTypes.func.isRequired,

    datasetSettingsUpdated: PropTypes.func,

    /**
     * Optional starting step (defaults to 0)
     */
    step: PropTypes.number
}

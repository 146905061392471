/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useContext, useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import {Configuration, LoginApi} from "./api";
import AppContext from "./AppContext";
import SvgMsSigninLight from "./SvgMsSigninLight";
import SvgGoogle from "./icons/SvgGoogle"

const LoginButton = styled(Button)(({ _theme }) => ({
    minWidth: "140px",
    maxWidth: "120px",
    minHeight: "60px",
    maxHeight: "60px",
}));

function SignIn() {
    const {basePath} = useContext(AppContext);
    const [clients, setClients] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        (async () => {
            const conf = new Configuration({basePath: basePath});
            const api = new LoginApi(conf);
            try {
                let r = await api.getOAuthClients();
                setClients(r.data);
            } catch (e) {
                console.log(e);
                let msg = ""
                if (e.response != null)
                    msg = ": "+e.response.statusText
                setError('Failed to connect the the server - please try again later or refresh the page'+msg);
            }
        })();
    },[basePath]);

    const onClickLogin = (provider) => {
        if (process.env.REACT_APP_BASE !== undefined) {
            window.location.replace(process.env.REACT_APP_BASE + "/oauth2/authorization/"+provider);
        } else {
            window.location.replace(basePath + "/oauth2/authorization/"+provider);
        }
    }

    const getLoginFromSSO = (sso) => {
        switch (sso) {
            case "Microsoft":
                return (
                    <Tooltip title="Microsoft">
                        <LoginButton variant="outlined"
                                     startIcon={<SvgMsSigninLight/>}
                                     onClick={() => onClickLogin("microsoft")}>
                        </LoginButton>
                    </Tooltip>
                );
            case "Azure":
                return (
                    <Tooltip title="Azure AD">
                        <LoginButton variant="outlined"
                                     startIcon={<SvgMsSigninLight/>}
                                     onClick={() => onClickLogin("azure")}>
                        </LoginButton>
                    </Tooltip>
                );
            case "GitHub":
                return <Tooltip title="GitHub">
                        <LoginButton variant="outlined"
                                     onClick={() => onClickLogin("github")}
                                     startIcon={<GitHubIcon/>}>
                        </LoginButton>
                    </Tooltip>
            case "Google":
                return <Tooltip title="Google">
                        <LoginButton variant="outlined"
                                     onClick={() => onClickLogin("google")}
                                     startIcon={<SvgGoogle/>}>
                        </LoginButton>
                    </Tooltip>
            case "LinkedIn":
                return <Tooltip title="LinkedIn">
                    <LoginButton variant="outlined"
                                 onClick={() => onClickLogin("linkedin")}
                                 startIcon={<LinkedInIcon color="primary" style={{ fontSize: 30 }}/>}>
                    </LoginButton>
                </Tooltip>
            default:
                return <></>
        }
    }

    return (
        <Grid container item xs={12} mt={8}>
            <Grid container direction="column" alignItems={"center"} spacing={2}>
                <Grid item>
                    <Avatar sx={{margin: 1, backgroundColor: 'primary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                </Grid>
                <Grid item>
                    <Typography variant={"h6"}>
                        Sign in using one of the authentication providers listed below
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"h6"} color="error">
                        {error}
                    </Typography>
                </Grid>
                {clients.map(sso =>
                    <Grid key={sso} item>
                        {getLoginFromSSO(sso)}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default SignIn;

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React from "react";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";

export default function ListItemLink(props) {
    return <ListItem button component={Link} {...props} style={{color: 'black'}}/>;
}

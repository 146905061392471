/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect} from 'react';
import {
    useParams, useHistory
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Checkbox from '@mui/material/Checkbox';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import AlertDialog from "../dialogs/AlertDialog";
import NameEditor from "../util/NameEditor";
import {shared, isValidPythonName} from "../util/util";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import CodeEditor from "../CodeEditor";
import {useMetrics} from "./CustomMetrics"
import DeleteIcon from "../util/TrashIcon"
import Paper from "../util/Paper";

/**
 * View of a Custom metric
 * @author Kobus Grobler
 * @component
 */
export default function CustomMetric() {
    const history = useHistory();
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    let {uuid} = useParams();
    const [metric, setMetric] = useState(null);
    const [contents, setContents] = useState("");
    const [openEditor, setOpenEditor] = useState(false);
    const [error, setError] = useState("");
    const {getMetric, updateMetric, uploadFile, downloadFile, deleteMetric} = useMetrics();

    useEffect(() => {
        (async () => {
            setMetric(await getMetric(uuid));
        })();
    }, [getMetric, uuid]);

    const onUpdateMetricName = async (_id, value) => {
        if (isValidPythonName(value)) {
            setError("");
            let oldName = metric.name;
            metric.name = value;
            metric.codebase_name = value;
            let updated = await updateMetric(uuid, metric);
            if (updated == null) {
                metric.name = oldName;
            }
        } else {
            setError("Invalid metric name");
        }
    }

    const onUpdateDescription = (_id, value) => {
        metric.description = value;
        updateMetric(uuid, metric).finally();
    }

    const editMetric = async () => {
        if (metric.codebase_name == null || metric.codebase_name.length === 0) {
            setError("Codebase name must be specified.")
            return;
        }
        setError("");
        let r = await downloadFile(uuid);
        setOpenEditor(true);
        setContents(r);
    }

    const saveCodeChanges = async () => {
        let blob = new Blob([contents], {
            type: 'text/x-python'
        });
        setMetric(await uploadFile(uuid, blob));
        setContents(contents);
        setOpenEditor(false);
    }

    const onDeleteMetricClosed = async (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            await deleteMetric(uuid);
            history.replace(history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/")));
        }
    }

    const handleTaskChange = (e) => {
        const idx = metric.tasks.indexOf(e.target.name);
        if (idx > -1) {
            metric.tasks.splice(idx, 1);
        } else {
            metric.tasks.push(e.target.name);
        }
        setMetric({...metric});
        updateMetric(uuid, metric).finally();
    }

    const metricTypeUpdate = async (val) => {
        metric.metric_type = val;
        setMetric({...metric});
        if (metric.metric_type === 'output') {
            metric.metric_type = null;
        }
        await updateMetric(uuid, metric);
    }

    return (
        <Grid container item xs={12} md={10} lg={8} xl={6} justifyContent="center" mt={2}>
            <AlertDialog open={openConfirmDelete} title="Delete metric?"
                         description="This will permanently delete the metric and its data."
                         onClose={onDeleteMetricClosed}/>
            <Grid item xs={12}>
                <Paper>
                    {metric != null &&
                        <>
                            <OkCancelDialog onClose={() => setOpenEditor(false)}
                                            dialogProps={{fullWidth: true, maxWidth: 'md'}}
                                            open={openEditor}
                                            title="Metric editor"
                                            hideOk={true}
                                            actions={<Tooltip title="Save changes">
                                                <IconButton onClick={saveCodeChanges} size="large">
                                                    <SaveIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            }>
                                <CodeEditor id={metric.uid}
                                            content={contents}
                                            onChange={setContents}
                                            onSaveChanges={saveCodeChanges}
                                />
                            </OkCancelDialog>
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs={12} container direction="column" spacing={2}>
                                    <Grid item mr={1}>
                                        <NameEditor
                                            style={{marginTop: "3px"}}
                                            fullWidth
                                            hiddenLabel={false}
                                            label="Metric Class Name"
                                            onChange={(val) => val.length === 0 || isValidPythonName(val)}
                                            name={metric.name}
                                            onUpdateName={onUpdateMetricName}/>
                                    </Grid>
                                    <Grid item mr={1}>
                                        <NameEditor variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    maxRows={4}
                                                    minRows={4}
                                                    fieldName="Description"
                                                    label="Description"
                                                    name={metric.description == null ? "" : metric.description}
                                                    onUpdateName={onUpdateDescription}/>
                                    </Grid>
                                    <Grid item ml={1}>
                                        <FormControl variant="outlined">
                                            <InputLabel>Metric Type</InputLabel>
                                            <Select
                                                value={metric.metric_type == null ? "output" : metric.metric_type}
                                                onChange={(e) => {
                                                    metricTypeUpdate(e.target.value)
                                                }}
                                                label="Metric Type">
                                                <MenuItem value="output">Output (performance)</MenuItem>
                                                <MenuItem value="input">Input (distortion)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item ml={1}>
                                        <Typography variant="subtitle1">
                                            Metric tasks:
                                        </Typography>
                                        {shared.TaskEnum.values().map((task, idx) =>
                                            <FormControlLabel key={idx}
                                                              control={
                                                                  <Checkbox
                                                                      color="primary"
                                                                      checked={metric.tasks.indexOf(task.name) > -1}
                                                                      onChange={handleTaskChange}
                                                                      name={task.name}
                                                                  />
                                                              }
                                                              label={task.displayName()}
                                            />)
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Edit metric">
                                        <IconButton
                                            size="small"
                                            onClick={editMetric}>
                                            <EditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Help">
                                        <IconButton
                                            size="small"
                                            aria-label="Help"
                                            onClick={() => window.open(
                                                process.env.REACT_APP_DOCS_BASE + "modules/reports/metrics/custom.html",
                                                '_blank')}>
                                            <HelpIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete metric">
                                        <IconButton
                                            size="small"
                                            onClick={() => setOpenConfirmDelete(true)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {error.length > 0 &&
                                    <Grid item>
                                        <Typography variant="body1" color="error">
                                            {error}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </>
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

CustomMetric.propTypes = {};

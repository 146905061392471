/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import TextField from "@mui/material/TextField";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import {OrganizationApi} from "../api";
import {checkError, errOptions} from "../util/util";
import Organization, {useOrgs} from "./Organization";
import ErrorBoundary from "../util/ErrorBoundary";
import {namedItemFilter, useFilter} from "../util/SearchBox";
import SearchAndSort from "../util/SearchAndSort";
import {sortItemsByProps, useSortBox} from "../util/SortBox";
import Paper from "../util/Paper"
import {SubmitButton} from "../util/SubmitButton";

const SORT_ITEMS = [
    {key: "name", label: "Name"},
];

/**
 * Show the list of organizations
 * @component
 * @author Kobus Grobler
 */
function Organizations(props) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [organizations, setOrganizations] = useState(null);
    const [filterProps] = useFilter();
    const [sortProps] = useSortBox(SORT_ITEMS[0].key);
    const api = new OrganizationApi(props.conf);
    const {getOrgs} = useOrgs();

    useEffect(() => {
        getOrgs().then((orgs) => {
            if (orgs != null) {
                setOrganizations(orgs.sort((a, b) => a.name.localeCompare(b.name)));
            }
        });
    }, [getOrgs]);

    const onAddOrg = () => {
        if (name.length === 0) {
            setNameError("Organisation name cannot be empty");
            return;
        }
        setNameError("");
        api.addOrganization({name: name})
            .then((org) => {
                setOrganizations([...organizations, org.data]);
                setName("");
            })
            .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to add organisation: ' + e.statusText, errOptions)));
    }

    const onUpdateOrganization = async (orgIn, rq) => {
        try {
            let org = await api.updateOrganization(orgIn.id, rq);
            setOrganizations(organizations.map((item) => {
                if (org.data.id === item.id) {
                    return {...org.data};
                }
                return item;
            }));
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to update organization', errOptions));
        }
    }

    const onOrgCopied = (org) => {
        setOrganizations([org, ...organizations]);
    }

    const onDeleteOrg = (org) => {
        api.deleteOrganization(org.id)
            .then(() => {
                setOrganizations(organizations.filter((item) => {
                    return org.id !== item.id;
                }));
            })
            .catch((e) => checkError(e, history, () => {
                enqueueSnackbar('Failed to delete organization', errOptions);
            }));
    }

    const sortItems = (a, b) => {
        return sortItemsByProps(a, b, sortProps);
    }

    const inFilter = (item) => {
        return namedItemFilter(filterProps.filter, item);
    }

    return (<ErrorBoundary>
            <Grid container item xs={12} lg={5} spacing={1}>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container item spacing={1} xs={12} justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="h6">Add New Organisation</Typography>
                                <TextField value={name}
                                           fullWidth
                                           error={nameError.length > 0}
                                           helperText={nameError}
                                           label="Organisation Name"
                                           onChange={(event) => setName(event.target.value)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <SubmitButton
                                    title="Add"
                                    onClick={onAddOrg}
                                    startIcon={<AddIcon/>}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item>
                    <Paper>
                        <Typography variant="h6">Organisations: {organizations == null ? "" : organizations.length}</Typography>
                        <SearchAndSort sortItems={SORT_ITEMS} sortProps={sortProps} filterProps={filterProps}/>
                        <Grid style={{marginTop: "1em"}} container item xs={12} spacing={1}>
                            {organizations != null && organizations.filter(inFilter).sort(sortItems).map((organization) =>
                                <Grid item key={organization.id}><Organization
                                    onOrgCopied={onOrgCopied}
                                    organization={organization}
                                    onUpdateOrganization={onUpdateOrganization}
                                    onDeleteOrg={onDeleteOrg}/></Grid>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </ErrorBoundary>
    );
}

Organizations.propTypes = {
    /**
     * The API configuration
     */
    conf: PropTypes.any.isRequired,
};

export default Organizations;

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import React, {useEffect, useState} from "react";
import {getLogger, mergeDefaultsWithUserParams, shared} from "../util/util";
import {useMetrics} from "../metrics/CustomMetrics";
import Paper from "../util/Paper";

const log = getLogger("metrics");

const findMetricDef= (metric, defs) => {
    if (metric.uid != null) { // custom metric has uid
        return defs.find(a => a.uid === metric.uid);
    }
    return defs.find(a => a.name === metric.name);
}

/**
 * Component to enable metric selection
 * @param task
 * @param metrics
 * @param settings
 * @param saveSettings
 * @param onSetParams
 * @returns {JSX.Element}
 * @author Kobus Grobler
 */
export default function MetricSelection({task, metricDefs, settings, saveSettings, onSetParams}) {
    const {getMetrics} = useMetrics();
    const [customMetrics, setCustomMetrics] = useState(null);
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        getMetrics().then((ms) => {
            if (ms != null) {
                setCustomMetrics(ms.filter(m => m.tasks == null || m.tasks.includes(task)));
            }
        });
    }, [getMetrics, task]);

    useEffect(() => {
        if (customMetrics != null) {
            let fms = metricDefs.filter(m => (m.tasks == null || m.tasks.includes(task)) && m.enabled);
            fms.push(...customMetrics);
            setMetrics(fms);
            let updated = false;

            if (settings.metrics == null) {
                log.debug("Creating new metric settings");
                settings.metrics = {};
                updated = true;
            }

            if (settings.metrics[task] == null) {
                log.debug("Creating new metric settings for "+task);
                settings.metrics[task] = [];
                updated = true;
            }
            fms.forEach(m => {
                // add new metrics defined but not yet in settings
                // happens when metrics.json updated with new metric but project previously created
                if (!settings.metrics[task].find(sm => sm.name === m.name)) {
                    log.debug("creating metric default for " + m.name);
                    let enabled = true;
                    if (m.uid != null) {  // custom
                        enabled = false;
                    }
                    settings.metrics[task].push(
                        {"name": m.name,
                            "uid": m.uid,
                            "enabled": enabled,
                            "params": mergeDefaultsWithUserParams(m.params, {})
                        });
                    updated = true;
                }
            });

            settings.metrics[task].forEach((m, idx, array) => {
                let f = findMetricDef(m, fms);
                if (f == null) {
                    log.debug(`Removing metric ${m.name} from settings - not found.`);
                    updated = true;
                    array.splice(idx, 1);
                }
            });

            if (updated) {
                saveSettings(settings);
            }
        }
    },[customMetrics, metricDefs, settings, saveSettings, task]);

    const metricToggle = (metric) => {
        metric.enabled = !metric.enabled;
        saveSettings(settings);
    }

    return <>
        <Paper variant="outlined">
            <Typography variant='h6' style={{textAlign: "center"}}>{shared.TaskEnum.valueOf(task).displayName()} Metrics</Typography>
            <Grid item xs={12} container alignItems="flex-start">
                {settings.metrics != null && settings.metrics[task] != null && settings.metrics[task].map(metric =>
                    <span key={metric.name}>
                                    {findMetricDef(metric, metrics) != null && findMetricDef(metric, metrics).params.length !== 0 &&
                                        // don't display if it has no params to tune - or metric def not found
                                        <IconButton
                                            size="small"
                                            onClick={() => onSetParams(findMetricDef(metric, metrics), metric.params, task)}>
                                            <TuneIcon/>
                                        </IconButton>
                                    }

                        <FormControlLabel
                            control={
                                <Switch
                                    name={metric.name}
                                    color="primary"
                                    onChange={() => metricToggle(metric)}
                                    checked={metric.enabled}
                                />}
                            label={metric.name}
                        />
                    </span>
                )}
            </Grid>
        </Paper>
    </>
}

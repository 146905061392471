/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, {useCallback, useContext, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from "@mui/material/Tooltip";
import {useHistory, useParams} from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import IconButton from "@mui/material/IconButton";
import {useSnackbar} from "notistack";
import {getLogger} from "../util/util";
import AlertDialog from "../dialogs/AlertDialog";
import ProjectSetupWizard from "./ProjectSetupWizard";
import ProjectSettings from "./ProjectSettings";
import LogView from "../LogView";
import ErrorBoundary from "../util/ErrorBoundary"
import Test, {isTestBusy, StartStopProgress} from '../aitests/Test';
import NameEditor from "../util/NameEditor";
import DeleteIcon from "../util/TrashIcon"

import {
    useLoadResources,
    TimeRunningOutIndicator,
    isTimeAvailable,
    getTimeOutMessage
} from "../organizations/Organization";
import {useServerUpdates} from "../ServerUpdates";
import AppContext from "../AppContext";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import SelectTestProfile from "../profiles/SelectTestProfile";
import {useModel} from "../models/Model";
import {useLoadProfiles, useProfiles} from "../profiles/TestProfiles";
import {useTests} from "../aitests/Test"
import {useProjects} from "./Projects";
import {ModelRequestFrameworkTypeEnum} from "../api";
import {SubmitButton} from "../util/SubmitButton";

const log = getLogger("project");

const newProfile = {id: 0,
    name: "New Test",
    settings: {defenses: []},
    description: "Create a test with no pre-configured settings."
};

/**
 * Displays and allows configuration of an ASA project
 * @author Kobus Grobler
 * @component
 */
export default function Project() {
    const {conf} = useContext(AppContext);
    const history = useHistory();
    const {addTest, deleteTest, stopTest, updateTest} = useTests();
    const {enqueueSnackbar} = useSnackbar();
    const [project, setProject] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openConfirmDeleteProject, setOpenConfirmDeleteProject] = useState(false);
    const [openAddTestDlg, setOpenAddTestDlg] = useState(false);
    const [profile, setProfile] = useState(null);
    const [testToUpdate, setTestToUpdate] = useState(0);
    const {projectId} = useParams();
    const [configuring, setConfiguring] = useState(false);
    const [resources, loadResourcesByOrgId] = useLoadResources();
    const {getTests, startTest} = useTests();
    const {getProject, deleteProject} = useProjects();
    const [tests, setTests] = useState([]);
    const [busyTests, setBusyTests] = useState(new Map()); // dict of test id to testRuns
    const [{model, updateModel, updateFrameworkType, updateEnvironment}, setModelId] = useModel();
    const [profiles, setProfiles] = useLoadProfiles();
    const {updateProfile} = useProfiles();

    const [openConfirmUpdateProfileDlg, setOpenConfirmUpdateProfileDlg] =  useState(false);

    const onBusyTestRuns = useCallback((runs) => {
        if (tests != null) {
            tests.forEach(test => {
                let pre = 0;
                let copy = busyTests.get(test.id);
                if (copy != null) {
                    pre = copy.testRuns.length;
                }
                copy = {...test}
                copy.testRuns = runs.reduce((acc, r) => {
                    if (r.testId === test.id) {
                        acc.push(r);
                    }
                    return acc;
                }, []);
                busyTests.set(test.id, copy);
                if (copy.testRuns.length !== pre) {
                    testUpdated(copy);
                }
            });
            setBusyTests(new Map(busyTests));
        }
    },[tests]);

    useServerUpdates('/topic/testruns/busy', onBusyTestRuns);

    useEffect(() => {
        getProject(projectId).then(prj => {
            setProject(prj);
            setConfiguring(!prj.verified);
            getTests(prj.id).then(t => {
                if (t != null) {
                    setTests(t);
                }
            });
        });
    }, [projectId, getProject, getTests]);

    useEffect( () => {
        if (project != null) {
            setModelId(project.modelId);
            loadResourcesByOrgId(project.organization.id).finally();
        }
    }, [project, loadResourcesByOrgId, setModelId]);

    useEffect(() => {
        const id = setInterval( () => {
            if (project != null && !project.verified) {
                getProject(project.id, true).then(p => setProject(p))
                    .catch(_e => {}); // logged in getProject
            }
        }, 1000);
        return () => clearInterval(id);
    }, [project, getProject]);

    useEffect(() => {
        if (profiles != null && project != null && project.settings != null && project.settings.tasks != null) {
            let profs = profiles.filter(p => (p.settings.tasks == null) || p.settings.tasks.find(t => t === project.settings.tasks[0]));
            if (profs.length === 0) {
                profs.push(newProfile);
                setProfiles(profs);
            } else {
                if (profs[0].id !== 0) {
                    setProfiles([newProfile, ...profs]);
                }
            }
        }
    },[profiles, projectId, project, setProfiles]);

    const handleExpansion = (expanded, test) => {
        setTests(tests.map((item) => {
            if (test.id === item.id) {
                test.expanded = expanded;
                return {...test};
            }
            return item;
        }));
    };

    const testUpdated = (test) => {
        log.debug("Test updated.");
        setTests(tests.map((item) => {
            if (test.id === item.id) {
                if (test.expanded === undefined) {
                    test.expanded = item.expanded; // otherwise we loose the expanded state (and therefore state updates)
                }
                if (test.configure === undefined) {
                    test.configure = item.configure;
                }
                return {...test};
            }
            return item;
        }));
    }

    const onUpdateTestName = async (id, name) => {
        let r = await updateTest(id, {name: name});
        if (r != null)
            testUpdated(r);
    }

    const askDeleteTest = (id) => {
        setTestToUpdate(id);
        setOpenConfirmDelete(true);
    }

    const onDeleteTest = async (result) => {
        setOpenConfirmDelete(false);
        if (result) {
            await deleteTest(testToUpdate);
            setTests(tests.filter((item) => {
                return testToUpdate !== item.id;
            }));
        }
    }

    function isProjectValid(prj) {
        return !!(prj.verified && prj.settings != null && prj.settings.headSettings != null);
    }

    const onWizardFinish = () => {
        setConfiguring(false);
    }

    const projectUpdated = (p) => {
        log.debug("Project updated.");
        setProject(p);
    }

    const onStartStopClick = async (event, test) => {
        event.stopPropagation();
        if (isTestBusy(test)) {
            await stopTest(test.id);
        } else {
            if (!isTimeAvailable(project.organization)) {
                getTimeOutMessage(project.organization);
                enqueueSnackbar(getTimeOutMessage(project.organization),
                    {autoHideDuration: 5000, variant: 'error'});
                return;
            }
            await startTest(test.id);
            enqueueSnackbar('Started test #' + test.id);
        }
    }

    const onTestWizardFinish = (test) => {
        log.debug("Test wizard done.");
        test.configure = false;
        testUpdated(test);
        if (profiles.find(p => p.id !== 0 && p.name === test.name)) {
            setTestToUpdate(test.id);
            setOpenConfirmUpdateProfileDlg(true);
        }
    }

    const onDeleteProject = (result) => {
        setOpenConfirmDeleteProject(false);
        if (result) {
            deleteProject(project.id).then( () => {
                history.replace(history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/")));
                enqueueSnackbar('Project deleted.');
            });
        }
    }

    const onAddTest = async () => {
        let t = await addTest(project, profile.id, profile.name);
        if (t != null) {
            setTests([t, ...tests]);
        }
    }

    const onAddDetectionTest = async () => {
        let t = await addTest(project, null, "New detection test", "Detection");
        if (t != null) {
            setTests([t, ...tests]);
        }
    }

    const onAddCraftedTest = async () => {
        let t = await addTest(project, null, "New crafted attack", "Crafted");
        if (t != null) {
            setTests([t, ...tests]);
        }
    }

    const handleCloseAddTest = (accepted) => {
        setOpenAddTestDlg(false);
        if (accepted && profile != null) {
            onAddTest().finally();
        }
    }

    const onProfileSelected = (prof) => {
        setProfile(prof);
    }

    const onFrameworkTypeUpdated = (frameworkType) => {
        updateFrameworkType(frameworkType).finally(() => project.verified = false);
    }

    const onModelUpdated = (rq) => {
        updateModel(rq);
    }

    const onEnvironmentUpdated = (environment) => {
        updateEnvironment(environment).finally(() => project.verified = false);
    }

    const handleCloseUpdateProfile = (accepted) => {
        setOpenConfirmUpdateProfileDlg(false);
        if (accepted) {
            let test = tests.find(t => t.id === testToUpdate);
            let prof = profiles.find(p => p.id !== 0 && p.name === test.name);
            if (prof != null) {
                let updatedSettings = test.testSettings;
                updatedSettings.tasks = prof.settings.tasks;
                updatedSettings.defenses = test.defenses;
                updateProfile(prof.id, {settings: updatedSettings}).finally();
                enqueueSnackbar('Profile updated.');
            }
        }
    }

    return <ErrorBoundary>
        {project != null && project.settings != null && project.settings.tasks != null &&
            <OkCancelDialog onClose={handleCloseAddTest}
                            dialogProps={{fullWidth: true, maxWidth: 'md'}}
                            open={openAddTestDlg}
                            title="Add new test">
                <SelectTestProfile onItemClicked={onProfileSelected}
                                   profiles={profiles}
                                   tasks={project.settings.tasks}
                                   resources={resources}/>
            </OkCancelDialog>
        }
        <AlertDialog open={openConfirmDeleteProject} title="Delete project?"
                     description="This will permanently delete the project and its data."
                     onClose={onDeleteProject}/>

        <AlertDialog onClose={handleCloseUpdateProfile}
                     open={openConfirmUpdateProfileDlg}
                     title="Update test profile?"
                     description={"Update the existing test profile from this test configuration."}>
        </AlertDialog>

        {project != null && resources.loaded &&
        <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
                <IconButton
                    size="small"
                    onFocus={(event) => event.stopPropagation()}
                    onClick={(event) => {
                        event.stopPropagation();
                        setOpenConfirmDeleteProject(true);
                    }}>
                    <DeleteIcon/>
                </IconButton>
                <Tooltip title="Change project configuration">
                    <IconButton
                        edge="start"
                        size="small"
                        onClick={() => {
                            setConfiguring(true);
                        }}>
                        <SettingsIcon/>
                    </IconButton>
                </Tooltip>
                <Typography style={{verticalAlign: "bottom"}} component={"span"} variant="h6">Project <b>{project.name}</b></Typography>
            </Grid>
            {(configuring || !isProjectValid(project)) ? <>
            <Grid item xs={12}>
                <ProjectSetupWizard
                    conf={conf}
                    project={project}
                    assets={resources}
                    model={model}
                    onFrameworkTypeUpdated={onFrameworkTypeUpdated}
                    onEnvironmentUpdated={onEnvironmentUpdated}
                    onModelUpdated={onModelUpdated}
                    projectUpdated={projectUpdated}
                    onFinish={onWizardFinish}
                    step={isProjectValid(project) ? 3 : 0}
                />
            </Grid>
            <Grid item xs={12}>
                <LogView logs={project.message}/>
            </Grid></>
            :
            <Grid item>
                <ProjectSettings project={project}
                                 projectUpdated={projectUpdated}
                                 conf={conf}/>
            </Grid>
        }
        {isProjectValid(project) && !configuring &&
        <>
            <Grid item xs={12}>
                <SubmitButton title="Configure Project"
                              onClick={() => setConfiguring(true)}
                              startIcon={<SettingsIcon/>}/>
                <SubmitButton
                    title="Add Test"
                    onClick={() => setOpenAddTestDlg(true)}
                    startIcon={<AddIcon/>}/>
                {false && project.settings != null && project.settings.tasks != null
                    && project.model.frameworkType === ModelRequestFrameworkTypeEnum.PyTorch
                    && (project.settings.tasks.includes('classification')) &&
                    <SubmitButton
                        title="Add Data Poisoning Detection Test"
                        onClick={onAddDetectionTest}
                        startIcon={<AddIcon/>}/>
                }
                {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                    <SubmitButton
                        title="Craft Attack"
                        onClick={onAddCraftedTest}
                        startIcon={<AddIcon/>}/>
                }
            </Grid>

            <Grid item xs={12}>
                <TimeRunningOutIndicator organization={project.organization}/>
            </Grid>
            <AlertDialog open={openConfirmDelete} title="Delete test?"
                         description="This will permanently delete the test and its data."
                         onClose={onDeleteTest}/>

            {tests !== null && tests.sort((a,b) => b.id-a.id).map((test) =>
                <Grid key={test.id} item xs={12}>
                    <Accordion key={test.id} expanded={test.expanded === undefined ? false : test.expanded}
                               TransitionProps={{ unmountOnExit: true }}
                               onChange={(_event, expanded) =>
                        handleExpansion(expanded, test)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            <IconButton
                                size="small"
                                onFocus={(event) => event.stopPropagation()}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    askDeleteTest(test.id)
                                }}>
                                <DeleteIcon/>
                            </IconButton>
                            <Tooltip title="Change test configuration">
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        test.configure = true;
                                        handleExpansion(true, test);
                                    }}>
                                    <SettingsIcon/>
                                </IconButton>
                            </Tooltip>
                            <span style={{
                                flexBasis: '33.33%',
                                paddingTop: '5px',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                display: 'inline-flex'}}>
                            <Typography sx={{marginLeft: 1, whiteSpace: 'nowrap'}}
                                        variant={"body1"}>Test {test.id}</Typography>
                                <NameEditor
                                    title="Test Name"
                                    name={test.name}
                                    style={{minWidth: "20ch"}}
                                    fullWidth
                                    id={test.id}
                                    onUpdateName={onUpdateTestName}/>
                            </span>
                            {test.testSettings.configured && busyTests.get(test.id) != null &&
                                <span style={{marginLeft: "2em", display: 'inline-flex'}}>
                                <StartStopProgress compact status={busyTests.get(test.id)} onClick={(e) => onStartStopClick(e, test)}/>
                            </span>
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <Test test={test}
                                  showWizard={test.configure || !test.testSettings.configured}
                                  onWizardFinish={() => onTestWizardFinish(test)}
                                  project={project}
                                  testUpdated={testUpdated}
                                  assets={resources}/>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
        </>
        }
    </Grid>
        }
    </ErrorBoundary>
}

Project.propTypes = {
};

import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DownwardIcon from '@mui/icons-material/ArrowDownward';
import UpwardIcon from '@mui/icons-material/ArrowUpward';
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";

const useSortBox = (initialKey, initialUp=false) => {
    const [sortKey, setSortKey] = useState(initialKey);
    const [sortDir, setSortDir] = useState(initialUp);
    return [{sortKey, sortDir, onChange: val => setSortKey(val), onDirClick: () => setSortDir(!sortDir)}];
}

const sortItemsByProps = (a, b, sortProps) => {
    let r;
    if (isNaN(a[sortProps.sortKey])) {
        r = a[sortProps.sortKey].localeCompare(b[sortProps.sortKey]);
    } else {
        r = a[sortProps.sortKey]-b[sortProps.sortKey];
    }
    if (sortProps.sortDir) {
        r *= -1;
    }
    return r;
}

/**
 * Utility component to show sort options
 * @param sortItems
 * @param sortKey
 * @param onChange
 * @param sortDir
 * @param onDirClick
 * @param style
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
export default function SortBox({sortItems, sortKey, onChange, sortDir, onDirClick, style}) {
    return <>
        <span style={{display: 'inline-flex', ...style}}>
        <IconButton style={{marginTop: "20px", marginRight: "2px"}}
            size="small"
            onClick={(_event) => {
                onDirClick()
            }}>
            {sortDir ? <UpwardIcon/> : <DownwardIcon/>}
        </IconButton>
        <FormControl component="fieldset">
            <FormLabel component="legend">Sort By</FormLabel>
            <RadioGroup aria-label="sort" name="sort" value={sortKey} onChange={(e) => onChange(e.target.value)}>
                <span>
                    {sortItems.map((item) =>
                        <FormControlLabel key={item.key} value={item.key} control={<Radio color="primary"/>}
                                                                                 label={item.label}/>
                    )}
                </span>
            </RadioGroup>
        </FormControl>
        </span>
    </>
}

export {useSortBox, sortItemsByProps}
/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import {useSnackbar} from "notistack";
import {Configuration, ProjectApi} from "../api";
import {checkError, errOptions, getLogger} from "../util/util";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useModel} from "./Model";

const log = getLogger("modelcopy");

/**
 * View to copy/update models to org level.
 *
 * @author Kobus Grobler
 * @component
 */
export default function ModelCopyUpdate({conf, project, open, onClose, dialogProps}) {
    const history = useHistory();
    const [{getModels}] = useModel();
    const {enqueueSnackbar} = useSnackbar();
    const [models, setModels] = useState(null);
    const [opType, setOpType] = useState("new");
    const [selectedModelId, setSelectedModelId] = useState(0);
    const [name, setName] = useState("");
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        async function getFirstModelId() {
            const r = await getModels();
            setModels(r);
            if (r != null && r.length > 0) {
                setSelectedModelId(r[0].id);
            } else {
                log.debug("Model list is empty.");
            }
        }
        if (open) {
            getFirstModelId();
            setClosed(false);
        }
    }, [open, getModels]);

    const handleOk = async () => {
        if (closed)
            return;
        let destModel;
        if (opType === "new") {
            if (name.length === 0)
                return;
        } else {
            destModel =  selectedModelId;
        }

        setClosed(true); // to prevent multiple click events while copying
        const projectApi = new ProjectApi(conf);
        try {
            enqueueSnackbar('Model copy in progress...');
            await projectApi.copyProjectModel(project.id, {name: name, description: ""}, destModel);
            enqueueSnackbar('Model copied successfully.');
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to copy model', errOptions));
        }
        onClose();
    }

    return <Dialog onClose={onClose} open={open} {...dialogProps}>
        <DialogTitle>Make this model available to other projects</DialogTitle>
        <Grid container justifyContent={"center"}>
        <Grid item xs={6}>
            <FormControl component="fieldset">
                <RadioGroup aria-label="copy or update" value={opType}
                            onChange={(e) => setOpType(e.target.value)}>
                    <FormControlLabel color="primary" value="new" control={<Radio color="primary" />}
                                      label="Make a new copy of the model" />
                    <TextField value={name}
                               fullWidth
                               label="Model name"
                               onChange={(event) =>
                                   setName(event.target.value)}/>
                    <FormControlLabel value="update"
                                      disabled={models == null || models.length === 0}
                                      control={<Radio color="primary" />}
                                      label="Update an existing model with this one" />
                    {models != null && models.length > 0 &&
                        <>
                            <TextField style={{minWidth: "15ch"}}
                                       select
                                       SelectProps={{
                                           native: true,
                                       }}
                                       label="Existing Model"
                                       value={selectedModelId}
                                       onChange={(e) =>
                                           setSelectedModelId(e.target.value)}>
                                {models.map((model) =>
                                    <option key={model.id} value={model.id}>
                                        {model.name}
                                    </option>
                                )}
                            </TextField>
                        </>
                    }
                </RadioGroup>
            </FormControl>
        </Grid>
    </Grid>
    <DialogActions>
        <Button onClick={onClose} color="primary">
            Cancel
        </Button>
        <Button onClick={handleOk} color="primary" disabled={closed}>
            OK
        </Button>
    </DialogActions>
    </Dialog>
}

ModelCopyUpdate.propTypes = {
    /**
     * The API configuration
     */
    conf: PropTypes.instanceOf(Configuration).isRequired,

    /**
     * Open or close the dialog
     */
    open: PropTypes.bool.isRequired,

    /**
     * Called when the dialog is closed
     */
    onClose: PropTypes.func.isRequired,

    /**
     * Project instance
     */
    project: PropTypes.object.isRequired,

};

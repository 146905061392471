/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect} from 'react';
import {
    useRouteMatch
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import ListItemText from "@mui/material/ListItemText";
import ListItemLink from "../util/ListItemLink";
import ProjectTypeIndicator from "../projects/ProjectTypeIndicator"
import ModelTypeIndicator from "./ModelTypeIndicator";
import {useModel} from "./Model";
import List from "@mui/material/List";
import {AddProjectBlock} from "../projects/Projects";
import {namedItemFilter, useFilter} from "../util/SearchBox";
import {useSortBox} from "../util/SortBox";
import SearchAndSort from "../util/SearchAndSort";

const SORT_ITEMS = [
    {key: "name", label: "Name"},
    {key: "tasks", label: "Task"},
];

/**
 * Retrieves and shows the list of models
 * @returns {JSX.Element}
 * @constructor
 */
export default function Models() {
    const match = useRouteMatch();

    const [models, setModels] = useState(null);
    const [filterProps] = useFilter();
    const [sortProps] = useSortBox(SORT_ITEMS[0].key);
    const [{getModels}] = useModel();

    useEffect(() => {
        (async () => {
            setModels(await getModels());
        })();
    }, [getModels]);

    const inFilter = (item) => {
        return namedItemFilter(filterProps.filter, item) ||
            item.tasks.find(t => t.startsWith(filterProps.filter));
    }

    const sortItems = (a, b) => {
        let r;
        if (sortProps.sortKey === SORT_ITEMS[0].key) {
            r = a.name.localeCompare(b.name);
        } else {
            r = a.tasks[0].localeCompare(b.tasks[0]);
        }
        if (sortProps.sortDir) {
            r *= -1;
        }
        return r;
    }

    return <Grid container item xs={12} md={10} lg={8} xl={6} justifyContent={"center"}>
        <Grid item>
            <AddProjectBlock buttonText="Add New Model"/>
        </Grid>
        <Grid item xs={12}>
            {models !== null &&
                <>
                    {models.length === 0 &&
                        <Typography variant="subtitle1" style={{textAlign: "center"}}>
                            There are no models for this organisation
                            - use an existing project and make the model available to the organisation.
                        </Typography>
                    }
                    {models.length > 0 &&
                        <>
                            <Typography variant="h6" style={{textAlign: "center"}}>
                                Configured models
                            </Typography>
                            <SearchAndSort sortItems={SORT_ITEMS} sortProps={sortProps} filterProps={filterProps}/>
                            <List>
                            {models.filter(inFilter).sort(sortItems).map((model) =>
                                <Accordion key={model.id}>
                                <ListItemLink href={`#${match.url}/${model.id}`}>
                                    <ProjectTypeIndicator tasks={model.tasks} mr={1}/>
                                    <ModelTypeIndicator model={model} verified mr={1}/>
                                    <ListItemText primary={`${model.organization.name +" - "+model.name}`}/>
                                </ListItemLink>
                                </Accordion>
                            )}
                            </List>
                        </>
                    }
                </>
            }
        </Grid>
    </Grid>
}

Models.propTypes = {

};

/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useHistory} from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import HelpIcon from "@mui/icons-material/HelpOutline";
import {getLogger, checkError, errOptions, convertUserValuesToParams, validateAllParams} from "../util/util";
import {Configuration, DatasetsApi} from "../api";
import UploadFile from "../UploadFile";
import TransformSelection from "../transforms/TransformSelection";
import ParamEntryList from "../ParamEntryList";
import {useDatasets} from "./Datasets";
import Paper from "../util/Paper";
import "./CustomDataset.css"
import {SubmitButton} from "../util/SubmitButton";

const log = getLogger("dataset");

/**
 * Allows configuration of a custom dataset
 * @author Kobus Grobler
 * @component
 */
export default function CustomDataset({organization, assets, datasetFormats = assets.datasetFormats, dataset, conf,
                                          onDatasetsUpdated, ...props}) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {uploadDatasetFile, getDataset} = useDatasets();

    const [enableDatasetUpload, setEnableDatasetUpload] = useState(true);
    const [progress, setProgress] = useState(0);
    const [busy, setBusy] = useState(false);
    const [progressVariant, setProgressVariant] = useState("determinate");
    const [datasetName, setDatasetName] = useState(() => dataset === undefined ? "" : dataset.name);
    const [datasetDescription, setDatasetDescription] = useState(() => dataset === undefined ? "" : dataset.description);
    const [datasetError, setDatasetError] = useState(() => dataset === undefined ? null : dataset.error);
    const [nameError, setNameError] = useState("");
    const [transforms, setTransforms] = useState(() => dataset === undefined ? [] : dataset.transforms);
    const [datasetFormat, setDatasetFormat] = useState(null);
    const [datasetFormatDef, setDatasetFormatDef] = useState(null);
    const [datasetSettings] = useState(() =>
        dataset === undefined ? {} : dataset.settings);
    const [values, setValues] = useState(null);
    const [errors, setErrors] = useState(null);
    const [validate, setValidate] = useState(false);

    useEffect(() => {
        setDatasetFormat(dataset === undefined ? datasetFormats[0].name : dataset.format);
    },[organization.id]);

    useEffect(() => {
        let dsdef = datasetFormats.find( ds => ds.name === datasetFormat);
        setDatasetFormatDef(dsdef);
    },[datasetFormat]);

    function onUploadProgress(evt) {
        if (evt.loaded > 0) {
            setProgressVariant("determinate");
            if (evt.total > 0) {
                setProgress((evt.loaded / evt.total) * 100);
            }
        } else {
            setProgressVariant("");
        }
    }

    function validateInput() {
        if (datasetName.length < 1) {
            setNameError("Please enter a valid dataset name");
            return false;
        }
        setNameError("");
        if (datasetDescription.length < 1) {
            enqueueSnackbar('Invalid dataset description');
            return false;
        }

        setValidate(true);
        const [msg, index] = validateAllParams(datasetFormatDef.params, values);
        if (msg !== "") {
            let msgs = new Array(values.length).fill("");
            msgs[index] = msg;
            setErrors(msgs);
            return false;
        }

        return true;
    }

    const onAddExternal = async () => {
        if (!validateInput()) {
            return;
        }
        datasetSettings.params = convertUserValuesToParams(values, datasetFormatDef.params);
        setBusy(true);
        const dsApi = new DatasetsApi(conf);
        try {
            let rs = await dsApi.addExternalDataset(organization.id, datasetName,
                datasetDescription, datasetFormat );
            let ds = rs.data;
            let us = await dsApi.updateCustomDataset(organization.id,ds.id,
                {description: datasetDescription,
                    format: datasetFormat,
                    name: datasetName,
                    settings: datasetSettings,
                    transforms: transforms});
            let newdataset = us.data.find( item => item.id === ds.id);
            onDatasetsUpdated(us.data, newdataset);
            if (newdataset.valid) {
                enqueueSnackbar('Dataset added.');
            } else {
                enqueueSnackbar('Dataset added, but not not valid.');
            }
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to add dataset'+msg,
                    errOptions));
        } finally {
            setBusy(false);
            setProgress(0);
        }
    };

    const onUploadNew = async (file) => {
        if (!validateInput()) {
            return;
        }
        datasetSettings.params = convertUserValuesToParams(values, datasetFormatDef.params);
        setBusy(true);
        const dsApi = new DatasetsApi(conf);
        try {
            let rs = await dsApi.uploadDataset(organization.id, datasetName, datasetDescription, datasetFormat,
                file, {onUploadProgress: onUploadProgress});
            setEnableDatasetUpload(false);
            setProgressVariant("indeterminate");
            let ds = rs.data.find( ds => ds.name === datasetName && ds.description === datasetDescription)
            let us = await dsApi.updateCustomDataset(organization.id,ds.id,
                {description: datasetDescription,
                    format: datasetFormat,
                    name: datasetName,
                    settings: datasetSettings,
                    transforms: transforms});
            let newdataset = us.data.find( item => item.id === ds.id);
            onDatasetsUpdated(us.data, newdataset);
            if (newdataset.valid) {
                enqueueSnackbar('Dataset uploaded.');
            } else {
                enqueueSnackbar('Dataset uploaded, but not not valid.');
            }
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to upload data'+msg,
                    errOptions));
        } finally {
            setBusy(false);
            setProgress(0);
        }
    };

    const onUpload = async (file) => {
        setBusy(true);
        try {
            let r = await uploadDatasetFile(organization.id, dataset.id,"dataset",
                file, onUploadProgress);
            if (r != null) {
                setEnableDatasetUpload(false);
                setProgress(0);
                enqueueSnackbar('Upload succeeded.');
                if (props.onDatasetUpdated != null) {
                    let ds = await getDataset(organization.id, dataset.id);
                    if (ds != null)
                        props.onDatasetUpdated(ds);
                }
            }
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to upload data',
                    errOptions));
        } finally {
            setBusy(false);
        }
    };

    const saveChanges = async () => {
        if (!validateInput()) {
            return;
        }
        datasetSettings.params = convertUserValuesToParams(values, datasetFormatDef.params);
        setBusy(true);
        const dsApi = new DatasetsApi(conf);

        try {
            log.debug(`Updating DS ${dataset.id}`);
            let d = await dsApi.updateCustomDataset(organization.id,dataset.id,
                {description: datasetDescription,
                    format: datasetFormat,
                    name: datasetName,
                    settings: datasetSettings,
                    transforms: transforms});

            let updatedDS = d.data.find(ds => ds.id === dataset.id);
            if (updatedDS && updatedDS.valid) {
                enqueueSnackbar('Dataset updated.');
                onDatasetsUpdated(d.data, updatedDS);
            } else {
                log.debug(`DS not valid: ${updatedDS.error}`);
                setDatasetError(updatedDS.error);
            }
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to update dataset',
                    errOptions));
        } finally {
            setBusy(false);
        }
    }

    const onTransformsUpdated = (newTransforms) => {
        setTransforms(newTransforms);
    }

    const onFormatChange = (e) => {
        setDatasetFormat(e.target.value);
    }

    async function onFileUpload(param, name, file) {
        setBusy(true);
        let t = await uploadDatasetFile(organization.id, dataset.id, "transform", file, undefined);
        if (t != null) {
            enqueueSnackbar('Upload completed.');
        }
        setBusy(false);
        return t;
    }

    const onParamsChanged = (vals) => {
        setValues(vals);
    }

    return (<Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Grid container justifyContent="flex-start">
                        <Grid container md={4} spacing={1} justifyContent="flex-start" alignItems="flex-start" item>
                            <Grid item xs={12} container mr={2}>
                                    <Grid item xs><TextField value={datasetName}
                                               fullWidth
                                           className="textField"
                                           error={nameError.length > 0}
                                           helperText={nameError}
                                           label="Dataset Name"
                                           onChange={(event) => setDatasetName(event.target.value)}/></Grid>
                                <Grid item xs={1}><IconButton
                                    color="primary"
                                    onClick={() => window.open(
                                        process.env.REACT_APP_DOCS_BASE + "modules/dataset_formats/overview.html",
                                        '_blank')}
                                    aria-label="Help">
                                    <HelpIcon/>
                                </IconButton></Grid>
                            </Grid>
                            <Grid item xs={12} mr={1}>
                                <TextField value={datasetDescription}
                                           fullWidth
                                           className="textField"
                                           label="Dataset description"
                                           onChange={(event) => setDatasetDescription(event.target.value)}/>
                            </Grid>
                            <Grid item xs={12} container mr={2}>
                                <Grid item xs>{datasetFormat != null &&
                                    <TextField select
                                               fullWidth
                                               className="textField"
                                               SelectProps={{
                                                   native: true,
                                               }}
                                               label="Dataset format"
                                               value={datasetFormat}
                                               onChange={onFormatChange}>
                                        {datasetFormats.map((fmt) =>
                                            <option key={fmt.name} value={fmt.name}>
                                                {fmt.name}
                                            </option>
                                        )}
                                    </TextField>
                                }</Grid>
                                <Grid item xs={1}><IconButton
                                    color="primary"
                                    onClick={() => window.open(
                                        process.env.REACT_APP_DOCS_BASE + "modules/dataset_formats/index.html",
                                        '_blank')}
                                    aria-label="Help">
                                    <HelpIcon/>
                                </IconButton></Grid>
                            </Grid>
                            <Grid item xs={12} mr={1}>
                                {datasetFormatDef != null &&
                                    <ParamEntryList onFileUpload={onFileUpload}
                                                    paramDef={datasetFormatDef}
                                                    onValuesChanged={onParamsChanged}
                                                    valdate={validate}
                                                    errors={errors}
                                                    params={dataset !=null ? dataset.settings.params : null}
                                    />
                                }
                            </Grid>
                            {dataset === undefined ?
                            <Grid item xs={12}>
                                {(datasetFormatDef != null && datasetFormatDef.external) ?
                                    <>
                                        <SubmitButton title={"Add dataset"} onClick={onAddExternal}/>
                                    </>
                                        :
                                <UploadFile buttonTxt="Upload Dataset Package"
                                            accept=".zip,.tar.gz,.tar"
                                            id={organization.id + '-csdataset'}
                                            enableUpload={enableDatasetUpload}
                                            progress={progress}
                                            disabled={busy}
                                            progressVariant={progressVariant}
                                            onSelected={() => setEnableDatasetUpload(true)}
                                            onUpload={(file) => onUploadNew(file)}/>
                                }
                            </Grid> :
                            <Grid item xs={12}>
                                {(datasetFormatDef != null && !datasetFormatDef.external) &&
                                    <UploadFile buttonTxt="Update Dataset Package"
                                                accept=".zip,.tar.gz,.tar"
                                                id={organization.id + '-csdataset'}
                                                enableUpload={enableDatasetUpload}
                                                progress={progress}
                                                disabled={busy}
                                                progressVariant={progressVariant}
                                                onSelected={() => setEnableDatasetUpload(true)}
                                                onUpload={(file) => onUpload(file)}/>
                                }
                            </Grid>
                            }
                        </Grid>
                        <Grid item xs container justifyContent="flex-start">
                            <Grid item xs={12}>
                                {datasetFormat != null &&
                                    <TransformSelection
                                        availableTransforms={props.availableTransforms}
                                        onTransformsUpdated={onTransformsUpdated}
                                        onFileUpload={onFileUpload}
                                        transforms={dataset !== undefined ? dataset.transforms : undefined}
                                        format={datasetFormat}
                                        assets={assets}
                                        organization={organization}
                                    />
                                }
                            </Grid>
                        </Grid>
                            <>
                                <Grid item xs={12} container justifyContent="space-between">
                                    <Grid item>
                                        {datasetError &&
                                            <Typography variant="body1" color="error" mt={1}>
                                            {datasetError}
                                            </Typography>
                                        }
                                    </Grid>
                                {dataset !== undefined &&
                                <Grid item>
                                    {busy &&
                                        <CircularProgress size={20}/>
                                    }
                                    <Tooltip title="Save changes">
                                        <IconButton onClick={saveChanges} size="large">
                                            <SaveIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Button onClick={props.onCancel} color="primary" autoFocus>
                                        Cancel
                                    </Button>
                                </Grid>
                                }
                                </Grid>
                            </>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

CustomDataset.propTypes = {
    /**
     * The API configuration
     */
    conf: PropTypes.instanceOf(Configuration).isRequired,

    /**
     * The organization instance
     */
    organization: PropTypes.object.isRequired,

    /**
     * The dataset instance
     */
    dataset: PropTypes.object,

    /**
     * Called when the datasets have been updated
     */
    onDatasetsUpdated: PropTypes.func.isRequired,

    /**
     * Called when the dataset have been updated
     */
    onDatasetUpdated: PropTypes.func,

    /**
     * Called when the edit is cancelled.
     */
    onCancel: PropTypes.func,

    /**
     * List of available transforms
     */
    availableTransforms: PropTypes.array.isRequired,

    /**
     * Available assets
     */
    assets: PropTypes.object.isRequired

};

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import TableBody from "@mui/material/TableBody";
import TuneIcon from "@mui/icons-material/Tune";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import {FilterRequestTypeEnum} from "../api";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import TuneParamsDialog from "./TuneParamsDialog";
import {getLogger} from "../util/util";
import {hasDisplayedParams} from "../ParamEntryList";
import Paper from "../util/Paper";
import ParameterizeAsset from "./ParameterizeAsset";

const log = getLogger("adselector");

/**
 * Returns a unique list of groups in the asset list, sorted and capitalized
 * @param array the asset list
 * @returns {*[]} a unique list of groups in the asset list
 */
const groupsFromAsset = (array) => {
    return [...array.reduce((grps, a) => {
        a.group.forEach(grp => grps.add(grp.charAt(0).toUpperCase() + grp.slice(1)));
        return grps;
    }, new Set()).keys()].sort((a, b) => a.localeCompare(b));
}

const assetFromType = (assets, type) => {
    switch (type) {
        case FilterRequestTypeEnum.AdversarialAttacks:
            return assets.availableAttacks;
        case FilterRequestTypeEnum.Noises:
            return assets.availableNoises;
        case FilterRequestTypeEnum.MetaAttacks:
            return assets.metaAttacks;
        case FilterRequestTypeEnum.DomainAdaptations:
            return assets.domainAdaptations;
        default:
            log.error("Unknown type: "+type);
            return [];
    }
}

function findFilterDef(resources, filter) {
    let defs = assetFromType(resources, filter.type);
    return defs.find(a => a.name === filter.name);
}

const getAttackHelpLink = (resources, attack, attackType, onlyUrl=false) => {
    let fDef = attack;
    if (attack.type != null) {
        // attack is an attack instance, find the definition
        fDef = findFilterDef(resources, attack);
    }

    if (attackType == null) {
        attackType = attack.type;
    }

    let url;
    if (fDef != null) {
        let group = "";
        if (attackType === FilterRequestTypeEnum.AdversarialAttacks) {
            group = fDef.group[0]+"/";
        }
        url = process.env.REACT_APP_DOCS_BASE + "modules/attacks/"+attackType+"/"+group+attack.name.toLowerCase()+".html";
    } else {
        url = process.env.REACT_APP_DOCS_BASE;
    }
    if (onlyUrl)
        return url;
    return <a href={url} target='_blank'>{attack.name}</a>;
}

function filterByTasks(tasks, filters) {
    if (tasks != null) {
        return filters.filter(a => a.tasks == null || // null defaults to all tasks
            a.tasks.find(t => tasks.includes(t))); // or if filter tasks in project tasks
    } else {
        return filters;
    }
}

const filterListByGroupAndTasks = (tasks, lst, grp) => {
    return filterByTasks(tasks, lst.filter( item => item.group.find(g => g.toLowerCase() === grp.toLowerCase())));
}

const requiresIcon = {
    display: 'inline-block',
    minWidth: '1.9rem',
    minHeight: "1.9rem",
    maxWidth: "1.9rem",
    maxHeight: "1.9rem",
    height: "1.9rem",
    width: "1.9rem",
    marginTop: "2px",
    textAlign: "center",
    fontSize: "1.3rem"
}

const checkRequires = (attack, name) => {
    let param = attack.params.find(p => p.name === name);
    if (param != null) {
        return param.def;
    }
    if (attack[name] != null) {
        return attack[name];
    }
    return false;
}

function RequiresIcons({attack}) {

    const hasRequires = (name) => {
        return checkRequires(attack, name);
    }

    return <>
        {attack.params != null &&
            <>
            <Tooltip title={hasRequires('requires_grad') ? "Requires gradients." : "Gradients not required."}>
                <Box component="span" sx={[requiresIcon, hasRequires('requires_grad') ? {
                    color :'info.dark' } : {color :'text.secondary'}]}>
                        &nabla;
                </Box>
            </Tooltip>
                &nbsp;
            <Tooltip title={hasRequires('requires_loss') ? "Requires a loss function." : "Loss function not required."}>
                <Box component="span" sx={[requiresIcon, hasRequires('requires_loss') ? {
                    color :'info.dark' } : {color :'text.secondary'}]}>
             &#8466;
            </Box>
            </Tooltip>
            </>
        }
    </>
}

function filterByGradients(assets, tasks, attackType, group, hasGradients) {
    let fl = filterListByGroupAndTasks(tasks, assetFromType(assets, attackType), group);
    fl = fl.filter(a => {
        let needsGrad = checkRequires(a, "requires_grad");
        if (!needsGrad) return true;
        return !!(needsGrad && hasGradients);
    });
    return fl;
}

/**
 * Shows the list of available attacks grouped by types
 * @param tasks the tasks that will be performed by the model to which the attack is applied to
 * @param assets the assets struct
 * @param hasGradients true if the model has gradients
 * @param onItemSelected will be called when a user selects an attack item
 * @returns {JSX.Element} a react component
 */
function SelectAttackView({tasks, assets, hasGradients, onItemSelected}) {
    const [attackType, setAttackType] = useState(FilterRequestTypeEnum.AdversarialAttacks);
    const [group, setGroup] = useState("");
    const [groups, setGroups] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() => {
        let grps = groupsFromAsset(assetFromType(assets, attackType));
        setGroups(grps);
        if (grps.length > 0) {
            setGroup(grps[0]);
        } else {
            setGroup("");
        }
    }, [tasks, attackType]) //Note: when 'assets' is added to deps list, re-render breaks attack selection when test is
    // running

    useEffect(() => {
        if (group === "") {
            setList([]);
            return;
        }
        setList(filterByGradients(assets, tasks, attackType, group, hasGradients));
    }, [group, attackType, tasks, hasGradients])
    //Note: when 'assets' is added to deps list, re-render breaks attack selection when test is
    // running

    const handleTypeChange = (e) => {
        setAttackType(e.target.value);
    }

    const handleGroupChange = (e) => {
        setGroup(e.target.value);
    }

    const handleListItemClick = (item) => {
        item.type = attackType;
        onItemSelected(true, item);
    }

    const getGroupLabel = (grp) => {
        switch (grp) {
            case "White-box":
                return "Full knowledge";
            case "Black-box":
                return "Zero knowledge";
            case "Grey-box":
                return "Model stealing";
            default:
                return grp;
        }
    }

    return <>
        <Grid container style={{minHeight: "30em"}} alignItems="stretch" spacing={1}>
            <Grid container item xs={4} direction={"column"} spacing={1}>
                <Grid item>
                    <Paper variant="outlined">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Attack Type</FormLabel>
                        <RadioGroup aria-label="gender" name="attackType" value={attackType}
                                    onChange={handleTypeChange}>
                            <FormControlLabel value={FilterRequestTypeEnum.AdversarialAttacks} control={<Radio color="primary"/>}
                                              label="Adversarial Attacks"/>
                            <FormControlLabel value={FilterRequestTypeEnum.MetaAttacks} control={<Radio color="primary"/>}
                                              label="Meta Attacks"/>
                            <FormControlLabel value={FilterRequestTypeEnum.Noises} control={<Radio color="primary"/>}
                                              label="Noises"/>
                            <FormControlLabel value={FilterRequestTypeEnum.DomainAdaptations} control={<Radio color="primary"/>}
                                              label="Domain Adaptations"/>
                        </RadioGroup>
                    </FormControl>
                    </Paper>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" style={{maxHeight: '20.5em', minHeight: '20.5em', overflow: 'auto'}}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Group</FormLabel>
                        <RadioGroup aria-label="gender" name="group" value={group} onChange={handleGroupChange}>
                            {groups.map(grp =>
                                <FormControlLabel key={grp} value={grp}
                                                  control={<Radio color="primary"/>}
                                                  label={getGroupLabel(grp)}/>
                            )}
                        </RadioGroup>
                    </FormControl>
                    </Paper>
                </Grid>
            </Grid>

            <Grid item xs>
                <Paper variant="outlined">
                <Typography variant="body1">Attacks</Typography>
                    {list.filter(li => (li.displayed === undefined || li.displayed === true)).length === 0 &&
                        <Typography variant="body2">No attacks available for this model type
                    </Typography>
                    }
                <List style={{maxHeight: '32em', minHeight: '32em', overflow: 'auto'}}>
                    {list.map((item) => {
                        if (item.displayed === undefined || item.displayed === true) {
                            return (
                                <React.Fragment key={item.id}>
                                    <ListItem disabled={item.enabled !== undefined && !item.enabled}
                                              button
                                              onClick={() => handleListItemClick(item)}>
                                        <ListItemText primary={(item.format === undefined || item.format === null) ?
                                            item.name : item.name + ' - ' + item.format}
                                                      secondary={item.description}/>
                                        <ListItemSecondaryAction>
                                        <RequiresIcons attack={item}/>
                                        {(item.paper_link != null && item.paper_link.length > 0) ?
                                                <Tooltip title="Link to documentation.">
                                                    <IconButton
                                                        edge="end"
                                                        style={{marginBottom: "6px"}}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            window.open(getAttackHelpLink(assets, item, attackType, true),
                                                                '_blank')
                                                        }}
                                                        aria-label="paper"
                                                        size="large">
                                                        <HelpIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            :
                                            <span style={{display: "inline-block", width: "2.2rem"}}>
                                            </span>
                                        }
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </React.Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}
                </List>
                </Paper>
            </Grid>
        </Grid>
    </>;
}

/**
 * Shows the list of available defenses grouped by types
 * @param tasks the tasks that will be performed by the model to which the attack is applied to
 * @param assets the assets struct
 * @param onItemSelected will be called when a user selects an attack item
 * @returns {JSX.Element} a react component
 */
function SelectDefenseView({tasks, assets, onItemSelected}) {
    const [group, setGroup] = useState("");
    const [groups, setGroups] = useState([]);
    const [list, setList] = useState([]);
    const [defenses, setDefenses] = useState([]);

    useEffect(() => {
        let defenses = filterByTasks(tasks, assets.availableDefenses).filter( d => !d.group.includes("detection"));
        setDefenses(defenses);
        let grps = groupsFromAsset(defenses);
        setGroups(grps);
        if (grps.length > 0) {
            setGroup(grps[0]);
            setList(filterListByGroupAndTasks(tasks, defenses, grps[0]));
        } else {
            setGroup("");
        }
    }, [tasks]); //Note: when 'assets' is added to deps list, re-render breaks attack selection when test is
    // running

    const handleGroupChange = (e) => {
        setGroup(e.target.value);
        setList(filterListByGroupAndTasks(tasks, defenses, e.target.value));
    }

    const handleListItemClick = (item) => {
        onItemSelected(true, item);
    }

    const getGroupLabel = (grp) => {
        switch (grp) {
            case "Training":
                return "Training";
            case "Preprocessing":
                return "Pre-processing";
            case "Postprocessing":
                return "Post-processing";
            default:
                return grp;
        }
    }

    return <>
        <Grid container style={{minHeight: "30em"}} alignItems="stretch" spacing={1}>
            <Grid container item xs={4} direction={"column"} spacing={1}>
                <Grid item>
                    <Paper variant="outlined" style={{maxHeight: '20.5em', minHeight: '20.5em', overflow: 'auto'}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Defense Group</FormLabel>
                            <RadioGroup aria-label="gender" name="group" value={group} onChange={handleGroupChange}>
                                {groups.map(grp =>
                                    <FormControlLabel key={grp} value={grp}
                                                      control={<Radio color="primary"/>}
                                                      label={getGroupLabel(grp)}/>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>

            <Grid item xs>
                <Paper variant="outlined">
                    <Typography variant="body1">Defenses</Typography>
                    <List style={{maxHeight: '33em', minHeight: '33em', overflow: 'auto'}}>
                        {list.map((item) => {
                            if (item.displayed === undefined || item.displayed === true) {
                                return <React.Fragment key={item.id}>
                                    <ListItem disabled={item.enabled !== undefined && !item.enabled}
                                              button
                                              onClick={() => handleListItemClick(item)}>
                                        <ListItemText primary={(item.format === undefined || item.format === null) ?
                                            item.name : item.name + ' - ' + item.format}
                                                      secondary={item.description}/>
                                    </ListItem>
                                </React.Fragment>
                            } else {
                                return null;
                            }
                        })}
                    </List>
                </Paper>
            </Grid>
        </Grid>
    </>
}

/**
 * Component for configuration of attacks and defenses.
 * @author Kobus Grobler
 * @component
 */
export default function AttackDefenseSelector({assets, tasks, test, hasGradients, onAddDefense, onAttackAdded,
                                                  onRemoveDefense, onRemoveFilter, onUpdateTest}) {
    const [openAttackDlg, setOpenAttackDlg] = useState(false);
    const [openDefenseDlg, setOpenDefenseDlg] = useState(false);
    const [openTuneAttackDlg, setOpenTuneAttackDlg] = useState(false);
    const [attackDef, setAttackDef] = useState(assets.availableAttacks[0]);
    const [defenseDef, setDefenseDef] = useState(assets.availableDefenses[0]);
    const [attack, setAttack] = useState(null);
    const [openTuneDefenseDlg, setOpenTuneDefenseDlg] = useState(false);
    const [defense, setDefense] = useState(null);

    function updateDefense(dIn) {
        onUpdateTest(test, {defense: dIn});
    }

    const enableChanged = (pair) => {
        let filter = pair[0];
        filter.enabled = !filter.enabled;
        updateDefense(pair[1]);
    }

    const onLossParamsUpdated = (defense, aId, v) => {
        let f = defense.filters.find(f => f.id === aId);
        f.lossParams = v;
        updateDefense(defense);
    }

    const onRemoveAttack = (filter) => {
        onRemoveFilter(filter);
    }

    function findDefenseDef(dIn) {
        return assets.availableDefenses.find(a => a.name === dIn.name);
    }

    const onTuneDefense = (dIn) => {
        setDefenseDef(findDefenseDef(dIn));
        setDefense(dIn);
        setTimeout(() => {
            setOpenTuneDefenseDlg(true);
        }, 100);
    }

    const onTuneAttack = (pair) => {
        setDefense(pair[1]);
        setAttackDef(findFilterDef(assets, pair[0]));
        setAttack(pair[0]);
        setTimeout(() => {
            setOpenTuneAttackDlg(true);
        }, 100);
    }

    const hasTunableParams = (filter) => {
        let def = findFilterDef(assets, filter);
        return hasDisplayedParams(def);
    }

    const needsLoss = (filter) => {
        let def = findFilterDef(assets, filter);
        return checkRequires(def, "requires_loss");
    }

    const handleCloseTuneDefenseDlg = (values) => {
        setOpenTuneDefenseDlg(false);
        if (values == null)
            return;
        let params = {};
        for (let i = 0; i < values.length; i++) {
            if (values[i] !=='') {
                params[defenseDef.params[i].name] = values[i];
            }
        }
        defense.defenseParameters = JSON.stringify(params);
        updateDefense(defense);
    }

    const handleCloseTuneDlg = (values) => {
        setOpenTuneAttackDlg(false);
        if (values != null) {
            let params = {};
            for (let i = 0; i < values.length; i++) {
                if (values[i] != null && values[i].length !== 0) {
                    params[attackDef.params[i].name] = values[i];
                }
            }
            attack.parameters = JSON.stringify(params);
            updateDefense(defense);
        }
    }

    const handleCloseDefenseDlg = (accepted, value) => {
        setOpenDefenseDlg(false)
        if (accepted) {
            onAddDefense(value, test)
        }
    }

    const handleCloseAttackDlg = (accepted, value) => {
        setOpenAttackDlg(false);
        if (accepted) {
            onAttackAdded(test, {name: value.name, type: value.type, parameters: "{}"});
        }
    }

    const onAddAttack = () => {
        setOpenAttackDlg(true);
    }

    function transpose(a) {
        let w = a.length || 0;
        let h = a[0] instanceof Array ? a[0].length : 0;

        if (h === 0 || w === 0) {
            return [];
        }

        let i, j, t = [];

        for (i = 0; i < h; i++) {
            t[i] = [];
            for (j = 0; j < w; j++) {
                t[i][j] = a[j][i];
            }
        }
        return t;
    }

    function paramSummary(filter) {
        if (filter.parameters != null) {
            let params = JSON.parse(filter.parameters);
            let str = "";
            Object.keys(params).map(function (key, index) {
                if (index > 0) {
                    str += ", ";
                }
                str += key + ": " + params[key];
                return key;
            });
            return str;
        }
        return '';
    }

    function orderFilters(filters) {
        return filters.sort((a, b) => b.id - a.id);
    }

    function getAttacks() {
        // transpose attacks for defense
        let matrix = [];
        if (test.defenses != null) {
            for (let i = 0; i < test.defenses.length; i++) {
                matrix.push([]);
                let d = test.defenses[i];
                if (d.filters != null) {
                    d.filters = orderFilters(d.filters);
                    for (let filter of d.filters) {
                        matrix[i].push([filter, d]);
                    }
                }
            }
        }
        return transpose(matrix);
    }

    const getDefenseCount = () => {
        if (test.defenses != null) {
            return test.defenses.length;
        }
        return 0;
    }

    const checkedState = (filter) => {
        if (needsLoss(filter)) {
            return filter.enabled && filter.lossName != null && filter.lossName !== "";
        }
        return filter.enabled;
    }

    return <Paper><TableContainer>
        <OkCancelDialog onClose={handleCloseAttackDlg}
                        dialogProps={{fullWidth: true, maxWidth: 'lg'}}
                        open={openAttackDlg}
                        hideOk
                        title="Select Attack">
            <SelectAttackView tasks={tasks}
                              assets={assets}
                              hasGradients={hasGradients}
                              onItemSelected={handleCloseAttackDlg}/>
        </OkCancelDialog>

        <OkCancelDialog onClose={handleCloseDefenseDlg}
                        dialogProps={{fullWidth: true, maxWidth: 'lg'}}
                        open={openDefenseDlg}
                        hideOk
                        title="Select Defense">
            <SelectDefenseView tasks={tasks}
                              assets={assets}
                              onItemSelected={handleCloseDefenseDlg}/>
        </OkCancelDialog>

        <TuneParamsDialog onClose={handleCloseTuneDlg}
                          open={openTuneAttackDlg}
                          paramDef={attackDef}
                          item={attack}
        />
        <TuneParamsDialog onClose={handleCloseTuneDefenseDlg}
                          open={openTuneDefenseDlg}
                          paramDef={defenseDef}
                          item={defense}
        />
        <Table style={{minWidth: 650}}>
            <TableHead>
                <TableRow>
                    <TableCell align="center" colSpan={1 + getDefenseCount()}><b>Defenses</b>&nbsp;
                        <IconButton
                            color="primary"
                            size="small"
                            data-testid="add-defense"
                            onClick={() => setOpenDefenseDlg(true)}>
                            <AddIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="left"> </TableCell>
                    {getDefenseCount() > 0 && test.defenses.map((defense, _idx) => (
                        <TableCell key={defense.id} align="left">
                            {test.defenses.length > 1 &&
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => onRemoveDefense(defense, test)}>
                                    <RemoveIcon/>
                                </IconButton>
                            }
                            <b>{defense.name}</b>
                            {findDefenseDef(defense).params.length !== 0 && // don't display if has no params to tune
                                <IconButton
                                    size="small"
                                    onClick={() => onTuneDefense(defense)}>
                                    <TuneIcon/>
                                </IconButton>
                            }
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell align="left" colSpan={1 + getDefenseCount()}>
                        <b>Attacks</b>&nbsp;
                        <IconButton
                            color="primary"
                            size="small"
                            data-testid="add-attack"
                            onClick={onAddAttack}>
                            <AddIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell component="th" scope="row" colSpan={1 + getDefenseCount()}>
                        No Attack
                    </TableCell>
                </TableRow>
                {getAttacks().map((row, idx) => (
                    <TableRow key={idx + '-' + row[0][0].id}>
                        <TableCell component="th" scope="row">
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={() => onRemoveAttack(row[0][0])}>
                                <RemoveIcon/>
                            </IconButton>
                            {getAttackHelpLink(assets, row[0][0])}
                        </TableCell>
                        {row.map((pair) => (
                            (typeof pair !== 'undefined' && pair !== null) &&
                            <TableCell key={pair[0].id}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Switch title={pair[0].lossName != null ? undefined : "First select a loss function"}
                                                color="primary"
                                                checked={checkedState(pair[0])}
                                                onChange={() => enableChanged(pair)}/>
                                        <div>{paramSummary(pair[0])}</div>
                                    </Grid>
                                <Grid item>{hasTunableParams(pair[0]) ?
                                    <IconButton
                                        size="small"
                                        title={"Tune attack parameters"}
                                        onClick={() => onTuneAttack(pair)}>
                                        <TuneIcon/>
                                    </IconButton> :
                                    <IconButton disabled
                                        size="small">
                                        <TuneIcon/>
                                    </IconButton>
                                }</Grid>
                                {needsLoss(pair[0]) &&
                                    <Grid item>
                                    <ParameterizeAsset title="Loss function" compact={true}
                                                       asset={assets.losses}
                                                       assetName={pair[0].lossName}
                                                       assetParams={pair[0].lossParams}
                                                       paramsUpdated={(v) => onLossParamsUpdated(pair[1], pair[0].id, v)}
                                                       assetUpdated={(v) => {pair[0].lossName = v;
                                                           updateDefense(pair[1]);}}/>
                                    </Grid>
                                }
                                </Grid>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer></Paper>
}

AttackDefenseSelector.propTypes = {
    /**
     * The test associated with this selector
     */
    test: PropTypes.object.isRequired,

    hasGradients:  PropTypes.bool.isRequired,

    /**
     * The organization's assets
     */
    assets: PropTypes.object.isRequired,

    tasks: PropTypes.array.isRequired,

    /**
     * Called when an attack should be added
     */
    onAttackAdded: PropTypes.func.isRequired,

    /**
     * Called when a filter should be removed
     */
    onRemoveFilter: PropTypes.func.isRequired,

    /**
     * Called when a test should be updated
     */
    onUpdateTest: PropTypes.func.isRequired,

}

export {groupsFromAsset, getAttackHelpLink, filterListByGroupAndTasks}

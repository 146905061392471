/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";
import TuneIcon from "@mui/icons-material/Tune";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";

import {getLogger, checkError, errOptions, convertUserValuesToParams} from "../util/util";
import {Configuration, ProjectApi} from "../api";
import TuneParamsDialog from "../aitests/TuneParamsDialog";
import NameEditor from "../util/NameEditor";
import {useModel} from "../models/Model";
import {useLoadDataset} from "../datasets/Datasets"
import ProjectTypeIndicator from "./ProjectTypeIndicator"
import {EPS_DEF, AR_DEF} from "../aitests/RobustnessSettings"
import ModelTypeIndicator from "../models/ModelTypeIndicator";

const log = getLogger("projectsettings");

const CLASS_MAPPING_DEF = {
    params: [{
        name: 'mode',
        description: "Map or merge classes",
        type: 'list',
        list: ["map","merge"],
        def: 'map'
    },{
        name: 'out_classes',
        description: "Output classes",
        type: 'int',
        def: 100,
        min: 1
    },{
        name: 'iterations',
        description: "Iterations",
        type: 'int',
        def: 100,
        min: 1,
        disabled: true
    },{
        name: 'ignore_class',
        description: "Ignore class",
        type: 'int',
        def: null,
    }],
};

function ClassMappingSettings({settings, onSettingsChange, onSetClassMappingParams}) {

return <FormControlLabel
    control={<>
        <Switch
            name="classMappingEstimation"
            color="primary"
            onChange={(e) => onSettingsChange(e)}
            checked={settings.classMappingEstimation != null ?
                settings.classMappingEstimation : false}
        />    <IconButton
        size="small"
        onClick={onSetClassMappingParams}>
        <TuneIcon/>
        </IconButton></>
    }

    label="Enable class mapping estimator"
/>

}

/**
 * Displays and allows configuration of an ASA project
 * @author Kobus Grobler
 * @component
 */
export default function ProjectSettings({conf, project, projectUpdated}) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const projectApi = new ProjectApi(conf);
    const [openTuneProjectParam, setOpenTuneProjectParam] = useState(false);
    const [projectParam, setProjectParam] = useState(null);
    const [projectParamDef, setProjectParamDef] = useState(null);
    const [{model, updateModelName}] = useModel(project.modelId);
    const [{dataset}] = useLoadDataset(project.organization.id, project.settings.datasetId);
    const [openAdv, setOpenAdv] = useState(false);

    const saveSettings = async settings => {
        try {
            let p = await projectApi.updateProject(project.id, {settings: settings})
            projectUpdated(p.data);
        } catch(e) {
            checkError(e, history, () => {
                enqueueSnackbar('Failed to update project', errOptions);
            });
        }
    }

    const handleSettingsChange = e => {
        let settings = project.settings;
        settings[e.target.name] = !settings[e.target.name]
        saveSettings(settings).then();
    }

    const onSetParams = (def, params) => {
        let paramStr = {
            parameters: JSON.stringify(params)
        }
        setProjectParam(paramStr);
        setProjectParamDef(def);
        setOpenTuneProjectParam(true);
    }

    function handleCloseTuneProjectDlg(values) {
        setOpenTuneProjectParam(false);
        if (values != null) {
            if (projectParamDef === EPS_DEF) {
                log.debug("saving eps proj params");
                project.settings.epsExplorationParams = convertUserValuesToParams(values, EPS_DEF.params);
            }  else if (projectParamDef === AR_DEF) {
                log.debug("saving AR proj params");
                project.settings.arParams = convertUserValuesToParams(values, AR_DEF.params);
            }  else if (projectParamDef === CLASS_MAPPING_DEF) {
                log.debug("saving class mapping proj params");
                project.settings.classMappingParams = convertUserValuesToParams(values, CLASS_MAPPING_DEF.params);
            }
            saveSettings(project.settings).then();
        }
    }

    return <Grid item container>
        {projectParamDef != null &&
            <TuneParamsDialog onClose={handleCloseTuneProjectDlg}
                              open={openTuneProjectParam}
                              paramDef={projectParamDef}
                              item={projectParam}
            />
        }
        <Paper variant="outlined" sx={{padding: 2}}>
            <Grid item xs={12}>
                <Typography variant='h5' style={{textAlign: "center", width: "20em"}}>
                    Project Settings
                </Typography>
            </Grid>
            <Grid item container spacing={1} direction="column" xs={12}>
                <Grid item>
                    <span style={{display: 'inline-flex', width: "100%"}}>
                    {project.settings != null &&
                        <>
                        <ProjectTypeIndicator style={{marginRight: "5px", marginTop: "0"}}
                                              tasks={project.settings.tasks != null ? project.settings.tasks : []}/>
                        </>
                    }
                    {model != null &&
                        <>
                            <ModelTypeIndicator model={model}/>
                        </>
                    }
                    <Typography style={{marginTop: "5px", minWidth: "7em", marginLeft: "1em"}}
                                variant='body1' component="span">Model Name:</Typography>
                    <NameEditor fieldName="Model Name" name={model != null ? model.name : ""}
                                style={{marginTop: "5px", minWidth: "20em"}}
                                onUpdateName={(id, value) => updateModelName(value)}/>
                        </span>
                </Grid>
                <Grid item>
                    {dataset != null &&
                        <><Typography variant='body1'>
                            Configured Dataset: {dataset.name}
                        </Typography>
                        </>
                    }
                </Grid>
                <Grid item>
                <IconButton aria-label="expand advanced"
                            size="small"
                            onClick={() => setOpenAdv(!openAdv)}>
                    {openAdv ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    <Typography variant="body1">
                        Advanced Settings
                    </Typography>
                </IconButton>
                <Collapse in={openAdv} timeout="auto" unmountOnExit>
                {project.settings != null &&
                    <>
                    <Grid item>
                        <ClassMappingSettings settings={project.settings}
                                              onSetClassMappingParams={() => onSetParams(CLASS_MAPPING_DEF, project.settings.classMappingParams)}
                                              onSettingsChange={handleSettingsChange}/>
                    </Grid>
                    </>
                }
                </Collapse>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}

ProjectSettings.propTypes = {
    /**
     * The API configuration
     */
    conf: PropTypes.instanceOf(Configuration).isRequired,

    /**
     * The project instance
     */
    project: PropTypes.object.isRequired,

    projectUpdated: PropTypes.func.isRequired
};

/*
 * Copyright (C) 2023 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import TuneParamsDialog from "./TuneParamsDialog";
import {convertUserValuesToParams} from "../util/util";

export default function ParameterizeAsset({title, asset, assetName, assetParams, assetUpdated, paramsUpdated,
                                              showTune = true, compact=false}) {
    const [val, setVal] = useState(assetName==null ? "" :assetName);
    const [openTuneParam, setOpenTuneParam] = useState(false);
    const [testParam, setTestParam] = useState(null);
    const [testParamDef, setTestParamDef] = useState(null);

    useEffect(() => {
        let a = asset.find((v) => v.codebase_name === val);
        setTestParamDef(a);
    }, [asset, val]);

    const onSetParams = () => {
        let p = {
            parameters: assetParams
        }
        setTestParam(p);
        setOpenTuneParam(true);
    }

    function handleCloseTuneDlg(values) {
        setOpenTuneParam(false);
        if (values != null) {
            let params = convertUserValuesToParams(values, testParamDef.params);
            paramsUpdated(JSON.stringify(params));
        }
    }

    return <Grid container alignItems="center">
        {testParamDef != null &&
            <TuneParamsDialog onClose={handleCloseTuneDlg}
                          open={openTuneParam}
                          paramDef={testParamDef}
                          item={testParam}/>
        }
        <Grid item>
            <FormControl variant="outlined" sx={{minWidth: 200}} size={compact ? "small" : undefined}>
                <InputLabel>{title}</InputLabel>
                <Select
                    value={val}
                    onChange={(e)=> {
                        setVal(e.target.value);
                        assetUpdated(e.target.value);
                    }}
                    label={title}>
                    {asset.map((v, idx) =>
                        <MenuItem key={idx} value={v.codebase_name}>{v.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
        {showTune &&
        <Grid item>
            <IconButton title="Adjust parameters"
                disabled={!val || val === ""}
                size="small"
                onClick={onSetParams}>
                <TuneIcon/>
            </IconButton>
        </Grid>
        }
    </Grid>
}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js/react-plotly";
import {getLogger} from "../util/util";

const log = getLogger("eps.plot");

/**
 * This component plots the EPS exploration
 *
 * @author Kobus Grobler
 * @component
 */
export default function EPSPlot({attack, title}) {

    const MODE = 'lines+markers';

    const [data] = React.useState(() => getPlots());

    function getParameterPlot(x, y, name) {
        return {
            x: x,
            y: y,
            type: 'scatter',
            mode: MODE,
            name: name
        }
    }

    function getData(eps) {
        let data = {'eps': eps};
        let mName = attack.metricNames()[0];
        let metric = attack.getMetric(mName);
        metric.getTypes().forEach( mType => {
            data[mType] = metric.getValues(mType);
        })

        // eps may be unsorted if depending on how results are returned from the worker
        let index = [...Array(eps.length).keys()];
        index.sort((el1, el2) => {
            return eps[el2] - eps[el1];
        });

        Object.keys(data).forEach(key => {
            let d = data[key];
            if (d.length !== eps.length) { // sanity check
                log.error(`x and y len mismatch. (y) ${d.length} != (x) ${eps.length}, key = ${key}`);
            }
            let sorted = new Array(d.length); // clone
            for (let i = 0; i < d.length; i++) {
                sorted[i] = d[index[i]];
            }
            data[key] = sorted;
        });
        return data;
    }

    function getPlots() {
        let data = getData(attack.getParam('eps'));
        let plots = [];
        let x = data['eps'];
        delete data['eps'];
        Object.keys(data).forEach(key => {
            plots.push(getParameterPlot(x, data[key], key));
        });
        return plots;
    }

    return (<>
            <Plot
                data={data}
                layout={{
                    title: title,
                    xaxis: {
                        title: {
                            text: 'eps',
                            font: {
                                size: 14,
                            }
                        },
                    },
                    yaxis: {
                        title: {
                            text: attack.metricNames()[0],
                            font: {
                                size: 14,
                            }
                        },
                    },
                }}

                config={{displaylogo: false}}
            />
        </>
    );
}

EPSPlot.propTypes = {
    /**
     * The attack instance
     */
    attack: PropTypes.object.isRequired,

    /**
     * The plot title
     */
    title: PropTypes.string.isRequired
}

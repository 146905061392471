/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Grid from '@mui/material/Grid';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import {useHistory} from "react-router-dom";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import {TestApi, TestRunResponseStatusEnum} from "../api";
import Reports, {useLoadReports, useReports} from "./Reports";
import {checkError, errOptions, getLogger, javaDateToTimeStr, limitStringLen, secondsToTimeStr} from "../util/util";
import LogView from "../LogView";
import AppContext from "../AppContext";
import {useServerUpdates} from "../ServerUpdates";
import TestSetupWizard from "./TestSetupWizard";
import PassFailReport, {createPassFailReports} from "./PassFailReport";
import NameEditor from "../util/NameEditor";
import {TestProfileSummary} from "../profiles/TestProfile";
import DetectionTestSetupWizard from "./DetectionTestSetupWizard";
import ReportSampleViewer from "./ReportSampleViewer";
import {buildReportTableModel} from "./ReportTable";
import {ReportMetricGraph} from "./ReportMetricGraph";
import {SubmitButton} from "../util/SubmitButton";
import AttackCraftingWizard from "../attackcrafting/AttackCraftingWizard"
import Player from "./ImagePlayer";

const log = getLogger("test");

const useTests = () => {
    const {conf} = useContext(AppContext);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const testApi = useMemo( () => new TestApi(conf), [conf]);

    const addTest = useCallback(async (project, profile_id, testName, testType = "Robustness") => {
        try {
            log.debug("Adding test " + testName + " to project " + project.id);
            const r = await testApi.addTest({
                name: testName,
                projectId: project.id,
                pipelineId: project.pipelines[0].id, // we only have one pipeline
                profileId: profile_id,
                testSettings: {"testType": testType}
            });
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to add test' + msg, errOptions));
        }
    }, [testApi, enqueueSnackbar, history]);

    const deleteTest = useCallback(async (testId) => {
        try {
            log.debug("Deleting test " + testId);
            await testApi.deleteTest(testId);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to delete test' + msg, errOptions));
        }
    }, [testApi, enqueueSnackbar, history]);

    const stopTest = useCallback(async (testId) => {
        try {
            log.debug("Stopping test " + testId);
            await testApi.stopTest(testId);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to stop test' + msg, errOptions));
        }
    }, [testApi, enqueueSnackbar, history]);

    const startTest = useCallback(async (testId) => {
        try {
            log.debug("Starting test " + testId);
            await testApi.startTest(testId);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to start test' + msg, errOptions));
            throw e;
        }
    }, [testApi, enqueueSnackbar, history]);

    const updateTest = useCallback(async (testId, request) => {
        try {
            log.debug("Updating test " + testId);
            const r = await testApi.updateTest(testId, request);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to update test' + msg, errOptions));
            throw e;
        }
    }, [testApi, enqueueSnackbar, history]);

    const getStatus = useCallback(async (testId) => {
        try {
            log.debug(`Getting test# ${testId} status.`);
            const r = await testApi.getStatus(testId);
            return r.data;
        } catch (e) {
            checkError(e, history);
        }
    }, [testApi, history]);

    const getTests = useCallback(async (prjId) => {
        try {
            log.debug(`Loading tests for project ${prjId}.`);
            const list = await testApi.getTests(prjId);
            log.debug(`Retrieved ${list.data.length} tests.`);
            return list.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get tests' + msg, errOptions));
        }
    }, [enqueueSnackbar, testApi, history]);

    return {getTests, addTest, deleteTest, stopTest, startTest, updateTest, getStatus};
}

const useTestRun = () => {
    const {conf} = useContext(AppContext);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const updateTestRun = async (id, request) => {
        log.debug('Updating test run.');
        const api = new TestApi(conf);
        try {
            let r = await api.updateTestRun(id, request);
            return r.data;
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to update test run',
                    errOptions));
        }
    }
    return [updateTestRun];
}

function isTestRunBusy(testRun) {
    return testRun != null && testRun.status !== null &&
        (testRun.status === TestRunResponseStatusEnum.Initializing
            || testRun.status === TestRunResponseStatusEnum.Submitted
            || testRun.status === TestRunResponseStatusEnum.Running);
}

/**
 * Returns true if the test is busy
 * @param test
 * @returns {boolean}
 */
function isTestBusy(test) {
    if (test != null && test.testRuns != null) {
        return test.testRuns.find(tr => isTestRunBusy(tr)) !== undefined;
    }
    return false;
}

function getProgressVal(testRun) {
    if (testRun.submitCount > 1) {
        return 100 - ((testRun.submitCount - testRun.completedCount) / testRun.submitCount) * 100;
    }
    if (testRun.progress != null) {
        return testRun.progress;
    }
    return undefined;
}

function getProgressAttribs(testRun) {
    let attribs = {variant: "indeterminate", value: 0};
    if (testRun == null) {
        return attribs;
    }
    let progress = getProgressVal(testRun);
    if (testRun.status !== TestRunResponseStatusEnum.Submitted && progress != null) {
        attribs.variant = "determinate";
        attribs.value = progress;
    }
    return attribs;
}

function CircularProgressWithLabel({value = 0, props}) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left="0.2em"
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="body2" component="div" color="textSecondary">
                    {value != null ? `${Math.round(value)}` : ""}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const getBusyTestRunFromStatus = (status) => {
    if (status != null && status.testRuns != null && status.testRuns.length > 0) {
        // find a test run that is actually busy, not just submitted
        let br = status.testRuns.find(tr => {
            return isTestRunBusy(tr) && tr.status !== TestRunResponseStatusEnum.Submitted;
        });

        // if no busy one found, then just return the most recent test run.
        if (br === undefined) {
            return status.testRuns[0];
        } else {
            return br;
        }
    }
    return {};
}

function StartStopProgress({status, compact = false, onClick}) {

    const getLastMessage = () => {
        let tr = getBusyTestRunFromStatus(status);
        if (tr.message != null && tr.message !== "Done") {
            let timeMsg = "";
            if (tr.elapsed != null && tr.elapsed > 0) {
                timeMsg = ", Elapsed:" + secondsToTimeStr(tr.elapsed);
            }
            if (tr.eta != null && tr.eta > 0) {
                timeMsg += ", ETA:" + secondsToTimeStr(tr.eta);
            }
            return tr.message + timeMsg;
        } else {
            return "";
        }
    }

    const getStartedMsg = () => {
        let br = getBusyTestRunFromStatus(status);
        let time = javaDateToTimeStr(br.startTime);
        return `Started by: ${br.initiator} on ${time}`;
    }

    return (<>{isTestBusy(status) ?
        <>
            {compact &&
                <>
                    <SubmitButton
                        onClick={e => onClick(e)}
                        startIcon={<StopIcon/>}>
                        Stop Test
                    </SubmitButton>
                    <span style={{
                        marginLeft: "1em",
                        marginRight: "1em",
                        verticalAlign: "bottom",
                        display: 'inline-flex'
                    }}>
                    <CircularProgressWithLabel {...getProgressAttribs(getBusyTestRunFromStatus(status))}/>
                </span>
                </>
            }
            <Typography component="span"
                        style={{marginTop: "0.5em", verticalAlign: "bottom", display: 'inline-flex'}}>
                Status: {getLastMessage()}
            </Typography>
            {!compact &&
                <>
                    <Typography variant="body2">
                        {getStartedMsg()}
                    </Typography>
                </>
            }
        </>
        :
        <>
            {compact &&
                <SubmitButton
                    onClick={e => onClick(e)}
                    data-testid="run-test"
                    style={{margin: 0}}
                    startIcon={<PlayArrowIcon/>}>
                Run Test
                </SubmitButton>
            }
            {!compact && getLastMessage().length > 0 &&
                <>
                    <LogView logs={getLastMessage()} minSize='3em'/>
                </>
            }
        </>
    }</>)
}

const hasPassFailCriteria = (test) => {
    return test.testSettings.passFailCriteria != null &&
        test.testSettings.passFailCriteria.filter(pf => pf.enabled).length > 0;
}

const ReportSection = ({project, test, reports, reportModel, passPassFailReports, onViewTypeChange, onReportDeleted, onReportNameChanged}) => {
  return <>{hasPassFailCriteria(test) &&
      <Grid xs={12} item container>
          {passPassFailReports.map((r, idx) =>
              <Grid key={idx} item xs={12}>
                  <PassFailReport report={r}
                                  passPercentage={test.testSettings.passPercentage}
                                  criteria={test.testSettings.passFailCriteria}/>
              </Grid>
          )}
      </Grid>
  }
    <Grid xs={12} item container>
        <Grid item xs={12}>
            {reports != null && reports.length > 0 && reports[0].dataRef != null &&
                <>
                    {["depth", "custom"].find((el) => project.settings.tasks.includes(el)) ?
                        <>
                    <Player datasetId={project.settings.datasetId} orgId={project.organization.id}
                        reportId={reports[0].id} showReportData={true}/></>:
                        <ReportSampleViewer id={reports[0].id} title={"Explore data from the last test run:"}/>
                    }
                </>
            }
            {reportModel != null && reportModel.rows.length > 1 && !reportModel.isDetectionModel() &&
                <>
                    <ReportMetricGraph reports={reports}/>
                </>
            }
            <Reports reports={reports}
                     model={reportModel}
                     onViewTypeChange={onViewTypeChange}
                     onReportDeleted={onReportDeleted}
                     onReportNameChanged={onReportNameChanged}
                     test={test}/>
        </Grid>
    </Grid>
  </>
}

/**
 * Composes the configuration of an adversarial test
 * @author Kobus Grobler
 *
 * @component
 */
export default function Test({test, assets, showWizard, onWizardFinish, testUpdated, project}) {
    const {enqueueSnackbar} = useSnackbar();
    const {stopTest, updateTest, getStatus, startTest} = useTests();
    const [status, setStatus] = useState(null);
    const [busy, setBusy] = useState(false);
    const [metaData, setMetaData] = useState([]);
    const [passPassFailReports, setPassPassFailReports] = useState([]);
    const [reportModel, setReportModel] = useState(null);
    const {getReportMetaData} = useReports();

    const onLoaded = useCallback((newReports) => {
        setReports(newReports);
        loadReportsByTestId(null); // to force refresh when busy flag changes and test is set
    }, []);

    const [{reports, setReports}, loadReportsByTestId] = useLoadReports(onLoaded);

    const onBusyTestRuns = useCallback((runs) => {
        let testRuns = runs.reduce((acc, r) => {
            if (r.testId === test.id) {
                acc.push(r);
            }
            return acc;
        }, []);

        if (testRuns.length > 0) {
            test.testRuns = testRuns;
            setBusy(true);
            setStatus(test);
        } else {
            setBusy(false);
        }
    }, [test]);

    useServerUpdates('/topic/testruns/busy', onBusyTestRuns);

    useEffect(() => {
        (async () => {
            let s = await getStatus(test.id);
            if (s != null) {
                setBusy(isTestBusy(s));
                setStatus(s);
            }
        })();
    }, [getStatus, test.id, busy]);

    useEffect(() => {
        if (test.expanded && !busy) {
            loadReportsByTestId(test.id);
        }
    }, [test.id, test.expanded, busy, loadReportsByTestId]);

    useEffect(() => {
        (async () => {
            if (reports != null && hasPassFailCriteria(test)) {
                for (let r of reports) {
                    try {
                        let data = await getReportMetaData(r.id);
                        setMetaData([data]);
                        break; // only last report
                    } catch (e) {
                        if (e.request == null || e.request.status !== 404) {
                            log.error(e);
                        } else {
                            log.debug("Meta data not found, exiting.");
                            break
                        }
                    }
                }
            }
        })();
    }, [reports, test]);

    useEffect(() => {
        let pfReports = [];
        for (let i = 0; i < metaData.length; i++) {
            try {
                pfReports.push(createPassFailReports(test.testSettings.passFailCriteria,
                    metaData[i], JSON.parse(reports[i].jsonReport)[0]));
            } catch (e) {
                log.error(e);
            }
        }
        setPassPassFailReports(pfReports);
    }, [reports, metaData, test.testSettings.passFailCriteria]);

    useEffect(() => {
        if (reports != null)
            setReportModel(buildReportTableModel(reports, 'runId'));
    },[reports]);

    const onViewTypeChange = (viewType) => {
        setReportModel(buildReportTableModel(reports, 'runId', null, null, viewType));
    }

    const onStartStopClick = async () => {
        if (isTestBusy(status)) {
            await stopTest(test.id);
        } else {
            let r = await updateTest(test.id, {
                testSettings: test.testSettings
            });
            if (r != null) {
                await startTest(test.id);
                enqueueSnackbar('Started test #' + test.id);
                setBusy(true);
                testUpdated(r);
            }
        }
    }

    const datasetSettingsUpdated = async (datasetSettings) => {
        log.debug("Dataset settings update requested.");
        test.testSettings.datasetSetting = datasetSettings;
        let rs = await updateTest(test.id, {
            testSettings: test.testSettings
        });
        log.debug("Dataset settings updated.");
        testUpdated(rs);
    }

    const onReportDeleted = (id) => {
        let updated = reports.filter(r => r.id !== id);
        setReports(updated);
    }

    const onReportNameChanged = (id, newName) => {
        let updated = reports.map(r => {
            if (r.runId === id) {
                let cp = {...r};
                cp.runName = newName;
                return cp;
            } else {
                return r;
            }
        });
        setReports(updated);
    }

    const onDescriptionUpdated = async (id, desc) => {
        log.debug("Saving test description.");
        let rs = await updateTest(test.id, {
            description: limitStringLen(512, desc)
        });
        if (rs != null)
            testUpdated(rs);
    }

    const getTestWizard = () => {
        switch (test.testSettings.testType) {
            case "Crafted":
                return <AttackCraftingWizard assets={assets}
                                             project={project}
                                             test={test}
                                             testUpdated={testUpdated}
                                             onFinish={onWizardFinish}
                                             datasetSettingsUpdated={datasetSettingsUpdated}/>
            case "Detection":
                return <DetectionTestSetupWizard assets={assets}
                                      project={project}
                                      test={test}
                                      testUpdated={testUpdated}
                                      onFinish={onWizardFinish}
                                      datasetSettingsUpdated={datasetSettingsUpdated}/>
            default:
                return <TestSetupWizard assets={assets}
                             project={project}
                             test={test}
                             testUpdated={testUpdated}
                             onFinish={onWizardFinish}
                             datasetSettingsUpdated={datasetSettingsUpdated}/>
        }
    }

    return <Grid container spacing={2} alignItems="baseline" justifyContent="flex-start">
        {showWizard ?
            <Grid item xs={10}>
                {getTestWizard()}
            </Grid> :
            <>
                {test.testSettings.testType === 'Detection' ?
                    <>
                    </>
                    :
                    <>
                        <Grid item xs={7}>
                            <NameEditor variant="outlined"
                                        multiline
                                        fullWidth
                                        maxRows={4}
                                        fieldName="Description"
                                        label="Description"
                                        name={test.description == null ? "" : test.description}
                                        onUpdateName={onDescriptionUpdated}/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h6">
                                Test Configuration
                            </Typography>
                            <TestProfileSummary resources={assets}
                                                tasks={project.settings.tasks}
                                                settings={test.testSettings}
                                                defenses={test.defenses}/>
                        </Grid>
                    </>
                }
            </>
        }
        {test.testSettings.configured &&
            <Grid item xs={12}>
                <StartStopProgress status={status} onClick={onStartStopClick}/>
            </Grid>
        }
        <ReportSection project={project} test={test} reports={reports} reportModel={reportModel}
                       onReportDeleted={onReportDeleted}
                       passPassFailReports={passPassFailReports}
                       onViewTypeChange={onViewTypeChange}
                       onReportNameChanged={onReportNameChanged}
        />
    </Grid>
}

Test.propTypes = {
    /**
     * The test object
     */
    test: PropTypes.object.isRequired,

    /**
     * The project object
     */
    project: PropTypes.object.isRequired,

    /**
     * The assets object
     */
    assets: PropTypes.object.isRequired,

    onWizardFinish: PropTypes.func.isRequired

}

export {
    isTestBusy, getProgressAttribs, getBusyTestRunFromStatus, StartStopProgress, CircularProgressWithLabel,
    useTestRun, useTests
}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useCallback, useContext, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/Share';
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import Accordion from "@mui/material/Accordion";
import {checkError, errOptions, getAPIBase, getAPITokenParameter, getLogger, isAdminUser} from "../util/util";
import {DatasetsApi} from "../api";
import AlertDialog from "../dialogs/AlertDialog";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import CustomDataset from "./CustomDataset";
import AppContext from "../AppContext";
import SelectListDialog from "../dialogs/SelectListDialog";
import {useLoadOrgs, useLoadResources} from "../organizations/Organization";
import DeleteIcon from "../util/TrashIcon"
import SearchAndSort from "../util/SearchAndSort";
import {namedItemFilter, useFilter} from "../util/SearchBox";
import {sortItemsByProps, useSortBox} from "../util/SortBox";
import {SubmitButton} from "../util/SubmitButton";

const log = getLogger("datasets");

const useDatasets = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);

    const getDatasetClasses = useCallback(async (orgId, datasetId) => {
        try {
            const api = new DatasetsApi(conf);
            log.debug("Loading class names for ds "+datasetId);
            let rs = await api.getDatasetClasses(orgId, datasetId);
            log.debug("Loaded class names");
            return rs.data;
        } catch(ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to get dataset classes'+msg,
                    errOptions));
        }
    },[conf, enqueueSnackbar, history]);

    const getDatasets = useCallback(async (orgId) => {
        try {
            log.debug("Loading datasets.");
            const api = new DatasetsApi(conf);
            let ds = await api.getDatasets(orgId);
            log.debug("Datasets loaded.");
            return ds.data;
        } catch(ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to get list of datasets'+msg,
                    errOptions))
        }
        return null;

    },[conf, enqueueSnackbar, history]);

    const getDataset = useCallback(async (orgId, datasetId) => {
        try {
            log.debug("Loading dataset.");
            const api = new DatasetsApi(conf);
            let ds = await api.getDataset(orgId, datasetId);
            log.debug("Dataset loaded.");
            return ds.data;
        } catch(ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to load dataset'+msg,
                    errOptions))
        }
        return null;

    },[conf, enqueueSnackbar, history]);

    const downloadDataset = useCallback(async (orgId, datasetId, downloadProgress) => {
        try {
            log.debug("downloading dataset.");
            const api = new DatasetsApi(conf);
            let r = await api.downloadDataset(orgId, datasetId, {responseType: 'blob', onDownloadProgress: downloadProgress});
            log.debug("Dataset downloaded.");
            return r;
        } catch(ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to download dataset'+msg,
                    errOptions))
        }
        return null;

    },[conf, enqueueSnackbar, history]);

    const getDatasetItem = useCallback(async (datasetId, idx, showError = true, downloadProgress = undefined) => {
        try {
            if (showError)
                log.debug("downloading image "+idx);
            const api = new DatasetsApi(conf);
            let r = await api.getDatasetItem(datasetId, idx, {responseType: 'blob', onDownloadProgress: downloadProgress});
            if (showError)
                log.debug("image downloaded.");
            return r.data;
        } catch(ex) {
            if (showError) {
                checkError(ex, history, (msg) =>
                    enqueueSnackbar('Failed to download dataset item' + msg,
                        errOptions));
            }
            throw ex;
        }
    },[conf, enqueueSnackbar, history]);

    const startDatasetBrowserDownload = useCallback( (orgId, datasetId) => {
        log.debug(`Starting download of dataset ${datasetId} data of org ${orgId}`);
        window.location = getAPIBase(conf) + "datasets/" + datasetId + "/" + orgId +
            "/data?"+getAPITokenParameter(conf);
    }, [conf]);

    const uploadDatasetFile = useCallback(async (orgId, datasetId, type, file, uploadProgress) => {
        try {
            log.debug("uploading dataset file.");
            const api = new DatasetsApi(conf);
            let r = await api.uploadDatasetFile(datasetId,type, orgId, file, {onUploadProgress: uploadProgress});
            log.debug(`Dataset file uploaded, ref: ${r.data}`);
            return r.data;
        } catch(ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to upload dataset'+msg,
                    errOptions))
        }
        return null;
    },[conf, enqueueSnackbar, history]);

    return {getDatasetClasses, getDatasets, getDataset, downloadDataset, startDatasetBrowserDownload, uploadDatasetFile,
        getDatasetItem}
}

/**
 * Hook function used to load dataset list
 * @param onLoaded called when load completes with list as param
 */
const useLoadDatasets = (onLoaded) => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);
    const [datasets, setDatasets] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const {getDatasets} = useDatasets();

    useEffect( () => {
        if (orgId != null) {
            (async () => {
                let ds = await getDatasets(orgId);
                setDatasets(ds);
                if (onLoaded != null && ds != null)
                    onLoaded(ds);
            })();
        }
    },[conf, orgId, enqueueSnackbar, history, onLoaded, getDatasets]);

    const loadDatasets = useCallback( (orgIdVal) => {
        setOrgId(orgIdVal);
    },[]);

    return [{datasets, setDatasets}, loadDatasets, getDatasets];
}

const useLoadDataset = (orgId, datasetId) => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);
    const [dataset, setDataset] = useState(null);

    const loadOrgDataset = useCallback(async (orgId, datasetId) => {
        log.debug(`Loading org ${orgId} dataset id ${datasetId}`);
        const api = new DatasetsApi(conf);
        try {
            let ds = await api.getDataset(orgId, datasetId);
            log.debug("Dataset loaded.");
            setDataset(ds.data);
            return ds.data;
        } catch(ex) {
            checkError(ex, history, (msg) =>
                enqueueSnackbar('Failed to get dataset'+msg,
                    errOptions));
        }
        return null;
    }, [conf, history, enqueueSnackbar]);

    useEffect( () => {
        if (orgId != null && datasetId != null) {
            (async () => {
                await loadOrgDataset(orgId, datasetId);
            })();
        }
    },[conf, orgId, datasetId, enqueueSnackbar, history, loadOrgDataset]);

    return [{dataset}, loadOrgDataset];
}

const SORT_ITEMS = [
    {key: "name", label: "Name"},
    {key: "format", label: "Format"},
];

/**
 * Management of datasets
 * @author Kobus Grobler
 * @component
 */
export default function Datasets() {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openAddDataset, setOpenAddDataset] = useState(false);
    const [openEditDataset, setOpenEditDataset] = useState(false);
    const [datasetId, setDatasetId] = useState(0);
    const [organization, setOrganization] = useState(null);
    const [dataset, setDataset] = useState(null);
    const [datasets, setDatasets] = useState(null);
    const [openOrgDlg, setOpenOrgDlg] = useState(false);
    const [openSelectOrgDlg, setOpenSelectOrgDlg] = useState(false);
    const [filterProps] = useFilter();
    const [sortProps] = useSortBox(SORT_ITEMS[0].key);

    const orgs = useLoadOrgs();
    const {conf, user} = useContext(AppContext);
    const [assets, loadResourcesByOrgId] = useLoadResources();
    const {getDatasets, startDatasetBrowserDownload} = useDatasets();

    const datasetsApi = new DatasetsApi(conf);

    const sortDSList = (dslist) => {
        if (dslist == null)
            return null;
        return dslist.sort((a, b) => {
            if (a.predefined) return 10; return -Math.abs(a.name.localeCompare(b.name));
        });
    }

    useEffect(() => {
        if (user != null && organization == null) {
            if (user.organizations.length === 1) {
                setOrganization(user.organizations[0]);
            } else {
                setOpenSelectOrgDlg(true);
            }
        } else {
            loadResourcesByOrgId(organization.id).finally();
            getDatasets(organization.id).then(ds => {
                setDatasets(sortDSList(ds));
            });
        }
    }, [user, organization, loadResourcesByOrgId, getDatasets]);

    const onDatasetsUpdated = (dslist) => {
        setDatasets(sortDSList(dslist));
    }

    const handleCloseOrgDlg = async (orgIn) => {
        setOpenOrgDlg(false);
        if (orgIn != null) {
            try {
                log.debug("Copying dataset.");
                const api = new DatasetsApi(conf);
                let ds = await api.copyDataset(dataset.id, orgIn.id);
                onDatasetsUpdated([ds.data, ...datasets]);
            } catch (e) {
                checkError(e, history, () =>
                    enqueueSnackbar('Failed to copy dataset.',
                        errOptions))
            }
        }
    }

    const askDeleteAction = (id) => {
        setDatasetId(id);
        setOpenConfirmDelete(true);
    }

    const onEditClosed = () => {
        setOpenEditDataset(false);
    }

    const deleteDataset = (accepted) => {
        if (accepted) {
            datasetsApi.deleteCustomDataset(organization.id, datasetId)
                .then( (r) => {
                    onDatasetsUpdated(r.data);
                    enqueueSnackbar('Dataset deleted');
                })
                .catch((e) => checkError(e, history, () =>
                enqueueSnackbar('Failed to delete dataset',
                    errOptions)));
        }
        setOpenConfirmDelete(false);
    }

    const handleCloseSelectOrgDlg = (org) => {
        setOpenSelectOrgDlg(false);
        if (org != null) {
            setOrganization(org);
        }
    }

    const downloadData = (dsId) => {
        startDatasetBrowserDownload(organization.id, dsId);
        enqueueSnackbar("Download started.");
    }

    const inFilter = (item) => {
        return namedItemFilter(filterProps.filter, item) ||
            item.format.toLowerCase().startsWith(filterProps.filter.toLowerCase());
    }

    const sortItems = (a, b) => {
        return sortItemsByProps(a, b, sortProps);
    }

    return (
        <Grid container item xs={12} md={10} lg={8} xl={6} spacing={1} justifyContent="center">
            {user != null &&
                <SelectListDialog open={openSelectOrgDlg}
                                  onClose={handleCloseSelectOrgDlg}
                                  list={user.organizations}
                                  title="Select Organisation"/>
            }

            <SelectListDialog open={openOrgDlg}
                              onClose={handleCloseOrgDlg}
                              list={orgs}
                              title="Select destination organisation"/>
            <AlertDialog open={openConfirmDelete}
                         title="Delete dataset?"
                         description="This will permanently delete the dataset and its data. Projects that were configured to use this dataset will need to be re-configured."
                         onClose={deleteDataset}/>

            {assets.loaded &&
                <>
                    <OkCancelDialog onClose={() => setOpenAddDataset(false)}
                                    dialogProps={{maxWidth: 'xl'}}
                                    open={openAddDataset}
                                    hideOk={true}
                                    title="Add custom dataset">
                        <CustomDataset onDatasetsUpdated={(dsets, ds) => {
                            setOpenAddDataset(false);
                            onDatasetsUpdated(dsets);
                            setDataset(ds);
                            setOpenEditDataset(true);
                        }}
                                       availableTransforms={assets.transforms}
                                       organization={organization}
                                       assets={assets}
                                       conf={conf}/>
                    </OkCancelDialog>

                    <OkCancelDialog onClose={onEditClosed}
                                    dialogProps={{maxWidth: "xl"}}
                                    open={openEditDataset}
                                    hideOk={true}
                                    hideCancel={true}
                                    title="Edit dataset">
                        <CustomDataset onDatasetsUpdated={(dsets) => {
                            setOpenEditDataset(false);
                            onDatasetsUpdated(dsets);
                        }}
                                       onDatasetUpdated={(ds) => setDataset(ds)}
                                       organization={organization}
                                       dataset={dataset}
                                       availableTransforms={assets.transforms}
                                       assets={assets}
                                       onCancel={() => setOpenEditDataset(false)}
                                       conf={conf}/>
                    </OkCancelDialog>
                </>
            }
            <Grid item xs={12}>
                <SubmitButton
                    title="Add Custom Dataset"
                    onClick={() => setOpenAddDataset(true)}
                    startIcon={<AddIcon/>}/>
            </Grid>
            <Grid item xs={12}>
                <SearchAndSort sortItems={SORT_ITEMS} sortProps={sortProps} filterProps={filterProps}/>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {datasets != null && datasets.filter(inFilter).sort(sortItems).map( (ds) =>
                        <Accordion key={ds.id}>
                            <ListItem>
                            <ListItemText primary={ds.name + ' - ' + ds.format + (ds.predefined ? " (predefined)" : "")}
                                          secondary={ds.description}/>
                            <ListItemSecondaryAction>
                                {!ds.predefined &&
                                    <Tooltip title="Configure dataset">
                                        <IconButton
                                            onClick={() => {
                                                setDataset(datasets.find((d) => ds.id === d.id));
                                                setOpenEditDataset(true);
                                            }}
                                            size="large">
                                            <EditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                {!ds.predefined && isAdminUser(user) &&
                                    <Tooltip title="Share dataset">
                                        <IconButton
                                            onClick={() => {
                                                setDataset(ds);
                                                setOpenOrgDlg(true);
                                            }}
                                            size="large">
                                            <CopyIcon/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                {!ds.predefined &&
                                    <Tooltip title="Delete dataset">
                                        <IconButton
                                            onClick={() => {
                                                askDeleteAction(ds.id);
                                            }}
                                            size="large">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip title="Download dataset">
                                    <IconButton
                                        size="small"
                                        onClick={() => downloadData(ds.id)}>
                                        <DownloadIcon/>
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                            </ListItem>
                        </Accordion>
                    )}
                    </List>
            </Grid>
        </Grid>
    );
}

export {useLoadDatasets, useLoadDataset, useDatasets}

import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import DeleteIcon from "../util/TrashIcon";
import WebHook, {useWebhooks} from "./WebHook";
import ConfirmDelete, {useConfirmDelete} from "../util/ConfirmDelete";

export default function Webhooks({test}) {
    const [openWebhook, setOpenWebhook] = useState(false);
    const [webhooks, setWebhooks] = useState([]);
    const [webhook, setWebhook] = useState(null);
    const {getWebhooks, deleteWebhook} = useWebhooks();
    const [deleteProps, openWithContext] = useConfirmDelete();

    useEffect (() => {
        getWebhooks(test.id)
            .then( (hooks) => {
                setWebhooks(hooks);
            })
            .catch((_e) =>{});
    },[test, getWebhooks]);

    const handleAdd = () => {
        setWebhook(undefined);
        setOpenWebhook(true);
    }

    const onAddedWebhook = (hook) => {
        setOpenWebhook(false);
        setWebhooks([hook, ...webhooks]);
    }

    const onUpdated = (hook) => {
        setOpenWebhook(false);
        setWebhooks(webhooks.map(h => {
            if (h.id !== hook.id)
                return h;
            return hook;
        }));
    }

    const handleDelete = (accepted, hook) => {
        if (accepted) {
            deleteWebhook(hook.id).then( () => {
                setWebhooks(webhooks.filter(h => h.id !== hook.id));
            }).catch((_e) => {});
        }
    }

    return <>
        <ConfirmDelete title="Remove webhook?"
                       description="This will permanently remove the webhook."
                       onClose={handleDelete}
                       confirmDeleteProps={deleteProps}/>
        <Paper variant="outlined" sx={{padding: 2}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{textAlign: "center"}}>Webhooks</Typography>
                    <hr/>
                    <Typography variant="body1" sx={{marginTop: 1, marginBottom: 1}}>
                        Webhooks allow external services to be notified when a test completes or fails.
                        We will send a POST request to each of the URLs you provide.
                    </Typography>
                </Grid>
                {openWebhook ?
                    <Grid item>
                        <WebHook testId={test.id}
                                 onAdded={onAddedWebhook}
                                 onUpdated={onUpdated}
                                 webhook={webhook}
                                 newWebhook={webhook === undefined}
                                 onCancel={() => setOpenWebhook(false)}/>
                    </Grid>:
                    <>
                    <Grid item xs={12}>
                        <Button onClick={handleAdd}
                                variant="contained"
                                color="primary">
                            Add webhook
                        </Button>
                    </Grid>
                        <Grid item xs={12}>
                            <List>
                                {webhooks.map( (hook) =>
                                    <React.Fragment key={hook.id}>
                                        <Accordion>
                                            <ListItem>
                                                <ListItemText primary={`${hook.url} ${hook.enabled ? "" : " (disabled)"}`}>
                                                </ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Tooltip title="Configure webhook">
                                                        <IconButton
                                                            onClick={() => {
                                                                setWebhook(hook);
                                                                setOpenWebhook(true);
                                                            }}
                                                            size="large">
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Remove webhook">
                                                        <IconButton onClick={() => openWithContext(hook)} size="large">
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </Accordion>
                                    </React.Fragment>)
                                }
                            </List>
                        </Grid>
                    </>
                }
            </Grid>
        </Paper>
    </>;
}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import {useCallback, useEffect, useState} from "react";
import Stomp from "stomp-websocket";
import {getLogger} from "./util/util";

const log = getLogger("ws");

let ws = null;

const createWSClient = () => {
    // wss for TLS begins with wss 'wss://localhost:8082/ws/updates'
    // See https://kubernetes.github.io/ingress-nginx/user-guide/miscellaneous/#websockets
    let socketUrl = window.location.protocol === 'http:' ?
        'ws://'+window.location.hostname+':8082' : 'wss://'+window.location.host;
    socketUrl += '/api/v1/ws/updates';
    return new WebSocket(socketUrl);
}

const getWS = () => {
    if (ws == null || retryCnt > 5 || ws.readyState === ws.CLOSED) {
        if (ws != null) {
            ws.close();
        }
        ws  = createWSClient();
        retryCnt = 0;
    }
    return ws;
}

const createStompClient = () => {
    let client = Stomp.over(getWS());
    client.debug = log.trace;
    client.reconnect_delay = 5000;
//    client.heartbeatIncoming = 4000;
//    client.heartbeatOutgoing = 4000;
//    client.connectionTimeout = 5000;
//    client.discardWebsocketOnCommFailure = true;
    return client;
}

let stompClient = {};

const getStompClient = () => {
    stompClient = createStompClient();
    return stompClient;
}

let retryCnt = 0;

const stompConnect = (cb) => {
    getStompClient();
    switch (stompClient.ws.readyState) {
        case stompClient.ws.CLOSING:
            log.debug("Connecting to WS - closing.");
            break;
        case stompClient.ws.OPEN:
        //     log.debug("Connecting to WS - open.");
        //     break;
        // falls through
        case stompClient.ws.CONNECTING:
        //     log.debug("Connecting to WS - connecting.");
        //    break;
        // falls through
        case stompClient.ws.CLOSED: {
            retryCnt++;
            log.debug("Connecting to WS "+ retryCnt+ " state:"+stompClient.ws.readyState);
            stompClient.connect({}, function () {
                log.debug('WS connected.');
                retryCnt = 0;
                cb(true);
            }, function (error) {
                log.error('WS: ' + error);
                cb(false);
            });
            break;
        }
        default:
    }
}

/**
 * Handles server updates
 * @returns {[(function(*): void)]}
 */
const useServerUpdates = (endpoint, eventHandler) => {
    const [updateFunc, setUpdateFunc] = useState(null);
    const [connected, setConnected] = useState(false);
    const callback = useCallback( (func) => {
        setUpdateFunc({f: func})
    },[])

    useEffect( () => {
        return subscribe((val) => {
                setConnected(val);
                log.debug("connection state: "+val);
                if (val === false)
                    eventHandler(null)
            },
            {f: eventHandler}, setUpdateFunc, endpoint);
    }, [connected, updateFunc, endpoint, eventHandler])

    return [() => {}];
}

const subscribe = (disconnectCb, updateFunc, setUpdateFunc, endpoint) => {
    let id = null;
    if (updateFunc != null) {
        try {
            if (stompClient.connected) {
                let subscription = stompClient.subscribe(endpoint, function (rsp) {
                    updateFunc.f(JSON.parse(rsp.body));
                });
                log.debug("Subscribed to "+endpoint+" ID:"+subscription.id);
                return () => {
                    log.debug("Unsubscribing from: " +endpoint+" ID:"+ subscription.id);
                    try {
                        subscription.unsubscribe();
                    } catch (e) {
                        log.error(e);
                    }
                }
            } else {
                log.info("Failed to subscribe, not connected.");
                stompConnect(disconnectCb);
                id = setInterval(() => setUpdateFunc({f: updateFunc.f}), 5000);
            }
        } catch (e) {
            log.error("Failed to subscribe to "+endpoint, e);
        }
    }
    return () => {if (id != null) clearInterval(id);}
}

export {useServerUpdates}

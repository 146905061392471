/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext, useCallback} from 'react';
import {
    useRouteMatch, useHistory
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import {useSnackbar} from "notistack";
import {MetricsApi} from "../api";
import {checkError, errOptions, getLogger, isValidPythonName} from "../util/util";
import AppContext from "../AppContext";
import SelectListDialog from "../dialogs/SelectListDialog";
import ListItemLink from "../util/ListItemLink";
import {Accordion} from "@mui/material";
import {SubmitButton} from "../util/SubmitButton";
import Paper from "../util/Paper"

const log = getLogger("metrics");

const useMetrics = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);

    const getMetrics = useCallback(async () => {

        const api = new MetricsApi(conf);
        try {
            log.debug("Retrieving metrics.")
            const r = await api.getMetrics();
            log.debug(`Retrieved ${r.data.length} metrics.`);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get metrics'+msg, errOptions));
        }
    },[conf, enqueueSnackbar, history]);

    const addMetric = useCallback(async (org, metricName) => {
        const api = new MetricsApi(conf);
        try {
            log.debug("Adding metric.")
            const r = await api.addMetric(org.id, metricName);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to add metric'+msg, errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    const getMetric = useCallback(async (uuid) => {
        const api = new MetricsApi(conf);
        try {
            log.debug("Getting metric.")
            const r = await api.getMetric(uuid);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to get metric'+msg, errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    const updateMetric = useCallback(async (uuid, metric) => {
        const api = new MetricsApi(conf);
        try {
            log.debug(`Updating metric ${uuid}`);
            const r = await api.updateMetric(uuid, metric);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to update metric'+msg, errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    const uploadFile = useCallback(async (uuid, file) => {
        const api = new MetricsApi(conf);
        try {
            log.debug(`Uploading metric ${uuid} file.`);
            const r = await api.uploadMetricFile(uuid, file);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to upload metric'+msg, errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    const downloadFile = useCallback(async (uuid) => {
        const api = new MetricsApi(conf);
        try {
            log.debug(`Downloading metric ${uuid} file.`);
            const r = await api.downloadMetricFile(uuid);
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to retrieve metric script'+msg, errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    const deleteMetric = useCallback(async (uuid) => {
        const api = new MetricsApi(conf);
        try {
            log.debug(`Deleting metric ${uuid}`);
            await api.deleteMetric(uuid);
            log.debug(`Deleting metric ${uuid} done.`);
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to delete metric'+msg, errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    return {getMetrics, addMetric, getMetric, updateMetric, uploadFile, downloadFile, deleteMetric};
}

export default function CustomMetrics() {
    const history = useHistory();
    const match = useRouteMatch();
    const {user} = useContext(AppContext);

    const [metrics, setMetrics] = useState(null);
    const [metricName, setMetricName] = useState("");
    const [nameError, setNameError] = useState("");
    const [openOrgDlg, setOpenOrgDlg] = useState(false);
    const {getMetrics, addMetric} = useMetrics();

    useEffect(() => {
        (async () => {
            setMetrics(await getMetrics());
        })();
    }, [getMetrics]);

    const onAddMetricClick = () => {
        if (!isValidPythonName(metricName.trim())) {
            setNameError("A valid metric name must be specified.");
            return;
        }
        setNameError("");
        if (user.organizations.length > 1) {
            setOpenOrgDlg(true);
        } else {
            onAddMetric(user.organizations[0]).finally();
        }
    }

    const onAddMetric = async (org) => {
        let metric = await addMetric(org, metricName);
        if (metric != null) {
            setMetrics([metric, ...metrics]);
            history.push(`${match.url}/${metric.uid}`);
        }
    }

    const handleCloseOrgDlg = (org) => {
        setOpenOrgDlg(false);
        if (org != null) {
            onAddMetric(org).finally();
        }
    }

    return <Grid container item xs={12} md={10} lg={8} xl={6} justifyContent={"center"} mt={2}>
        {user != null &&
            <SelectListDialog open={openOrgDlg}
                              onClose={handleCloseOrgDlg}
                              list={user.organizations}
                              title="Select Organisation"/>
        }

        <Grid item xs={12} md={10} lg={6} xl={4}>
            <Paper>
                <TextField value={metricName}
                           fullWidth
                           error={nameError.length > 0}
                           helperText={nameError}
                           label="Custom Metric Class Name"
                           onChange={(event) => {
                               if (event.target.value.length === 0 || isValidPythonName(event.target.value)) setMetricName(event.target.value)
                           }}/>
                <SubmitButton
                    title="Add Metric"
                    onClick={onAddMetricClick}
                    startIcon={<AddIcon/>}/>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <div>
            {metrics !== null &&
                <>
                    {metrics.length === 0 &&
                        <Typography variant="subtitle1">
                            There are no custom metrics configured for this organisation.
                        </Typography>
                    }
                    {metrics.length > 0 &&
                        <>
                            <Typography variant="h6" sx={{textAlign: "center"}}>
                                Custom Metrics
                            </Typography>
                            <List>
                                {metrics.map((metric) =>
                                    <Accordion key={metric.uid}>
                                    <ListItemLink href={`#${match.url}/${metric.uid}`}>
                                        <ListItemText primary={metric.name}/>
                                    </ListItemLink>
                                    </Accordion>
                                )
                                }
                            </List>
                        </>
                    }
                </>
            }
            </div>
        </Grid>
    </Grid>
}

CustomMetrics.propTypes = {
};

export {useMetrics}

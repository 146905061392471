/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useCallback, useContext, useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import * as logLib from "loglevel";
import User from './User'
import {OrganizationApi, RegisterApi, UsersApi} from "../api";
import {checkError, errOptions, isAdminUser} from "../util/util";
import AppContext from "../AppContext";
import SearchAndSort from "../util/SearchAndSort";
import {namedItemFilter, useFilter} from "../util/SearchBox";
import {sortItemsByProps, useSortBox} from "../util/SortBox";

const log = logLib.getLogger("users");

const useUsers = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {conf} = useContext(AppContext);

    const getUserLogs = useCallback(async (id) => {
        try {
            log.debug("Getting user logs.");
            const api = new UsersApi(conf);
            let r = await api.getUserLogs(id);
            log.debug("User logs retrieved.");
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to retrieve user logs' + msg, errOptions));
        }
        return [];
    }, [conf, history, enqueueSnackbar]);

    const updateProfile = useCallback(async (request) => {
        try {
            log.debug("Updating user profile.");
            const api = new UsersApi(conf);
            let r = await api.updateProfile(request);
            log.debug("User profile updated.");
            return r.data;
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to update user' + msg, errOptions));
        }
    }, [conf, history, enqueueSnackbar]);

    const sendFeedback = useCallback(async (feedbackStr) => {
        try {
            log.debug("Sending user feedback.");
            const api = new UsersApi(conf);
            enqueueSnackbar('Sending feedback...');
            await api.sendFeedback({feedback: feedbackStr});
            log.debug("Sent feedback.");
            enqueueSnackbar('Feedback sent - thank you!');
        } catch (e) {
            checkError(e, history, (msg) =>
                enqueueSnackbar('Failed to send feedback' + msg, errOptions));
        }
    }, [conf, history, enqueueSnackbar]);

    return {getUserLogs, updateProfile, sendFeedback};
}

const SORT_ITEMS = [
    {key: "name", label: "Name"},
    {key: "email", label: "Email"},
];

/**
 * Shows the list of users on the platform
 * @author Kobus Grobler
 * @component
 */
export default function Users() {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const {user, setUser, conf} = useContext(AppContext);

    const [sendBtEnabled, setSendBtEnabled] = useState(true);
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [users, setUsers] = useState(null);
    const [filterProps] = useFilter();
    const [sortProps] = useSortBox(SORT_ITEMS[0].key);

    const [organizations, setOrganizations] = useState(null);
    const api = new UsersApi(conf);

    const getUserList = useCallback(async () => {
        log.debug("Retrieving user list.");
        const orgApi = new OrganizationApi(conf);
        try {
            const usersApi = new UsersApi(conf);
            let rs = await usersApi.getUsers();
            log.debug("Retrieving organizations.");
            let orgsRs = await orgApi.getOrganizations();
            setUsers(rs.data);
            setOrganizations(orgsRs.data);
        } catch (e) {
            checkError(e, history, () =>
                enqueueSnackbar('Failed to get users', errOptions));
        }
    }, [conf, enqueueSnackbar, history]);

    useEffect(() => {
        getUserList().then();
    }, [getUserList]);

    const onDeleteUser = (toDelete) => {
        if (toDelete.uid === user.uid) {
            enqueueSnackbar('Cannot delete yourself.', errOptions);
            return;
        }

        api.deleteUser(toDelete.id)
            .then(() => {
                setUsers(users.filter((item) => {
                    return toDelete.id !== item.id;
                }));
            })
            .catch((e) => checkError(e, history, () => {
                enqueueSnackbar('Failed to delete user: ' + e.statusText, errOptions);
            }));
    }

    const onMemberUpdate = (userIn, orgs) => {
        api.updateUser(userIn.id, {organizations: orgs})
            .then((userOut) => {
                updateUserInList(userOut.data);
                if (userOut.data.id === user.id) {
                    // edge case of self update - to force refresh
                    setUser(userOut.data);
                }
            })
            .catch((e) => checkError(e, history, (msg) => {
                enqueueSnackbar('Failed to update user' + msg, errOptions);
            }));
    }

    function updateUserInList(update) {
        setUsers(users.map((item) => {
            if (update.id === item.id) {
                return {...update};
            }
            return item;
        }));
    }

    const handleAdminChange = (toChange, val) => {
        if (user.uid === toChange.uid) {
            enqueueSnackbar('Cannot update own your role', errOptions);
            return !val;
        }

        let roleStr = "USER";
        if (val) {
            roleStr += " ADMIN";
        }
        api.updateUser(toChange.id, {roles: roleStr})
            .then((userrsp) => {
                updateUserInList(userrsp.data);
            })
            .catch((e) => checkError(e, history, () => {
                enqueueSnackbar('Failed to update user: ' + e.statusText, errOptions);
            }));
        return val;
    }

    const sendInvite = async (email, name) => {
        const api = new RegisterApi(conf);
        try {
            await api.sendInvite({email: email, name: name})
            enqueueSnackbar('Sent invite');
            await getUserList();
            setSendBtEnabled(true);
        } catch (e) {
            checkError(e, history, () => {
                enqueueSnackbar('Failed to send invite',
                    errOptions);
                setSendBtEnabled(true);
            });
        }
    }

    const onClick = async () => {
        let atIdx = email.indexOf("@");
        if (email.length <= 4 || atIdx === -1 || atIdx === email.length - 1) {
            setError("Invalid email address.");
            return;
        }
        setError("");
        if (name.length === 0) {
            setNameError("User name cannot be empty");
            return;
        }
        setNameError("")
        setSendBtEnabled(false);
        await sendInvite(email, name);
    }

    const sortItems = (a, b) => {
        return sortItemsByProps(a, b, sortProps);
    }

    const inFilter = (item) => {
        return namedItemFilter(filterProps.filter, item) || item.email.toLowerCase().includes(filterProps.filter.toLowerCase())
    }

    return (<Grid container item xs={12} spacing={1} justifyContent="center">
            <Grid item xs={12} md={7} lg={5}>
                <Paper sx={{padding: 1}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Invite New User</Typography>
                            <TextField value={email}
                                       fullWidth
                                       error={error.length > 0}
                                       helperText={error}
                                       label="Email"
                                       onChange={(event) => setEmail(event.target.value)}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <EmailIcon/>
                                               </InputAdornment>
                                           )
                                       }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={name}
                                       fullWidth
                                       error={nameError.length > 0}
                                       helperText={nameError}
                                       label="Name"
                                       onChange={(event) => setName(event.target.value)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={!sendBtEnabled}
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={onClick}
                                startIcon={<SendIcon/>}>
                                Send Invite
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>{/* spacer to force following block in new row*/}
            </Grid>
            <Grid item md={7} lg={5}>
                <Paper sx={{padding: 1}}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="h6">Users</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SearchAndSort sortItems={SORT_ITEMS} sortProps={sortProps} filterProps={filterProps}/>
                        </Grid>
                        {users != null && users.filter(inFilter).sort(sortItems).map((user) => {
                                let admin = isAdminUser(user);
                                return <User key={user.id} user={user}
                                             admin={admin}
                                             organizations={organizations}
                                             onMemberUpdate={onMemberUpdate}
                                             onDeleteUser={onDeleteUser}
                                             onSendInvite={sendInvite}
                                             handleAdminChange={handleAdminChange}/>
                            }
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

Users.propTypes = {}

export {useUsers};

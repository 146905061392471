/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */
import React, { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from "@mui/material/Typography";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import {printRound} from "../util/util";

export default function ImageSamples({items, cols, imwidth, imheight}) {

    const [openZoom, setOpenZoom] = useState(false);
    const [zoomedImage, setZoomedImage] = useState(null);

    const scale = 2;

    const onClickZoomImage = (item) => {
        const element = document.createElement("a");
        element.href = item.img;
        element.download = item.title+".png";
        document.body.appendChild(element);
        element.click();
    }

    const onZoomImage = (event, item) => {
        setZoomedImage({item: item,
            width: event.target.naturalWidth,
            height: event.target.naturalHeight});
        setOpenZoom(true);
    }

    const onCloseDlg = (_item) => {
        setOpenZoom(false);
    }

    return (<div>
        <OkCancelDialog open={openZoom}
                        title={zoomedImage == null ? "" :`Click to download ${zoomedImage.item.title}.png`}
                        dialogProps={{fullWidth: true, maxWidth: 'lg'}}
                        onClose={onCloseDlg}
                        hideCancel={true}
                        item={zoomedImage}>
            <span>
                {zoomedImage != null &&
                    <>
                    <img
                        onClick={() => onClickZoomImage(zoomedImage.item)}
                        src={zoomedImage.item.img}
                        height={window.innerHeight*0.7}
                        alt={zoomedImage.item.title}/>
                        {zoomedImage.item.attacked_metric != null &&
                            <Typography variant="body2" component="div">
                                {zoomedImage.item.metric_name} benign : {printRound(zoomedImage.item.benign_metric, 2)},
                                perturbed: {printRound(zoomedImage.item.attacked_metric, 2)}
                            </Typography>
                        }
                    </>
                }
            </span>
        </OkCancelDialog>
        <ImageList rowHeight={imheight*scale} gap={1}
                   style={{width: imwidth*cols*scale+5, height: imheight*scale*3}}
                   cols={cols}>
            {items!= null && items.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1}>
                    <img
                         onClick={(evt) => onZoomImage(evt, item)}
                         style={{width: imwidth*scale, height: imheight*scale}}
                     src={item.img} alt={item.title} />
                </ImageListItem>
            ))}
        </ImageList>
    </div>)
}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import Tooltip from "@mui/material/Tooltip";
import React from "react";
import Box from "@mui/material/Box";
import {ModelResponseFrameworkTypeEnum} from "../api";
import SvgOnnx from "../icons/SvgOnnx";
import SvgPytorch from "../icons/SvgPyTorch";

const modelIndicator = {
        display: "inline-block",
        minWidth: "1.9rem",
        minHeight: "1.9rem",
        maxWidth: "1.9rem",
        maxHeight: "1.9rem",
        height: "1.9rem",
        width: "1.9rem",
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "bold",
        paddingTop: "4px",
}

/**
 * Displays a project type icon
 * @param model the model response
 * @param verified if the model is verified
 * @param style optional style
 * @returns {JSX.Element} the component
 * @constructor
 */
export default function ModelTypeIndicator({model = {}, verified = model.verified, ...props}) {

    const GetFrameworkType = () => {
        return model.frameworkType == null ? "" : model.frameworkType;
    }

    const GetIconFromType = () => {
        switch (GetFrameworkType()) {
            case ModelResponseFrameworkTypeEnum.PyTorch:
                return <><SvgPytorch/></>;
            case ModelResponseFrameworkTypeEnum.Keras:
                return <><span style={{color: "white", backgroundColor: "#C20005", padding: "2px"}}>K</span></>;
            case ModelResponseFrameworkTypeEnum.Onnx:
                return <><SvgOnnx/></>;
            default:
                return <></>;
        }
    }

    return <Tooltip title={`${(verified ? "Verified "+ GetFrameworkType()+ " model": GetFrameworkType() + " not verified")}`}>
        <Box component={"span"}
             sx={[modelIndicator, verified && {
             }, !verified && {
             }]}
            {...props}>
            {GetIconFromType()}
        </Box>
    </Tooltip>
}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useContext, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import CopyIcon from "@mui/icons-material/FileCopy";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {TestApi} from "../api";
import {checkError, convertUserValuesToParams, errOptions, getLogger, modelFrameworkHasGradients} from "../util/util";
import AppContext from "../AppContext";
import DatasetSettings from "../datasets/DatasetSettings";
import AttackDefenseSelector from "./AttackDefenseSelector";
import HPOSetting from "./HPOSetting";
import RobustnessSettings, {EPS_DEF, AR_DEF} from "./RobustnessSettings"
import TuneParamsDialog from "./TuneParamsDialog";
import MetricSelection from "./MetricSelection";
import PassFailCriteria from "./PassFailCriteria";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import NameEditor from "../util/NameEditor";
import {useProfiles} from "../profiles/TestProfiles";
import Webhooks from "../webhooks/Webhooks";
import {SubmitButton} from "../util/SubmitButton";
import Paper from "../util/Paper";
import {BackButton} from "../util/Buttons";

const log = getLogger("testwizard");

function DataStorage({task, settings, onSettingsChange}) {

    return <>
        <Paper variant="outlined">
            <Typography variant='h6' style={{textAlign: 'center'}}>Generated Data Storage</Typography>
            <Grid item xs={12} container direction="column"
          alignItems="flex-start"
          justifyContent="center">
        <Grid item>
            <FormControlLabel
                control={
                    <Switch
                        name="storeIntermediateData"
                        color="primary"
                        onChange={(e) => onSettingsChange(e)}
                        checked={settings.storeIntermediateData}
                    />}
                label="Store intermediate data generated during tests "
            />
        </Grid>
        <Grid item>
            <FormControlLabel
                control={
                    <Switch
                        name="storeCompositeImages"
                        color="primary"
                        onChange={(e) => onSettingsChange(e)}
                        checked={settings.storeCompositeImages != null ? settings.storeCompositeImages : true}
                    />}
                label="Store composite images"
            />
        </Grid>
                {/*{task === 'segmentation' &&*/}
                {/*    <Grid item>*/}
                {/*        <FormControlLabel*/}
                {/*            control={*/}
                {/*                <Switch*/}
                {/*                    name="overlayImageOnSegMap"*/}
                {/*                    color="primary"*/}
                {/*                    onChange={(e) => onSettingsChange(e)}*/}
                {/*                    checked={settings.overlayImageOnSegMap != null ? settings.overlayImageOnSegMap : true}*/}
                {/*                />}*/}
                {/*            label="Overlay original image on segmentation map"*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*}*/}
    </Grid>
        </Paper>
    </>
}

function IntegrationWizardStep({test}) {
    return <>
        <Box mt={2}><Webhooks test={test}/></Box>
    </>
}

/**
 * Test configuration wizard.
 * @author Kobus Grobler
 * @component
 */
export default function TestSetupWizard({step = 0, project, test, settings = test.testSettings,
                                            tasks = project.settings.tasks, testUpdated, datasetSettingsUpdated,
                                            assets, onFinish, onAttackAdded, onRemoveFilter, onUpdateTest,
                                            onSaveSettings, onAddDefense, onRemoveDefense}) {
    const {conf} = useContext(AppContext);
    const {addProfile} = useProfiles();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [testParam, setTestParam] = useState(null);
    const [testParamDef, setTestParamDef] = useState(null);
    const [openTuneParam, setOpenTuneParam] = useState(false);
    const [openTestProfileName, setOpenTestProfileName] = useState(false);
    const [profileName, setProfileName] = useState("");
    const [activeStep, setActiveStep] = useState(step);
    const inProfile = project == null;

    const steps = inProfile ? ['Profile configuration', 'Analysis'] :
        ['Data loader', 'Test configuration', 'Analysis', 'Integration'];

    const [completed] = useState([settings.configured, settings.configured, settings.configured, settings.configured]);

    const api = new TestApi(conf);

    const saveSettings = async (s) => {
        if (onSaveSettings != null) {
            onSaveSettings(s);
        } else {
            try {
                let t = await api.updateTest(test.id, {testSettings: s})
                testUpdated(t.data);
            } catch (e) {
                checkError(e, history, (msg) => {
                    enqueueSnackbar('Failed to update test '+msg, errOptions);
                });
            }
        }
    }

    const handleSettingsChange = e => {
        settings[e.target.name] = !settings[e.target.name]
        saveSettings(settings).then();
    }

    const onSetARParams = () => {
        onSetParams(AR_DEF, settings.arParams);
    }

    const onSetEPSParams = () => {
        onSetParams(EPS_DEF, settings.epsExplorationParams);
    }

    const onSetParams = (def, params, task) => {
        let paramStr = {
            parameters: JSON.stringify(params)
        }
        def.task = task; // temporary store task in def (needed for profile editing)
        setTestParam(paramStr);
        setTestParamDef(def);
        setOpenTuneParam(true);
    }

    function handleCloseTuneDlg(values) {
        setOpenTuneParam(false);
        if (values != null) {
            if (testParamDef.name === EPS_DEF.name) {
                settings.epsExplorationParams = convertUserValuesToParams(values, testParamDef.params);
            } else if (testParamDef.name === AR_DEF.name) {
                settings.arParams = convertUserValuesToParams(values, testParamDef.params);
            } else {
                let metric = settings.metrics[testParamDef.task].find(m => m.name === testParamDef.name);
                if (metric != null) {
                    metric.params = convertUserValuesToParams(values, testParamDef.params);
                } else {
                    log.error("Metric not found:"+testParamDef.name);
                }
            }
            log.debug("Saving params");
            saveSettings(settings).then();
        }
    }

    function analysisStep() {
        return <><Grid item xs={12} spacing={1} container alignItems="stretch" justifyContent={"center"} mt={2}>
            {tasks.map(task =>
                <Grid item xs={9} key={task}>
                    <MetricSelection task={task}
                                     settings={settings}
                                     metricDefs={assets.availableMetrics}
                                     onSetParams={onSetParams}
                                     saveSettings={saveSettings}/>
                </Grid>
            )}
            {!inProfile &&
                <>
                <Grid item xs={9}>
                    <PassFailCriteria task={tasks[0]}
                                      settings={settings}
                                      metricDefs={assets.availableMetrics}
                                      saveSettings={saveSettings}/>
                </Grid>
                <Grid item xs={9}>
                <DataStorage task={tasks[0]}
                settings={settings}
                onSettingsChange={handleSettingsChange}/>
                </Grid>
                {/*<Grid item xs={9}>*/}
                {/*<RobustnessSettings settings={settings}*/}
                {/*onSetEPSParams={onSetEPSParams}*/}
                {/*onSetARParams={onSetARParams}*/}
                {/*onSettingsChange={handleSettingsChange}/>*/}
                {/*</Grid>*/}
                </>
            }
        </Grid>
        </>
    }

    function testConfigStep() {
        const attackAdded = async (_testIn, filter) => {
            if (onAttackAdded != null) {
                onAttackAdded(filter, test);
            } else {
                try {
                    let rs = await api.addFilter(test.id, filter);
                    testUpdated(rs.data);
                } catch (e) {
                    checkError(e, history, () =>
                        enqueueSnackbar('Failed to add attack',
                            errOptions));
                }
            }
        }

        const removeFilter = async (filter) => {
            if (onRemoveFilter != null) { // check override
                onRemoveFilter(filter, test);
            } else {
                try {
                    let rs = await api.removeFilter(test.id, filter.id)
                    testUpdated(rs.data);
                } catch (e) {
                    checkError(e, history, (msg) =>
                        enqueueSnackbar('Failed to remove ' + filter.name+' ('+msg+')',
                            errOptions));
                }
            }
        }

        const updateTest = async (_testIn, updateRequest) => {
            if (onUpdateTest != null) {
                onUpdateTest(updateRequest, test);
            } else {
                try {
                    let rs = await api.updateTest(test.id, updateRequest);
                    testUpdated(rs.data);
                } catch (e) {
                    checkError(e, history, (msg) =>
                        enqueueSnackbar('Failed to update test' + msg,
                            errOptions));
                }
            }
        }

        const removeDefense = (dIn) => {
            if (onRemoveDefense != null) {
                onRemoveDefense(dIn, test);
            } else {
                api.removeDefense(test.id, dIn.id)
                    .then((a) => {
                        testUpdated(a.data);
                    })
                    .catch((e) => checkError(e, history, (msg) =>
                        enqueueSnackbar('Failed to remove defense' + msg,
                            errOptions)));
            }
        }

        const addDefense = async dIn => {
            if (test.defenses.find((d) => d.name === dIn.name) !== undefined) {
                enqueueSnackbar('Defense already added.');
                return;
            }
            let dr = {name: dIn.name, parameters: "{}"};
            if (onAddDefense != null) {
                onAddDefense(dr, test);
            } else {
                try {
                    let t = await api.addDefense(test.id, dr);
                    testUpdated(t.data);
                } catch (e) {
                    checkError(e, history, (msg) =>
                        enqueueSnackbar('Failed to add defense' + msg,
                            errOptions));
                }
            }
        }

        return <>
            <Grid spacing={1} container alignItems="stretch" justifyContent={"center"} mt={2}>
                {project != null && false && // re-enable when HPO is ready in new pipeline
                    <Grid item xs={12}>
                    <HPOSetting test={test}
                                project={project}
                                saveSettings={saveSettings}
                                conf={conf}/>
                </Grid>
                }
                <Grid item xs={12} m={1}>
                    <AttackDefenseSelector conf={conf}
                                           test={test}
                                           tasks={tasks}
                                           onAttackAdded={attackAdded}
                                           onRemoveFilter={removeFilter}
                                           onUpdateTest={updateTest}
                                           onAddDefense={addDefense}
                                           onRemoveDefense={removeDefense}
                                           assets={assets}
                                           testUpdated={testUpdated}
                                           hasGradients={project != null ?
                                               modelFrameworkHasGradients(project.model.frameworkType) : true}
                    />
                </Grid>
            </Grid>
        </>
    }

    function dataLoaderStep() {
        return <Box mt={2}><DatasetSettings test={test}
                                maxItemCount={project.organization.settings.maxItemCount}
                                orgId={project.organization.id}
                                datasetId={project.settings.datasetId}
                                settingsUpdated={datasetSettingsUpdated}
                                testUpdated={testUpdated}
        /></Box>
    }

    function isStepComplete(stp) {
        return completed[stp];
    }

    function getStepContent(stp) {
        if (inProfile) {
            switch (stp) {
                case 0:
                    return testConfigStep();
                case 1:
                    return analysisStep();
                default:
                    return 'Unknown step';
            }
        } else {
            switch (stp) {
                case 0:
                    return dataLoaderStep();
                case 1:
                    return testConfigStep();
                case 2:
                    return analysisStep();
                case 3:
                    return <IntegrationWizardStep test={test}/>;
                default:
                    return 'Unknown step';
            }
        }
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            if (!settings.configured) {
                settings.configured = true;
                saveSettings(settings).then();
            }
            onFinish();
        } else {
            completed[activeStep] = true;
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleStep = (idx) => () => {
        if (idx > 0) {
            if (completed[idx-1])
                setActiveStep(idx);
        } else {
            setActiveStep(idx);
        }
    }

    const copyTestConfig = async (name) => {
        let profile = await addProfile(project.organization.id, test.id, name);
        if (profile != null) {
            history.replace("/main/profiles/" + profile.id);
        }
    }

    const testProfileNameClosed = (accepted) => {
        setOpenTestProfileName(false);
        if (accepted) {
            copyTestConfig(profileName).finally();
        }
    }

    return (<Grid container>
        {testParamDef != null &&
            <TuneParamsDialog onClose={handleCloseTuneDlg}
                              open={openTuneParam}
                              paramDef={testParamDef}
                              item={testParam}
            />
        }
        <OkCancelDialog title="Please provide a profile name"
                        open={openTestProfileName}
                        onClose={testProfileNameClosed}>
        <p>
            <NameEditor
                fullWidth
                hiddenLabel={false}
                label="Profile Name"
                name={profileName}
                onChange={(name)=> setProfileName(name)}
                onUpdateName={(id, name)=> setProfileName(name)}/>
        </p>
        </OkCancelDialog>
        <Grid item xs={12}>
            <Typography>Test Configuration</Typography>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, idx) => {
                        const stepProps = {};
                        return (
                            <Step key={label} {...stepProps} completed={isStepComplete(idx)}>
                                <StepButton onClick={handleStep(idx)}>
                                    {label}
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid container>
                <Grid container spacing={1} alignItems="stretch">
                    <Grid item xs={12} style={{minHeight:"50vh", overflowY: "auto"}}>
                        {getStepContent(activeStep)}
                    </Grid>
                    <Grid container item justifyContent="center">
                        {activeStep > 0 &&
                            <BackButton onClick={handleBack}/>
                        }

                        <SubmitButton
                            title={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            onClick={handleNext}
                            startIcon={activeStep === steps.length - 1 ? <DoneIcon/> : <></>}/>
                        {!inProfile && activeStep === steps.length - 1 && isStepComplete(activeStep) &&
                            <Tooltip title="Make this test configuration available to other projects">
                                <SubmitButton
                                    startIcon={<CopyIcon/>}
                                    onClick={()=> setOpenTestProfileName(true)}>
                                    Save Profile
                                </SubmitButton>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>);
}

TestSetupWizard.propTypes = {
    /**
     * The test instance
     */
    test: PropTypes.object.isRequired,

    /**
     * The project instance
     */
    project: PropTypes.object,

    /**
     * The organizations assets
     */
    assets: PropTypes.object.isRequired,

    /**
     * The finish handler
     */
    onFinish: PropTypes.func.isRequired,

    /**
     * The test update handler
     */
    testUpdated: PropTypes.func.isRequired,

    datasetSettingsUpdated: PropTypes.func,

    /**
     * Optional starting step (defaults to 0)
     */
    step: PropTypes.number
}

export {DataStorage}

/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Plot from "react-plotly.js/react-plotly";
import PropTypes from "prop-types";
import {getLogger} from "../util/util";

const log = getLogger("opt.plot");

/**
 * This component plots the HPO optimization
 *
 * @author Kobus Grobler
 * @component
 */
export default function OptimizationPlot(props) {

    const MODE = 'lines+markers';

    const [sortKeys, setSortKeys] = React.useState([]);
    const [sortKey, setSortKey] = React.useState("");

    const [plots, setPlots] = React.useState(() => getPlots(props.report, ""));

    function getParameterPlot(x, data, name, group, dashType, yAxis) {
        return {
            x: x,
            y: data,
            yaxis: yAxis,
            type: 'scatter',
            mode: MODE,
            name: name,
            line: {dash: dashType},
            legendgroup: group,
            legendgrouptitle: { text: group}
        }
    }

    function sortData(data, index) {
        Object.keys(data).forEach(key => {
            let d = data[key];
            let sorted = new Array(d.length);
            for (let i = 0; i < d.length; i++) {
                sorted[i] = d[index[i]];
            }
            data[key] = sorted;
        });
    }

    function getHPOData(report, sortKey) {
        let data = report.optData;
        let metrics = report.optMetricData;
        if (sortKey !== "" && metrics[sortKey] != null) {
            log.debug("Sorting data by "+ sortKey);
            data = {...report.optData}; // clone before sort
            metrics = {...report.optMetricData};
            let attacked = metrics[sortKey];
            let index = [...Array(attacked.length).keys()];
            index.sort((el1, el2) => {
                return attacked[el2] - attacked[el1];
            });
            sortData(data, index);
            sortData(metrics, index);
        }
        return [data, metrics];
    }

    function getPlots(report, sortKey) {
        const [data, metrics] = getHPOData(report, sortKey);
        let x = [...Array(data[Object.keys(data)[0]].length).keys()];
        let plots = [];
        Object.keys(data).forEach(key => {
            plots.push(getParameterPlot(x, data[key], key, "parameters", "dot", "y"));
        });
        let sortKeys = Object.keys(metrics);
        setSortKeys(sortKeys);
        sortKeys.forEach(key => {
            plots.push(getParameterPlot(x, metrics[key], key, "metrics","solid", "y2"));
        });
        return plots;
    }

    const onSortKeyChange = (e) => {
        setSortKey(e.target.value);
        setPlots(getPlots(props.report, e.target.value));
    }

    return (<Grid container direction="column" alignContent="center">
            <Grid item xs={12}>
                <TextField
                    select
                    SelectProps={{
                        native: true,
                    }}
                    label="Sort by"
                    value={sortKey}
                    onChange={onSortKeyChange}>
                    <option value={""}>
                    </option>
                    {sortKeys.map((key, idx) =>
                        <option key={idx} value={key}>
                            {key}
                        </option>
                    )}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Plot
                    data={plots}
                    layout={{title: props.title,
                        legend: {
                            x: 1.15,
                            traceorder: "grouped",
                            tracegroupgap: 10,
                            groupclick: "toggleitem"
                        },
                        yaxis: {
                            title: "parameter value",
                            side: "left",
                            rangemode: 'nonnegative'
                        },
                        yaxis2: {
                            side: "right",
                            overlaying: 'y',
                            anchor: 'x',
                            title: "metric"
                        }
                    }}
                    config={{displaylogo: false}}
                />
            </Grid>
        </Grid>
    );
}

OptimizationPlot.propTypes = {
    /**
     * The report instance
     */
    report: PropTypes.object.isRequired,

    /**
     * The plot title
     */
    title: PropTypes.string.isRequired
}

import React from 'react';
import {Configuration} from "./api";
const AppContext = React.createContext({
    conf: new Configuration(),
    setConf: () => {},
    user: {},
    setUser: () => {},
    basePath: window.location.protocol + "//" + window.location.host
});
export default AppContext;


import {SvgIcon, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {PlatformApi} from "../api";
import AppContext from "../AppContext";
import * as logLib from "loglevel";
import {isAdminUser, minutesToTimeStr} from "../util/util";
import {usePlatform} from "./Platform";
import {useServerUpdates} from "../ServerUpdates";
import {useOrgs} from "../organizations/Organization";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";

const log = logLib.getLogger("platforminfo");

function StopIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="currentColor" d="M12 2a9 9 0 0 0-9 9a8.99 8.99 0 0 0 4 7.47V22h2v-3h2v3h2v-3h2v3h2v-3.54c2.47-1.65 4-4.46 4-7.46a9 9 0 0 0-9-9m-4 9a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m8 0a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m-4 3l1.5 3h-3l1.5-3Z"/>
        </SvgIcon>
    );
}

/**
 * Displays info on the platform status
 *
 * @returns {JSX.Element}
 * @constructor
 * @author Kobus Grobler
 */
export default function PlatformInfo() {
    const {conf, user} = useContext(AppContext);
    const history = useHistory();
    const {getQueueInfo} = usePlatform();

    const [queueInfo, setQueueInfo] = useState(null);
    const {getOrg} = useOrgs();
    const [timeStr, setTimeStr] = useState(null);

    const getUserOrg = useCallback(async () => {
        if (user.organizations != null && user.organizations.length === 1) {
            let org = user.organizations[0];
            if (org.lastUpdate == null || Date.now() - org.lastUpdate > 30000) {
                org = await getOrg(org.id);
                if (org != null) {
                    org.lastUpdate = Date.now();
                    user.organizations[0] = org;
                }
            }
            return org;
        }
    },[user, getOrg]);

    const onPlatformUpdate = useCallback( async (updates) => {
        if (user == null) {
            return
        }
        if (updates == null) {
            log.info("WS failed - logging out.");
            history.replace("/login");
            return
        }
        if (!isAdminUser(user)) {
            setQueueInfo(updates);
        }
        const org = await getUserOrg();
        if (org != null && org.settings != null && org.settings.maxTimeAllocated > 0) {
            setTimeStr(`Processing Time: ${minutesToTimeStr(org.timeUsed / (1000 * 60))} of ${minutesToTimeStr(org.settings.maxTimeAllocated)}`);
        }
    },[user, getUserOrg]);

    useServerUpdates('/topic/platform/updates', onPlatformUpdate);

    useEffect(() => {
        // if (user != null)
        //     setUpdateFunc(onPlatformUpdate);
        if (isAdminUser(user)) {
            let stopped = false;
            async function getq() {
                try {
                    let r = await getQueueInfo();
                    if (r != null)
                        setQueueInfo(r);
                } finally {
                    if (!stopped) {
                        setTimeout(getq, 2000);
                    }
                }
            }
            const id = setTimeout(getq, 2000);
            return () => {
                stopped = true;
                clearTimeout(id);
            };
        }
    }, [getQueueInfo, onPlatformUpdate, user]);

    const hasRunningWorkers = () => {
        return queueInfo.busyCount > 0;
    }

    const handleStopWorkers = async () => {
        if (queueInfo != null && queueInfo.workers != null) {
            const platFormApi = new PlatformApi(conf);
            try {
                for (let w of queueInfo.workers) {
                    await platFormApi.cancelWorker(w.id);
                }
            } catch (e) {
                log.error(e);
            }
        }
    }

    return <>
        {queueInfo != null &&
            <>
                <Typography variant="body1">
                    {timeStr} &bull; Pending jobs: {queueInfo.messageCount}&nbsp;&bull;
                Available workers: {queueInfo.busyCount < queueInfo.consumerCount ?
                <>{queueInfo.consumerCount-queueInfo.busyCount}/{queueInfo.consumerCount}</> :
                <>0/{queueInfo.consumerCount}</>
                    }
                </Typography>
                {hasRunningWorkers() &&
                    <Tooltip title="Stop all running workers">
                        <IconButton
                            sx={{color: "white"}}
                            aria-label="stop workers"
                            onClick={handleStopWorkers}
                            size="large">
                            <StopIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                }
            </>
        }
    </>;
}


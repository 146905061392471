/*
 * Copyright (C) 2021 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useState, useEffect, useContext} from 'react';
import {useRouteMatch, Switch, Route} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItemText from "@mui/material/ListItemText";
import ListItemLink from "../util/ListItemLink";
import {getLogger} from "../util/util";
import AppContext from "../AppContext";
import OkCancelDialog from "../dialogs/OkCancelDialog";
import ClassMapping from "./ClassMapping";
import Accordion from "@mui/material/Accordion";

const log = getLogger("mappings");

const getClassMappingFiles = (organizations) => {
    let mps = [];
    if (organizations != null) {
        organizations.forEach(org => {
            org.settings.classMappingFiles.forEach(mf => {
                mps.push({orgId: org.id, orgName: org.name, fileName: mf.fileName, ref: mf.ref});
            });
        });
    } else {
        log.error("organizations is null");
    }
    return mps;
}

/**
 * Retrieves and shows the list of class mappings
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClassMappings() {
    const match = useRouteMatch();
    const {user} = useContext(AppContext);

    const [mappings, setMappings] = useState([]);
    const [openClassView, setOpenClassView] = useState(false);
    const [classNames] = useState([]);

    useEffect(() => {
        log.debug("get mappings");
        setMappings(getClassMappingFiles(user.organizations));
    }, [user.organizations]);

    return <Switch>
        <Route exact path={`${match.path}`}>
    <Grid container item xs={12} md={10} lg={8} xl={6} justifyContent={"center"} mt={2}>
        <OkCancelDialog open={openClassView}
                        title="Class mapping"
                        dialogProps={{fullWidth: true, maxWidth: 'md'}}
                        onClose={() => {
                            setOpenClassView(false);
                        }}
                        hideCancel={true}>
            <span>
                {Object.keys(classNames).map((classId, idx) =>
                    <div key={idx}>{classId}: {classNames[classId]}</div>
                )}
            </span>
        </OkCancelDialog>
        <Grid item xs={12}>
                    {mappings.length === 0 &&
                        <Typography variant="subtitle1">
                            There are no class mappings files
                            - upload new class mappings files or use the class mapping estimation option.
                        </Typography>
                    }
                    {mappings.length > 0 &&
                        <>
                            <Typography variant="h6" style={{textAlign: "center"}}>
                                Class mapping files
                            </Typography>
                            <List>
                                {mappings.map((mapping, idx) =>
                                    <Accordion key={idx}>
                                    <ListItemLink href={`#${match.url}/${mapping.orgId}/${mapping.ref}`}>
                                        <ListItemText style={{marginLeft: "0.5em"}}
                                                      primary={`${mapping.orgName} - ${mapping.fileName}`}>
                                        </ListItemText>
                                    </ListItemLink>
                                    </Accordion>
                                )
                                }
                            </List>
                        </>
                    }
        </Grid>
    </Grid>
        </Route>
        <Route path={`${match.path}/:orgId/:ref`}>
            <ClassMapping/>
        </Route>
    </Switch>
}

ClassMappings.propTypes = {

};

export {getClassMappingFiles}

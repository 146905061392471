/*
 * Copyright (C) 2022 by NavInfo Europe B.V. The Netherlands - All rights reserved
 * Information classification: Confidential
 * This content is protected by international copyright laws.
 * Reproduction and distribution is prohibited without written permission.
 */

import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import TuneIcon from "@mui/icons-material/Tune";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {getLogger, convertUserValuesToParams} from "../util/util";
import TuneParamsDialog from "./TuneParamsDialog";
import {useOrgs} from "../organizations/Organization";
import SectionHeading from "../util/SectionHeading";
import Paper from "../util/Paper";

const log = getLogger("hposetting");

/**
 * Displays and allows configuration of HPO
 * @author Kobus Grobler
 * @component
 */
export default function HPOSetting({project, test, saveSettings}) {
    const {getHPO} = useOrgs();
    const [openTuneParam, setOpenTuneParam] = useState(false);
    const [hpoAlgo, setHpoAlgo] = useState(0);
    const [testParam, setTestParam] = useState(null);
    const [testParamDef, setTestParamDef] = useState(null);
    const [hpoDefs, setHpoDefs] = useState(null);
    const [settings, setSettings] = useState(test.testSettings);

    useEffect(() => {
        setSettings(test.testSettings);
    },[test]);

    useEffect(() => {
        getHPO(project.organization.id).then( defs => {
            if (defs != null) {
                setHpoDefs(defs);
                setDefById(defs, settings.hpoId);
            }
        });
        }, [getHPO, project.organization.id, settings.hpoId]
    );

    useEffect(() => {
        if (settings.hpoId != null) {
            setHpoAlgo(settings.hpoId);
            if (hpoDefs != null) {
                setDefById(hpoDefs, settings.hpoId);
            } else {
                // still pending from use effect above.
                log.debug("hpo defs not retrieved yet");
            }
        }
    },[hpoDefs, settings.hpoId]);

    function setDefById(defs, id) {
        if (id != null && id !== 0) {
            let def = {...defs.find(item => item.id === id)};
            if (def.params == null) {
                def.params = [];
            }
            if (!def.params.find( p => p.name === "optimizer_budget")) {
                def.params.push({
                    "displayed": true,
                    "name": "optimizer_budget",
                    "type": "int",
                    "def": 10
                });
            }
            setTestParamDef(def);
        } else { // if algo id not defined yet, use first algo id
            if (defs != null && defs.length > 0)
                setHpoAlgo(defs[0].id);
        }
    }

    const handleSettingsChange = (e) => {
        settings[e.target.name] = !settings[e.target.name]
        if (settings.hpoId == null || settings.hpoId === 0) {
            // algo setting not saved in settings yet, but required by server when hpo is on
            settings.hpoId = hpoAlgo;
        }
        setSettings(settings);
        saveSettings(settings);
    }

    const onSetHPOParams = () => {
        let hpo = {
            parameters: JSON.stringify(settings.hpoParams)
        }
        setTestParam(hpo);
        setOpenTuneParam(true);
    }

    const onAlgoChange = (e) => {
        settings.hpoParams = {};
        settings.hpoId = e.target.value;
        saveSettings(settings);
        setHpoAlgo(settings.hpoId);
        setDefById(hpoDefs, settings.hpoId);
    }

    function handleCloseTuneDlg(values) {
        setOpenTuneParam(false);
        if (values != null) {
            settings.hpoParams = convertUserValuesToParams(values, testParamDef.params);
            log.debug("saving hpo params "+JSON.stringify(settings.hpoParams));
            saveSettings(settings);
        }
    }

    return <Grid item container spacing={1} justifyContent="center">
        {testParamDef != null &&
        <TuneParamsDialog onClose={handleCloseTuneDlg}
                          open={openTuneParam}
                          paramDef={testParamDef}
                          item={testParam}
        />
        }
        <Paper variant="outlined">
            <SectionHeading>
                Hyper-Parameter Optimisation
            </SectionHeading>
            {hpoDefs != null &&
            <>
                <Grid item xs={12} container direction="column" alignItems="flex-start">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <>
                                    <Switch
                                        name="optimalParameterization"
                                        color="primary"
                                        onChange={handleSettingsChange}
                                        checked={settings.optimalParameterization}
                                    />
                                </>
                            }
                            label="Enable optimal parameterisation"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch disabled={!settings.optimalParameterization}
                                    name="batchWiseHPO"
                                    color="primary"
                                    onChange={handleSettingsChange}
                                    checked={settings.batchWiseHPO}
                                />}
                            label="Run batch-wise"
                        />
                    </Grid>

                    <Grid item container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <TextField disabled={!settings.optimalParameterization}
                                        select
                                       style={{minWidth: "25ch"}}
                                       SelectProps={{
                                           native: true,
                                       }}
                                       label="Select HPO Algorithm"
                                       value={hpoAlgo}
                                       onChange={onAlgoChange}>
                                {hpoDefs.map((def) =>
                                    <option key={def.id} value={def.id}>
                                        {def.name}
                                    </option>
                                )}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Adjust parameters">
                                <span>
                                <IconButton
                                    disabled={!settings.optimalParameterization}
                                    size="small"
                                    onClick={onSetHPOParams}>
                                    <TuneIcon/>
                                </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </>
            }
        </Paper>
    </Grid>
}

HPOSetting.propTypes = {
    project: PropTypes.object.isRequired,

    test: PropTypes.object.isRequired,

    saveSettings: PropTypes.func.isRequired
}
